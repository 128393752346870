import React, { useCallback, useState } from "react";
import * as S from "./styles";
import { Modal } from "../../../../components/Modal";

const UploadInstructions = () => {
  const [modalVisibility, setModalVisibility] = useState(false);

  const handleToggleInstructionsModal = useCallback(() => {
    setModalVisibility((prevState) => !prevState);
  }, []);

  const renderMainInstructionsList = () => (
    <ol>
      <li>O arquivo deve utilizar ponto e vírgula (;) com separador; </li>
      <li>O tamanho máximo aceito é de 10MB;</li>
      <li>
        É obrigatório definir as colunas "Código de barras", “Nome do produto”,
        "Preço" e "Quantidade em Estoque";{" "}
      </li>
      <li>
        Caso o preço seja igual a zero, o produto será definido como
        indisponível.
      </li>
    </ol>
  );

  const renderFullInstructionsList = () => (
    <S.FullInstructionsList>
      <S.InstructionsSubList>
        <h3>Organização do arquivo</h3>

        <ul>
          <li>
            O aquivo deve ter o formato .csv e utilizar ponto e vírgula (;) como
            separador de colunas;
          </li>
          <li>O tamanho máximo aceito para o arquivo é de 10MB;</li>
          <li>
            Garanta que o arquivo possua as informações de código de barras,
            preço e quantidade em estoque;
          </li>
          <li>Verifique o formato das informações:</li>
          <ul>
            <li>Código de barras: 13 dígitos;</li>
            <li>Nome do produto: Caracteres e dígitos;</li>
            <li>Preço: Apenas números com até duas casas decimais;</li>
            <li>
              Preço promocional: Valor final do produto com desconto. Números
              com até duas casas decimais;
            </li>
            <li>Quantidade em estoque: Apenas números;</li>
            <li>
              Código PDV: Apenas números. Código para identificação do produto
              em sistemas terceiros. Inserir apenas se algum consultor do
              pede.ai solicitar.
            </li>
          </ul>
          <li>Verifique se o nome dos produtos se encontram corretos;</li>
        </ul>
      </S.InstructionsSubList>

      <S.InstructionsSubList>
        <h3>Cadastro de produtos</h3>
        <ul>
          <li>
            Todo produto presente no arquivo que ainda não estiver cadastrado na
            sua loja será então criado, e seu status de disponibilidade
            dependerá da quantidade em estoque e definição de preço;
          </li>
          <li>
            Produtos com quantidade igual a 0 ou sem preço serão definidos como
            indisponíveis;
          </li>
          <li>
            Os produtos cadastrados podem não apresentar foto na sua loja caso
            ele não seja um produto já conhecido pelo pede.ai. Recomenda-se,
            nesse caso, adicionar as imagens manualmente;
          </li>
          <li>
            Caso uma informação obrigatória não esteja disponível, inválida ou
            considerada incorreta, o produto pode não ser cadastrado;
          </li>
          <li>
            Caso o produto não possua nome, será cadastrado apenas se ele já
            existir na base de produtos com código de barras do pede.ai;
          </li>
          <li>
            Não adicione códigos internos como o código de barras do produto;
          </li>
          <li>
            O progresso do cadastro poderá ser acompanhado nessa mesma página;
          </li>
          <li>
            Caso um upload de arquivo esteja em andamento, não será possível
            iniciar outro até a finalização ou cancelamento do atual;
          </li>
          <li>
            Caso um upload seja cancelado, o progresso alcançado será mantido.
            Ou seja, os produtos já cadastrados ou atualizados no upload parcial
            serão mantidos.
          </li>
        </ul>
      </S.InstructionsSubList>

      <S.InstructionsSubList>
        <h3>Atualização de produtos</h3>
        <ul>
          <li>
            Ao selecionar a opção de atualização, caso o produto enviado no
            arquivo já tenha sido cadastrado anteriormente, suas informações de
            nome, preço, preço promocional e disponibilidade (com base no
            estoque) serão atualizadas;
          </li>
        </ul>
      </S.InstructionsSubList>
    </S.FullInstructionsList>
  );

  return (
    <S.Container>
      <S.TitleContainer>
        <i className="la la-warning" />
        <h2>Instruções</h2>
      </S.TitleContainer>
      {renderMainInstructionsList()}
      <S.ExpandInstructionsButton onClick={handleToggleInstructionsModal}>
        Ver todas instruções
      </S.ExpandInstructionsButton>

      <Modal
        toggleModalVisibility={handleToggleInstructionsModal}
        visible={modalVisibility}
        showCloseButton={true}
      >
        <S.InstructionsModalContent>
          <S.ModalTitleContainer>
            <i className="la la-warning" />
            <h2>Instruções</h2>
          </S.ModalTitleContainer>
          <S.Separator />
          {renderFullInstructionsList()}
        </S.InstructionsModalContent>
      </Modal>
    </S.Container>
  );
};
export default UploadInstructions;
