import React, { useState } from 'react';
import Api from '../../services/api';
import Swal from "sweetalert2";
import LoadingOverlay from 'react-loading-overlay';

import { withRouter } from "react-router";
import { Line, Doughnut } from 'react-chartjs-2';
import ReactTooltip from 'react-tooltip';

import DatePicker, { registerLocale } from "react-datepicker";
import InputMask from 'react-input-mask';
import pt from "date-fns/locale/pt-BR";
registerLocale("pt-BR", pt);

const Painel = () => {

    const [ dataInicial, setDataInicial ] = useState();
    const [ dataFinal, setDataFinal ] = useState(new Date());
    const [ dataInicio_Formatada, setDataInicioFormatada ] = useState('');
    const [ dataFinal_Formatada, setDataFinalFormatada ] = useState('');

    const [ pedidosCountCancelados, setPedidosCountCancelados ] = useState(0);
    const [ pedidosSomaCancelados, setPedidosSomaCancelados ] = useState(0);
    const [ pedidosCountEfetivados, setPedidosCountEfetivados ] = useState(0);
    const [ pedidosSomaEfetivados, setPedidosSomaEfetivados ] = useState(0);
    const [ pedidosSomaEntregues, setPedidosSomaEntregues ] = useState(0);
    const [ pedidosCountEntrega, setPedidosCountEntrega ] = useState(0);
    const [ pedidosSomaDesconto, setPedidosSomaDesconto ] = useState(0);
    const [ pedidosCountCupom, setPedidosCountCupom ] = useState(0);
    const [ graficoDiaLabel, setDiaLabel ] = useState(0);
    const [ graficoDiaValor, setDiaValor ] = useState(0);
    const [ pedidosticketmedio, setPedidosTicketMedio ] = useState(0);
    const [ pedidosROI, setPedidosROI ] = useState(0);
    const [ pedidosCountNovosUsuarios, setPedidosCountNovosUsuarios ] = useState(0);
    const [ pedidosCountUsuariosRecorrente, setPedidosCountUsuariosRecorrente ] = useState(0);

    const [ caProcessa, setCaProcessa ] = useState(false);

    const data = {
        labels: graficoDiaLabel,
        datasets: [
            {
                data: graficoDiaValor,
                label: 'Pedidos por dia',
			fill: false,
			lineTension: 0.1,
			backgroundColor: 'rgba(75,192,192,0.4)',
			borderColor: 'rgba(75,192,192,1)',
			borderCapStyle: 'butt',
			borderDash: [],
			borderDashOffset: 0.0,
			borderJoinStyle: 'miter',
			pointBorderColor: 'rgba(75,192,192,1)',
			pointBackgroundColor: '#fff',
			pointBorderWidth: 1,
			pointHoverRadius: 5,
			pointHoverBackgroundColor: 'rgba(75,192,192,1)',
			pointHoverBorderColor: 'rgba(220,220,220,1)',
			pointHoverBorderWidth: 2,
			pointRadius: 1,
			pointHitRadius: 10,

            }
        ]
    };

    const dataU = {
        labels: ['Novos', 'Recorrentes'],
        datasets: [
            {
                data: [pedidosCountNovosUsuarios, pedidosCountUsuariosRecorrente],
                backgroundColor: ['#F64E60', '#1BC5BD'],
                hoverBackgroundColor: ['#F64E60', '#1BC5BD']
            }
        ]
    };

        function efetuarPesquisa(){
            setCaProcessa(true);
            var data = {
                data_inicio: dataInicial,
                data_final: dataFinal,
            };

            Api.post('relatorio/geraRelatorio', data).then((rps) => {

                if(rps.data.status==false){
                    Swal.fire({
                        title: "Atenção!",
                        icon: "info",
                        html: rps.data.erro,
                        showCloseButton: true,
                        showCancelButton: false,
                      });
                    setDataInicioFormatada(rps.data.data_inicio);
                    setDataFinalFormatada(rps.data.data_final);
                } else {
                    processaDados(rps.data.retorno);
                    geraGrafico(rps.data.grafico);
                    setDataInicioFormatada(rps.data.data_inicio);
                    setDataFinalFormatada(rps.data.data_final);
                }
                setCaProcessa(false);
            });
        }

        function geraGrafico(dados){
            //***Inicio do Grafico Vendas por dia

            const formataDiaTexto = dados.map( item => {
              return item.data_criacao
            })
            const formataDiaValor = dados.map( item => {
              return item.qtde
            })
            setDiaLabel(formataDiaTexto);
            setDiaValor(formataDiaValor);

            //Fim***
        }

        function processaDados(dados){

            var countPedidosCancelados = 0;
            var sumPedidosCancelados = 0;
            var countPedidosEfetivados = 0;
            var sumPedidosEfetivados = 0;
            var sumTaxaEntregue = 0;
            var countPedidoEntrega = 0;
            var sumDescontoAiboo = 0;
            var countQtdeCupom = 0;
            var ticketMedio = 0;
            var sumValorTotal = 0;
            var sumValorComissao = 0;
            var ROI = 0;
            var countNovosUsuarios = 0;
            var countUsuariosRecorrente = 0;

            dados.map( pedido => {
                if(pedido?.status==='PC'){
                    countPedidosCancelados++;
                    sumPedidosCancelados+= parseFloat(pedido?.valor_pedido);
                }
                if(pedido?.status==='PD'){
                    countPedidosEfetivados++;
                    sumPedidosEfetivados+= parseFloat(pedido?.valor_pedido) - parseFloat(pedido?.valor_desconto_rest);
                    sumTaxaEntregue+= parseFloat(pedido?.valor_taxa);
                    sumDescontoAiboo+= parseFloat(pedido?.valor_desconto_aiboo);
                    sumValorTotal+= parseFloat(pedido?.valor_total);
                    sumValorComissao+= parseFloat(pedido?.comissao);
                    if(pedido?.id_cupom != null) {
                        countQtdeCupom++;
                    }
                    if(pedido?.valor_taxa > 0) {
                        countPedidoEntrega++;
                    }
                    if(pedido?.compraGlobal == 1) {
                        countNovosUsuarios++;
                    } else {
                        countUsuariosRecorrente++;
                    }
                }
              return null
            });

            ticketMedio = sumPedidosEfetivados / countPedidosEfetivados;

            if (sumValorComissao > 0){
                ROI = (sumValorTotal - sumValorComissao) / sumValorComissao;
            } else {
                ROI = 0;
            }

            setPedidosCountCancelados(countPedidosCancelados);
            setPedidosSomaCancelados(sumPedidosCancelados.toFixed(2));
            setPedidosCountEfetivados(countPedidosEfetivados);
            setPedidosSomaEfetivados(sumPedidosEfetivados.toFixed(2));
            setPedidosSomaEntregues(sumTaxaEntregue.toFixed(2));
            setPedidosCountEntrega(countPedidoEntrega);
            setPedidosSomaDesconto(sumDescontoAiboo.toFixed(2));
            setPedidosCountCupom(countQtdeCupom);
            setPedidosTicketMedio(ticketMedio.toFixed(2));
            setPedidosROI(ROI.toFixed(2));
            setPedidosCountNovosUsuarios(countNovosUsuarios);
            setPedidosCountUsuariosRecorrente(countUsuariosRecorrente);
        }

        return (
            <>

        <ReactTooltip
            html={true} />

    {/*begin::Entry*/}
    <div className="d-flex flex-column-fluid">
        {/*begin::Container*/}
        <div className="container">


            {/*begin card de pesquisa de vendas */}
            <div className="card card-custom">
				<div className="card-header">
					<h3 className="card-title">Painel de Controle</h3>
				</div>
                <div className="card-body">
                    <div className="row justify-content-md-center">
                        <div className="col-md-2 text-center">
                            <h5>Data Inicial</h5>
                            <div>
                            <DatePicker
                                name="dt_inicio"
                                onChange={setDataInicial}
                                isClearable
                                selectsStart
                                locale="pt-BR"
                                className="form-control"
                                placeholderText="dd/mm/aaaa"
                                selected ={dataInicial}
                                dateFormat="dd/MM/y"
                                startDate={dataInicial}
                                endDate={dataFinal}
                                customInput={
                                    <InputMask mask="99/99/9999" />}/>
                            </div>
                        </div>
                        <div className="col-md-2 text-center">
                            <h5>Data Final</h5>
                            <div>
                            <DatePicker
                                name="dt_final"
                                onChange={setDataFinal}
                                isClearable
                                locale="pt-BR"
                                className="form-control"
                                placeholderText="dd/mm/aaaa"
                                selected ={dataFinal}
                                dateFormat="dd/MM/y"
                                selectsEnd
                                startDate={dataInicial}
                                endDate={dataFinal}
                                minDate={dataInicial}
                                customInput={
                                    <InputMask mask="99/99/9999" />}/>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <br />
                            <button className="btn btn-primary" onClick={e=>{efetuarPesquisa()}}>Ver Painel</button>
                        </div>
                    </div>
                </div>
            </div>
            <br />


<div className="row mb-10">

<div className="col-lg-3 mb-10">
<div className="card card-custom card-body">
    <span className="font-weight-bolder text-dark-75 font-size-h5 mb-2 text-hover-primary">Pedidos Totais <i className="fas fa-question-circle" data-tip={"Apresenta o total em pedidos, subtraindo o desconto. <br />não está somando com a Taxa de Entrega"} /></span>
    <div className="row">
        <div className="col-sm-8">
        <span className="text-dark-75 font-weight-bolder font-size-h2 mr-2">{pedidosCountEfetivados}</span>
        </div>
        <div className="col-sm-4">
        <i className="icon-xl far fa-bookmark cor-icone-red float-right"></i>
        </div>
    </div>
    <span className="d-block text-dark-50 mt-2 font-size-sm">R$ {pedidosSomaEfetivados}</span>
</div>
</div>

<div className="col-lg-3 mb-10">
<div className="card card-custom card-body">
    <span className="font-weight-bolder text-dark-75 font-size-h5 mb-2 text-hover-primary">Pedidos Cancelados</span>
    <div className="row">
        <div className="col-sm-8">
        <span className="text-dark-75 font-weight-bolder font-size-h2 mr-2">{pedidosCountCancelados}</span>
        </div>
        <div className="col-sm-4">
        <i className="icon-xl far fa-times-circle cor-icone-red float-right"></i>
        </div>
    </div>
    <span className="d-block text-dark-50 mt-2 font-size-sm">R$ {pedidosSomaCancelados} em vendas canceladas</span>
</div>
</div>

<div className="col-lg-3 mb-10">
<div className="card card-custom card-body">
    <span className="font-weight-bolder text-dark-75 font-size-h5 mb-2 text-hover-primary">Cupons</span>
    <div className="row">
        <div className="col-sm-8">
        <span className="text-dark-75 font-weight-bolder font-size-h2 mr-2">{pedidosCountCupom}</span>
        </div>
        <div className="col-sm-4">
        <i className="icon-xl fas fa-ticket-alt cor-icone-red float-right"></i>
        </div>
    </div>
    <span className="d-block text-dark-50 mt-2 font-size-sm">R$ {pedidosSomaDesconto} em vendas por nossa conta</span>
</div>
</div>

<div className="col-lg-3 mb-10">
<div className="card card-custom card-body">
    <span className="font-weight-bolder text-dark-75 font-size-h5 mb-2 text-hover-primary">Taxa de Entrega</span>
    <div className="row">
        <div className="col-sm-8">
        <span className="text-dark-75 font-weight-bolder font-size-h2 mr-2">R$ {pedidosSomaEntregues}</span>
        </div>
        <div className="col-sm-4">
        <i className="icon-xl fas fa-motorcycle cor-icone-red float-right"></i>
        </div>
    </div>
    <span className="d-block text-dark-50 mt-2 font-size-sm">{pedidosCountEntrega} corrida(s)</span>
</div>
</div>



</div>

<div className="row mb-10">
<div className="col-lg-3 mb-10">
<div className="card card-custom card-body">
    <span className="font-weight-bolder text-dark-75 font-size-h5 mb-2 text-hover-primary">Ticket Médio</span>
    <div className="row">
        <div className="col-sm-8">
        <span className="text-dark-75 font-weight-bolder font-size-h2 mr-2">
            R$ {pedidosticketmedio} <i className="icon-xl fas fa-question-circle" data-tip="É o valor médio das vendas realizadas em sua loja.<br />Total (R$) em pedidos dividido pela quantidade de pedidos."></i>
</span>
        </div>
        <div className="col-sm-4">
        <i className="icon-xl fas fa-dollar-sign cor-icone-red float-right mt--10"></i>
        </div>
    </div>
</div>
<br />
<br />
<div className="card card-custom card-body">
    <span className="font-weight-bolder text-dark-75 font-size-h5 mb-2 text-hover-primary">ROI</span>
    <div className="row">
        <div className="col-sm-8">
        <span className="text-dark-75 font-weight-bolder font-size-h2 mr-2">
            R$ {pedidosROI} <i className="icon-xl fas fa-question-circle"  data-tip="<b>Retorno Sobre Investimento</b><br />A cada R$ 1,00 pago em comissão ao Aiboo,<br /> nós lhe entregamos R$ em vendas"></i>
</span>
        </div>
        <div className="col-sm-4">
        <i className="icon-xl fas fa-hand-holding-usd cor-icone-red float-right mt--10"></i>
        </div>
    </div>
</div>
<br />
<br />
<div className="card card-custom card-body">
    <span className="font-weight-bolder text-dark-75 font-size-h5 mb-2 text-hover-primary">Novos Usuários</span>
    <div className="row">
        <div className="col-sm-8">
        <span className="text-dark-75 font-weight-bolder font-size-h2 mr-2">
            {pedidosCountNovosUsuarios}
</span>
        </div>
        <div className="col-sm-4">
        <i className="icon-xl fas fa-user-friends cor-icone-red float-right mt--10"></i>
        </div>
    </div>
    <span className="d-block text-dark-50 mt-2 font-size-sm">{pedidosCountUsuariosRecorrente} usuários recorrentes</span>
    <Doughnut data={dataU}/>
</div>
</div>

<div className="col-lg-9 mb-10">
  <div className="card card-custom">
    <div className="card-header">
        <h3 className="card-title">Gráfico de vendas do dia {dataInicio_Formatada} ao dia {dataFinal_Formatada}</h3>
	</div>
    <div className="card-body">
        <LoadingOverlay
            active={caProcessa}
            spinner
            text='Carregando...'
            >

                <Line data={data}/>


        </LoadingOverlay>
    </div>
  </div>
</div>



</div>




        </div>
    </div>
    </>

    );

}

export default withRouter(Painel);
