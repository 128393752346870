import "dotenv/config";

import React from "react";
import ReactDOM from "react-dom";
import { ToastProvider } from "react-toast-notifications";
import "sweetalert2/dist/sweetalert2.css";

import App from "./App";
import { CloseElectronProvider } from "./contexts/closeElectron";
import { UpdateProvider } from "./contexts/update";
import { ChatProvider } from "./hooks/useChat";

import GlobalStyle from "./styles/globalStyles";

ReactDOM.render(
  <ChatProvider>
    <ToastProvider>
      <UpdateProvider>
        <CloseElectronProvider>
          <App />
        </CloseElectronProvider>
      </UpdateProvider>
      <GlobalStyle />
    </ToastProvider>
  </ChatProvider>,
  document.getElementById("root")
);
