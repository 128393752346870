import React, { useState, useEffect } from 'react';
import Api from '../../services/api';
import { withRouter } from "react-router";

import { Modal } from "react-bootstrap";
import Iframe from 'react-iframe'
import LoadingOverlay from 'react-loading-overlay';


const FinanceiroFaturas = () => {

    useEffect(() =>{
      getFaturas();

    },[]);

    const [linkFaturaExterno, setLinkFaturaExterno] = useState('');
    const [modalFaturaExterno, setModalFaturaExterno] = useState(false);

    const [caFaturas, setCaFaturas] = useState(true);
    const [faturas, setFaturas] = useState([]);
    function getFaturas(){
      Api.get("faturas/listar").then((rps) =>{
        if(rps?.data?.status===true){
          setFaturas(rps.data.faturas);
          setCaFaturas(false);
        }
      });
    }

    return (
    <>

      {/*begin::Entry*/}
      <div className="d-flex flex-column-fluid">
        {/*begin::Container*/}
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              {/*begin::Card*/}
              <div className="card card-custom gutter-b">
                <div className="card-header flex-wrap">
                  {/*begin::Subheader*/}
                  <div className="subheader py-2 py-lg-4 subheader-transparent" id="kt_subheader">
                    <div className="d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
                      {/*begin::Details*/}
                      <div className="d-flex align-items-center flex-wrap mr-2">
                        {/*begin::Title*/}
                        <h5 className="text-dark font-weight-bold mt-2 mb-2 mr-5">Faturas</h5>
                        {/*end::Title*/}
                        {/*begin::Separator*/}
                        <div className="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200" />
                        {/*end::Separator*/}
                        {/*begin::Search Form*/}
                        <div className="d-flex align-items-center" id="kt_subheader_search">
                          {/*<form className="ml-5">
                            <div className="input-group input-group-sm input-group-solid" style={{maxWidth: '175px'}}>
                              <input type="text" className="form-control" id="kt_subheader_search_form" placeholder="Procurar..." />
                              <div className="input-group-append">
                                <span className="input-group-text">
                                  <span className="svg-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                      <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                        <rect x={0} y={0} width={24} height={24} />
                                        <path d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z" fill="#000000" fillRule="nonzero" opacity="0.3" />
                                        <path d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z" fill="#000000" fillRule="nonzero" />
                                      </g>
                                    </svg>
                                  </span>
                                </span>
                              </div>
                            </div>
                          </form>*/}
                        </div>
                        {/*end::Search Form*/}
                      </div>
                      {/*end::Details*/}
                    </div>
                  </div>
                  {/*end::Subheader*/}
                </div>
                <div className="card-body no-padding" >
                  <div className="table-div-header padding-horizontal">
                    <div className="table-div-header-fatura-lista">Hash</div>
                    <div className="table-div-header-fatura-lista">Referência</div>
                    <div className="table-div-header-fatura-lista">Vendas</div>
                    <div className="table-div-header-fatura-lista">Status</div>
                    <div className="table-div-header-fatura-lista">Valor</div>
                    <div className="table-div-header-fatura-lista">Vencimento</div>
                    <div className="table-div-header-fatura-lista">Ações</div>
                  </div>
                  <LoadingOverlay
                  active={caFaturas}
                  spinner
                  text='Carregando...'
                >


                  {faturas.map(fat => {
                    var classe = '';
                    if(fat.status==='AT'){
                      classe = ' fatura-aberto';
                    } else if(fat.status==='VC'){
                      classe = ' fatura-aberto';
                    }

                  return(
                  <div className={"table-item padding-horizontal"+classe}>
                    <div className="table-fatura-lista">
                      #{fat.hash_fatura}
                    </div>
                    <div className="table-fatura-lista">
                      {fat.referencia}
                    </div>
                    <div className="table-fatura-lista">
                      <div className="area-vendas">
                        <strong>R$ {fat.vendas_total}</strong>
                        <br />{fat.vendas} Pedidos
                      </div>
                    </div>
                    <div className="table-fatura-lista">
                      {fat.status==='PG' && <span className="label label-success label-pill label-inline mr-2">Paga</span>}
                      {fat.status==='AT' && <span className="label label-warning label-pill label-inline mr-2">Em Aberto</span>}
                      {fat.status==='VC' && <span className="label label-danger label-pill label-inline mr-2">Vencida</span>}
                      {fat.status==='IS' && <span className="label label-info label-pill label-inline mr-2">Isenta</span>}
                      {fat.status==='FA' && <span className="label label-pill label-inline mr-2">Acumulada</span>}
                    </div>
                    <div className="table-fatura-lista">
                      R$ {fat.valor_pagar}
                    </div>
                    <div className="table-fatura-lista">
                      {fat.data_vencimento}
                    </div>
                    <div className="table-fatura-lista">
                      {fat.boleto_link!=null &&
                        <button onClick={ e=> {
                          setLinkFaturaExterno(fat.boleto_link);
                          setModalFaturaExterno(true);
                        }}
                        className="btn btn-light-primary font-weight-bold mr-2"><i className="fas fa-print" /> Boleto</button>
                      }
                      {fat.nfse_link!=null &&
                        <button onClick={ e=> {
                          setLinkFaturaExterno(fat.nfse_link);
                          setModalFaturaExterno(true);
                        }}
                        className="btn btn-light-primary font-weight-bold mr-2"><i className="fas fa-print" /> Nota fiscal</button>
                      }
                      <button onClick={ e=> {
                        console.log(fat.fatura_link)
                        setLinkFaturaExterno(fat.fatura_link);
                        setModalFaturaExterno(true);
                      }}
                      className="btn btn-light-primary font-weight-bold mr-2"><i className="fas fa-th-list" /> Relatório</button>
                    </div>
                  </div>

                  )})}
                  </LoadingOverlay>

                  </div>
              </div>
              {/*end::Card*/}
            </div>
          </div>
        </div>
        {/*end::Container*/}
      </div>
      {/*end::Entry*/}

      <Modal size={'xl'} show={modalFaturaExterno} onHide={() => setModalFaturaExterno(false)}>
        <Iframe url={linkFaturaExterno}
          id="myId"
          className="myClassname"
          display="initial"
          position="relative"
          height="450px"
        />


      </Modal>


    </>
    )

}

export default withRouter(FinanceiroFaturas);
