import axios from "axios";
import Api from "../api";


const apiRemaster = axios.create({
    baseURL: process.env.REACT_APP_BASEURL_AIBOO_WS_LARAVEL,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': Api.defaults.headers['Authorization'],
    },
  });

  export default apiRemaster
