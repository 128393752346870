import React, { useState, useEffect} from 'react';
import InputMask from "react-input-mask";

const LAST_MINUTE_OF_THE_DAY = 1439;

const DisponibilidadeComp = (props) => {
    const { disponibilidadeOriginalObj, setDisponibilidadeString } = props;
    

    useEffect(() =>{
        initDisponibilidade(disponibilidadeOriginalObj);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [disponibilidadeOriginalObj]);

    const [sempreDisponivel, setSempreDisponivel] = useState(true);
    const [dispDo, setDispDo] = useState({ initAt: '', endAt: '', checked: false, initAt2: '', endAt2: ''});
    const [dispSg, setDispSg] = useState({ initAt: '', endAt: '', checked: false, initAt2: '', endAt2: ''});
    const [dispTe, setDispTe] = useState({ initAt: '', endAt: '', checked: false, initAt2: '', endAt2: ''});
    const [dispQa, setDispQa] = useState({ initAt: '', endAt: '', checked: false, initAt2: '', endAt2: ''});
    const [dispQi, setDispQi] = useState({ initAt: '', endAt: '', checked: false, initAt2: '', endAt2: ''});
    const [dispSx, setDispSx] = useState({ initAt: '', endAt: '', checked: false, initAt2: '', endAt2: ''});
    const [dispSb, setDispSb] = useState({ initAt: '', endAt: '', checked: false, initAt2: '', endAt2: ''});
    function minuteToHour(minute){
      minute = parseInt(minute);
      var sobra = minute % 60;
      var horas = (minute - sobra) / 60;
      return ("00"+horas).slice(-2)+":"+("00"+sobra).slice(-2);
    }
    function hourToMinute(hour){
        if(hour===undefined || hour===''){
            return 0;
        }
        var hEx = (hour+"").split(":");
        var horas = parseInt(hEx[0]) * 60;
        var minutos = parseInt(hEx[1]);
        var soma = (horas + minutos);
        return soma;
    }

    const formatEndTime = (hour) => {
      const invalidEndTime = !hour || hour === '' || hour === "00:00"

      if(invalidEndTime){
        return LAST_MINUTE_OF_THE_DAY;
      }
      
      return hourToMinute(hour)
    }

    function initDisponibilidade(obj){
        let existeAlgum = false;
      setDispDo({ initAt: '', endAt: '', checked: false, initAt2: '', endAt2: ''});
      setDispSg({ initAt: '', endAt: '', checked: false, initAt2: '', endAt2: ''});
      setDispTe({ initAt: '', endAt: '', checked: false, initAt2: '', endAt2: ''});
      setDispQa({ initAt: '', endAt: '', checked: false, initAt2: '', endAt2: ''});
      setDispQi({ initAt: '', endAt: '', checked: false, initAt2: '', endAt2: ''});
      setDispSx({ initAt: '', endAt: '', checked: false, initAt2: '', endAt2: ''});
      setDispSb({ initAt: '', endAt: '', checked: false, initAt2: '', endAt2: ''});
      if(obj!=null){
        if(obj.do){
            existeAlgum = true;
            var doNew = { initAt: '', endAt: '', checked: false, initAt2: '', endAt2: ''};
            if(obj.do.initAt >= 0){
                doNew.checked = true;
                doNew.initAt = minuteToHour(obj.do.initAt);
                if (obj.do.endAt === 0) {
                  doNew.endAt = minuteToHour(1439);
                } else {
                  doNew.endAt = minuteToHour(obj.do.endAt);
                }
            }
            if(obj.do.initAt2 >= 0){
                doNew.checked2 = true;
                doNew.initAt2 = minuteToHour(obj.do.initAt2);
                doNew.endAt2 = minuteToHour(obj.do.endAt2);
            }
            setDispDo(doNew);
        }
        if(obj.sg){
            existeAlgum = true;
            var sgNew = { initAt: '', endAt: '', checked: false, initAt2: '', endAt2: ''};
          if(obj.sg.initAt >= 0){
            sgNew.checked = true;
            sgNew.initAt = minuteToHour(obj.sg.initAt);
            if (obj.sg.endAt === 0) {
              sgNew.endAt = minuteToHour(1439);
            } else {
              sgNew.endAt = minuteToHour(obj.sg.endAt);
            }
          }
          if(obj.sg.initAt2 >= 0){
            sgNew.checked2 = true;
            sgNew.initAt2 = minuteToHour(obj.sg.initAt2);
            sgNew.endAt2 = minuteToHour(obj.sg.endAt2);
          }
          setDispSg(sgNew);
        }
        if(obj.te){
            existeAlgum = true;
            var teNew = { initAt: '', endAt: '', checked: false, initAt2: '', endAt2: ''};
          if(obj.te.initAt >= 0){
            teNew.checked = true;
            teNew.initAt = minuteToHour(obj.te.initAt);
            if (obj.te.endAt === 0) {
              teNew.endAt = minuteToHour(1439);
            } else {
              teNew.endAt = minuteToHour(obj.te.endAt);
            }
          }
          if(obj.te.initAt2 >= 0){
            teNew.checked2 = true;
            teNew.initAt2 = minuteToHour(obj.te.initAt2);
            teNew.endAt2 = minuteToHour(obj.te.endAt2);
          }
          setDispTe(teNew);
        }
        if(obj.qa){
            existeAlgum = true;
            var qaNew = { initAt: '', endAt: '', checked: false, initAt2: '', endAt2: ''};
          if(obj.qa.initAt >= 0){
            qaNew.checked = true;
            qaNew.initAt = minuteToHour(obj.qa.initAt);
            if (obj.qa.endAt === 0) {
              qaNew.endAt = minuteToHour(1439);
            } else {
              qaNew.endAt = minuteToHour(obj.qa.endAt);
            }
          }
          if(obj.qa.initAt2 >= 0){
            qaNew.checked2 = true;
            qaNew.initAt2 = minuteToHour(obj.qa.initAt2);
            qaNew.endAt2 = minuteToHour(obj.qa.endAt2);
          }
          setDispQa(qaNew);
        }
        if(obj.qi){
            existeAlgum = true;
            var qiNew = { initAt: '', endAt: '', checked: false, initAt2: '', endAt2: ''};
          if(obj.qi.initAt >= 0){
            qiNew.checked = true;
            qiNew.initAt = minuteToHour(obj.qi.initAt);
            if (obj.qi.endAt === 0) {
              qiNew.endAt = minuteToHour(1439);
            } else {
              qiNew.endAt = minuteToHour(obj.qi.endAt);
            }
          }
          if(obj.qi.initAt2 >= 0){
            qiNew.checked2 = true;
            qiNew.initAt2 = minuteToHour(obj.qi.initAt2);
            qiNew.endAt2 = minuteToHour(obj.qi.endAt2);
          }
          setDispQi(qiNew);
        }
        if(obj.sx){
            existeAlgum = true;
            var sxNew = { initAt: '', endAt: '', checked: false, initAt2: '', endAt2: ''};
          if(obj.sx.initAt >= 0){
            sxNew.checked = true;
            sxNew.initAt = minuteToHour(obj.sx.initAt);
            if (obj.sx.endAt === 0) {
              sxNew.endAt = minuteToHour(1439);
            } else {
              sxNew.endAt = minuteToHour(obj.sx.endAt);
            }
          }
          if(obj.sx.initAt2 >= 0){
            sxNew.checked2 = true;
            sxNew.initAt2 = minuteToHour(obj.sx.initAt2);
            sxNew.endAt2 = minuteToHour(obj.sx.endAt2);
          }
          setDispSx(sxNew);
        }
        if(obj.sb){
            existeAlgum = true;
            var sbNew = { initAt: '', endAt: '', checked: false, initAt2: '', endAt2: ''};
          if(obj.sb.initAt >= 0){
            sbNew.checked = true;
            sbNew.initAt = minuteToHour(obj.sb.initAt);
            if (obj.sb.endAt === 0) {
              sbNew.endAt = minuteToHour(1439);
            } else {
              sbNew.endAt = minuteToHour(obj.sb.endAt);
            }
          }
          if(obj.sb.initAt2 >= 0){
            sbNew.checked2 = true;
            sbNew.initAt2 = minuteToHour(obj.sb.initAt2);
            sbNew.endAt2 = minuteToHour(obj.sb.endAt2);
          }
          setDispSb(sbNew);
        }
      }
      setSempreDisponivel(!existeAlgum);
    }

    useEffect(() =>{
        let newObj = {};
        if(sempreDisponivel===true){
          
        } else {
            if(dispDo.checked===true || dispDo.checked2===true) newObj.do = {};
            if(dispDo.checked===true) {
                newObj.do.initAt = hourToMinute(dispDo.initAt);
                newObj.do.endAt = formatEndTime(dispDo.endAt);
                
            }
            if(dispDo.checked2===true) {
                newObj.do.initAt2 = hourToMinute(dispDo.initAt2);
                newObj.do.endAt2 = formatEndTime(dispDo.endAt2);
            }

            if(dispSg.checked===true || dispSg.checked2===true) newObj.sg = {};
            if(dispSg.checked===true) {
                newObj.sg.initAt = hourToMinute(dispSg.initAt);
                newObj.sg.endAt = formatEndTime(dispSg.endAt);
            }
            if(dispSg.checked2===true) {
                newObj.sg.initAt2 = hourToMinute(dispSg.initAt2);
                newObj.sg.endAt2 = formatEndTime(dispSg.endAt2);
            }

            if(dispTe.checked===true || dispTe.checked2===true) newObj.te = {};
            if(dispTe.checked===true) {
                newObj.te.initAt = hourToMinute(dispTe.initAt);
                newObj.te.endAt = formatEndTime(dispTe.endAt);
            }
            if(dispTe.checked2===true) {
                newObj.te.initAt2 = hourToMinute(dispTe.initAt2);
                newObj.te.endAt2 = formatEndTime(dispTe.endAt2);
            }

            if(dispQa.checked===true || dispQa.checked2===true) newObj.qa = {};
            if(dispQa.checked===true) {
                newObj.qa.initAt = hourToMinute(dispQa.initAt);
                newObj.qa.endAt = formatEndTime(dispQa.endAt);
            }
            if(dispQa.checked2===true) {
                newObj.qa.initAt2 = hourToMinute(dispQa.initAt2);
                newObj.qa.endAt2 = formatEndTime(dispQa.endAt2);
            }

            if(dispQi.checked===true || dispQi.checked2===true) newObj.qi = {};
            if(dispQi.checked===true) {
                newObj.qi.initAt = hourToMinute(dispQi.initAt);
                newObj.qi.endAt = formatEndTime(dispQi.endAt);
            }
            if(dispQi.checked2===true) {
                newObj.qi.initAt2 = hourToMinute(dispQi.initAt2);
                newObj.qi.endAt2 = formatEndTime(dispQi.endAt2);
            }

            if(dispSx.checked===true || dispSx.checked2===true) newObj.sx = {};
            if(dispSx.checked===true) {
                newObj.sx.initAt = hourToMinute(dispSx.initAt);
                newObj.sx.endAt = formatEndTime(dispSx.endAt);
            }
            if(dispSx.checked2===true) {
                newObj.sx.initAt2 = hourToMinute(dispSx.initAt2);
                newObj.sx.endAt2 = formatEndTime(dispSx.endAt2);
            }

            if(dispSb.checked===true || dispSb.checked2===true) newObj.sb = {};
            if(dispSb.checked===true) {
                newObj.sb.initAt = hourToMinute(dispSb.initAt);
                newObj.sb.endAt = formatEndTime(dispSb.endAt);
            }
            if(dispSb.checked2===true) {
                newObj.sb.initAt2 = hourToMinute(dispSb.initAt2);
                newObj.sb.endAt2 = formatEndTime(dispSb.endAt2);
            }
        }
        setDisponibilidadeString(newObj);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispDo, dispSg, dispTe, dispQa, dispQi, dispSx, dispSb, sempreDisponivel]);

    return (
        <>
 <label className="option option-plain">
                <span className="option-control">
                  <span className="radio">
                    <input type="radio" onChange={(e)=> setSempreDisponivel(true)}  checked={sempreDisponivel===true} defaultChecked="checked" />
                    <span />
                  </span>
                </span>
                <span className="option-label">
                  <span className="option-head">
                    <span className="option-title">Sempre Disponível</span>
                  </span>
                  <span className="option-body">O item ficará disponível sempre que o estabelecimento estiver aberto</span>
                </span>
              </label>
              <label className="option option option-plain">
                <span className="option-control">
                  <span className="radio">
                    <input type="radio" onChange={(e)=> setSempreDisponivel(false)}  checked={sempreDisponivel===false} defaultChecked="checked" />
                    <span />
                  </span>
                </span>
                <span className="option-label">
                  <span className="option-head">
                    <span className="option-title">Disponível em dias e horários específicos</span>
                  </span>
                  <span className="option-body">Selecione os períodos em que o item ficará disponível no aplicativo</span>
                </span>
              </label>
              {sempreDisponivel===false &&
              <>
              <div className="table-div-header padding-horizontal area-adjust-composicao">
                <div className="table-div-header-item-produto-disponibilidade" />
                <div className="table-div-header-item-produto-disponibilidade">1º Período</div>
                <div className="table-div-header-item-produto-disponibilidade">2º Período</div>
              </div>
              <div className="table-item padding-horizontal area-margin-composicao">
                <div className="table-item-produto-disponibilidade">
                  Domingo
                </div>
                <div className="table-item-produto-disponibilidade">
                  <label className="checkbox checkbox-square checkbox-primary m-t-4">
                    <input type="checkbox" checked={dispDo.checked} onChange={e=> {setDispDo({...dispDo, checked: e.target.checked})}} />
                    <span /></label>
                  <InputMask mask={"99:99"} disabled={!dispDo.checked} value={dispDo.initAt} onChange={e=> {setDispDo({...dispDo, initAt: e.target.value})}}  className="form-control form-small form-disponibilidade" type="text" />
                  <span className="disponibilidade-as">às</span>
                  <InputMask mask={"99:99"} disabled={!dispDo.checked} value={dispDo.endAt} onChange={e=> {setDispDo({...dispDo, endAt: e.target.value})}}  className="form-control form-small form-disponibilidade" type="text" />
                </div>
                <div className="table-item-produto-disponibilidade">
                  <label className="checkbox checkbox-square checkbox-primary m-t-4">
                    <input type="checkbox" checked={dispDo.checked2} onChange={e=> {setDispDo({...dispDo, checked2: e.target.checked})}} />
                    <span /></label>
                  <InputMask mask={"99:99"} disabled={!dispDo.checked2} value={dispDo.initAt2} onChange={e=> {setDispDo({...dispDo, initAt2: e.target.value})}}  className="form-control form-small form-disponibilidade" type="text" />
                  <span className="disponibilidade-as">às</span>
                  <InputMask mask={"99:99"} disabled={!dispDo.checked2} value={dispDo.endAt2} onChange={e=> {setDispDo({...dispDo, endAt2: e.target.value})}}  className="form-control form-small form-disponibilidade" type="text" />
                </div>
              </div>
              <div className="table-item padding-horizontal area-margin-composicao">
                <div className="table-item-produto-disponibilidade">
                  Segunda-feira
                </div>
                <div className="table-item-produto-disponibilidade">
                  <label className="checkbox checkbox-square checkbox-primary m-t-4">
                    <input type="checkbox" checked={dispSg.checked} onChange={e=> {setDispSg({...dispSg, checked: e.target.checked})}} />
                    <span /></label>
                  <InputMask mask={"99:99"} disabled={!dispSg.checked} value={dispSg.initAt} onChange={e=> {setDispSg({...dispSg, initAt: e.target.value})}}  className="form-control form-small form-disponibilidade" type="text" />
                  <span className="disponibilidade-as">às</span>
                  <InputMask mask={"99:99"} disabled={!dispSg.checked} value={dispSg.endAt} onChange={e=> {setDispSg({...dispSg, endAt: e.target.value})}}  className="form-control form-small form-disponibilidade" type="text" />
                </div>
                <div className="table-item-produto-disponibilidade">
                  <label className="checkbox checkbox-square checkbox-primary m-t-4">
                    <input type="checkbox" checked={dispSg.checked2} onChange={e=> {setDispSg({...dispSg, checked2: e.target.checked})}} />
                    <span /></label>
                  <InputMask mask={"99:99"} disabled={!dispSg.checked2} value={dispSg.initAt2} onChange={e=> {setDispSg({...dispSg, initAt2: e.target.value})}}  className="form-control form-small form-disponibilidade" type="text" />
                  <span className="disponibilidade-as">às</span>
                  <InputMask mask={"99:99"} disabled={!dispSg.checked2} value={dispSg.endAt2} onChange={e=> {setDispSg({...dispSg, endAt2: e.target.value})}}  className="form-control form-small form-disponibilidade" type="text" />
                </div>
              </div>
              <div className="table-item padding-horizontal area-margin-composicao">
                <div className="table-item-produto-disponibilidade">
                  Terça-feira
                </div>
                <div className="table-item-produto-disponibilidade">
                  <label className="checkbox checkbox-square checkbox-primary m-t-4">
                    <input type="checkbox" checked={dispTe.checked} onChange={e=> {setDispTe({...dispTe, checked: e.target.checked})}} />
                    <span /></label>
                  <InputMask mask={"99:99"} disabled={!dispTe.checked} value={dispTe.initAt} onChange={e=> {setDispTe({...dispTe, initAt: e.target.value})}}  className="form-control form-small form-disponibilidade" type="text" />
                  <span className="disponibilidade-as">às</span>
                  <InputMask mask={"99:99"} disabled={!dispTe.checked} value={dispTe.endAt} onChange={e=> {setDispTe({...dispTe, endAt: e.target.value})}}  className="form-control form-small form-disponibilidade" type="text" />
                </div>
                <div className="table-item-produto-disponibilidade">
                  <label className="checkbox checkbox-square checkbox-primary m-t-4">
                    <input type="checkbox" checked={dispTe.checked2} onChange={e=> {setDispTe({...dispTe, checked2: e.target.checked})}} />
                    <span /></label>
                  <InputMask mask={"99:99"} disabled={!dispTe.checked2} value={dispTe.initAt2} onChange={e=> {setDispTe({...dispTe, initAt2: e.target.value})}}  className="form-control form-small form-disponibilidade" type="text" />
                  <span className="disponibilidade-as">às</span>
                  <InputMask mask={"99:99"} disabled={!dispTe.checked2} value={dispTe.endAt2} onChange={e=> {setDispTe({...dispTe, endAt2: e.target.value})}}  className="form-control form-small form-disponibilidade" type="text" />
                </div>
              </div>
              <div className="table-item padding-horizontal area-margin-composicao">
                <div className="table-item-produto-disponibilidade">
                  Quarta-feira
                </div>
                <div className="table-item-produto-disponibilidade">
                  <label className="checkbox checkbox-square checkbox-primary m-t-4">
                    <input type="checkbox" checked={dispQa.checked} onChange={e=> {setDispQa({...dispQa, checked: e.target.checked})}} />
                    <span /></label>
                  <InputMask mask={"99:99"} disabled={!dispQa.checked} value={dispQa.initAt} onChange={e=> {setDispQa({...dispQa, initAt: e.target.value})}}  className="form-control form-small form-disponibilidade" type="text" />
                  <span className="disponibilidade-as">às</span>
                  <InputMask mask={"99:99"} disabled={!dispQa.checked} value={dispQa.endAt} onChange={e=> {setDispQa({...dispQa, endAt: e.target.value})}}  className="form-control form-small form-disponibilidade" type="text" />
                </div>
                <div className="table-item-produto-disponibilidade">
                  <label className="checkbox checkbox-square checkbox-primary m-t-4">
                    <input type="checkbox" checked={dispQa.checked2} onChange={e=> {setDispQa({...dispQa, checked2: e.target.checked})}} />
                    <span /></label>
                  <InputMask mask={"99:99"} disabled={!dispQa.checked2} value={dispQa.initAt2} onChange={e=> {setDispQa({...dispQa, initAt2: e.target.value})}}  className="form-control form-small form-disponibilidade" type="text" />
                  <span className="disponibilidade-as">às</span>
                  <InputMask mask={"99:99"} disabled={!dispQa.checked2} value={dispQa.endAt2} onChange={e=> {setDispQa({...dispQa, endAt2: e.target.value})}}  className="form-control form-small form-disponibilidade" type="text" />
                </div>
              </div>
              <div className="table-item padding-horizontal area-margin-composicao">
                <div className="table-item-produto-disponibilidade">
                  Quinta-feira
                </div>
                <div className="table-item-produto-disponibilidade">
                  <label className="checkbox checkbox-square checkbox-primary m-t-4">
                    <input type="checkbox" checked={dispQi.checked} onChange={e=> {setDispQi({...dispQi, checked: e.target.checked})}} />
                    <span /></label>
                  <InputMask mask={"99:99"} disabled={!dispQi.checked} value={dispQi.initAt} onChange={e=> {setDispQi({...dispQi, initAt: e.target.value})}}  className="form-control form-small form-disponibilidade" type="text" />
                  <span className="disponibilidade-as">às</span>
                  <InputMask mask={"99:99"} disabled={!dispQi.checked} value={dispQi.endAt} onChange={e=> {setDispQi({...dispQi, endAt: e.target.value})}}  className="form-control form-small form-disponibilidade" type="text" />
                </div>
                <div className="table-item-produto-disponibilidade">
                  <label className="checkbox checkbox-square checkbox-primary m-t-4">
                    <input type="checkbox" checked={dispQi.checked2} onChange={e=> {setDispQi({...dispQi, checked2: e.target.checked})}} />
                    <span /></label>
                  <InputMask mask={"99:99"} disabled={!dispQi.checked2} value={dispQi.initAt2} onChange={e=> {setDispQi({...dispQi, initAt2: e.target.value})}}  className="form-control form-small form-disponibilidade" type="text" />
                  <span className="disponibilidade-as">às</span>
                  <InputMask mask={"99:99"} disabled={!dispQi.checked2} value={dispQi.endAt2} onChange={e=> {setDispQi({...dispQi, endAt2: e.target.value})}}  className="form-control form-small form-disponibilidade" type="text" />
                </div>
              </div>
              <div className="table-item padding-horizontal area-margin-composicao">
                <div className="table-item-produto-disponibilidade">
                  Sexta-feira
                </div>
                <div className="table-item-produto-disponibilidade">
                  <label className="checkbox checkbox-square checkbox-primary m-t-4">
                    <input type="checkbox" checked={dispSx.checked} onChange={e=> {setDispSx({...dispSx, checked: e.target.checked})}} />
                    <span /></label>
                  <InputMask mask={"99:99"} disabled={!dispSx.checked} value={dispSx.initAt} onChange={e=> {setDispSx({...dispSx, initAt: e.target.value})}}  className="form-control form-small form-disponibilidade" type="text" />
                  <span className="disponibilidade-as">às</span>
                  <InputMask mask={"99:99"} disabled={!dispSx.checked} value={dispSx.endAt} onChange={e=> {setDispSx({...dispSx, endAt: e.target.value})}}  className="form-control form-small form-disponibilidade" type="text" />
                </div>
                <div className="table-item-produto-disponibilidade">
                  <label className="checkbox checkbox-square checkbox-primary m-t-4">
                    <input type="checkbox" checked={dispSx.checked2} onChange={e=> {setDispSx({...dispSx, checked2: e.target.checked})}} />
                    <span /></label>
                  <InputMask mask={"99:99"} disabled={!dispSx.checked2} value={dispSx.initAt2} onChange={e=> {setDispSx({...dispSx, initAt2: e.target.value})}}  className="form-control form-small form-disponibilidade" type="text" />
                  <span className="disponibilidade-as">às</span>
                  <InputMask mask={"99:99"} disabled={!dispSx.checked2} value={dispSx.endAt2} onChange={e=> {setDispSx({...dispSx, endAt2: e.target.value})}}  className="form-control form-small form-disponibilidade" type="text" />
                </div>
              </div>
              <div className="table-item padding-horizontal area-margin-composicao">
                <div className="table-item-produto-disponibilidade">
                  Sábado
                </div>
                <div className="table-item-produto-disponibilidade">
                  <label className="checkbox checkbox-square checkbox-primary m-t-4">
                    <input type="checkbox" checked={dispSb.checked} onChange={e=> {setDispSb({...dispSb, checked: e.target.checked})}} />
                    <span /></label>
                  <InputMask mask={"99:99"} disabled={!dispSb.checked} value={dispSb.initAt} onChange={e=> {setDispSb({...dispSb, initAt: e.target.value})}}  className="form-control form-small form-disponibilidade" type="text" />
                  <span className="disponibilidade-as">às</span>
                  <InputMask mask={"99:99"} disabled={!dispSb.checked} value={dispSb.endAt} onChange={e=> {setDispSb({...dispSb, endAt: e.target.value})}}  className="form-control form-small form-disponibilidade" type="text" />
                </div>
                <div className="table-item-produto-disponibilidade">
                  <label className="checkbox checkbox-square checkbox-primary m-t-4">
                    <input type="checkbox" checked={dispSb.checked2} onChange={e=> {setDispSb({...dispSb, checked2: e.target.checked})}} />
                    <span /></label>
                  <InputMask mask={"99:99"} disabled={!dispSb.checked2} value={dispSb.initAt2} onChange={e=> {setDispSb({...dispSb, initAt2: e.target.value})}}  className="form-control form-small form-disponibilidade" type="text" />
                  <span className="disponibilidade-as">às</span>
                  <InputMask mask={"99:99"} disabled={!dispSb.checked2} value={dispSb.endAt2} onChange={e=> {setDispSb({...dispSb, endAt2: e.target.value})}}  className="form-control form-small form-disponibilidade" type="text" />
                </div>
              </div>
              </>
              }
        </>
    )
}

export default DisponibilidadeComp;
