/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { createContext, useContext } from "react";
import CurrencyInput from "react-currency-input";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";

const DragHandle = SortableHandle(() => (
  <i style={{ cursor: "move" }} className="fas fa-bars f16" />
));

const ComponentItemContext = createContext({});

const SortableComponentItem = SortableElement(({ item }) => {
  const { frmEditaPreco, setFrmEditaPreco, sendFrmEditaPreco, excluirItem } = useContext(
    ComponentItemContext
  );

  return (
    <div
      key={item.id_variacao_estoque}
      className={
        "table-item table-item-border-branco no-padding padding-horizontal-border " +
        item?.selectedClass
      }
    >
      <div className="table-item-produto-variacoes-modal">
        <DragHandle />
      </div>
      <div className="table-item-produto-variacoes-modal">
        {item.id_estoque === "-1" && <> {item.nome_var} (avulso) </>}
        {item.id_estoque !== "-1" && <> {item.nome_item} (global) </>}
      </div>
      <div className="table-item-produto-variacoes-modal">
        {frmEditaPreco?.id_variacao_estoque === item.id_variacao_estoque ? (
          <CurrencyInput
            maxLength={6}
            precision={"2"}
            value={frmEditaPreco.preco}
            onChangeEvent={(e) => {
              setFrmEditaPreco({ ...frmEditaPreco, preco: e.target.value });
            }}
            decimalSeparator="."
            className="form-control form-control-solid form-small border border-secondary border-2"
            type="text"
          />
        ) : (
          <input
            disabled={true}
            value={item.preco}
            className="form-control form-small"
            type="text"
          />
        )}
      </div>

      {frmEditaPreco?.id_variacao_estoque === item.id_variacao_estoque ? (
        <div className="table-item-produto-variacoes-modal text-right">
          <button
            onClick={(e) => {
              sendFrmEditaPreco();
            }}
            className="btn btn-sm btn-clean btn-icon"
            title="Editar Opção"
          >
            <i className="fa fa-check" />
          </button>
          <button
            onClick={(e) => {
              setFrmEditaPreco({});
            }}
            className="btn btn-sm btn-clean btn-icon"
            title="Apagar Opção"
          >
            <i className="fa fa-times" />
          </button>
        </div>
      ) : (
        <div className="table-item-produto-variacoes-modal text-right">
          <button
            onClick={(e) => {
              setFrmEditaPreco(item);
            }}
            className="btn btn-sm btn-clean btn-icon"
            title="Editar Opção"
          >
            <i className="fa fa-edit" />
          </button>
          <button
            onClick={(e) => {
              excluirItem(item);
            }}
            className="btn btn-sm btn-clean btn-icon"
            title="Apagar Opção"
          >
            <i className="fa fa-trash" />
          </button>
        </div>
      )}
    </div>
  );
});

const SortableListCategory = SortableContainer(
  ({ items }) => {
    return (
      <div>
        {items?.map((value, index) => (
          <SortableComponentItem
            key={`item-${value.id_variacao_estoque}`}
            item={value}
            index={index}
          />
        ))}
      </div>
    );
  }
);

function SortableComponent({
  items,
  onSortEnd,
  frmEditaPreco,
  setFrmEditaPreco,
  sendFrmEditaPreco,
  excluirItem,
}) {
  return (
    <ComponentItemContext.Provider
      value={{
        frmEditaPreco,
        setFrmEditaPreco,
        sendFrmEditaPreco,
        excluirItem,
      }}
    >
      <SortableListCategory
        items={items}
        onSortEnd={onSortEnd}
        axis="y"
        helperClass="dragclass"
        useDragHandle={true}
      />
    </ComponentItemContext.Provider>
  );
}

export default SortableComponent;
