/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useCallback } from "react";
import { withRouter } from "react-router";
import Swal from "sweetalert2";
import { useToasts } from "react-toast-notifications";
import Select from "react-select";
import LoadingOverlay from "react-loading-overlay";
import { Modal, Dropdown } from "react-bootstrap";

import Api from "../../services/api";
import MudaStatus from "../../helpers/mudaStatus";

const Stock = () => {
  const { addToast } = useToasts();
  const [listaEstoque, setListaEstoque] = useState([]);
  const [listaEstoqueFiltrada, setListaEstoqueFiltrada] = useState([]);
  const [filtro, setFiltro] = useState();

  const [loading, setLoading] = useState(true);
  const [frmEstoque, setFrmEstoque] = useState({});
  const [modalEstoque, setModalEstoque] = useState(false);

  const status = [
    { value: "S", label: "Ativo" },
    { value: "O", label: "Oculto" },
    { value: "F", label: "Em Falta" },
  ];

  const handleCloseModalEstoque = useCallback(() => {
    setFrmEstoque({});
    setModalEstoque(false);
  }, []);

  const getEstoque = useCallback(() => {
    setLoading(true);
    Api.get("estoque/listar").then((response) => {
      if (response.data.status !== undefined && response.data.status === true) {
        setListaEstoque(response.data.estoque);
        setLoading(false);
      }
    });
  }, []);

  const editaEstoque = useCallback((item) => {
    if (item === null) {
      setFrmEstoque({
        titulo: "Adicionando estoque",
        nome_item: "",
        status: "S",
      });
      setModalEstoque(true);
    } else {
      item.titulo = "Editando estoque";
      setFrmEstoque(item);
    }
  }, []);

  const excluirEstoque = useCallback(
    (item) => {
      Api.post("estoque/excluir", item).then((rps) => {
        if (rps.data.status !== undefined && rps.data.status === true) {
          addToast(rps.data.mensagem, {
            appearance: "success",
            autoDismiss: true,
            autoDismissTimeout: 2000,
          });
        }
        getEstoque();
      });
    },
    [addToast, getEstoque]
  );

  const sendFrmEstoque = useCallback(() => {
    setLoading(true);

    Api.post("estoque/adicionar", frmEstoque).then((rps) => {
      if (rps.data.status !== undefined && rps.data.status === true) {
        addToast(rps.data.mensagem, {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 2000,
        });

        if (frmEstoque.id_estoque === undefined) {
          editaEstoque(null);
        }
      } else {
        Swal.fire({
          title: "Atenção!",
          icon: "info",
          html: rps.data.erro,
          showCloseButton: true,
          showCancelButton: false,
        });
      }
      setFrmEstoque({
        titulo: "Adicionando estoque",
        nome_item: "",
        status: "S",
      });
      getEstoque();
    });
  }, [addToast, editaEstoque, frmEstoque, getEstoque]);

  useEffect(() => {
    getEstoque();
  }, [getEstoque]);

  useEffect(() => {
    var estoqueOrig = [];
    estoqueOrig = listaEstoque;

    if (filtro) {
      let fEstoque = estoqueOrig.filter((item) => {
        return (
          JSON.stringify(item)
            .toLowerCase()
            .normalize("NFD")
            .replace(/[^a-zA-Zs]/g, "")
            .indexOf(
              filtro
                .toLowerCase()
                .normalize("NFD")
                .replace(/[^a-zA-Zs]/g, "")
            ) !== -1
        );
      });
      setListaEstoqueFiltrada(fEstoque);
    } else {
      setListaEstoqueFiltrada(estoqueOrig);
    }
  }, [listaEstoque, filtro]);

  return (
    <>
      <div
        className="content d-flex flex-column flex-column-fluid"
        id="kt_content"
      >
        <div
          className="subheader py-2 py-lg-4 subheader-transparent"
          id="kt_subheader"
        >
          <div className="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
            <div className="d-flex align-items-center flex-wrap mr-2">
              <h5 className="text-dark font-weight-bold mt-2 mb-2 mr-5">
                Estoque
              </h5>
              <div className="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200" />
              <div
                className="d-flex align-items-center"
                id="kt_subheader_search"
              >
                <span
                  className="text-dark-50 font-weight-bold"
                  id="kt_subheader_total"
                >
                  {listaEstoque.length} Itens
                </span>
                <form className="ml-5" onSubmit={(e) => e.preventDefault()}>
                  <div
                    className="input-group input-group-sm input-group-solid"
                    style={{ maxWidth: "175px" }}
                  >
                    <input
                      type="text"
                      onChange={(e) => {
                        setFiltro(e.target.value);
                      }}
                      className="form-control"
                      id="kt_subheader_search_form"
                      placeholder="Procurar..."
                    />
                    <div className="input-group-append">
                      <span className="input-group-text">
                        <span className="svg-icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            width="24px"
                            height="24px"
                            viewBox="0 0 24 24"
                            version="1.1"
                          >
                            <g
                              stroke="none"
                              strokeWidth={1}
                              fill="none"
                              fillRule="evenodd"
                            >
                              <rect x={0} y={0} width={24} height={24} />
                              <path
                                d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z"
                                fill="#000000"
                                fillRule="nonzero"
                                opacity="0.3"
                              />
                              <path
                                d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z"
                                fill="#000000"
                                fillRule="nonzero"
                              />
                            </g>
                          </svg>
                        </span>
                      </span>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="d-flex align-items-center">
              <div
                onClick={() => editaEstoque(null)}
                className="btn btn-light-primary font-weight-bold ml-2"
                data-toggle="modal"
              >
                Adicionar Novo Item
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex flex-column-fluid">
          <div className="container">
            <div className="card card-custom gutter-b">
              <div className="card-body p-0">
                <LoadingOverlay
                  active={loading}
                  spinner
                  text="Carregando..."
                >
                  <table className="table table-hover">
                    <thead>
                      <tr>
                        <th>Item</th>
                        <th>Status</th>
                        <th className="text-center">Ações</th>
                      </tr>
                    </thead>
                    <tbody>
                      {listaEstoqueFiltrada.map((item) => (
                        <tr key={item.id_estoque}>
                          <td className="w-50">
                            {item.id_estoque === frmEstoque.id_estoque ? (
                              <input
                                onKeyPress={(e) => {
                                  if (e.key === "Enter") sendFrmEstoque();
                                }}
                                type="text"
                                className="form-control"
                                placeholder="Ex. Alface"
                                value={frmEstoque?.nome_item}
                                onChange={(e) =>
                                  setFrmEstoque({
                                    ...frmEstoque,
                                    nome_item: e.target.value,
                                  })
                                }
                              />
                            ) : (
                              item.nome_item
                            )}
                          </td>
                          <td className="w-25">
                            <Select
                              options={status}
                              defaultValue={status.find(
                                (x) => x.value === item.status
                              )}
                              onChange={(event) => {
                                item.status = event.value;
                                MudaStatus(
                                  "estoque/mudaStatus",
                                  {
                                    id_estoque: item.id_estoque,
                                    novo_status: event.value,
                                  },
                                  addToast
                                );
                              }}
                            />
                          </td>
                          <td className="w-25 text-center">
                            {item.id_estoque === frmEstoque.id_estoque ? (
                              <>
                                <button
                                  onClick={(e) => sendFrmEstoque()}
                                  className="btn btn-sm btn-clean btn-icon"
                                  title="Salvar item"
                                >
                                  <i className="fa fa-check" />
                                </button>
                                <button
                                  onClick={(e) => setFrmEstoque({})}
                                  className="btn btn-sm btn-clean btn-icon"
                                  title="Cancelar Edição"
                                >
                                  <i className="fa fa-times" />
                                </button>
                              </>
                            ) : (
                              <span>
                                <Dropdown>
                                  <Dropdown.Toggle id="dropdown-basic" className="btn btn-light-primary btn-icon btn-sm text-center dropdown-nocaret">
                                      <i className="ki ki-bold-more-ver" />
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu>
                                    <Dropdown.Item
                                      onClick={() => {
                                        editaEstoque(item);
                                      }}
                                    >
                                      Editar
                                    </Dropdown.Item>
                                    <Dropdown.Divider />
                                    <Dropdown.Item onClick={() => {
                                        excluirEstoque(item);
                                      }}>
                                      Excluir
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </span>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </LoadingOverlay>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={modalEstoque} onHide={() => handleCloseModalEstoque()}>
        <Modal.Header closeButton>
          <Modal.Title>{frmEstoque?.titulo}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <label>Nome do Item</label>
            <input
              onKeyPress={(e) => {
                if (e.key === "Enter") sendFrmEstoque();
              }}
              type="text"
              className="form-control"
              placeholder="Ex. Alface"
              value={frmEstoque?.nome_item}
              onChange={(e) =>
                setFrmEstoque({ ...frmEstoque, nome_item: e.target.value })
              }
            />
          </div>
          <label>Status </label>
          <Select
            options={status}
            value={status.find((x) => x.value === frmEstoque?.status)}
            onChange={(event) => {
              setFrmEstoque({ ...frmEstoque, status: event.value });
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={() => {
              handleCloseModalEstoque();
            }}
            type="button"
            className="btn btn-light-primary font-weight-bold"
          >
            Fechar
          </button>
          <button
            onClick={() => {
              sendFrmEstoque();
            }}
            type="button"
            className="btn btn-success font-weight-bold"
          >
            Salvar
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default withRouter(Stock);
