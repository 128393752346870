import React from "react";

import useUpdateElectron from "../../hooks/useUpdateElectron";

export default function Update() {
  const { text, progressPercent } = useUpdateElectron()

  return (
    <div>
      <div className="d-flex flex-column-fluid flex-center login-area-center">
        <div className="mt-lg-10 text-center pb-15">
          <div href="#">
            <center>
              <img
                alt="Logo"
                src="assets/media/logos/aiboo.png"
                className="logo-sticky mb-10"
                style={{ width: "200px", textAlign: "center", margin: "auto" }}
              />
            </center>
          </div>
          <h3 className="font-weight-bolder text-dark display5">
            Atualizando...
          </h3>
          <span className="font-size-h6">
            Estamos atualizando o seu gerenciador Aiboo. Isso pode demorar um
            pouco.
          </span>
          <br />
          {!!progressPercent && (
            <div className="progress mt-10" style={{ height: "25px" }}>
              <div
                className="progress-bar progress-bar-striped progress-bar-animated bg-success font-weight-bolder font-size-h6"
                role="progressbar"
                style={{ width: `${progressPercent}%` }}
                aria-valuenow="75"
                aria-valuemin="0"
                aria-valuemax="100"
              >
                {progressPercent}%
              </div>
            </div>
          )}
          <span>{text}</span>
        </div>
      </div>
    </div>
  );
}
