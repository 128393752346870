import React, { useRef, useState } from "react";

import * as S from "./styles";

import UploadProgressBar from "./components/UploadProgressBar";
import UploadInstructions from "./components/UploadInstructions";
import UploadConfigForm from "./components/UploadConfigForm";
import PageHeader from "../../components/PageHeader";

const BulkUpload = () => {
  const [uploadedFile, setUploadedFile] = useState({});
  const [bulkUploadId, setBulkUploadId] = useState(null);
  const [pageStatus, setPageStatus] = useState("idle");

  const fileInputRef = useRef(null);

  return (
    <S.Container className="container">
      <PageHeader
        title="Upload em massa"
        subTitle="Cadastre e atualize múltiplos produtos em sua loja"
      />

      <S.CardContent>
        <UploadProgressBar
          uploadedFile={uploadedFile}
          setUploadedFile={setUploadedFile}
          bulkUploadId={bulkUploadId}
          setBulkUploadId={setBulkUploadId}
          fileInputRef={fileInputRef}
          pageStatus={pageStatus}
          setPageStatus={setPageStatus}
        />
        <UploadInstructions />
        <UploadConfigForm
          uploadedFile={uploadedFile}
          setUploadedFile={setUploadedFile}
          bulkUploadId={bulkUploadId}
          setBulkUploadId={setBulkUploadId}
          fileInputRef={fileInputRef}
          pageStatus={pageStatus}
          setPageStatus={setPageStatus}
        />
      </S.CardContent>
    </S.Container>
  );
};
export default BulkUpload;
