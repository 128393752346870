import React from "react";
import { FiXCircle } from "react-icons/fi";
import { useToasts } from "react-toast-notifications";

import {
  Container,
  ContainerCancelButton,
  Badge,
  CancelIcon,
  CancelButton,
  ContainerCancel,
} from "./styles";

export function CodeButton({ title, disabled, onClick = () => {} }) {
  return (
    <Container onClick={disabled ? () => {} : onClick} disabled={!!disabled}>
      Vincular Motoboy
    </Container>
  );
}

export function CodeCancelButton({ disabled, code, onClick = () => {} }) {
  const { addToast } = useToasts();

  const handleCopy = () => {
    navigator.clipboard.writeText && navigator.clipboard.writeText(code);
    // copyToClipboard(code);
    addToast("Código copiado para a área de transferência!", {
      autoDismiss: true,
      autoDismissTimeout: 2000,
    });
  };

  return (
    <ContainerCancel>
      <ContainerCancelButton
        onClick={disabled ? () => {} : handleCopy}
        disabled={!!disabled}
      >
        Cód. de vinculo
        <Badge>{code}</Badge>
      </ContainerCancelButton>
      <CancelButton>
        <CancelIcon onClick={disabled ? () => {} : onClick}>
          <FiXCircle size={24} />
        </CancelIcon>
      </CancelButton>
    </ContainerCancel>
  );
}
