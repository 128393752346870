export function generateRandomHash(length, prefix = "") {
    const map = [
      '0123456789',
      "ABCDEFGHIJKLMNOPQRSTUVWXYZ",
      // "abcdefghijklmnopqrstuvwxyz",
      // "!@#$%&*()_+-=[]{}|;:,.<>?"
    ];

    const characters = map.join('').split('');

    let hash = '';
    for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        hash += characters[randomIndex];
    }
    return `${prefix}${hash}`;
}
