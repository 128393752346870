import React, { useState, useEffect } from 'react';
import Api from '../../../../services/api';
import { withRouter } from "react-router";

import "./index.scss";

import Swal from "sweetalert2";
import { useToasts } from 'react-toast-notifications';
import LoadingOverlay from 'react-loading-overlay';
import Axios from 'axios';

const FCDocumentos = () => {
  const { addToast } = useToasts();
  const [ caProcessa, setCaProcessa ] = useState(false);
  const [ pagOnCadastro, setPagOnCadastro ] = useState([]);
  const refImgIdentificacao= React.useRef(null);
  const refImgResidencia = React.useRef(null);
  const refImgAtividade = React.useRef(null);

  useEffect(() => {
    getPagOnCadastro();
  }, []);

  function getPagOnCadastro(){
    setCaProcessa(true);
    var data = {
      id_estabelecimento: localStorage.getItem('id_estabelecimento')
    };

    Api.post('pagamentoonline/listarPagOnCadastro', data).then(rps => {
      
      setPagOnCadastro(rps.data.obj);
    });
    setCaProcessa(false);
  }

  //Documento - Identificação
  async function handleCadIdentificacaoChange(e) {
    setCaProcessa(true);
    const form_data = new FormData();
    form_data.append('arquivo', e.target.files[0]);
    form_data.append('id_estabelecimento', localStorage.getItem('id_estabelecimento'));
        
    const cf = {
        baseURL: Api.defaults.baseURL,
        headers: {
          'content-type': 'multipart/form-data',
          'Authorization' : Api.defaults.headers['Authorization']
      }
    }
    const response = await Axios.post('pagamentoonline/imgidentificacao', form_data, cf);
    if (response.data.status !== undefined && response.data.status === true) {
      addToast(response.data.mensagem, {
        appearance: "success",
        autoDismiss: true,
        autoDismissTimeout: 5000
      });
      setPagOnCadastro({...pagOnCadastro, est_imgIdentificacao: response.data.url_imagem});
      setCaProcessa(false);
    } else {
      Swal.fire({
        title: "Atenção!",
        icon: "info",
        html: response.data.erros,
        showCloseButton: true,
        showCancelButton: false
      });
    }   
  }

  //Documento - Residencia
  async function handleCadResidenciaChange(e) {
    setCaProcessa(true);
    const form_data = new FormData();
    form_data.append('arquivo', e.target.files[0]);
    form_data.append('id_estabelecimento', localStorage.getItem('id_estabelecimento'));
        
    const cf = {
        baseURL: Api.defaults.baseURL,
        headers: {
          'content-type': 'multipart/form-data',
          'Authorization' : Api.defaults.headers['Authorization']
      }
    }
    const response = await Axios.post('pagamentoonline/imgresidencia', form_data, cf);
    if (response.data.status !== undefined && response.data.status === true) {
      addToast(response.data.mensagem, {
        appearance: "success",
        autoDismiss: true,
        autoDismissTimeout: 5000
      });
      setPagOnCadastro({...pagOnCadastro, est_imgResidencia: response.data.url_imagem});
      setCaProcessa(false);
    } else {
      Swal.fire({
        title: "Atenção!",
        icon: "info",
        html: response.data.erros,
        showCloseButton: true,
        showCancelButton: false
      });
    }   
  }

  //Documento - Atividade
  async function handleCadAtividadeChange(e) {
    setCaProcessa(true);
    const form_data = new FormData();
    form_data.append('arquivo', e.target.files[0]);
    form_data.append('id_estabelecimento', localStorage.getItem('id_estabelecimento'));
        
    const cf = {
        baseURL: Api.defaults.baseURL,
        headers: {
          'content-type': 'multipart/form-data',
          'Authorization' : Api.defaults.headers['Authorization']
      }
    }
    const response = await Axios.post('pagamentoonline/imgatividade', form_data, cf);
    if (response.data.status !== undefined && response.data.status === true) {
      addToast(response.data.mensagem, {
        appearance: "success",
        autoDismiss: true,
        autoDismissTimeout: 5000
      });
      setPagOnCadastro({...pagOnCadastro, est_imgAtividade: response.data.url_imagem});
      setCaProcessa(false);
    } else {
      Swal.fire({
        title: "Atenção!",
        icon: "info",
        html: response.data.erros,
        showCloseButton: true,
        showCancelButton: false
      });
    }   
  }
        
  return (
    <>

  <div className="subheader espaco-header-pedidos subheader-transparent" id="kt_subheader">
    <div className="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
      {/*begin::Details*/}
      <div className="d-flex align-items-center flex-wrap mr-2">
        {/*begin::Title*/}
        <h5 className="text-dark font-weight-bold mt-2 mr-5">Financeiro</h5>
        {/*end::Title*/}
        {/*begin::Separator*/}
        <div className="subheader-separator subheader-separator-ver mt-2 mb-2 bg-gray-200" />
        {/*end::Separator*/}
        {/*begin::Title*/}
        <h6 className="text-dark mt-2 mr-5 ml-5">Meu Cadastro</h6>
        {/*end::Title*/}
        {/*begin::Separator*/}
        <div className="subheader-separator subheader-separator-ver mt-2 mb-2 bg-gray-200" />
        {/*end::Separator*/}
        {/*begin::Title*/}
        <span className="breadcrumb breadcrumb-transparent mt-5 mr-5">Documentos</span>
        {/*end::Title*/}
      </div>
      {/*end::Details*/}
      {/*begin::Toolbar*/}
      <div className="d-flex align-items-center">
      </div>
      {/*end::Toolbar*/}
    </div>
  </div>
        
  {/*begin::Entry*/}
  <div className="d-flex flex-column-fluid mb-10">
    {/*begin::Container*/}
    <div className="container">
      <div className="card card-custom">
        <div className="ml-10 mr-10 mt-10 mb-10">   

        {/*begin::Group - Pagamento Online - Arquivos*/}
              <h6 className="font-weight-boldest">Identificação</h6>
                <div className="row">
                  <div className="col-md-6">
                    Documento com foto do proprietário do estabelecimento (RG, CPF ou CNH) ou Cartão CNPJ (no caso de PJ)
                  </div>
                  <div className="col-md-6">
                    <LoadingOverlay
                      active={caProcessa}
                      spinner
                      text='Carregando...'
                    >
                    <div className="area-imagem dropzone--squared menu-category-drawer-item-details-tab__image">
                      <div className="dropzone__area" aria-disabled="false" style={{position: 'relative'}} 
                        onClick={e => {
                          
                            refImgIdentificacao.current.click();}
                          
                          } >
                        <div className="dropzone-preview dropzone__preview">
                          <img className="dropzone-preview__image" src={pagOnCadastro?.est_imgIdentificacao ? pagOnCadastro?.est_imgIdentificacao : "assets/media/no-img2.jpg"} alt="" />
                        </div>
                        <div className="dropzone-placeholder dropzone__placeholder">
                          <span>Trocar imagem</span>
                        </div>
                        <input type="file" ref={refImgIdentificacao} name="imagemLogo" onChange={(e) => handleCadIdentificacaoChange(e)} accept=".png, .jpg, .jpeg" className="classeInputImage" />
                      </div>
                    </div>
                    </LoadingOverlay> 
                </div>
              </div>
              
              <hr />

              <h6 className="font-weight-boldest">Residência</h6>
                <div className="row">
                  <div className="col-md-6">
                    Conta de luz, telefone ou gás no nome do proprietário do estabelecimento ou de familiares
                  </div>
                  <div className="col-md-6">
                    <LoadingOverlay
                      active={caProcessa}
                      spinner
                      text='Carregando...'
                    >
                    <div className="area-imagem dropzone--squared menu-category-drawer-item-details-tab__image">
                      <div className="dropzone__area" aria-disabled="false" style={{position: 'relative'}} 
                        onClick={e => {
                         
                            refImgResidencia.current.click();}

                          } >
                        <div className="dropzone-preview dropzone__preview">
                          <img className="dropzone-preview__image" src={pagOnCadastro?.est_imgResidencia ? pagOnCadastro?.est_imgResidencia : "assets/media/no-img2.jpg"} alt="" />
                        </div>
                        <div className="dropzone-placeholder dropzone__placeholder">
                          <span>Trocar imagem</span>
                        </div>
                        <input type="file" ref={refImgResidencia} name="imagemLogo" onChange={(e) => handleCadResidenciaChange(e)} accept=".png, .jpg, .jpeg" className="classeInputImage" />
                      </div>
                    </div>
                    </LoadingOverlay>
                  </div>
                </div>

              <hr />

              <h6 className="font-weight-boldest">Atividade</h6>
                <div className="row">
                  <div className="col-md-6">
                    Alvará, nota fiscal de compras de produtos ou cartão de visitas do estabelecimento        
                  </div>
                  <div className="col-md-6">
                  <LoadingOverlay
                      active={caProcessa}
                      spinner
                      text='Carregando...'
                    >
                    <div className="area-imagem dropzone--squared menu-category-drawer-item-details-tab__image">
                      <div className="dropzone__area" aria-disabled="false" style={{position: 'relative'}} 
                        onClick={e => {

                            refImgAtividade.current.click();}
                          
                          } >
                        <div className="dropzone-preview dropzone__preview">
                          <img className="dropzone-preview__image" src={pagOnCadastro?.est_imgAtividade ? pagOnCadastro?.est_imgAtividade : "assets/media/no-img2.jpg"} alt="" />
                        </div>
                        <div className="dropzone-placeholder dropzone__placeholder">
                          <span>Trocar imagem</span>
                        </div>
                        <input type="file" ref={refImgAtividade} name="imagemLogo" onChange={(e) => handleCadAtividadeChange(e)} accept=".png, .jpg, .jpeg" className="classeInputImage" />
                      </div>
                    </div> 
                  </LoadingOverlay>
                  </div>
                </div>  
            {/*end::Group - Pagamento Online - Arquivos*/}     
        </div>
        <div class="card-footer text-right">
       
        </div>
      </div>
    </div>
  </div>
                  
            
  </>         
        
  );
}

export default withRouter(FCDocumentos);                  