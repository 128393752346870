/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useMemo } from "react";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import Select from "react-select";
import { useToasts } from "react-toast-notifications";
import Swal from "sweetalert2";

const DragHandle = SortableHandle(() => (
  <i style={{ cursor: "move" }} className="fas fa-bars f16" />
));

const SortableItemVariacoes = SortableElement(
  ({
    item,
    //
    editarVariacao,
    duplicarVariacao,
    excluirVariacao,
    MudaStatus,
  }) => {
    const statusVar = useMemo(
      () => [
        { value: "S", label: "Ativo" },
        { value: "F", label: "Em falta" },
        { value: "O", label: "Oculto" },
      ],
      []
    );

    const { addToast } = useToasts();

    return (
      <div
        key={item.id_cardapio_variacao}
        className="table-item padding-horizontal area-margin-composicao"
      >
        <div className="table-item-produto-variacoes">
          <DragHandle />
        </div>
        <div className="table-item-produto-variacoes">
          {item.nome_variacao}
          {item.id_cardapio === "0" && (
            <>
              <br />
              <span className="font-weight-bolder font-size-xs text-primary">
                <i className="icon-xs text-primary fas fa-globe" /> VARIAÇÃO
                GLOBAL
              </span>
            </>
          )}
        </div>
        <div className="table-item-produto-variacoes">
          {item.escolha_minima} / {item.escolha_maxima}
        </div>
        <div className="table-item-produto-variacoes">
          {item.calculo_preco === "mp" && `Maior preço`}
          {item.calculo_preco === "pm" && `Menor preço`}
          {item.calculo_preco === "mm" && `Média preços`}
          {item.calculo_preco === "st" && `Soma Total`}
          {item.calculo_preco === "in" && `Incremental`}
        </div>
        <div className="table-item-produto-variacoes">
          {item?.tamanho_nome ? item?.tamanho_nome : "TODOS"}
        </div>
        <div className="table-item-produto-variacoes">
          <Select
            options={statusVar}
            defaultValue={statusVar.find((x) => x.value === item.status_cv)}
            onChange={(event) => {
              item.status_cv = event.value;
              MudaStatus(
                "cardapiovariacoes/mudaSts",
                {
                  id_cardapio_variacao: item.id_cardapio_variacao,
                  status: event.value,
                },
                addToast
              );
            }}
          />
        </div>
        <div className="table-item-produto-variacoes ml-5">

            <div className="dropdown dropdown-inline">
              
              <button
                type="button"
                className="btn btn-light-primary btn-icon btn-sm"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i className="ki ki-bold-more-ver"></i>
              </button> 
              
              <div className="dropdown-menu">
              {item.id_cardapio !== "0" && <> 
                <a
                  className="dropdown-item"
                  onClick={(e) => {
                    editarVariacao(item);
                  }}
                >
                  Editar
                </a>
              
                <a
                  className="dropdown-item"
                  onClick={(e) => {
                    duplicarVariacao(item);
                  }}
                >
                  Duplicar
                </a>
                <div className="dropdown-divider"></div>
                </> }
                <a
                  className="dropdown-item"
                  onClick={(e) => {
                    excluirVariacao(item);
                  }}
                >
                  Excluir
                </a>
              </div>
            </div>
         
        </div>
      </div>
    );
  }
);

const SortableListVariacoes = SortableContainer(
  ({
    items,
    //
    editarVariacao,
    duplicarVariacao,
    excluirVariacao,
    MudaStatus,
  }) => {
    return (
      <div>
        {items?.map((value, index) => (
          <SortableItemVariacoes
            key={`item-${value.id_cardapio_variacao}`}
            index={index}
            item={value}
            //
            editarVariacao={editarVariacao}
            duplicarVariacao={duplicarVariacao}
            excluirVariacao={excluirVariacao}
            MudaStatus={MudaStatus}
          />
        ))}
      </div>
    );
  }
);

function SortableComponentVariacoes({
  selectedProductVariacoes,
  onSortEnd,
  //
  editarVariacao,
  duplicarVariacao,
  excluirVariacao,
  MudaStatus,
}) {
  return (
    <SortableListVariacoes
      items={selectedProductVariacoes}
      onSortEnd={onSortEnd}
      axis="y"
      helperClass="dragclass"
      useDragHandle={true}
      //
      editarVariacao={editarVariacao}
      duplicarVariacao={duplicarVariacao}
      excluirVariacao={excluirVariacao}
      MudaStatus={MudaStatus}
    />
  );
}

export default SortableComponentVariacoes;
