import React, { useState, useEffect } from 'react';
import Api from '../../../../services/api';
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import { Doughnut  } from 'react-chartjs-2';

import Swal from "sweetalert2";
import { useToasts } from 'react-toast-notifications';
import LoadingOverlay from 'react-loading-overlay';

import ReactTooltip from 'react-tooltip';

import DataTable from 'react-data-table-component';
import { Modal } from "react-bootstrap";

import DatePicker, { registerLocale } from "react-datepicker";
import InputMask from 'react-input-mask';
import pt from "date-fns/locale/pt-BR";
registerLocale("pt-BR", pt);

const FVHistorico = () => {
  const { addToast } = useToasts();
  const [ caProcessa, setCaProcessa ] = useState(false);
  const [ pedidos, setPedidos ] = useState([]);
  const [ periodo, setPeriodo ] = useState(0);
  const [ dataInicial, setDataInicial ] = useState();
  const [ dataFinal, setDataFinal ] = useState(new Date());

  const [ pedidosCountTotal, setPedidosCountTotal ] = useState(0);
  const [ pedidosSomaTotal, setPedidosSomaTotal ] = useState(0);
  const [ pedidosCountDinheiro, setPedidosCountDinheiro ] = useState(0);
  const [ pedidosSomaDinheiro, setPedidosSomaDinheiro ] = useState(0);
  const [ pedidosCountCartao, setPedidosCountCartao ] = useState(0);
  const [ pedidosSomaCartao, setPedidosSomaCartao ] = useState(0);
  const [ pedidosCountOnline, setPedidosCountOnline ] = useState(0);
  const [ pedidosSomaOnline, setPedidosSomaOnline ] = useState(0);

   useEffect(() => {
    
  }, []);
  
  function filtrar() {
    setCaProcessa(true); 
    var data = {
      periodo: periodo,
      data_inicio: dataInicial,
      data_final: dataFinal,
      id_estabelecimento: localStorage.getItem('id_estabelecimento')
    };

    Api.post("pagamentoonline/gerahistorico", data).then(rps => {

        if(rps.data.status==false){
          Swal.fire({
            title: "Atenção!",
            icon: "info",
            html: rps.data.erro,
            showCloseButton: true,
            showCancelButton: false,
          });
        } else {
          setPedidos(rps.data.retorno);
          processaDados(rps.data.retorno);
        }
      setCaProcessa(false); 
    });
  }

  function processaDados(dados){
    var countPedidosTotal = 0;
    var sumPedidosTotal = 0;
    var countPedidosCartao = 0;
    var sumPedidosCartao = 0;
    var countPedidosDinheiro = 0;
    var sumPedidosDinheiro = 0;
    var countPedidosOnline = 0;
    var sumPedidosOnline = 0;

    dados.map( pedido => {
        if(pedido?.status==='PD'){
            countPedidosTotal++;
            sumPedidosTotal+= parseFloat(pedido?.valor_total);

            if (pedido?.pagamento == -2 || pedido?.pagamento == -10 || pedido?.pagamento == -11|| pedido?.pagamento == -12){
              countPedidosOnline++;
              sumPedidosOnline+= parseFloat(pedido?.valor_total);
            } else if (pedido?.pagamento == -1) {
              countPedidosDinheiro++;
              sumPedidosDinheiro+= parseFloat(pedido?.valor_total);
            } else {
              countPedidosCartao++;
              sumPedidosCartao+= parseFloat(pedido?.valor_total);
            }
        }
      return null
    });
    setPedidosCountTotal(countPedidosTotal);
    setPedidosSomaTotal(sumPedidosTotal.toLocaleString('pt-br', {minimumFractionDigits: 2}));
    setPedidosCountOnline(countPedidosOnline);
    setPedidosSomaOnline(sumPedidosOnline);
    setPedidosCountCartao(countPedidosCartao);
    setPedidosSomaCartao(sumPedidosCartao);
    setPedidosCountDinheiro(countPedidosDinheiro);
    setPedidosSomaDinheiro(sumPedidosDinheiro);
}

const data = {
  labels: ['Cartão', 'Dinheiro', 'Online' ],
  datasets: [
      {
          data: [pedidosCountCartao, pedidosCountDinheiro, pedidosCountOnline ],
          backgroundColor: ['#212121', '#1BC5BD','#F64E60'],
          hoverBackgroundColor: ['#212121', '#1BC5BD','#F64E60'],
      }
  ]
};

const dataValor = {
  labels: ['Cartão', 'Dinheiro', 'Online' ],
  datasets: [
      {
          data: [pedidosSomaCartao, pedidosSomaDinheiro, pedidosSomaOnline ],
          backgroundColor: ['#212121', '#1BC5BD','#F64E60'],
          hoverBackgroundColor: ['#212121', '#1BC5BD','#F64E60'],
      }
  ]
};

const options = {
  legend: {
    display: true,
    position: "left"
  }
};
    
    return (
        <><ReactTooltip />
<div>

<div className="subheader espaco-header-pedidos subheader-transparent" id="kt_subheader">
          <div className="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
            {/*begin::Details*/}
            <div className="d-flex align-items-center flex-wrap mr-2">
              {/*begin::Title*/}
              <h5 className="text-dark font-weight-bold mt-2 mr-5">Financeiro</h5>
              {/*end::Title*/}
              {/*begin::Separator*/}
              <div className="subheader-separator subheader-separator-ver mt-2 mb-2 bg-gray-200" />
              {/*end::Separator*/}
              {/*begin::Title*/}
              <h6 className="text-dark mt-2 mr-5 ml-5">Minhas Vendas</h6>
              {/*end::Title*/}
              {/*begin::Separator*/}
              <div className="subheader-separator subheader-separator-ver mt-2 mb-2 bg-gray-200" />
              {/*end::Separator*/}
              {/*begin::Title*/}
              <span className="breadcrumb breadcrumb-transparent mt-5 mr-5">Histórico</span>
              {/*end::Title*/}
            </div>
            {/*end::Details*/}
            {/*begin::Toolbar*/}
            <div className="d-flex align-items-center">
              
            </div>
            {/*end::Toolbar*/}
          </div>
        </div>
        {/*begin::Entry*/}
        <div className="d-flex flex-column-fluid">
          {/*begin::Container*/}
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="card card-custom mr-5 pt-5 pl-5 pr-5 pb-5">
                  <div className="row border-bottom">            
                    <div className="col-md-1">
                      <label><i className="fas fa-filter mr-3"></i><b>Filtros</b></label>
                    </div>                              
                    <div className="col-md-2 form-group">
                        <select onChange={(e) => { setPeriodo(e.target.value)}} className="form-control">
                          <option value="0">Dia atual</option>
                          <option value="7">Últimos 7 dias</option>
                          <option value="30">Últimos 30 dias</option>
                          <option value="60">Últimos 60 dias</option>
                          <option value="90">Últimos 90 dias</option>
                          <option value="PP">Período Personalizado</option>
                        </select>
                    </div>
                    { periodo === "PP" && <>
                      <div className="col-md-2 form-group">
                          <div className="input-group">
                          <DatePicker 
                            onChange={setDataInicial}
                            isClearable
                            selectsStart
                            locale="pt-BR"
                            className="form-control"
                            placeholderText="dd/mm/aaaa" 
                            selected ={dataInicial}
                            dateFormat="dd/MM/y"
                            startDate={dataInicial}
                            endDate={dataFinal}
                            customInput={
                              <InputMask mask="99/99/9999" />}/>
                          </div>
                      </div>
                      <div className="col-md-2 form-group">
                        <div className="input-group">
                          <DatePicker 
                            onChange={setDataFinal}
                            isClearable
                            locale="pt-BR"
                            className="form-control"
                            placeholderText="dd/mm/aaaa" 
                            selected ={dataFinal}
                            dateFormat="dd/MM/y"
                            selectsEnd
                            startDate={dataInicial}
                            endDate={dataFinal}
                            minDate={dataInicial} 
                            customInput={
                              <InputMask mask="99/99/9999" />}/>
                          </div>
                        </div>
                      </>
                    }
                    <div className="col-md-2">
                      {/*begin::Button*/}
                      <button onClick={e => { filtrar(); }} className="btn btn-primary btn-block"><i className="fas fa-filter" /> Filtrar</button>
                      {/*end::Button*/}
                      {/*begin::Button*/}
                    </div>   
                  </div>

                  <div className="row mt-3">
                    <div className="col-md-2">
                      <label>Valor total</label> <i className="fas fa-question-circle" data-tip={"Valor total = Valor em Produtos + Taxa Entrega"} /><br />
                      <h2 className="text-primary"><small>R$ </small>{pedidosSomaTotal}</h2>
                      <br />
                      <label>Quantidade</label><br />
                      <h2 className="text-primary">{pedidosCountTotal}</h2>
                    </div>

                    <div className="col-md-6 border-left">
                      <div className="row">
                        <div className="col text-center">
                          <label><b>Tipo de venda</b></label>
                        </div>
                        <div className="col text-center">
                          <label><b>Nº de vendas</b></label>
                        </div>
                        <div className="col text-center">
                          <label><b>Total </b><i className="fas fa-question-circle" data-tip={"Total = Valor em Produtos + Taxa Entrega"} /></label>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col text-center">
                          <label>Cartão</label>
                        </div>
                        <div className="col text-center">
                          <label>{pedidosCountCartao}</label>
                        </div>
                        <div className="col text-right">
                          <label>{pedidosSomaCartao.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</label>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col text-center">
                          <label>Dinheiro</label>
                        </div>
                        <div className="col text-center">
                          <label>{pedidosCountDinheiro}</label>
                        </div>
                        <div className="col text-right">
                          <label>{pedidosSomaDinheiro.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</label>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col text-center">
                          <label>Online</label>
                        </div>
                        <div className="col text-center">
                          <label>{pedidosCountOnline}</label>
                        </div>
                        <div className="col text-right">
                          <label>{pedidosSomaOnline.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</label>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-3 border-left">
                      <ul class="nav nav-tabs" id="myTab" role="tablist">
                        <li class="nav-item">
                          <a class="nav-link active" id="qtde-tab" data-toggle="tab" href="#qtde">
                            <span class="nav-text">Nº de Vendas</span>
                          </a>
                        </li>
                        <li class="nav-item">
                          <a class="nav-link" id="valor-tab" data-toggle="tab" href="#valor">
                            <span class="nav-text">Valor</span>
                          </a>
                        </li>				
										  </ul>

                      <div class="tab-content mt-5" id="myTabContent">
                        <div class="tab-pane fade show active" id="qtde" role="tabpanel" aria-labelledby="qtde-tab">
                          <Doughnut data={data} options={options} />
                        </div>
                        <div class="tab-pane fade" id="valor" role="valor" aria-labelledby="valor-tab">
                          <Doughnut data={dataValor} options={options} />
                        </div>
                      </div>


                      
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <br />

            <div className="card card-custom">
              <div className="ml-10 mr-10 mt-10">    

              <LoadingOverlay
                active={caProcessa}
                spinner
                text='Carregando...'
              >                 
                <table className="table table-striped">
                        <thead>                       
                            <tr>
                                <th className="text-center">Hash</th>
                                <th>Cliente</th>
                                <th className="text-center">Data</th>
                                <th className="text-center">Valor em Produtos</th>
                                <th className="text-center">Desconto</th>
                                <th className="text-center">Taxa Entrega</th>
                                <th className="text-center">Pagamento</th>
                            </tr>
                        </thead>

                        <tbody>

                        {
                        pedidos !== null &&
                        pedidos.map( pedido => {
                            var pagamento = '';
                            var imagemPagamento = '';

                            if (pedido?.pagamento == -2 || pedido?.pagamento == -10 || pedido?.pagamento == -11 || pedido?.pagamento == -12) {
                                pagamento = 'Online'
                                imagemPagamento = 'fas fa-link text-danger';
                            } else if(pedido?.pagamento == -1){
                                pagamento = 'Dinheiro';
                                imagemPagamento = 'far fa-money-bill-alt text-success';
                            } else {
                                pagamento = 'Cartão';
                                imagemPagamento = 'far fa-credit-card text-dark';
                            }

                            return(
                            <>
                            <ReactTooltip />
                            { pedido?.status === 'PD' &&
                            <tr>
                                <td>{pedido.hash_pedido}</td>
                                <td>{pedido.usuario_nome}</td>
                                <td className="text-center">{pedido.data_criacao}</td>
                                <td className="text-right">{pedido.valor_pedido}</td>
                                <td className="text-right">{pedido.valor_desconto}</td>
                                <td className="text-right">{pedido.valor_taxa}</td>
                                <td className="text-center">
                                  <i className={""+imagemPagamento+""} data-tip={""+pagamento+""} />
                                </td>
                            </tr>
                            }
                            </>)
                        })}

                        </tbody>
                    </table>
                </LoadingOverlay>                    
              </div>
            </div>
          </div>
        </div>            
      </div>           
    </>
    );
}

export default withRouter(FVHistorico);                  