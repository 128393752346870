import React, { createContext, useContext, useMemo } from "react";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import Select from "react-select";
import { useToasts } from "react-toast-notifications";
import CurrencyInput from "react-currency-input";

const DragHandle = SortableHandle(() => (
  <i style={{ cursor: "move" }} className="fas fa-bars f16" />
));

const ItemSizeContext = createContext({});

const SortableItemSize = SortableElement(({ item }) => {
  const status = useMemo(
    () => [
      { value: "S", label: "Ativo" },
      { value: "O", label: "Oculto" },
      { value: "N", label: "Inativo" },
    ],
    []
  );
  const { addToast } = useToasts();
  const {
    MudaStatus,
    frmEdtTamanho,
    setFrmEdtTamanho,
    sendFormTamanho,
    editarTamanho,
    excluirTamanho,
  } = useContext(ItemSizeContext);

  return (
    <div
      key={item.id_cardapio_tamanho}
      className="table-item padding-horizontal area-margin-info"
      style={{ zIndex: 9999 }}
    >
      <div className="table-item-produto-tamanhos">
        <DragHandle />
      </div>
      {frmEdtTamanho?.id_cardapio_tamanho === item.id_cardapio_tamanho ? (
        <>
          <div className="table-item-produto-tamanhos">
            <input
              value={frmEdtTamanho?.nome}
              onChange={(e) =>
                setFrmEdtTamanho((oldFormSize) => ({
                  ...oldFormSize,
                  nome: e.target.value,
                }))
              }
              className="form-control form-control-solid border border-secondary border-2"
              type="text"
              placeholder="Nome do Tamanho"
            />
          </div>
          <div className="table-item-produto-tamanhos">
            <CurrencyInput
              placeholder="Preço em reais"
              maxLength={6}
              precision={"2"}
              value={frmEdtTamanho?.preco}
              onChangeEvent={(e) =>
                setFrmEdtTamanho((oldFormSize) => ({
                  ...oldFormSize,
                  preco: e.target.value,
                }))
              }
              decimalSeparator="."
              className="form-control form-control-lg form-control-solid border border-secondary border-2"
              type="text"
            />
          </div>
        </>
      ) : (
        <>
          <div className="table-item-produto-tamanhos">{item.nome}</div>
          <div className="table-item-produto-tamanhos">R$ {item.preco}</div>
        </>
      )}
      <div className="table-item-produto-tamanhos">
        <Select
          options={status}
          defaultValue={status.find((x) => x.value === item.status)}
          onChange={(event) => {
            item.status = event.value;
            MudaStatus(
              "cardapiotamanhos/mudaSts",
              {
                id_cardapio_tamanho: item.id_cardapio_tamanho,
                status: event.value,
              },
              addToast
            );
          }}
        />
      </div>
      {frmEdtTamanho?.id_cardapio_tamanho === item.id_cardapio_tamanho ? (
        <div className="table-item-produto-tamanhos text-center">
          <button
            onClick={(e) => sendFormTamanho(true)}
            className="btn btn-sm btn-clean btn-icon"
            title="Salvar Tamanho"
          >
            <i className="fa fa-check" />
          </button>
          <button
            onClick={(e) => setFrmEdtTamanho({})}
            className="btn btn-sm btn-clean btn-icon"
            title="Cancelar Edição"
          >
            <i className="fa fa-times" />
          </button>
        </div>
      ) : (
        <div className="table-item-produto-tamanhos text-center">
          <button
            onClick={(e) => editarTamanho(item)}
            className="btn btn-sm btn-clean btn-icon"
            title="Editar Tamanho"
          >
            <i className="fa fa-edit" />
          </button>
          <button
            onClick={(e) => excluirTamanho(item)}
            className="btn btn-sm btn-clean btn-icon"
            title="Apagar Tamanho"
          >
            <i className="fa fa-trash" />
          </button>
        </div>
      )}
    </div>
  );
});

const SortableListSizes = SortableContainer(({ items }) => {
  return (
    <div>
      {items?.map((value, index) => (
        <SortableItemSize
          key={`item-${value.id_cardapio_tamanho}`}
          index={index}
          item={value}
        />
      ))}
    </div>
  );
});

function SortableComponentSizes({
  selectedProductTamanhos,
  onSortEnd,
  //
  MudaStatus,
  frmEdtTamanho,
  setFrmEdtTamanho,
  sendFormTamanho,
  editarTamanho,
  excluirTamanho,
}) {
  return (
    <ItemSizeContext.Provider
      value={{
        MudaStatus,
        frmEdtTamanho,
        setFrmEdtTamanho,
        sendFormTamanho,
        editarTamanho,
        excluirTamanho,
      }}
    >
      <SortableListSizes
        items={selectedProductTamanhos}
        onSortEnd={onSortEnd}
        axis="y"
        useDragHandle
      />
    </ItemSizeContext.Provider>
  );
}

export default SortableComponentSizes;
