import Api from '../services/api';
import Swal from "sweetalert2";

async function ExcluirGenerico(url, parametros, addT, callback) {
    Swal.fire({
        title: "Você tem certeza?",
        text: "Não será possível reverter!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim deletar.",
        cancelButtonText: "Cancelar"
    }).then(result => {
        if (result.value) {
            Api.post(url, parametros).then((rps) => {
                if(rps.data.status===true){
                    addT(rps.data.mensagem, {
                        appearance: "success",
                        autoDismiss: true,
                        autoDismissTimeout: 2000
                    });
                    callback();
                } else {
                    Swal.fire({
                        title: "Atenção!",
                        icon: "info",
                        html: rps.data.erro,
                        showCloseButton: true,
                        showCancelButton: false
                    });
                }
            });
        }
    });
}

export default ExcluirGenerico;