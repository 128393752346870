import React, { useCallback, useEffect, useState } from "react";
import * as S from "./styles";

import Axios from "axios";

import { useToasts } from "react-toast-notifications";
import { format } from "date-fns";
const UploadProgressBar = ({
  uploadedFile,
  setUploadedFile,
  bulkUploadId,
  setBulkUploadId,
  fileInputRef,
  pageStatus,
  setPageStatus,
}) => {
  const [progress, setProgress] = useState(0);
  const [totalProducts, setTotalProducts] = useState(null);
  const [addedProducts, setAddedProducts] = useState(null);
  const [hasPreviousUpload, setHasPreviousUpload] = useState(false);
  const [finishedAt, setFinishedAt] = useState(null);

  const { addToast } = useToasts();

  const uploadApi = Axios.create({
    baseURL: process.env.REACT_APP_BASEURL_AIBOO_WS_LARAVEL,
    headers: {
      "content-type": "multipart/form-data",
      Authorization: localStorage.getItem("hash"),
    },
  });

  const checkPreviousUploadStatus = useCallback(async () => {
    try {
      const response = await uploadApi.get("product-files");
      const latestUploads = response.data.product_files;

      if (
        latestUploads[latestUploads.length - 1].product_file.status ===
        "processing"
      ) {
        setBulkUploadId(
          latestUploads[latestUploads.length - 1].product_file.id
        );
        return;
      }

      const lastUpload = latestUploads.reduceRight((lastUpload, upload) => {
        const lastUploadToshowStatus =
          upload.product_file.status === "finished" ||
          upload.product_file.status === "canceled";
        return lastUpload || (lastUploadToshowStatus ? upload : null);
      }, null);
      if (lastUpload) {
        const lastUploadData = lastUpload.product_file;
        setAddedProducts(lastUploadData.added_products);
        setTotalProducts(lastUploadData.total_products);
        const formattedDateTime = format(
          new Date(lastUploadData.finished_at),
          "dd/MM/yyyy 'as' HH:mm"
        );
        setFinishedAt(formattedDateTime);
        setHasPreviousUpload(true);
      }
    } catch {
      setPageStatus("idle");
    }
  }, [setBulkUploadId, setPageStatus, uploadApi]);

  const getUploadProgress = useCallback(async () => {
    if (progress >= 100) {
      return;
    }

    if (bulkUploadId) {
      try {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        setPageStatus("uploading");
        setHasPreviousUpload(false);
        const response = await uploadApi.get(`product-files/${bulkUploadId}`);
        if (response.data.progress.percentage >= 100) {
          setUploadedFile(null);
          fileInputRef.current.value = null;
          setPageStatus("finished");
          checkPreviousUploadStatus();
          setProgress(0);
          setBulkUploadId(null);
          return;
        }

        setProgress(response.data.progress.percentage);
        setAddedProducts(response.data.product_file.added_products);
        setTotalProducts(response.data.product_file.total_products);
      } catch {
        addToast(
          "Ops! algo deu errado com o upload dos produtos, tente novamente mais tarde!",
          {
            appearance: "error",
            autoDismiss: true,
            autoDismissTimeout: 4000,
          }
        );
        return;
      }
    }
  }, [
    addToast,
    bulkUploadId,
    checkPreviousUploadStatus,
    fileInputRef,
    progress,
    setBulkUploadId,
    setPageStatus,
    setUploadedFile,
    uploadApi,
  ]);

  const handleCancelUpload = useCallback(async () => {
    try {
      await uploadApi.patch(`product-files/${bulkUploadId}/cancel`);

      setUploadedFile(null);
      fileInputRef.current.value = null;
      setPageStatus("canceled");
      setProgress(0);
      setBulkUploadId(null);
      checkPreviousUploadStatus();

      addToast("Upload cancelado!", {
        appearance: "success",
        autoDismiss: true,
        autoDismissTimeout: 4000,
      });

      return;
    } catch {
      addToast("Ops! algo deu errado com o cancelamento do upload!", {
        appearance: "error",
        autoDismiss: true,
        autoDismissTimeout: 4000,
      });
      setPageStatus("error");
    }
  }, [
    addToast,
    bulkUploadId,
    fileInputRef,
    setBulkUploadId,
    setPageStatus,
    setUploadedFile,
    uploadApi,
  ]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      getUploadProgress();
    }, 2000);

    return () => clearInterval(intervalId);
  }, [getUploadProgress]);

  useEffect(() => {
    checkPreviousUploadStatus();
  }, []);

  const renderUploadInProgress = () => (
    <div>
      <h2>Upload em progresso</h2>

      <S.UploadContainer>
        <S.ProgressBarContainer>
          <S.ProgressBar width={progress} loading={addedProducts <= 0} />
        </S.ProgressBarContainer>

        <S.CancelUploadButton onClick={handleCancelUpload}>
          Cancelar upload
        </S.CancelUploadButton>
      </S.UploadContainer>
      {addedProducts > 0 && totalProducts > 0 && (
        <small>
          {addedProducts} de {totalProducts} produtos
        </small>
      )}
    </div>
  );

  const renderUploadFinished = () => (
    <div>
      <h2>Último upload</h2>

      <small>
        {addedProducts} de {totalProducts} produtos cadastrados/atualizados,{" "}
        {finishedAt}
      </small>
    </div>
  );

  return (
    <S.Container>
      {pageStatus === "uploading" && renderUploadInProgress()}
      {hasPreviousUpload && renderUploadFinished()}
    </S.Container>
  );
};
export default UploadProgressBar;
