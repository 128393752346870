import styled, { css } from 'styled-components';

export const Container = styled.button`
  background-color: #1B73C5;
  height: 100%;
  color: #fff;
  border-radius: 8px;
  min-height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: fit-content;
  padding: 0 10px;
  border: none;
  font-size: 13px;
  transition: opacity 0.2s ease;

  ${({ disabled }) => disabled && css`
    opacity: 0.5;
    cursor: not-allowed;
  `};
`;

export const ContainerCancel = styled.div`
  background-color: #1B73C5;
  border-radius: 8px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
`;

export const ContainerCancelButton = styled.button`
  background-color: transparent;
  color: #fff;
  border-radius: 8px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: fit-content;
  padding: 0 0 0 10px;
  border: none;
  font-size: 13px;
  transition: opacity 0.2s ease;

  ${({ disabled }) => disabled && css`
    opacity: 0.5;
    cursor: not-allowed;
  `};
`;

export const Badge = styled.p`
  background-color: rgba(255, 255, 255, 0.2);
  margin-left: 8px;
  padding: 0 8px;
  border-radius: 4px;
`;

export const CancelIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  color: #fff;
`;

export const CancelButton = styled.button`
  height: 100%;
  margin-left: 8px;
  background-color: #FF3333;
  border: none;
  padding: 0 8px;
  width: 32px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
`;
