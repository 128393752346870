import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router";

import { useToasts } from 'react-toast-notifications';

const ConfiguracoesSoftware = () => {
    const { addToast } = useToasts();

    const [ mudou , setMudou ] = useState(false);

    const [ autoAceiteImprimir , setAutoAceiteImprimir ] = useState(false);

    useEffect(() =>{
        getDefaults();
    }, []);

    function getDefaults(){
      if (localStorage.getItem('autoAceiteImprimir')){
        var autoAceiteImprimir = localStorage.getItem('autoAceiteImprimir');
        if(autoAceiteImprimir==='true'){
          setAutoAceiteImprimir(true);
        }
      }
    }
    function salvarConfiguracoes(){
      localStorage.setItem('autoAceiteImprimir', autoAceiteImprimir);

      addToast('Configurações salvas com sucesso', {
        appearance: "success",
        autoDismiss: true,
        autoDismissTimeout: 5000
      });
      setMudou(false);
    }


    return (
        <>

      {/*begin::Entry*/}
      <div className="d-flex flex-column-fluid">
        {/*begin::Container*/}
        <div className="container">
          <ul className="nav nav-tabs nav-tabs-line nav-tabs-line-3x mb-5">
            <li className="nav-item">
              <a className="nav-link active" data-toggle="tab" href="#kt_tab_pane_1_4">SOFTWARE</a>
            </li>
            {/*
            <li className="nav-item">
            <a className="nav-link" disabled>LAYOUT DE IMPRESSÃO (Breve)</a>
            </li>
            */}
          </ul>
          <div className="tab-content">
          {/*<button onClick={e=>{salvarConfiguracoes()}}>
                      SALVAR CONFIGURAÇÕES
          </button>*/}

            <div className="tab-pane fade active show" id="kt_tab_pane_1_4" role="tabpanel" aria-labelledby="kt_tab_pane_1_4">
              <div className="card card-custom">
                <div className="card-header flex-wrap">
                  {/*begin::Subheader*/}
                  <div className="subheader py-2 py-lg-4 subheader-transparent" id="kt_subheader">
                    <div className="d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
                      {/*begin::Details*/}
                      <div className="d-flex align-items-center flex-wrap mr-2">
                        {/*begin::Title*/}
                        <h5 className="text-dark font-weight-bold mt-2 mb-2 mr-5">Configuração do software</h5>
                        {/*end::Title*/}
                      </div>
                      {/*end::Details*/}
                    </div>
                  </div>
                  {/*end::Subheader*/}
                </div>
                <div className="area-auto-open m-10">
                  <div className="mt-10">
                    <h4>Impressão e Aceite Automático</h4>
                    <div className="alert alert-secondary" role="alert">
                      Essa opção permite que assim que um pedido chegar, automaticamente ele seja aceito e impresso pelo sistema.
                      <div className="checkbox-inline mt-5">
                        <label className="checkbox checkbox-square checkbox-primary m-t-3">
                          <input type="checkbox" checked={autoAceiteImprimir} onChange={e=>{setMudou(true); setAutoAceiteImprimir(e.target.checked)}} />
                          Desejo utilizar o auto imprimir e aceitar.
                          <span /></label>
                      </div>
                    </div>
                  </div>

                  <br/>
                  <br/>
                  <br/>
                  <br/>
                  <br/>
                  <br/>
                  <br/>


                </div>
                    {/*
                <div className="card-footer">
													<button type="submit" className="btn btn-success font-weight-bold mr-2">Salvar Alterações</button>
												</div>
                    */}
              </div>


              <div className="botao-salvar-alteracoes">
                <button disabled={!mudou} type="button" className="btn btn-success btn-lg btn-block" onClick={e=>{salvarConfiguracoes()}}>Salvar Alterações</button>
                </div>

            </div>
          </div>
        </div>
        {/*end::Container*/}
      </div>
      {/*end::Entry*/}
        </>
    );

}

export default withRouter(ConfiguracoesSoftware);
