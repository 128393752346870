import React, { createContext, useContext } from "react";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";

const DragHandle = SortableHandle(() => (
  <i style={{ cursor: "move" }} className="fas fa-bars f16" />
));

const ComposicaoContext = createContext({});

const SortableComposicaoItem = SortableElement(({ item }) => {
  const { excluirComposicao } = useContext(ComposicaoContext);

  return (
    <div
      key={item.id_cardapio_estoque}
      className="table-item padding-horizontal area-margin-composicao"
    >
      <div className="table-item-produto-composicao">
        <DragHandle />
      </div>
      <div className="table-item-produto-composicao">{item.quantidade}</div>
      <div className="table-item-produto-composicao">{item.nome_item}</div>
      <div className="table-item-produto-composicao">
        <div className="checkbox-inline">
          <label className="checkbox checkbox-square checkbox-primary m-t-3">
            <input
              type="checkbox"
              checked={item.removivel === 1 || item.removivel === "1"}
              disabled
            />
            Sim
            <span />
          </label>
        </div>
      </div>
      <div className="table-item-produto-composicao">
        <button
          onClick={(e) => {
            excluirComposicao(item);
          }}
          className="btn btn-sm btn-clean btn-icon"
          title="Apagar Composição"
        >
          <i className="fa fa-trash" />
        </button>
      </div>
    </div>
  );
});

const SortableListComposicao = SortableContainer(({ items }) => {
  return (
    <div>
      {items?.map((value, index) => (
        <SortableComposicaoItem
          key={`item-${value.id_cardapio_estoque}`}
          index={index}
          item={value}
        />
      ))}
    </div>
  );
});

function SortableComponentComposicao({
  selectedProductIngredients,
  onSortEnd,
  //
  excluirComposicao,
}) {
  return (
    <ComposicaoContext.Provider
      value={{
        excluirComposicao,
      }}
    >
      <SortableListComposicao
        items={selectedProductIngredients}
        onSortEnd={onSortEnd}
        axis="y"
        helperClass="dragclass"
        useDragHandle={true}
      />
    </ComposicaoContext.Provider>
  );
}

export default SortableComponentComposicao;
