import React, { useState, useEffect } from 'react';
import Api from '../../../../services/api';
import { Link } from "react-router-dom";
import { withRouter } from "react-router";

import Swal from "sweetalert2";
import { useToasts } from 'react-toast-notifications';
import LoadingOverlay from 'react-loading-overlay';

import DataTable from 'react-data-table-component';
import { Modal } from "react-bootstrap";

import DatePicker, { registerLocale } from "react-datepicker";
import InputMask from 'react-input-mask';
import pt from "date-fns/locale/pt-BR";
registerLocale("pt-BR", pt);

const FCDados = () => {
  const { addToast } = useToasts();
  const [ bancos, setBancos ] = useState([]);
  const [ caProcessa, setCaProcessa ] = useState(false);
  const [ buscaCodigo, setBuscaCodigo ] = useState();
  const [ buscaNome, setBuscaNome ] = useState();
  const [ buscaStatus, setBuscaStatus ] = useState();
  const [ modal, setModal ] = useState(false);
  const [ formBanco, setFormBanco ] = useState({});
  const [ periodo, setPeriodo ] = useState(0);
  const [ dataInicial, setDataInicial ] = useState();
  const [ dataFinal, setDataFinal ] = useState(new Date());

  function fecharModal(){
    setModal(false);
  }

  const data = bancos;
  const columns = [
    {
      name: <th>Código</th>,
      selector: 'cod',
      sortable: true,
      width: '10%',
      center: true,
    },
    {
      name: <th>Nome</th>,
      selector: 'banco',
      width: '70%',
      sortable: true,
    },
    {
      name: <th>Status</th>,
      width: '10%',
      sortable: true,
      selector: 'status',
      center: 'true',
      cell: row => { if (row.status == "S") {return <span class="label label-success label-inline mr-2">Ativo</span>}
        else {return <span class="label label-secondary label-inline mr-2">Desativado</span>}
      }
    },
  ];

   useEffect(() => {
    //getListar();
  }, []);

  function getListar() { 
    setCaProcessa(true); 
    Api.get("bancos/listar").then(rps => {
      setBancos(rps.data.grupos);
      setCaProcessa(false);
    })
  }
  
  function filtrar() {
    setCaProcessa(true); 
      var data = {
        codigo: buscaCodigo,
        nome: buscaNome,
        status: buscaStatus,
      };
    
      Api.post('bancos/filtrar', data).then((rps) => {

        if(rps.data.status==false){
          Swal.fire({
            title: "Atenção!",
            icon: "info",
            html: rps.data.erro,
            showCloseButton: true,
            showCancelButton: false,
          });
        } else {
          setBancos(rps.data.obj);
        }
      setCaProcessa(false); 
    });
  }
    
    return (
        <>
<div>

<div className="subheader espaco-header-pedidos subheader-transparent" id="kt_subheader">
          <div className="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
            {/*begin::Details*/}
            <div className="d-flex align-items-center flex-wrap mr-2">
              {/*begin::Title*/}
              <h5 className="text-dark font-weight-bold mt-2 mr-5">Financeiro</h5>
              {/*end::Title*/}
              {/*begin::Separator*/}
              <div className="subheader-separator subheader-separator-ver mt-2 mb-2 bg-gray-200" />
              {/*end::Separator*/}
              {/*begin::Title*/}
              <h6 className="text-dark mt-2 mr-5 ml-5">Minhas Vendas</h6>
              {/*end::Title*/}
              {/*begin::Separator*/}
              <div className="subheader-separator subheader-separator-ver mt-2 mb-2 bg-gray-200" />
              {/*end::Separator*/}
              {/*begin::Title*/}
              <span className="breadcrumb breadcrumb-transparent mt-5 mr-5">Extrato</span>
              {/*end::Title*/}
            </div>
            {/*end::Details*/}
            {/*begin::Toolbar*/}
            <div className="d-flex align-items-center">
              
            </div>
            {/*end::Toolbar*/}
          </div>
        </div>
        {/*begin::Entry*/}
        <div className="d-flex flex-column-fluid">
          {/*begin::Container*/}
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="card card-custom mr-5 pt-5 pl-5 pr-5 pb-5">
                  <div className="row">
                    <div className="col-md-8">
                      <label>Saldo a receber</label><br />
                      <h2 className="text-success"><small>R$ </small>1100,00</h2>
                      <Link to="/financeiro/vendas/vendas/transferir" className="btn btn-sm btn-primary">Transferir</Link>
                    </div>
                    <div className="col-md-4 border-left">
                      Valor sujeito a mudanças até o final do dia
                    </div>
                  </div>
                </div>
              </div>
                
              <div className="col-md-6">
                <div className="card card-custom ml-5 pt-5 pl-5 pr-5 pb-5">
                  <div className="row">
                    <div className="col-md-8">
                      <label>Saldo disponível</label><br />
                      <h2><small>R$ </small>1100,00</h2>
                      <button className="btn btn-sm btn-primary">Ver calendário</button>
                    </div>
                    <div className="col-md-4 border-left">
                      Lançamentos previstos para entrar na sua conta digital
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <br />

            <div className="card card-custom">
              <div className="ml-10 mr-10 mt-10">    
                <div className="row">
                
                
                  <div className="col-md-1">
                    <label><i className="fas fa-filter mr-3"></i><b>Filtros</b></label>
                  </div>                
                  
                  <div className="col-md-2 form-group">
                      <select onChange={(e) => { setPeriodo(e.target.value)}} className="form-control">
                        <option value="0">Dia atual</option>
                        <option value="7">Últimos 7 dias</option>
                        <option value="30">Últimos 30 dias</option>
                        <option value="60">Últimos 60 dias</option>
                        <option value="90">Últimos 90 dias</option>
                        <option value="PP">Período Personalizado</option>
                      </select>
                  </div>
                  { periodo === "PP" && <>
                    <div className="col-md-2 form-group">
                        <div className="input-group">
                        <DatePicker 
                          onChange={setDataInicial}
                          isClearable
                          selectsStart
                          locale="pt-BR"
                          className="form-control"
                          placeholderText="dd/mm/aaaa" 
                          selected ={dataInicial}
                          dateFormat="dd/MM/y"
                          startDate={dataInicial}
                          endDate={dataFinal}
                          customInput={
                            <InputMask mask="99/99/9999" />}/>
                        </div>
                    </div>
                    <div className="col-md-2 form-group">
                      <div className="input-group">
                        <DatePicker 
                          onChange={setDataFinal}
                          isClearable
                          locale="pt-BR"
                          className="form-control"
                          placeholderText="dd/mm/aaaa" 
                          selected ={dataFinal}
                          dateFormat="dd/MM/y"
                          selectsEnd
                          startDate={dataInicial}
                          endDate={dataFinal}
                          minDate={dataInicial} 
                          customInput={
                            <InputMask mask="99/99/9999" />}/>
                        </div>
                      </div>
                    </>
                  }
                  <div className="col-md-1">
                    {/*begin::Button*/}
                    <button onClick={e => { filtrar(); }} className="btn btn-primary btn-block">Filtrar</button>
                    {/*end::Button*/}
                    {/*begin::Button*/}
                  </div>   
                </div>
                
                  
               
              

              <LoadingOverlay
                active={caProcessa}
                spinner
                text='Carregando...'
              >                 
                <DataTable
                  title="Extrato"
                  columns={columns}
                  data={data}
                  striped="true" 
                  pagination="true"
                />
              </LoadingOverlay>                    
      
                  </div>
                  </div>
                  </div>
                  </div>
                  
      </div>           
        </>
    );
}

export default withRouter(FCDados);                  