import styled from 'styled-components';

export const Container = styled.div``;

export const Label = styled.label`
    font-family: 'Poppins';
    font-style: normal;
    font-size: 13px;
    line-height: 22px;
    min-width: 200px;

    display: flex;
    align-items: center;
    text-align: right;

    color: #333333;
`;
