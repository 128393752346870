import { Crisp } from "crisp-sdk-web";
import { v4 as uuidV4 } from "uuid";

const arrangePhonesInfo = (phones) => {
  if (!phones) {
    return;
  }

  const arrangedPhones = phones.map((item, index) => {
    return {
      ["telefone_" + (index + 1)]: item.telefone ?? "",
      ["operadora_telefone_" + (index + 1)]: item.operadora ?? "",
      ["nome_contato_" + (index + 1)]: item.nome_contato ?? "",
      ["tipo_telefone_" + (index + 1)]: item.tipo ?? "",
    };
  });

  return Object.assign({}, ...arrangedPhones);
};

export const setupSupportChat = () => {
  Crisp.configure(`${process.env.REACT_APP_CRISP_WEBSITE_ID}`, {
    autoload: false,
  });

  Crisp.setPosition("left");
  Crisp.setColorTheme("red");
};

export const showSupportChat = () => {
  Crisp.load();
  Crisp.chat.show();
};

export const setUserDataToSupportChat = (user) => {
  if (!user) {
    Crisp.session.onLoaded(() => {
      const sessionId = Crisp.session.getIdentifier();

      if (!sessionId) {
        Crisp.setTokenId(uuidV4());
      }

      localStorage.getItem("logoLoja");
      Crisp.user.setAvatar(localStorage.getItem("logoLoja"));
      Crisp.user.setNickname(localStorage.getItem("nome"));

      Crisp.session.setData({
        establishment_id: localStorage.getItem("id_estabelecimento"),
        user_id: localStorage.getItem("id_usuario"),
      });
    });
    return;
  }

  Crisp.setTokenId(uuidV4());

  const phonesInfo = arrangePhonesInfo(user.telefones);

  Crisp.user.setAvatar(user.logo);
  Crisp.user.setNickname(user.nome);
  Crisp.user.setEmail(user.email);

  Crisp.session.setData({
    establishment_id: user.id_estabelecimento,
    user_id: user.id_usuario,
    source_application: user.source_application,
    ...phonesInfo,
  });

  Crisp.setPosition("left");
  Crisp.setColorTheme("red");
};

export const finishSupportChatSession = () => {
  Crisp.setTokenId();
  Crisp.session.reset();
  Crisp.chat.hide();
};
