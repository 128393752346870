import React, { useState, useEffect } from 'react';
import Api from '../../../services/api';
import { withRouter } from "react-router";
import { Modal } from "react-bootstrap";

import Swal from "sweetalert2";
import { useToasts } from 'react-toast-notifications';
import LoadingOverlay from 'react-loading-overlay';

import InputMask from 'react-input-mask';
import "react-datepicker/dist/react-datepicker.css";
import DatePicker, { registerLocale } from "react-datepicker";
import pt from "date-fns/locale/pt-BR";
registerLocale("pt-BR", pt);

const OPBatchReport = () => {
  const { addToast } = useToasts();
  const [entities, setEntities] = useState([]);
  const [loadingActive, setLoadingActive] = useState(false);
  const [totalBatch, setBatchTotal] = useState();
  const [paymentDateFilter, setPaymentDateFilter] = useState();
  const [modal, setModal] = useState(false);
  const [detailedData, setDetailedData] = useState({});
  const [currentPage, setCurrentPage] = useState(1);

  const PER_PAGE = 100;
  const pageCount = Math.ceil(totalBatch / PER_PAGE);

  let acesso = JSON.parse(localStorage.getItem('@ADM-Aiboo/perfil'));

  useEffect(() => {
    list();
  }, []);

  function closeModal() {
    setModal(false);
  }

  function list() {
    var data = {
      limit: PER_PAGE,
      page: 1
    }

    Api.post("pagamentoonline/getReportBatch", data).then(rps => {
      setEntities(rps.data.report.entities);
      setBatchTotal(rps.data.report.count);
    })
  }

  function moreDetails(data) {
    setDetailedData(data);
    setModal(true);
  }

  function filter() {
    var data = {
      limit: PER_PAGE,
      page: 1,
      paymentData: paymentDateFilter,
    };

    Api.post("pagamentoonline/getReportBatch", data).then(rps => {
      setEntities(rps.data.report.entities);
      setBatchTotal(rps.data.report.count);
      setCurrentPage(1);
    })
  }

  function handlePageClick(page) {
    setCurrentPage(page);
    var data = {
      limit: PER_PAGE,
      page: page,
      paymentData: paymentDateFilter,
    };

    Api.post("pagamentoonline/getReportBatch", data).then(rps => {
      setEntities(rps.data.report.entities);
    })
  }

  class Paginacao extends React.Component {
    renderRow(row) {
      return (<span>{row}</span>)
    }
    render() {
      let rows = []

      if (currentPage != 1) {
        rows.push(<a onClick={() => handlePageClick(currentPage - 1)} class="btn btn-icon btn-sm btn-light-primary mr-2 my-1">
          <i class="ki ki-bold-arrow-back icon-xs"></i>
        </a>);
      } else {
        rows.push(<span class="btn-icon btn-sm btn-secondary disabled mr-2 my-1">
          <i class="ki ki-bold-arrow-back icon-xs"></i>
        </span>)
      }

      if (currentPage > 3) {
        rows.push(<>
          <a onClick={() => handlePageClick(1)} class="btn btn-icon btn-sm btn-light-primary mr-2 my-1">
            1
          </a>
          <span class="btn-icon btn-sm btn-secondary disabled mr-2 my-1">
            ...
          </span>
        </>
        );
      }

      for (let i = currentPage - 2; i < currentPage + 3; i++) {
        if (i > 0 && i <= pageCount) {
          if (i == currentPage) {
            rows.push(<a onClick={() => handlePageClick(i)} className="btn btn-icon btn-sm border-0 btn-hover-primary active mr-2 my-1">{i}</a>)
          } else {
            rows.push(<a onClick={() => handlePageClick(i)} className="btn btn-icon btn-sm border-0 btn-hover-primary mr-2 my-1">{i}</a>)
          }
        }
      }

      if (currentPage + 3 <= pageCount) {
        rows.push(<>
          <span class="btn-icon btn-sm btn-secondary disabled mr-2 my-1">
            ...
          </span>
          <a onClick={() => handlePageClick(pageCount)} class="btn btn-icon btn-sm btn-light-primary mr-2 my-1">
            {pageCount}
          </a>
        </>
        );
      }

      if (currentPage != pageCount) {
        rows.push(<a onClick={() => handlePageClick(currentPage + 1)} class="btn btn-icon btn-sm btn-light-primary mr-2 my-1">
          <i class="ki ki-bold-arrow-next icon-xs"></i>
        </a>);
      } else {
        rows.push(<span class="btn btn-icon btn-sm btn-secondary disabled mr-2 my-1">
          <i class="ki ki-bold-arrow-next icon-xs"></i>
        </span>)
      }

      if (rows.length == 0) {
        return (<p>Nenhum item</p>)
      }

      return (
        <div>
          {rows.map(this.renderRow)}
        </div>
      )
    }
  }

  return (
    <>
      <div>

        <div className="subheader espaco-header-pedidos subheader-transparent" id="kt_subheader">
          <div className="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
            {/*begin::Details*/}
            <div className="d-flex align-items-center flex-wrap mr-2">
              {/*begin::Title*/}
              <h5 className="text-dark font-weight-bold mt-2 mb-2 mr-5">Payout</h5>
              {/*end::Title*/}
              {/*begin::Separator*/}
              <div className="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200" />
              {/*end::Separator*/}
            </div>
            {/*end::Details*/}
            {/*begin::Toolbar*/}
            {/*end::Toolbar*/}
          </div>
        </div>
        {/*begin::Entry*/}
        <div className="d-flex flex-column-fluid">
          {/*begin::Container*/}
          <div className="container">
            <div className="card card-custom">
              <div className="ml-10 mr-10 mt-10">
                <div className="row">
                  <div className="form-group col-md-3">
                    <label>Data de Pagamento</label>
                    <div>
                      <DatePicker
                        name="dt_inicio"
                        onChange={setPaymentDateFilter}
                        isClearable
                        selectsStart
                        locale="pt-BR"
                        className="form-control"
                        placeholderText="aaaa-mm-dd"
                        selected={paymentDateFilter}
                        dateFormat="yyyy-MM-dd"
                        startDate={paymentDateFilter}
                        customInput={
                          <InputMask mask="9999-99-99" />} />
                    </div>
                  </div>

                  <div className="col-md-3 form-group">
                    <label>&nbsp;</label><br />
                    <button className="btn btn-primary btn-block" onClick={e => { filter() }}><i className="fas fa-filter" />Filtrar</button>
                  </div>
                </div>
              </div>

              <LoadingOverlay
                active={loadingActive}
                spinner
                text='Carregando...'
              >

                <table className="table table-striped">
                  <thead className="bg-light-primary">
                    <tr>
                      <th className="text-primary text-center">
                        <a className="text-hover-dark">
                          ID
                        </a>
                      </th>
                      <th className="text-primary text-center">
                        <a className="text-hover-dark">
                          Estabelecimento
                        </a>
                      </th>
                      <th className="text-primary text-center">
                        <a className="text-hover-dark">
                          Valor
                        </a>
                      </th>
                      <th className="text-primary text-center">
                        <a className="text-hover-dark">
                          Taxa
                        </a>
                      </th>
                      <th className="text-primary text-center">
                        <a className="text-hover-dark">
                          Comissão
                        </a>
                      </th>
                      <th className="text-primary text-center">
                        <a className="text-hover-dark">
                          Período
                        </a>
                      </th>
                      <th className="text-primary text-center">
                        <a className="text-hover-dark">
                          Status
                        </a>
                      </th>
                      <th className="text-center">Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    {entities !== null &&
                      entities.map((item) => {
                        return (
                          <>
                            <tr>
                              <td className="text-center">{item.id}</td>
                              <td className="text-center">#{item.establishment_id} - {item.establishment}</td>
                              <td className="text-center">R$ {item.value_final_establishment}</td>
                              <td className="text-center">R$ {item.value_tax}</td>
                              <td className="text-center">R$ {item.value_commission}</td>
                              <td className="text-center">De: <b>{item.date_from}</b> <br></br> Até <b>{item.date_to}</b></td>
                              <td className="text-center">
                                {item.status === "PAGO" ?           
                                <span class="label label-success label-inline mr-2">PAGO</span> :                            
                                <b>{detailedData.status}</b>}
                              </td>
                              <td className="text-center">
                                <div class="dropdown dropdown-inline">
                                  <button type="button" class="btn btn-light-primary btn-icon btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i class="ki ki-bold-more-ver"></i>
                                  </button>
                                  <div class="dropdown-menu">
                                    <a class="dropdown-item" onClick={e => { moreDetails(item) }}>Informações</a>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </>
                        )
                      })
                    }
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colspan="9" className="text-right">
                        <Paginacao />
                      </td>
                    </tr>
                  </tfoot>
                </table>

              </LoadingOverlay>

            </div>
          </div>
        </div>
      </div>

      <Modal size={'xl'} show={modal} onHide={() => closeModal()}>
        <Modal.Header>
          <Modal.Title>Payout - Informações Completas</Modal.Title>
          <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={e => { closeModal() }}>
            <i aria-hidden="true" className="ki ki-close" />
          </button>
        </Modal.Header>

        <div className="row mt-5 ml-5 mr-5 form-group">
          <div className="col-md-12">
            
            <div className='row'>
              <div class='col-md-2'>
                <label>Lote</label><br />
                <b>{detailedData.id}</b>
              </div>

              <div class='col-md-4'>
                <label>Token</label><br />
                <b>{detailedData.token}</b>
              </div>

              <div class='col-md-4'>
                <label>Período de: </label><br />
                <b><b>{detailedData.date_from}</b> - Até <b>{detailedData.date_to}</b></b>
              </div>

              <div class='col-md-2'>
                <label>Status</label><br />
                {detailedData.status === "PAGO" ?           
                  <span class="label label-success label-inline mr-2">PAGO</span> :                            
                  <b>{detailedData.status}</b>}
              </div>
            </div>

            <div className='row mt-5'>
              <div className='col'>
                <label>Valor</label><br />
                <b>R$ {detailedData.value_final_establishment}</b>
              </div>

              <div className='col'>
                <label>Taxa</label><br />
                <b>R$ {detailedData.value_tax}</b>
              </div>

              <div className='col'>
                <label>Comissão</label><br />
                <b>R$ {detailedData.value_commission}</b>
              </div>

              <div className='col'>
                <label>Valor Retido</label><br />
                <b>R$ {detailedData.retained_value}</b>
              </div>

              <div className='col'>
                <label>Valor Compensado</label><br />
                <b>R$ {detailedData.compensated_value}</b>
              </div>
            </div>

            <div className='row mt-5'>
              <div className='col-md-3'>
                <label>ID Fatura</label><br />
                <b>{detailedData.invoice_id}</b>
              </div>

              <div className='col'>
                <label>Finalizado</label><br />
                <b>{detailedData.finished_at}</b>
              </div>
            </div>
                           
            <div className='row mt-5'>
              <div className='col-md-12'>
                <span className="content" dangerouslySetInnerHTML={{__html: detailedData.complete_description}}></span>  
              </div>
            </div>           
          </div>
        </div>

        <Modal.Footer>
          <button type="button" onClick={e => { closeModal(); }} className="btn btn-light-primary font-weight-bold" data-dismiss="modal"><i className="fas fa-door-open" />Fechar</button>
        </Modal.Footer>
      </Modal>

    </>
  );

}

export default withRouter(OPBatchReport);