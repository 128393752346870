import styled, { keyframes } from "styled-components";

const blowUpModal = keyframes`
  from {
    transform:scale(0);
  }
  to{
    transform:scale(1);
  }
`;

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 1000;
  inset: 0;
  display: ${({ visible }) => (visible ? "block" : "none")};
`;

export const Overlay = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.75);
  backdrop-filter: blur(5px);
`;

export const ModalContainer = styled.div`
  position: fixed;
  inset: 0;
  margin: auto;
  width: fit-content;
  height: fit-content;
  max-width: 80%;
  max-height: 80%;

  animation: ${blowUpModal} 0.3s ease;
`;

export const CloseModalButton = styled.button`
  border: 0;
  background: transparent;
  position: absolute;
  right: 35px;
  top: 20px;
  z-index: 1000;
  width: 22px;
`;

export const CloseModalIcon = styled.i`
  color: #333333;
  font-size: 22px;
`;
