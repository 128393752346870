/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { createContext, useContext, useMemo } from "react";
import {
  sortableContainer,
  sortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import InfiniteScroll from 'react-infinite-scroller';
//import Infinite from "react-infinite";
import Select from "react-select";
import { useToasts } from "react-toast-notifications";

const DragHandle = SortableHandle(() => (
  <i style={{ cursor: "move" }} className="fas fa-bars f16" />
));

const ProductContext = createContext({});

const SortableItem = sortableElement(({ item }) => {
  const statusVar = useMemo(
    () => [
      { value: "S", label: "Ativo" },
      { value: "F", label: "Em falta" },
      { value: "O", label: "Oculto" },
    ],
    []
  );

  const {
    selecionaProduto,
    MudaStatus,
    ExcluirGenerico,
    duplicaProduto,
    getProductsByCategory,
    selectedCategory,
  } = useContext(ProductContext);
  const { addToast } = useToasts();

  return (
    <div key={item.id_cardapio} className="table-item padding-horizontal">
      <div className="table-item-produto">
        <DragHandle />
      </div>
      <div className="table-item-produto">
        <a
          onClick={(e) => {
            selecionaProduto(item);
           }}
        >
          <img
            src={item.foto ? item.foto : "assets/media/no-img.jpg"}
            alt="produto"
            className="foto-produto"
          />
          </a>
      </div>
      <div className="table-item-produto">
        <strong>{item.nome_prod}</strong>
        <br />
        <p>{item.descricao}</p>
      </div>
      <div className="table-item-produto">
        {item.apartirde === false && item.preco_antigo == null && (
          <span className="cor-verde">R$ {item.preco}</span>
        )}
        {item.preco_antigo != null && (
          <>
            <span>
              <strike>de R$ {item.preco_antigo}</strike>
            </span>
            <br />
            por{" "}
            <span className="cor-verde">
              R$ {item.novo_preco}
              <br />
            </span>
          </>
        )}
        {item.apartirde === true && (
          <>
            A partir de
            <br />
            <span className="cor-verde">
              R$ {item.preco_apartirde.toFixed(2)}
            </span>
          </>
        )}
      </div>
      <div className="table-item-produto">
        <Select
          options={statusVar}
          defaultValue={statusVar.find((x) => x.value === item.status)}
          onChange={(event) => {
            item.status = event.value;
            MudaStatus(
              "cardapio/mudaSts",
              { id_cardapio: item.id_cardapio, status: event.value },
              addToast
            );
          }}
        />
      </div>
      <div className="table-item-produto">
        <div className="dropdown dropdown-inline">
          <button
            type="button"
            className="btn btn-light-primary btn-icon btn-sm"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i className="ki ki-bold-more-ver"></i>
          </button>
          <div className="dropdown-menu">
            <a
              className="dropdown-item"
              onClick={(e) => {
                selecionaProduto(item);
              }}
            >
              Editar
            </a>
            <a
              className="dropdown-item"
              onClick={(e) => {
                duplicaProduto(item);
              }}
            >
              Duplicar
            </a>
            <div className="dropdown-divider"></div>
            <a
              className="dropdown-item"
              onClick={(e) => {
                ExcluirGenerico(
                  "cardapio/excluir",
                  { id_cardapio: item.id_cardapio },
                  addToast,
                  () => {
                    getProductsByCategory(
                      selectedCategory.id_cardapio_categoria
                    );
                  }
                );
              }}
            >
              Excluir
            </a>
          </div>
        </div>
      </div>
    </div>
  );
});

const SortableInfiniteList = sortableContainer(({ items }) => {
  return (
    
    
    <InfiniteScroll
    pageStart={0}
    //loadMore={loadFunc}
    hasMore={true}
    //loader={<div className="loader" key={0}>Loading ...</div>}
  >
      {items.map((value, index) => (
        <SortableItem
          key={`item-${value.id_cardapio}`}
          index={index}
          item={value}
        />
      ))} 
</InfiniteScroll>

    
  );
});

function SortableVirtualizedProduct3({
  filteredProducts,
  onSortEnd,
  //
  selecionaProduto,
  MudaStatus,
  ExcluirGenerico,
  duplicaProduto,
  getProductsByCategory,
  selectedCategory,
}) {
  return (
    <ProductContext.Provider
      value={{
        selecionaProduto,
        MudaStatus,
        ExcluirGenerico,
        duplicaProduto,
        getProductsByCategory,
        selectedCategory,
      }}
    >
      <SortableInfiniteList
        items={filteredProducts}
        onSortEnd={onSortEnd}
        useDragHandle
      />
    </ProductContext.Provider>
  );
}

export default SortableVirtualizedProduct3;
