import styled from "styled-components";

export const Container = styled.div`
  .conexaoOk {
    color: green !important;
  }
  .conexaoNok {
    color: red !important;
  }
`;

export const CampaignBannerWrapper = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 20px 0px 8px;
  
  img{
    max-width: 800px;
    border-radius: 8px;
  }
`;


