import React, { useState, useEffect } from 'react';
import Api from '../../../../services/api';
import { withRouter } from "react-router";

import Swal from "sweetalert2";
import { useToasts } from 'react-toast-notifications';
import LoadingOverlay from 'react-loading-overlay';
import Axios from 'axios';

const FCPlanos = () => {
  const { addToast } = useToasts();
  const [ caProcessa, setCaProcessa ] = useState(false);
  const [ pagOnCadastro, setPagOnCadastro ] = useState([]);
  const [ pagOnPlanosTaxas, setPagOnPlanosTaxas ] = useState([]);

  useEffect(() => {
    getListarPlanosTaxas();
    getPagOnCadastro();
  }, []);

  function getListar() { 
    setCaProcessa(true); 
    //Api.get("bancos/listar").then(rps => {
    //  setBancos(rps.data.grupos);
      setCaProcessa(false);
    //})
  }

  function getListarPlanosTaxas() { 
    Api.get("pagamentoonline/listarPlanosTaxas").then(rps => {
      setPagOnPlanosTaxas(rps.data.obj);
    })
  }

  function ativarPlano(idPlano) { 
    var data = {
      id_pagon_cadastro: pagOnCadastro?.id_pagon_cadastro,
      id_pagon_planos_taxas: idPlano,
    };
    setCaProcessa(true);
    Api.post('pagamentoonline/ativarPagOnPlano', data).then(rps => { 
      if (rps.data.status === true) {
        addToast(rps.data.mensagem, {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 2000
        });
        getPagOnCadastro(pagOnCadastro?.id_estabelecimento);
        setCaProcessa(false);
      } else {
        Swal.fire({
          title: "Erro!",
          icon: "error",
          html: rps.data.erros,
          showCloseButton: true,
          showCancelButton: false,
        });
      }
    });
  }

  function getPagOnCadastro(){
    var data = {
      id_estabelecimento: localStorage.getItem('id_estabelecimento')
    };

    Api.post('pagamentoonline/listarPagOnCadastro', data).then(rps => {
      
      setPagOnCadastro(rps.data.obj);
    });
  }
        
  return (
    <>

  <div className="subheader espaco-header-pedidos subheader-transparent" id="kt_subheader">
    <div className="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
      {/*begin::Details*/}
      <div className="d-flex align-items-center flex-wrap mr-2">
        {/*begin::Title*/}
        <h5 className="text-dark font-weight-bold mt-2 mr-5">Financeiro</h5>
        {/*end::Title*/}
        {/*begin::Separator*/}
        <div className="subheader-separator subheader-separator-ver mt-2 mb-2 bg-gray-200" />
        {/*end::Separator*/}
        {/*begin::Title*/}
        <h6 className="text-dark mt-2 mr-5 ml-5">Meu Cadastro</h6>
        {/*end::Title*/}
        {/*begin::Separator*/}
        <div className="subheader-separator subheader-separator-ver mt-2 mb-2 bg-gray-200" />
        {/*end::Separator*/}
        {/*begin::Title*/}
        <span className="breadcrumb breadcrumb-transparent mt-5 mr-5">Planos de taxas</span>
        {/*end::Title*/}
      </div>
      {/*end::Details*/}
      {/*begin::Toolbar*/}
      <div className="d-flex align-items-center">
      </div>
      {/*end::Toolbar*/}
    </div>
  </div>
        
  {/*begin::Entry*/}
  <div className="d-flex flex-column-fluid mb-10">
    {/*begin::Container*/}
    <div className="container">
      <div className="card card-custom">
        <div className="ml-10 mr-10 mt-10 mb-10">   

        {/*begin::Group - Pagamento Online - Planos*/}
        
              <h6 className="font-weight-boldest">Planos de taxas</h6>
              <small>Conheça as condições de prazos de recebimento e taxas disponíveis para você.</small>

              <LoadingOverlay
                active={caProcessa}
                spinner
                text='Carregando...'
              >
              <div className="card-body">
              {/*begin::Accordion*/}
              <div className="accordion accordion-solid accordion-toggle-plus" id="accordionExample6">
                <div className="card">
                  <div className="card-header" id="headingOne6">
                    <div className="card-title" data-toggle="collapse" data-target="#collapseOne6">
                      <i className="flaticon-pie-chart-1" />Plano Standard Padrão&nbsp;&nbsp; 
                      
                        {pagOnCadastro?.id_pagon_planos_taxas == 1 && <>          
                          <span class="label label-success label-inline mr-2">Plano Ativo</span>
                        </>}
                    </div>
                  </div>
      <div id="collapseOne6" className="collapse show" data-parent="#accordionExample6">
      <div className="row">
       <div className="col-xl-6">
          {/*begin::Stats Widget 20*/}
          <div className="card card-custom bg-light-danger gutter-b my-1">
            {/*begin::Body*/}
            <div className="card-body">
              <label className="card-title font-weight-bolder text-danger font-size-h6 mb-0 text-hover-state-dark d-block">Débito</label>
              <label>Recebimento em 1 dia</label>

              <div className="row">
                  <div className="col-xl-6">
                    <b>Bandeiras</b>
                  </div>
                  <div className="col-xl-6">
                    <b>Taxas</b>
                  </div>
                </div>
                
                {pagOnPlanosTaxas.map((pt) => {
                    if (pt.id_pagon_plano === '1' && pt.tipo == 'Débito')
                      return ( <>
                        <div className="row">
                          <div className="col-xl-6">
                            {pt.bandeira}
                          </div>
                          <div className="col-xl-6">
                            {pt.taxas}
                          </div>
                        </div>
                      </>
                    )}
                  )}            
            </div>
            {/*end::Body*/}
          </div>
          {/*end::Stats Widget 20*/}
        </div>

        <div className="col-xl-6">
          {/*begin::Stats Widget 20*/}
          <div className="card card-custom bg-light-success gutter-b my-1">
            {/*begin::Body*/}
            <div className="card-body">
              <label className="card-title font-weight-bolder text-success font-size-h6 mb-0 text-hover-state-dark d-block">Crédito</label>
              <label>Recebimento em 30 dia</label>
              
                <div className="row">
                  <div className="col-xl-4 ">
                    <b>Parcelas</b>
                  </div>
                  <div className="col-xl-4">
                    <b>Bandeiras</b>
                  </div>
                  <div className="col-xl-4">
                    <b>Taxas</b>
                  </div>
                </div>
                
                {pagOnPlanosTaxas.map((pt) => {
                    if (pt.id_pagon_plano === '1' && pt.tipo == 'Crédito')
                      return ( <>
                        <div className="row">
                          <div className="col-xl-4">
                            {pt.parcelas}
                          </div>
                          <div className="col-xl-4">
                            {pt.bandeira}
                          </div>
                          <div className="col-xl-4">
                            {pt.taxas}
                          </div>
                        </div>
                      </>
                    )}
                  )}            
            </div>
            {/*end::Body*/}
          </div>
          {/*end::Stats Widget 20*/}
        </div>
        </div>
          {pagOnCadastro?.id_pagon_planos_taxas != 1 && <>
          <div className="text-right">
            <button className="btn btn-primary" onClick={ e => { ativarPlano(1); }}>Ativar este plano</button>
          </div>
          </>} 
      </div>
    </div>
    <div className="card">
      <div className="card-header" id="headingTwo6">
        <div className="card-title collapsed" data-toggle="collapse" data-target="#collapseTwo6">
          <i className="flaticon2-notification" />Plano Pro Padrão&nbsp;&nbsp; 
          {pagOnCadastro?.id_pagon_planos_taxas == 2 && <>          
            <span class="label label-success label-inline mr-2">Plano Ativo</span>
          </>}  
        </div>
      </div>
      <div id="collapseTwo6" className="collapse" data-parent="#accordionExample6">
      <div className="row">
       <div className="col-xl-6">
          {/*begin::Stats Widget 20*/}
          <div className="card card-custom bg-light-danger gutter-b my-1">
            {/*begin::Body*/}
            <div className="card-body">
              <label className="card-title font-weight-bolder text-danger font-size-h6 mb-0 text-hover-state-dark d-block">Débito</label>
              <label>Recebimento em 1 dia</label>

              <div className="row">
                  <div className="col-xl-6">
                    <b>Bandeiras</b>
                  </div>
                  <div className="col-xl-6">
                    <b>Taxas</b>
                  </div>
                </div>
                
                {pagOnPlanosTaxas.map((pt) => {
                    if (pt.id_pagon_plano === '2' && pt.tipo == 'Débito')
                      return ( <>
                        <div className="row">
                          <div className="col-xl-6">
                            {pt.bandeira}
                          </div>
                          <div className="col-xl-6">
                            {pt.taxas}
                          </div>
                        </div>
                      </>
                    )}
                  )}            
            </div>
            {/*end::Body*/}
          </div>
          {/*end::Stats Widget 20*/}
        </div>

        <div className="col-xl-6">
          {/*begin::Stats Widget 20*/}
          <div className="card card-custom bg-light-success gutter-b my-1">
            {/*begin::Body*/}
            <div className="card-body">
              <label className="card-title font-weight-bolder text-success font-size-h6 mb-0 text-hover-state-dark d-block">Crédito</label>
              <label>Recebimento em 30 dia</label>
              
                <div className="row">
                  <div className="col-xl-4 ">
                    <b>Parcelas</b>
                  </div>
                  <div className="col-xl-4">
                    <b>Bandeiras</b>
                  </div>
                  <div className="col-xl-4">
                    <b>Taxas</b>
                  </div>
                </div>
                
                {pagOnPlanosTaxas.map((pt) => {
                    if (pt.id_pagon_plano === '2' && pt.tipo == 'Crédito')
                      return ( <>
                        <div className="row">
                          <div className="col-xl-4">
                            {pt.parcelas}
                          </div>
                          <div className="col-xl-4">
                            {pt.bandeira}
                          </div>
                          <div className="col-xl-4">
                            {pt.taxas}
                          </div>
                        </div>
                      </>
                    )}
                  )}            
            </div>
            {/*end::Body*/}
          </div>
          {/*end::Stats Widget 20*/}
        </div>
        </div> 
        {pagOnCadastro?.id_pagon_planos_taxas != 2 && <>
          <div className="text-right">
            <button className="btn btn-primary" onClick={ e => { ativarPlano(2); }}>Ativar este plano</button>
          </div>
          </>}  
      </div>
    </div>
  </div>
  {/*end::Accordion*/}
 </div>
 </LoadingOverlay>

            
            {/*end::Group - Pagamento Online - Planos*/}


        </div>
        <div class="card-footer text-right">
       
        </div>
      </div>
    </div>
  </div>
                  
            
  </>         
        
  );
}

export default withRouter(FCPlanos);                  