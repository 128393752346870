import React, { useCallback } from "react";
import apiProducts from "../../../../services/apiProducts";

export function useProduct() {
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [selectData, setSelectData] = React.useState([]);
  const [error, setError] = React.useState(null);

  const handleData = useCallback((text) => {
    const isNumeric = Number.isInteger(text * 1);
    const search = isNumeric ? { codigo_barras: text } : { nome_produto: text };

    apiProducts
      .get("products", {
        params: {
          page: 1,
          per_page: 300,
          ...search,
        },
      })
      .then((response) => {
        setData(response.data.products.data);
        const result = response.data.products.data.map((i) => ({
          value: i.id_produto,
          label: `${i.codigo_barras} - ${i.nome_produto}`,
          id_produto: i.id_produto,
          nome_produto: i.nome_produto,
          descricao: i.descricao,
          foto: i.imagem_redimensionada,
        }));
        setSelectData(result);
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  React.useEffect(() => {
    setLoading(true);
    handleData();
  }, [handleData]);

  const filter = (data, input) => {
    return data.filter((i) =>
      i.label.toLowerCase().includes(input.toLowerCase())
    );
  };

  const handleSelectData = async (text) => {
    const isNumeric = Number.isInteger(text * 1);

    const search = isNumeric ? { codigo_barras: text } : { nome_produto: text };

    const { data } = await apiProducts.get("products", {
      params: {
        page: 1,
        per_page: 300,
        ...search,
      },
    });

    const result = data.products.data.map((i) => ({
      value: i.id_produto,
      label: `${i.codigo_barras} - ${i.nome_produto}`,
      id_produto: i.id_produto,
      nome_produto: i.nome_produto,
      descricao: i.descricao,
      foto: i.imagem_redimensionada,
    }));

    setSelectData(result);
    return filter(result, text);
  };

  return { loading, data, selectData, handleSelectData, error };
}
