/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { createContext, useContext } from "react";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";

const DragHandle = SortableHandle(() => (
  <i style={{ cursor: "move" }} className="fas fa-bars f16" />
));

const CategoryContext = createContext({});

const SortableCategoryItem = SortableElement(({ item }) => {
  const { selectCategory, editarCategoria, excluirCategoria } = useContext(
    CategoryContext
  );

  return (
    <div
      key={item.id_cardapio_categoria}
      className={
        "table-item table-item-border-branco no-padding padding-horizontal-border " +
        item?.selectedClass
      }
    >
      <div className="table-item-ind-categoria table-item-center-midle">
        <DragHandle />
      </div>
      <div
        onClick={() => selectCategory(item)}
        className="table-item-ind-categoria table-item-center-midle"
      >
        {item.status === "F" && (
          <i className="fa fa-circle icone-status cor-amarelo mr-2"></i>
        )}
        {item.status === "O" && (
          <i className="fa fa-circle icone-status cor-vermelho mr-2"></i>
        )}
        {item.nome_categoria}
      </div>
      <div className="table-item-ind-categoria">
        <div className="dropdown dropdown-inline">
          <button
            type="button"
            className="btn btn-light-primary btn-icon btn-sm"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i className="ki ki-bold-more-ver" />
          </button>
          <div className="dropdown-menu">
            <a
              onClick={(e) => {
                editarCategoria(item);
              }}
              className="dropdown-item"
            >
              Editar
            </a>
            <div className="dropdown-divider" />
            <a
              onClick={(e) => {
                excluirCategoria(item);
              }}
              className="dropdown-item"
            >
              Excluir
            </a>
          </div>
        </div>
      </div>
    </div>
  );
});

const SortableListCategory = SortableContainer(
  ({ items }) => {
    return (
      <div>
        {items?.map((value, index) => (
          <SortableCategoryItem
            key={`item-${value.id_cardapio_categoria}`}
            item={value}
            index={index}
          />
        ))}
      </div>
    );
  }
);

function SortableComponentCategory({
  categories,
  onSortEnd,
  selectCategory,
  editarCategoria,
  excluirCategoria,
}) {
  return (
    <CategoryContext.Provider
      value={{
        selectCategory,
        editarCategoria,
        excluirCategoria,
      }}
    >
      <SortableListCategory
        items={categories}
        onSortEnd={onSortEnd}
        axis="y"
        helperClass="dragclass"
        useDragHandle={true}
      />
    </CategoryContext.Provider>
  );
}

export default SortableComponentCategory;
