import React from "react";

import * as S from "./styles";

function PageHeader({ title, subTitle, children }) {
  return (
    <S.Header>
      <div>
        <S.Title>{title}</S.Title>
        <S.SubTitle>{subTitle}</S.SubTitle>
      </div>
      <div>{children}</div>
    </S.Header>
  );
}

export default PageHeader;
