/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';

import { withRouter } from "react-router";
import Api from '../../services/api';
import VariacoesModal from '../variacoes/modal/index';
import {useToasts } from 'react-toast-notifications';
import MudaStatus from '../../helpers/mudaStatus';
import Swal from "sweetalert2";

// Loading
import LoadingOverlay from 'react-loading-overlay';

import Select from 'react-select';
import ExcluirGenerico from '../../helpers/excluirGenerico';
import { Modal } from 'react-bootstrap';

const Variacoes = () => {
  useEffect(() =>{
    getProductCategories();
    //getIngredientes();
    //getVariacoes();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { addToast } = useToasts();

  const status = [
    {value: 'S', label: 'Ativo'},
    {value: 'O', label: 'Oculto'},
    {value: 'N', label: 'Inativo'},
  ];
  const statusVar = [
    {value: 'S', label: 'Ativo'},
    {value: 'F', label: 'Em falta'},
    {value: 'O', label: 'Oculto'},
  ];


  const [ caCategoria, setCaCategoria ] = useState(true);
  const [ caProdutos,  setCaProdutos ] = useState(false);

  const [, setFiltro] = useState();

  /*Modal componente variação */
  const [modalVariacoesState, setModalVariacoesState] = useState(false);
  const [variacao, setVariacao] = useState(false);
  function atualizaProduto(){
    setVariacao(false);
    getCategoriaPorVariacao(selectedCategory.id_variacao_categoria);
  }


  /*Inicio - Funções referentes a categoria dos produtos */
  const [ categories, setCategories ] = useState([]);
  const [ selectedCategory, setSelectedCategory ] = useState([])
  async function getProductCategories(){
    setCaCategoria(true);
    const response = await Api.get("variacoescategorias/listar");

    if (response.data.status === true) {
      setCaCategoria(false);
      if(selectedCategory.id_variacao_categoria){
        var nCategories = [];
        response.data.categorias.forEach(c => {
          if(selectedCategory.id_variacao_categoria===c.id_variacao_categoria){
            c.selectedClass = 'categoria-selecionada';
          } else{
            c.selectedClass = '';
          }
          nCategories[nCategories.length] = c;
        });
        setCategories(nCategories);
      } else {
        selectCategory(response.data.categorias[0])
        setCategories(response.data.categorias);
      }
    }
  }


  function selectCategory(category){
    setCaProdutos(true);
    setFiltro('');

    category.selectedClass = 'categoria-selecionada';

    setSelectedCategory(category);
    var nCategories = [];
    categories.forEach(c => {
      if(category.id_variacao_categoria===c.id_variacao_categoria){
        c.selectedClass = 'categoria-selecionada';
      } else{
        c.selectedClass = '';
      }
      nCategories[nCategories.length] = c;
    });
    setCategories(nCategories);
    getCategoriaPorVariacao(category.id_variacao_categoria);
  }
  /*Final - Funções referentes a categoria dos produtos */


  /*Inicio - funções relativas a variações global */
  const [ products, setProducts ] = useState([]);

  async function getCategoriaPorVariacao(idCategory){
    setCaProdutos(true);
    const response = await Api.get("variacoes/listarCategoria/" + idCategory);
    if (response.data.status !== undefined && response.data.status === true) {
      setCaProdutos(false);
      setProducts(response.data.variacoes);
    }
  }

  function editarVariacao(v){
    setVariacao(v);
    setModalVariacoesState(true);
  }
  function excluirVariacao(item){
    ExcluirGenerico('variacoes/excluir', {id_variacao: item.id_variacao}, addToast, (e) => {
      getCategoriaPorVariacao(selectedCategory.id_variacao_categoria);
    });
  }

  function duplicarVariacao(item){
    Api.post('variacoes/duplicar', {variacao: item.id_variacao}).then((rps) => {
      if (rps.data.status !== undefined && rps.data.status === true) {
        addToast(rps.data.mensagem, {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 2000
        });
        getCategoriaPorVariacao(selectedCategory.id_variacao_categoria);
      } else {
        Swal.fire({
          title: "Atenção!",
          icon: "info",
          html: rps.data.erro,
          showCloseButton: true,
          showCancelButton: false
        });
      }
    });
  }
  /*Final - funções relativas a variações global */

  /* Inicio - Categorias das variações */
  const [ modalCategorias, setModalCategorias ] = useState(false)
  const [ frmCategoria, setFrmCategoria ] = useState({})
  function handleModalCategorias(){
    getProductCategories();
    setModalCategorias(false);
  }
  function abreCategoria(categoria){
    if(!categoria){
      setFrmCategoria({
        titulo: 'Adicionar categoria',
        status: 'S'
      });
    } else {
      categoria.titulo = 'Editar categoria'
      setFrmCategoria(categoria);
    }
    setModalCategorias(true);
  }
  function sendFrmCategoria(){
    var id = '';
    if(frmCategoria.id_variacao_categoria){
      id = frmCategoria.id_variacao_categoria;
    }

    Api.post("variacoescategorias/adicionar/"+id, frmCategoria).then((rps) => {
      if (rps.data.status !== undefined && rps.data.status === true) {
        setFrmCategoria({...frmCategoria, id_variacao_categoria: rps.data.id_variacao_categoria, titulo: 'Editar categoria'})
        addToast(rps.data.mensagem, {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 2000
      });
      }
    })
  }

  function excluirCategoria(categoria){
    ExcluirGenerico('variacoescategorias/excluir', {id_variacao_categoria: categoria.id_variacao_categoria}, addToast, (e) => {
      getProductCategories();
    });
  }
  /* Final - Categorias das variações */



    return (
        <>
            <div className="d-flex flex-column-fluid">
                <div className="container">
                <div className="row">
                    <div className="col-md-4">
                    <div className="card card-custom gutter-b">
                        <div className="card-header flex-wrap">
                        <div className="subheader py-2 py-lg-4 subheader-transparent" id="kt_subheader">
                            <div className="d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
                            <div className="d-flex align-items-center flex-wrap mr-2">
                                <h5 className="text-dark font-weight-bold mt-2 mb-2 mr-5">Categorias</h5>
                                <div className="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200" />
                            </div>
                            </div>
                        </div>
                        <div className="card-toolbar">
                            <button onClick={e => { abreCategoria(null) }}className="btn btn-primary font-weight-bolder">
                                Adicionar
                            </button>
                        </div>
                        </div>
                        <div className="card-body no-padding">
                        <LoadingOverlay
                          active={caCategoria}
                          spinner
                          text='Carregando...'
                        >
                          {categories.map((value, index) => (

                            <div onClick={()=> selectCategory(value)}  className={"table-item table-item-border-branco no-padding padding-horizontal-border "+value?.selectedClass}>

                                <div className="table-item-ind-categoria-variacao table-item-center-midle">
                                {value.nome_categoria}
                                </div>
                                <div className="table-item-ind-categoria-variacao">
                                    <div className="dropdown dropdown-inline">
                                        <button type="button" className="btn btn-light-primary btn-icon btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i className="ki ki-bold-more-ver" />
                                        </button>
                                        <div className="dropdown-menu">
                                        <div className="dropdown-item" onClick={e => {abreCategoria(value)}}>Editar</div>
                                        <div className="dropdown-divider" />
                                        <div className="dropdown-item" onClick={e => {excluirCategoria(value)}}>Excluir</div>
                                        </div>
                                    </div>
                                </div>
                             </div>

                          ))}
                          </LoadingOverlay>
                        </div>
                    </div>
            </div>
            <div className="col-md-8">
              {/*begin::Card*/}
              <div className="card card-custom gutter-b">
                <div className="card-header flex-wrap">
                  {/*begin::Subheader*/}
                  <div className="subheader py-2 py-lg-4 subheader-transparent" id="kt_subheader">
                    <div className="d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
                      {/*begin::Details*/}
                      <div className="d-flex align-items-center flex-wrap mr-2">
                        {/*begin::Title*/}
                        <h5 className="text-dark font-weight-bold mt-2 mb-2 mr-5">
                          Variações
                          {selectedCategory.nome_categoria && ' - '+selectedCategory.nome_categoria}
                        </h5>
                        {/*end::Title*/}
                        {/*begin::Separator*/}
                        <div className="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200" />
                        {/*end::Separator*/}
                        {/*begin::Search Form*/}
                        <div className="d-flex align-items-center" id="kt_subheader_search">
                          <span className="text-dark-50 font-weight-bold" id="kt_subheader_total">
                            {products.length === 0 && 'Nenhuma variação'}
                            {products.length === 1 && '1 Variação'}
                            {products.length > 1 && products.length+' Variações'}

                          </span>
                          {/*
                          <form className="ml-5">
                            <div className="input-group input-group-sm input-group-solid" style={{maxWidth: '175px'}}>
                              <input type="text" className="form-control" id="kt_subheader_search_form" placeholder="Procurar..." />
                              <div className="input-group-append">
                                <span className="input-group-text">
                                  <span className="svg-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                      <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                        <rect x={0} y={0} width={24} height={24} />
                                        <path d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z" fill="#000000" fillRule="nonzero" opacity="0.3" />
                                        <path d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z" fill="#000000" fillRule="nonzero" />
                                      </g>
                                    </svg>
                                  </span>
                                </span>
                              </div>
                            </div>
                          </form>
                          */}
                        </div>
                        {/*end::Search Form*/}
                      </div>
                      {/*end::Details*/}
                    </div>
                  </div>
                  {/*end::Subheader*/}
                  <div className="card-toolbar">
                    {/*begin::Button*/}
                    {selectedCategory.id_variacao_categoria!=null &&
                    <button onClick={e => {editarVariacao({})}} className="btn btn-primary font-weight-bolder">
                      Adicionar Variação
                    </button>
                    }
                    {/*end::Button*/}
                  </div>
                </div>
                <div className="card-body no-padding">
                  <div className="table-div-header padding-horizontal">
                    <div className="table-div-header-item-variacoes-global">Variação</div>
                    <div className="table-div-header-item-variacoes-global">Qtd. Escolha</div>
                    <div className="table-div-header-item-variacoes-global">Cálculo</div>
                    <div className="table-div-header-item-variacoes-global">Status</div>
                    <div className="table-div-header-item-variacoes-global">Ações</div>
                  </div>

                  {(selectedCategory.nome_categoria && products.length === 0 && caProdutos==false) && <><p className="no-one">Nenhuma variação encontrada</p></>}
                  {(selectedCategory.nome_categoria===undefined) && <><p className="no-one">Selecione uma categoria</p></>}

                  <LoadingOverlay
                          active={caProdutos}
                          spinner
                          text='Carregando...'
                        >

                  {products.map((item) => (
                  <div className="table-item padding-horizontal">
                    <div className="table-item-variacoes-global">
                      <strong>{item.nome_variacao}</strong>
                    </div>
                    <div className="table-item-variacoes-global">
                      min. {item.escolha_minima} / máx. {item.escolha_maxima}
                    </div>
                    <div className="table-item-variacoes-global">
                    {item.calculo_preco === 'mp' && 'Maior preço'}
                    {item.calculo_preco === 'pm' && 'Menor preço' }
                    {item.calculo_preco === 'mm' && 'Média preços' }
                    {item.calculo_preco === 'st' && 'Soma total' }
                    {item.calculo_preco === 'in' && 'Incremental' }
                    </div>
                    <div className="table-item-variacoes-global">
                    <Select
                        options={status}
                        defaultValue={status.find(
                          x => x.value === item.status
                        )}
                        onChange={(event) => {
                          item.status = event.value;
                          MudaStatus('variacoes/mudaSts',
                            {id: item.id_variacao, status: event.value}, addToast);
                        }}
                      />
                    </div>
                    <div className="table-item-variacoes-global">
                      <div className="dropdown dropdown-inline">
                        <button type="button" className="btn btn-light-primary btn-icon btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <i className="ki ki-bold-more-ver" />
                        </button>
                        <div className="dropdown-menu">
                          <a className="dropdown-item" onClick={e => {duplicarVariacao(item)}}>Duplicar</a>
                          <a className="dropdown-item" onClick={e => {editarVariacao(item)}}>Editar</a>
                          <div className="dropdown-divider" />
                          <a className="dropdown-item" onClick={e => {excluirVariacao(item)}}>Excluir</a>
                        </div>
                      </div>
                    </div>
                  </div>
                  ))}
                  </LoadingOverlay>
                </div>
              </div>
              {/*end::Card*/}
            </div>
          </div>
        </div>
        {/*end::Container*/}
      </div>
      {/*end::Entry*/}


      {/* ------------------------------------------------------- MODAIS -------------------------------------------------------- */}
      <div>
        {/* Modal Categoria */}
          <Modal show={modalCategorias} onHide={() => handleModalCategorias()}>
            <Modal.Header closeButton>
              <Modal.Title>{frmCategoria?.titulo}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="form-group">
                <label>Nome da Categoria</label>
                <input value={frmCategoria?.nome_categoria} onChange={e => {setFrmCategoria({...frmCategoria, nome_categoria: e.target.value}) }} type="text" className="form-control" placeholder="Digite a categoria" />
              </div>
              <div className="form-group">
                <label>Status</label>
                <Select
                  options={statusVar}
                  value={statusVar.find(
                    x => x.value === frmCategoria?.status
                  )}
                  onChange={(event) => {
                    setFrmCategoria({...frmCategoria, status: event.value})
                  }}
                />
              </div>
            </Modal.Body>

            <Modal.Footer>
              <button onClick={() => {handleModalCategorias();}} type="button" className="btn btn-light-primary font-weight-bold">Fechar</button>
              <button onClick={() => {sendFrmCategoria();}} type="button" className="btn btn-success font-weight-bold">Salvar</button>
            </Modal.Footer>
          </Modal>
        {/* End Modal Categoria */}
      </div>

      <VariacoesModal modalVariacoesState={modalVariacoesState} setModalVariacoesState={setModalVariacoesState} variacao={variacao} atualizaPai={atualizaProduto} idCategoria={selectedCategory.id_variacao_categoria}/>

        </>
    )
}

export default withRouter(Variacoes);
