import React from 'react'
import { Modal } from "react-bootstrap";

export default function ModalUpdate({ product, selectedProduct, isOpen, toggleModal, onChange }) {
  const [nameCheck, setNameCheck] = React.useState(true);
  const [descriptionCheck, setDescriptionCheck] = React.useState(true);
  const [imageCheck, setImageCheck] = React.useState(true);

  const handleConfirm = () => {
    let onChangeData = { ...selectedProduct, id_produto: product.id_produto };

    if (nameCheck) {
      onChangeData = {
        ...onChangeData,
        nome_prod: product.nome_produto,
      };
    }

    if (descriptionCheck) {
      onChangeData = {
        ...onChangeData,
        descricao: product.descricao,
      };
    }

    if (imageCheck) {
      onChangeData = {
        ...onChangeData,
        foto: product.foto,
      };
    }

    onChange(onChangeData);
    toggleModal();
  };

  return (
    <Modal
      size={"lg"}
      show={isOpen}
      onHide={toggleModal}
    >
      <Modal.Header>
        <Modal.Title>Escolha os dados que serão alterados</Modal.Title>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={toggleModal}
          >
            <i aria-hidden="true" className="ki ki-close" />
          </button>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-12">
            <div className="form-group">
              <label className="checkbox checkbox-square checkbox-primary m-t-3 m-b-6 m-b-6">
                <input type="checkbox" checked={nameCheck} onChange={(e) => setNameCheck(e.target.checked)} />
                <span></span>
                Nome do Produto
              </label>
              <input
                value={product?.nome_produto}
                readOnly
                className="form-control form-control-lg form-control-solid"
                type="text"
              />
            </div>
          </div>
          <div className="col-12">
            <div className="form-group">
              <div className="checkbox-inline">
                  <label className="checkbox checkbox-square checkbox-primary m-t-3 m-b-6">
                    <input type="checkbox" checked={descriptionCheck} onChange={(e) => setDescriptionCheck(e.target.checked)} />
                    <span></span>
                    Descrição
                  </label>
              </div>
              <input
                value={product?.descricao}
                readOnly
                className="form-control form-control-lg form-control-solid"
                type="text"
              />
            </div>
          </div>
          <div className="col-6">
            <div className="form-group">
              <label className="checkbox checkbox-square checkbox-primary m-t-3 m-b-6">
                <input type="checkbox" checked={imageCheck} onChange={(e) => setImageCheck(e.target.checked)} />
                <span></span>
                Foto
              </label>
              <div>
                <img
                  style={{ height: 120 }}
                  src={
                    product?.foto
                      ? product.foto
                      : "assets/media/no-img2.jpg"
                  }
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-light-primary font-weight-bold"
          onClick={toggleModal}
        >
          Não substituir dados
        </button>
        <button
          type="button"
          className="btn btn-success font-weight-bold"
          onClick={handleConfirm}
        >
          Alterar dados selecionados
        </button>

      </Modal.Footer>
    </Modal>
  )
}
