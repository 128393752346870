import styled, { css, keyframes } from "styled-components";

const clientMessage = css`
  background: #f1f3f4;
  border-radius: 0px 16px 16px 16px;

  span {
    color: #222;
  }

  p {
    color: #222;
  }
`;

const displayAnimation = keyframes`
  to {
    transform: scale(1);
  }
  from {
    transform: scale(0);
  }
`;

export const Container = styled.div`
  max-width: 90%;
  min-width: 20%;
  position: relative;
  background: #ff3333;
  word-wrap: break-word;
  animation: ${displayAnimation} 0.4s ease;

  padding: 5px 10px 15px;
  border-radius: 16px 0px 16px 16px;

  p {
    color: #fff;
    font-size: 14px;
  }

  span {
    position: absolute;
    right: 10px;
    bottom: 5px;
    color: #fff;
    font-size: 10px;
  }

  ${(props) => props.type === "client" && clientMessage}
`;
