import api from "./api";

export class ChatService {
  static async chatInfo(orderId) {
    try {
      const response = await api.get(`/chat/orderInfo/${orderId}`);
      if (response.status) {
        return {
          data: response.data,
          ok: true,
        };
      }

      return {
        message: response.data,
        ok: false,
      };
    } catch (error) {
      return {
        ok: false,
        message: error.response.data.message || "Erro",
      };
    }
  }

  static async establishConnection(data) {
    try {
      const response = await api.post("/chat/establishConnection", data);

      if (response.status) {
        return {
          data: response,
          ok: true,
        };
      }
      return {
        ok: false,
        message: response.data.message || "Erro",
      };
    } catch (error) {
      return {
        ok: false,
        message: "Error",
      };
    }
  }

  static async createSocketConnection(data) {
    try {
      const response = await api.post("/chat/createSocketConnection", data);

      if (response.status) {
        return {
          data: response.data,
          ok: true,
        };
      }
      return {
        ok: false,
        message: response.data.message || "Erro",
      };
    } catch (error) {
      return {
        ok: false,
        message: "Error",
      };
    }
  }

  static async create(data) {
    try {
      const response = await api.post("/order", data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      return {
        data: response.data,
        ok: true,
      };
    } catch (error) {
      return {
        ok: false,
        message: error.response.data.message || "Erro",
      };
    }
  }

  static async get(data) {
    try {
      const response = await api.get(
        "/order",
        { params: data },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      return {
        data: response.data,
        ok: true,
      };
    } catch (error) {
      return {
        ok: false,
        message: error.response.data.message || "Erro",
      };
    }
  }

  static async getById(data) {
    try {
      const response = await api.get(`/order/${data}`, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      return {
        data: response.data,
        ok: true,
      };
    } catch (error) {
      return {
        ok: false,
        message: error.response.data.message || "Erro",
      };
    }
  }
}
