import React, { useState, useEffect, useMemo } from "react";
import { useToasts } from "react-toast-notifications";
import Swal from "sweetalert2";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import CurrencyInput from "react-currency-input";
import arrayMove from "array-move";

import Api from "../../../services/api";
import SortableComponentItemNew from "./components/SortableComponentItem";

const VariacoesModal = ({
  modalVariacoesState,
  setModalVariacoesState,
  variacao,
  atualizaPai,
  idCategoria,
}) => {
  const calculoPrecos = useMemo(
    () => [
      { value: "", label: "Selecione" },
      { value: "mp", label: "Maior preço" },
      { value: "pm", label: "Menor preço" },
      { value: "mm", label: "Média preços" },
      { value: "st", label: "Soma total" },
      { value: "in", label: "Incremental" },
    ],
    []
  );

  const { addToast } = useToasts();

  const [formVariacoesGlob, setFormVariacoesGlob] = useState({ estoque: [] });

  useEffect(() => {
    getIngredientes();
  }, []);

  useEffect(() => {
    //Verificar se é uma inclusão ou adição
    if (typeof variacao === "object") {
      if (variacao.id_variacao === undefined || variacao.id_variacao === null) {
        variacao.escolha_minima = 0;
        variacao.escolha_maxima = 0;
        variacao.calculo_preco = "";
        variacao.estoque = [];

        if (variacao.id_cardapio) {
          variacao.id_variacao_categoria = -2;
        } else {
          variacao.id_cardapio = 0;
          if (idCategoria) {
            variacao.id_variacao_categoria = idCategoria;
          }
        }
        setLiberadoAdicaoItem(false);
        resetFormAdicaoItem(null);
      } else {
        setLiberadoAdicaoItem(true);
        resetFormAdicaoItem(null);
      }
      setFormVariacoesGlob(variacao);
    }

    //Carregar tamanhos do produto
    if (variacao.id_cardapio) {
      getTamanhos(variacao.id_cardapio);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalVariacoesState]);

  async function sendFormVariacao() {
    var id = "";
    if (formVariacoesGlob.id_variacao) id = formVariacoesGlob.id_variacao;
    Api.post("variacoes/adicionar/" + id, formVariacoesGlob).then((rps) => {
      if (rps.data.status === true) {
        setLiberadoAdicaoItem(true);
        setFormVariacoesGlob({
          ...formVariacoesGlob,
          id_variacao: rps.data.id_variacao,
        });

        addToast(rps.data.mensagem, {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 2000,
        });
      } else {
        Swal.fire({
          title: "Atenção!",
          icon: "info",
          html: rps.data.erro,
          showCloseButton: true,
          showCancelButton: false,
        });
      }
    });
  }

  /*Inicio - Edição de preço de variação */
  const [frmEditaPreco, setFrmEditaPreco] = useState({});

  function sendFrmEditaPreco() {
    Api.post(
      "variacoesestoque/adicionar/" + frmEditaPreco.id_variacao_estoque,
      frmEditaPreco
    ).then((rps) => {
      reloadVariacao();
      setFrmEditaPreco({});

      addToast(rps.data.mensagem, {
        appearance: "success",
        autoDismiss: true,
        autoDismissTimeout: 2000,
      });
    });
  }
  /*Final - Edição de preço de variação */

  /* Inicio - Ingredientes externo */
  const [ingredientes, setIngredientes] = useState([]);

  async function getIngredientes() {
    const response = await Api.get("estoque/listar");
    if (response.data.status === true) {
      const estoque = response.data.estoque.map((item) => ({
        value: item.id_estoque,
        label: item.nome_item,
      }));

      estoque.unshift({ value: -1, label: "Selecione..." });

      setIngredientes(estoque);
    }
  }
  /* Final - Ingredientes externo */

  /*Inicio - Cardápio tamanhos - vinculo */
  const [tamanhos, setTamanhos] = useState([]);
  async function getTamanhos(id_cardapio) {
    const response = await Api.get("cardapiotamanhos/listar/" + id_cardapio);
    if (response.data.status === true) {
      const cardapiotamanhos = response.data.cardapiotamanhos.map((item) => ({
        value: item.id_cardapio_tamanho,
        label: item.nome,
      }));

      cardapiotamanhos.unshift({ value: -1, label: "Selecione..." });

      setTamanhos(cardapiotamanhos);
    }
  }
  /*Final - Cardápio tamanhos - vinculo */

  const [liberadoAdicaoItem, setLiberadoAdicaoItem] = useState(false);

  /*Inicio - Form de ação de itens*/
  const [formAdicaoItem, setFormAdicaoItem] = useState({});
  function resetFormAdicaoItem(obj) {
    if (obj === null) {
      setFormAdicaoItem({
        id_estoque: -1,
      });
    }
  }
  function sendFormAdicaoItem() {
    var adicionaItem = formAdicaoItem;
    adicionaItem.id_variacao = formVariacoesGlob.id_variacao;

    Api.post("variacoesestoque/adicionar", adicionaItem).then((rps) => {
      if (rps.data.status === true) {
        addToast(rps.data.mensagem, {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 2000,
        });
        reloadVariacao();
        resetFormAdicaoItem(null);
      } else {
        Swal.fire({
          title: "Atenção!",
          icon: "info",
          html: rps.data.erro,
          showCloseButton: true,
          showCancelButton: false,
        });
      }
    });
  }
  function excluirItem(item) {
    Swal.fire({
      title: "Você tem certeza?",
      text: "Não será possível reverter!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim deletar.",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.value) {
        Api.post("variacoesestoque/excluir", {
          id_variacao_estoque: item.id_variacao_estoque,
        }).then((rps) => {
          if (rps.data.status === true) {
            addToast(rps.data.mensagem, {
              appearance: "success",
              autoDismiss: true,
              autoDismissTimeout: 2000,
            });
            reloadVariacao();
          } else {
            Swal.fire({
              title: "Atenção!",
              icon: "info",
              html: rps.data.erro,
              showCloseButton: true,
              showCancelButton: false,
            });
          }
        });
      }
    });
  }
  /*Final - Form de ação de itens*/

  function reloadVariacao() {
    Api.get("variacoes/listar/" + formVariacoesGlob.id_variacao).then((rps) => {
      if (rps?.data?.status === true) {
        setFormVariacoesGlob(rps.data.variacoes[0]);
      } else {
        alert("Erro ao obter variação");
      }
    });
  }

  function handleFechaModal() {
    setModalVariacoesState(false);
    atualizaPai();
  }

  //INICIO SORTABLE
  function onSortEnd({ oldIndex, newIndex }) {
    let newOrder = arrayMove(formVariacoesGlob.estoque, oldIndex, newIndex);
    setFormVariacoesGlob(oldFormVariacoes => ({
      ...oldFormVariacoes,
      estoque: newOrder,
    }));
    Api.post("variacoesestoque/ordem", { ordem: newOrder }).then((rps) => {
      if (rps.data.status === true) {
        addToast(rps.data.mensagem, {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 2000,
        });
      }
    });
  }
  //FIM SORTABLE

  return (
    <>
      <Modal
        size={"xl"}
        show={modalVariacoesState}
        onHide={() => handleFechaModal()}
      >
        <Modal.Header closeButton>
          <Modal.Title>Variações</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="area-top-header-variacao">
            <div className="row">
              <div className="col-7">
                <div className="form-group">
                  <label>Nome da Variação</label>
                  <input
                    value={formVariacoesGlob?.nome_variacao}
                    onChange={(e) => {
                      setFormVariacoesGlob({
                        ...formVariacoesGlob,
                        nome_variacao: e.target.value,
                      });
                    }}
                    className="form-control form-control-solid form-small"
                    type="text"
                  />
                </div>
              </div>
              <div className="col-5">
                {formVariacoesGlob?.id_cardapio && (
                  <div className="form-group">
                    <label>Vínculo a algum tamanho?</label>
                    <Select
                      options={tamanhos}
                      value={tamanhos.find(
                        (x) =>
                          x.value === formVariacoesGlob?.id_cardapio_tamanho
                      )}
                      onChange={(event) => {
                        setFormVariacoesGlob({
                          ...formVariacoesGlob,
                          id_cardapio_tamanho: event.value,
                        });
                      }}
                    />
                  </div>
                )}
              </div>
              <div className="col-3">
                <div className="form-group">
                  <label>Escolha Mín.</label>
                  <input
                    value={formVariacoesGlob?.escolha_minima}
                    onChange={(e) => {
                      setFormVariacoesGlob({
                        ...formVariacoesGlob,
                        escolha_minima: e.target.value,
                      });
                    }}
                    className="form-control form-control-solid form-small"
                    type="number"
                    min="0"
                  />
                </div>
              </div>
              <div className="col-3">
                <div className="form-group">
                  <label>Escolha Máx.</label>
                  <input
                    value={formVariacoesGlob?.escolha_maxima}
                    onChange={(e) => {
                      setFormVariacoesGlob({
                        ...formVariacoesGlob,
                        escolha_maxima: e.target.value,
                      });
                    }}
                    className="form-control form-control-solid form-small"
                    type="number"
                    min="0"
                  />
                </div>
              </div>
              <div className="col-4 col-calculo-preco">
                <div className="form-group">
                  <label>Cálculo do Preço</label>
                  <Select
                    options={calculoPrecos}
                    value={calculoPrecos.find(
                      (x) => x.value === formVariacoesGlob?.calculo_preco
                    )}
                    onChange={(event) => {
                      setFormVariacoesGlob({
                        ...formVariacoesGlob,
                        calculo_preco: event.value,
                      });
                    }}
                  />
                </div>
              </div>
              <div className="col-2">
                <button
                  onClick={(e) => {
                    sendFormVariacao();
                  }}
                  className="btn btn-primary font-weight-bolder btn-lg btn-block m-t-11"
                >
                  Salvar
                </button>
              </div>
              <hr style={{ width: "100%" }} />
              <div className="col-3">
                <div className="form-group">
                  <label>Item (Estoque)</label>
                  <Select
                    options={ingredientes}
                    value={ingredientes.find(
                      (x) => x.value === formAdicaoItem?.id_estoque
                    )}
                    onChange={(event) => {
                      setFormAdicaoItem({
                        ...formAdicaoItem,
                        id_estoque: event.value,
                        nome_var: "",
                      });
                    }}
                    isDisabled={!liberadoAdicaoItem}
                  />
                </div>
              </div>
              <div className="col-3">
                <div className="form-group">
                  <label>Item Avulso</label>
                  <input
                    value={formAdicaoItem?.nome_var}
                    onChange={(e) => {
                      setFormAdicaoItem({
                        ...formAdicaoItem,
                        nome_var: e.target.value,
                        id_estoque: -1,
                      });
                    }}
                    disabled={!liberadoAdicaoItem}
                    className="form-control form-control-solid form-small"
                    type="text"
                  />
                </div>
              </div>
              <div className="col-3">
                <div className="form-group">
                  <label>Preço</label>
                  <CurrencyInput
                    maxLength={6}
                    precision={"2"}
                    disabled={!liberadoAdicaoItem}
                    value={formAdicaoItem.preco}
                    onChangeEvent={(e) => {
                      setFormAdicaoItem({
                        ...formAdicaoItem,
                        preco: e.target.value,
                      });
                    }}
                    decimalSeparator="."
                    className="form-control form-control-solid form-small"
                    type="text"
                  />
                </div>
              </div>
              <div className="col-3">
                <button
                  onClick={(e) => {
                    sendFormAdicaoItem();
                  }}
                  disabled={!liberadoAdicaoItem}
                  className="btn btn-primary font-weight-bolder btn-lg btn-block m-t-11"
                >
                  Incluir
                </button>
              </div>
            </div>
          </div>
          <div className="table-div-header padding-horizontal area-adjust-composicao">
            <div className="table-div-header-item-produto-variacoes-modal" />
            <div className="table-div-header-item-produto-variacoes-modal">
              Item
            </div>
            <div className="table-div-header-item-produto-variacoes-modal">
              Preço
            </div>
            <div className="table-div-header-item-produto-variacoes-modal text-right pr-12">
              Ações
            </div>
          </div>

          <SortableComponentItemNew
            items={formVariacoesGlob?.estoque}
            onSortEnd={onSortEnd}
            frmEditaPreco={frmEditaPreco}
            setFrmEditaPreco={setFrmEditaPreco}
            sendFrmEditaPreco={sendFrmEditaPreco}
            excluirItem={excluirItem}
          />
        </Modal.Body>

        <Modal.Footer>
          <button
            onClick={() => {
              handleFechaModal();
            }}
            type="button"
            className="btn btn-light-primary font-weight-bold"
          >
            Fechar
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default VariacoesModal;
