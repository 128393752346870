import Swal from "sweetalert2";
import Axios from 'axios';
import qz from "qz-tray";
import { sha256 } from 'js-sha256';
import { KJUR, KEYUTIL, stob64, hextorstr } from 'jsrsasign';

const impressora = {

  qzSecurity(){

    var qzCertificate = localStorage.getItem('qzCertificate');
    var qzPrivate = localStorage.getItem('qzPrivate');

    if(!qzCertificate){
      alert("QZ: Falta configurar CERTIFICADO");
      return;
    }

    if(!qzPrivate){
      alert("QZ: Falta configurar PRIVATE");
      return;
    }

    qz.security.setCertificatePromise((resolve, reject) => {
      resolve(qzCertificate);
    });

    qz.security.setSignatureAlgorithm("SHA512"); // Since 2.1
    qz.security.setSignaturePromise(hash => {
    return (resolve, reject) => {
        var pk = KEYUTIL.getKey(qzPrivate);
        var sig = new KJUR.crypto.Signature({"alg": "SHA512withRSA"}); // Use "SHA1withRSA" for QZ Tray 2.0 and older
        sig.init(pk);
        sig.updateString(hash);
        var hex = sig.sign();
        console.log("DEBUG: \n\n" + stob64(hextorstr(hex)));
        resolve(stob64(hextorstr(hex)));
    }
    });
  },
  
  getPrinters(resposta) {
    const printersArray = [];
    
    var met = localStorage.getItem('metodoImpressao');
    if(met==='qz'){
      this.qzSecurity();
      qz.websocket.connect().then(() => {
        return qz.printers.find();
      }).then((printers) => {
        for (let index = 0; index < printers.length; index++) {
            printersArray.push({ "nome": printers[index] })
        }
        
      }).then(() => {
        qz.websocket.disconnect();
        resposta(printersArray);
      }).catch((err) => {
          console.error(err);
          // process.exit(1);
      });

    } else if(met==='nat') {
      try {
        Axios.get('http://127.0.0.1:8847/list').then(response => {

          const printersResponse = response.data.printers;
          
          printersResponse.forEach(p => {
            printersArray.push({ "nome": p.name})
          })

          resposta(printersArray);
        });
      } catch (error) {
        Swal.fire({
          title: "Atenção!",
          icon: "info",
          html: 'Erro na impressão nativa 8847!',
          showCloseButton: true,
          showCancelButton: false
        });
        resposta(null);
      }
    } else { 
      try {
        Axios.get('http://127.0.0.1:65517/impressoras').then(response => {

          const printersResponse = response.data.impressoras;
          
          for (let index = 0; index < printersResponse.length; index++) {
            printersArray.push({ "nome": printersResponse[index] })
          }
          resposta(printersArray);
        });
      } catch (error) {
        Swal.fire({
          title: "Atenção!",
          icon: "info",
          html: 'Módulo da impressora não encontrado ',
          showCloseButton: true,
          showCancelButton: false
        });
        resposta(null);
      }
    }
  },
  requestImpressao(impressora, corpo, tamanho){
    let tam = tamanho;
    if(tam===undefined || tam===null)
      tam = localStorage.getItem('tamanhoFonte')

    let nCorpo = (corpo+"").split("[").join("|");;
    nCorpo = nCorpo.split("]").join("|");;
    nCorpo = nCorpo.split("{").join("|");;
    nCorpo = nCorpo.split("}").join("|");;
  
    var met = localStorage.getItem('metodoImpressao');
    if(met==='qz'){
      this.qzSecurity();
      qz.websocket.connect().then(() => {
        return qz.printers.find(impressora);
      }).then((found) => {
        var config = qz.configs.create(found);
        var data = [{
          type: 'pixel',
          format: 'html',
          flavor: 'plain',
          data: nCorpo
        }]
        return qz.print(config, data);
      }).catch((e) => {
        alert(e);
      }).finally(() => {
        return qz.websocket.disconnect();
      })
    } else if(met==='nat') {
      let body = JSON.stringify({
        name: impressora,
        text: nCorpo,

        qtdeCopia: localStorage.getItem('qtdeCopia'),
        larguraMaximaPagina: localStorage.getItem('larguraMaximaPagina'),
        saltoLinhaInicio: localStorage.getItem('saltoLinhaInicio'),
        margemEsquerda: localStorage.getItem('margemEsquerda'),
        margemDireita: localStorage.getItem('margemDireita'),
        nomeFonte: localStorage.getItem('nomeFonte'),
        tamanhoFonte: tam,
        impressaoProducao: localStorage.getItem('impressaoProducao'),
      });

      fetch('http://127.0.0.1:8847/print', {
          method: 'post',
          headers: new Headers({
              'Content-Type': 'application/json'
            }),
          body: body
      }).then(function(response){
          console.log(response)
          // trate a resposta aqui
      }).catch(function(err) {
          console.log(err)
          alert("Erro EP01 - módulo de impressão");
      })
    } else {
      let body = JSON.stringify({
        impressora: impressora,
        texto: nCorpo,
        qtdeCopia: localStorage.getItem('qtdeCopia'),
        larguraMaximaPagina: localStorage.getItem('larguraMaximaPagina'),
        saltoLinhaInicio: localStorage.getItem('saltoLinhaInicio'),
        margemEsquerda: localStorage.getItem('margemEsquerda'),
        margemDireita: localStorage.getItem('margemDireita'),
        nomeFonte: localStorage.getItem('nomeFonte'),
        tamanhoFonte: tam,
        impressaoProducao: localStorage.getItem('impressaoProducao'),
      });

      fetch('http://127.0.0.1:65517/imprimir', {
          method: 'post',
          headers: new Headers({
              'Content-Type': 'application/json'
            }),
          body: body
      }).then(function(response){
          console.log(response)
          // trate a resposta aqui
      }).catch(function(err) {
          console.log(err)
          alert("Erro EP01 - módulo de impressão");
      })
    }
  },
  sleep(milliseconds) {
    milliseconds = parseInt(milliseconds);
    var start = new Date().getTime();

    while((new Date().getTime() - start) > milliseconds == false){
    }
  },
  geraHtmlProducao(itens, pedido){
    var texto = '';
    var qnt = 0;
    var qntI = 0;
    var nItem = 0;

    texto += '<br>';
    texto += '=======================';
    if (localStorage.getItem('impressaoEstabelecimento') === "true")
    texto += '<br><b>Estabelecimento:</b> '+pedido.estabelecimento_nome;
    texto += '<br><b>Pedido</b>: '+pedido.hash_pedido+' | '+pedido.data_criacao;
    if (localStorage.getItem('impressaoCliente') === "true")
    texto += '<br><b>Cliente:</b> '+pedido.usuario_nome;
    texto += '<br>';
    itens.forEach(item => {
      qntI++;
      qnt = qnt + parseInt(item.quantidade);

      if (localStorage.getItem('impressaoItemAgrupado') === "true") {
        for (var x=1; x <= item.quantidade; x++) {
          nItem++;
          texto+= '<br><a>Item: '+nItem+' - '+item.nome+'</a>';
          item.linhas.forEach(lin=> {
            texto += '<br>'+lin;
          })
        }
      } else {
        texto += '<br><br><a>'+item.quantidade+'und - '+item.nome+'</a>';
        item.linhas.forEach(lin=> {
          texto += '<br></a>'+lin;
        })
      }
    })

    texto += '<br><br>Qnt itens: '+qnt+' | Qnt prod: '+qntI;
    texto += '<br>';
    texto += '=======================';

    return texto;
  },
  geraHtmlCaixa(pedido, layout){
    var texto = '';
    var nItem = 0;

    if(layout===null || layout==="1" || layout==='' || layout===undefined){
      texto = pedido.pedido;
    } else if(layout==="2"){
      var pedido2 = pedido;
      pedido = pedido.pedidoObj;
      texto += "<br><b>=== AIBOO DELIVERY ===</b>";
      if (localStorage.getItem('impressaoEstabelecimento') === "true")
        texto += '<br><b>Estabelecimento:</b> '+pedido2.estabelecimento_nome;
      texto += "<br><b>Pedido:</b> #"+pedido.hash_pedido+" <b>Data: </b>"+pedido.data_criacao;
      texto += "<br>";
      texto += "<br><b>=== ITENS DO PEDIDO ===</b>";
      pedido.itens.forEach(item => {
      if (localStorage.getItem('impressaoItemAgrupado') === "false") {
        for (var x=1; x <= item.quantidade; x++) {
          nItem++;
          texto+= '<br><b>Item: '+nItem+' - '+item.nome+'</b>';
          item.linhas.forEach(lin=> {
            texto += '<br>'+lin;
          })
        }
      } else {
        texto += '<br><b>'+item.quantidade+'und - '+item.nome+'</b>';
        item.linhas.forEach(lin=> {
          texto += '<br>'+lin;
        })
      }
      });
      texto += "<br>";
      texto += "<br><b>===== PAGAMENTO =====</b>";
      pedido.dados_pagamento.map(val => {
        texto += "<br>"+val.label+": "+val.value;
      })
      texto += "<br><b>COBRAR: "+pedido.cobrar_cliente+"</b>";
      texto += "<br>F. Pgto: "+pedido.forma_pagamento;
      texto += "<br>";
      texto += "<br><b>====== ENTREGA/CLIENTE ======</b>";
      texto += "<br><b>Cliente: </b>"+pedido.usuario_nome;
      var confirmado = '';
      if(pedido.usuario_confirmado===true) confirmado = 'CONFIRMADO';
      texto += "<br><b>Tel: </b>"+pedido.usuario_telefone_formatado+" "+confirmado;
      if(pedido.retirada_local===true){
        texto += "<br><b>Cliente vai retirar</b>";
      } else {
        texto += "<br>"+pedido.endereco_completo;
      }
      texto += "<br>";
      texto += "<br>Nao e docto. fiscal.";
      texto += "<br><b>**---**---**---** Aiboo (27) 4042-1580</b>";
    }

    return texto;
  },
  imprimir(pedido){
    var impressoraPadrao = '';
    var impressaoLayout = '';
    var impressorasCaixa = [];
    var impressaoProducao = false;
    var impressorasProducao = [];
    if (localStorage.getItem('impressora')){
      impressoraPadrao = localStorage.getItem('impressora');
    }
    if (localStorage.getItem('impressaoLayout')){
      impressaoLayout = localStorage.getItem('impressaoLayout');
    }
    if (localStorage.getItem('impressorasCaixa')){
      impressorasCaixa = JSON.parse(localStorage.getItem('impressorasCaixa'));
    }
    if (localStorage.getItem('impressaoProducao')){
      var imp = localStorage.getItem('impressaoProducao');
      if(imp==='true'){
        impressaoProducao = true;
      }
    }
    if (localStorage.getItem('impressorasProducao')){
      impressorasProducao = JSON.parse(localStorage.getItem('impressorasProducao'));
    }

    if(impressoraPadrao===''){
      Swal.fire({
        title: "Atenção!",
        icon: "info",
        html: 'Impressora não selecionada, escolha uma impressora e tente novamente.',
        showCloseButton: true,
        showCancelButton: false
      });
      return
    }

    //Gerar impressão normal
    this.requestImpressao(impressoraPadrao, this.geraHtmlCaixa(pedido, impressaoLayout))
    this.sleep(localStorage.getItem('delay'));
    impressorasCaixa.forEach(impressora => {
      if(impressora!=0){
        this.requestImpressao(impressora, this.geraHtmlCaixa(pedido, impressaoLayout))
        this.sleep(localStorage.getItem('delay'));
      }
    });

    //Gerar impressão de produção
    var nProdutos = {};
    if(impressaoProducao===true){
      //1.0 Agrupar produtos por categoria:
      pedido.pedidoObj.itens.forEach(produto => {
        if(nProdutos[produto.id_cardapio_categoria]===undefined)
          nProdutos[produto.id_cardapio_categoria] = [];

        nProdutos[produto.id_cardapio_categoria][nProdutos[produto.id_cardapio_categoria].length] = produto;
      });

      //2.0 Agrupar produtos por "agrupamento"
      ///2.0.1 Verificar se existe seleção deste grupo:
      var ondeImprimir = {};
      var nProdutos1 = {};
      for (var [key, value] of Object.entries(nProdutos)) {
        for (var [, vava] of Object.entries(value)) {
          if(impressorasProducao[key]!=undefined){
            var chave = '';
            if(impressorasProducao[key].agrupamento!=undefined && impressorasProducao[key].agrupamento!=''){
              var agrNome = "A"+impressorasProducao[key].agrupamento;
              chave = agrNome;
              if(nProdutos1[agrNome]===undefined) nProdutos1[agrNome] = [];
              nProdutos1[agrNome][nProdutos1[agrNome].length] = vava;
            } else {
              chave = key;
              if(nProdutos1[key]===undefined) nProdutos1[key] = [];
              nProdutos1[key][nProdutos1[key].length] = vava;
            }
            ondeImprimir[chave] = impressoraPadrao;
            if(impressorasProducao[key].impressora!=undefined){
              if(impressorasProducao[key].impressora==="0"){
                ondeImprimir[chave] = impressoraPadrao;
              } else {
                ondeImprimir[chave] = impressorasProducao[key].impressora;
              }
            }
          } else {
            ondeImprimir[key] = impressoraPadrao;
            if(nProdutos1[key]===undefined) nProdutos1[key] = [];
            nProdutos1[key][nProdutos1[key].length] = vava;
          }
        }
      }

      for (var [key, value] of Object.entries(nProdutos1)) {
        var impressora = ondeImprimir[key];
        var textoAImprimir = this.geraHtmlProducao(value, pedido);
        this.sleep(localStorage.getItem('delay'));
        let tamTemp = parseInt(localStorage.getItem('tamanhoFonte'))+2;
        //let tamTemp = 'fs32';
        this.requestImpressao(impressora, textoAImprimir, tamTemp);
      }
    }
  }
}

export default impressora;