import React, { useCallback, useState } from "react";

import ApiService from "../../../../services/api";
import Axios from "axios";

import * as S from "./styles";
import { useToasts } from "react-toast-notifications";

const initialFormValues = [
  {
    id: "product_ean_cod",
    description: "Código de barras",
    required: true,
    checked: true,
  },

  {
    id: "product_name",
    description: "Nome do produto",
    checked: false,
  },
  {
    id: "product_qty",
    description: "Quantidade em estoque",
    required: true,
    checked: true,
  },
  {
    id: "product_price",
    description: "Preço",
    required: true,
    checked: true,
  },
  {
    id: "product_promo_price",
    description: "Preço promocional",
    checked: false,
  },
  {
    id: "product_category",
    description: "Categoria",
    checked: false,
  },
  { id: "product_pdv_cod", description: "Código PDV", checked: false },
];

const UploadConfigForm = ({
  uploadedFile,
  setUploadedFile,
  setBulkUploadId,
  fileInputRef,
  pageStatus,
  setPageStatus,
}) => {
  const [tooltipVisibility, setTooltipVisibility] = useState(false);

  const [initialDataColumnsConfig, setInitialDataColumnsConfig] =
    useState(initialFormValues);
  const [selectedColumnsConfig, setSelectedColumnsConfig] = useState({});

  const [updateRegisteredProducts, setUpdateRegisteredProducts] =
    useState(false);

  const { addToast } = useToasts();

  const uploadApi = Axios.create({
    baseURL: process.env.REACT_APP_BASEURL_AIBOO_WS_LARAVEL,
    headers: {
      "content-type": "multipart/form-data",
      Authorization: localStorage.getItem("hash"),
    },
  });

  const handleClickUploadFileButton = () => {
    fileInputRef.current.click();
  };

  const toggleTooltip = () => {
    setTooltipVisibility((previousState) => !previousState);
  };

  const handleUploadedFile = useCallback(
    (event) => {
      setUploadedFile(event.target?.files[0]);
      const maxSizeInBytes = 10485760;
      if (uploadedFile?.size > maxSizeInBytes) {
        event.target.value = null;

        addToast(
          "O arquivo é muito grande. Tente fazer o upload de um arquivo com no máximo 10MB.",
          {
            appearance: "error",
            autoDismiss: true,
            autoDismissTimeout: 4000,
          }
        );
        return;
      }
    },
    [addToast, setUploadedFile, uploadedFile?.size]
  );

  const handleUpdateRegisteredProducts = () => {
    setUpdateRegisteredProducts((prevState) => !prevState);
  };

  const handleCheckboxValues = (event, itemId) => {
    const { name, checked } = event.target;

    setInitialDataColumnsConfig((prevstate) => {
      const updatedDataColumnsConfig = prevstate.map((item) => {
        if (item?.id === itemId) {
          return { ...item, checked: item.required ?? !item.checked };
        }
        return item;
      });
      return updatedDataColumnsConfig;
    });

    if (!checked) {
      setSelectedColumnsConfig((prevState) => {
        const { [name]: uncheckedItem, ...checkedItems } = prevState;
        return checkedItems;
      });
      return;
    }
  };

  const handleColumnValues = (event, itemId) => {
    const { value } = event.target;

    setSelectedColumnsConfig((prevstate) => ({
      ...prevstate,
      [itemId]: parseInt(value),
    }));
  };

  const handleSubmitConfigForm = async (event) => {
    event.preventDefault();
    const selectedColumnsConfigEntries = Object.entries(selectedColumnsConfig);
    if (!uploadedFile.size) {
      addToast("Selecione um arquivo CSV.", {
        appearance: "warning",
        autoDismiss: true,
        autoDismissTimeout: 4000,
      });
      return;
    }

    const hasDuplicatedColumns = selectedColumnsConfigEntries.some(
      ([key, value], index) => {
        const remainingEntries = selectedColumnsConfigEntries.slice(index + 1);
        return remainingEntries.some(
          ([nextKey, nextValue]) => nextValue === value
        );
      }
    );
    if (hasDuplicatedColumns) {
      addToast("Não é possível ter dois tipos de dados na mesma coluna.", {
        appearance: "error",
        autoDismiss: true,
        autoDismissTimeout: 4000,
      });
      return;
    }

    const bulkUploadData = new FormData();

    for (const key in selectedColumnsConfig) {
      bulkUploadData.append(
        `columns_disposition[${key}]`,
        selectedColumnsConfig[key]
      );
    }

    bulkUploadData.append("file", uploadedFile);
    bulkUploadData.append("should_update", Number(updateRegisteredProducts));
    bulkUploadData.append(
      "id_estabelecimento",
      localStorage.getItem("id_estabelecimento")
    );

    try {
      setPageStatus("loading");
      const response = await uploadApi.post("product-files", bulkUploadData);
      setBulkUploadId(response.data.product_file.id);
    } catch {
      addToast(
        "Ops! algo deu errado com o upload dos produtos, tente novamente mais tarde!",
        {
          appearance: "error",
          autoDismiss: true,
          autoDismissTimeout: 4000,
        }
      );

      setPageStatus("error");
    }
  };

  const renderFormItems = () => {
    return initialDataColumnsConfig.map((item) => (
      <S.FormItemContainer>
        <S.CheckboxInputContainer>
          <S.Checkbox
            id={item.id}
            name={item.id}
            value={item.id}
            type="checkbox"
            defaultChecked={item.checked}
            required={!!item.required}
            onChange={(event) => handleCheckboxValues(event, item.id)}
            disabled={pageStatus === "uploading"}
          />
          <label for={item.id}>
            {item.description}
            {item.required && <span> *</span>}
          </label>
        </S.CheckboxInputContainer>
        <S.CollumnInputContainer>
          <p>Coluna</p>

          <input
            type="number"
            value={item.checked ? selectedColumnsConfig[item.id] : ""}
            onWheel={(e) => e.target.blur()}
            min="1"
            onChange={(event) => handleColumnValues(event, item.id)}
            required={item.checked}
            disabled={!item.checked || pageStatus === "uploading"}
          />
        </S.CollumnInputContainer>
      </S.FormItemContainer>
    ));
  };

  const renderForm = () => {
    return (
      <S.FormContainer>
        <h3>Organização dos dados</h3>
        <small>
          Especifique em qual coluna do arquivo cada dado se encontra
        </small>

        <form onSubmit={handleSubmitConfigForm}>
          {renderFormItems()}

          <S.SubmitButtonContainer>
            <S.StyledLoader
              active={pageStatus === "loading"}
              classNamePrefix="MyLoader_"
              spinner
              styles={{
                spinner: (base) => ({
                  ...base,
                  width: "24px",
                  "& svg circle": {
                    stroke: "#FFFFFF",
                  },
                }),
              }}
            >
              <button
                type="submit"
                disabled={
                  pageStatus === "loading" || pageStatus === "uploading"
                }
              >
                {pageStatus !== "loading" && "Enviar arquivo"}
              </button>
            </S.StyledLoader>
          </S.SubmitButtonContainer>
        </form>
      </S.FormContainer>
    );
  };

  return (
    <S.Container>
      <h2>Configurações</h2>
      <S.Separator />
      <input
        type="file"
        accept=".csv"
        style={{ display: "none" }}
        ref={fileInputRef}
        onChange={handleUploadedFile}
      />
      <S.UploadButtonContainer>
        <S.UploadButton onClick={handleClickUploadFileButton}>
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2 16C1.45 16 0.979002 15.804 0.587002 15.412C0.195002 15.02 -0.000664969 14.5493 1.69779e-06 14V11H2V14H14V11H16V14C16 14.55 15.804 15.021 15.412 15.413C15.02 15.805 14.5493 16.0007 14 16H2ZM7 12V3.85L4.4 6.45L3 5L8 0L13 5L11.6 6.45L9 3.85V12H7Z"
              fill="#FF3333"
            />
          </svg>
          <label>Selecione um arquivo .csv</label>
        </S.UploadButton>
        {uploadedFile?.name !== "" && <label>{uploadedFile?.name}</label>}
      </S.UploadButtonContainer>

      <S.CheckboxInputContainer>
        <S.Checkbox
          id="update"
          type="checkbox"
          value={updateRegisteredProducts}
          onChange={(event) => handleUpdateRegisteredProducts()}
        />
        <label for="update">Atualizar produtos cadastrados</label>
        <i
          className="fa fa-question-circle"
          onMouseEnter={toggleTooltip}
          onMouseLeave={toggleTooltip}
        />
        <S.Tooltip visibility={tooltipVisibility}>
          <p>
            Atualiza os dados e disponibilidade dos produtos existentes de
            acordo com arquivo que será enviado
          </p>
        </S.Tooltip>
      </S.CheckboxInputContainer>

      {renderForm()}
    </S.Container>
  );
};
export default UploadConfigForm;
