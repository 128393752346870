import { createContext, useEffect } from "react";
import Swal from 'sweetalert2'

const CloseElectronContext = createContext(null);

export const CloseElectronProvider = ({ children }) => {
  useEffect(() => {
    if (window.electron) {
      window.electron?.receive("on-close-click", (text) => {
        Swal.fire({
          title: 'Tem certeza que precisa fechar?',
          showDenyButton: true,
          showCancelButton: true,
          confirmButtonText: `Minimizar`,
          denyButtonText: `Fechar`,
          cancelButtonText: 'Cancelar'
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            console.log('minimizar')
            window.electron?.send('toAppQuit', {
              minimize: true
            })
          } else if (result.isDenied) {
            console.log('fechar')
            window.electron?.send('toAppQuit', {
              quitApp: true
            })
          }
        })
      });
    }
  }, []);

  return (
    <CloseElectronContext.Provider value={null}>
      {children}
    </CloseElectronContext.Provider>
  );
};

export default CloseElectronContext;
