const setSessionInStorage = (data) => {
    try {
        sessionStorage.setItem('id_estabelecimento', data.usuario.id_estabelecimento)
        sessionStorage.setItem('id_usuario', data.usuario.id_usuario)
        sessionStorage.setItem('nome', data.usuario.nome)
        sessionStorage.setItem('hash', data.usuario.sessao.hash)
        sessionStorage.setItem('id_sessao', data.usuario.sessao.id_sessao)
        sessionStorage.setItem('url_socket', data.usuario.socket.url)
        sessionStorage.setItem('canal_socket', data.usuario.socket.canal)
        return true;
    } catch (error) {
        console.log('Erro ao armazenar session')
        return error
    }
}

const setLocalStorage = (data) => {
    try {
        localStorage.setItem('id_estabelecimento', data.usuario.id_estabelecimento)
        localStorage.setItem('id_usuario', data.usuario.id_usuario)
        localStorage.setItem('nome', data.usuario.nome)
        localStorage.setItem('hash', data.usuario.sessao.hash)
        localStorage.setItem('id_sessao', data.usuario.sessao.id_sessao)
        localStorage.setItem('url_socket', data.usuario.socket.url)
        localStorage.setItem('canal_socket', data.usuario.socket.canal)

        //Impressão - Valores que não podem ser enviados em Branco.
        localStorage.setItem('qtdeCopia', '1')
        localStorage.setItem('delay', '1000')
        localStorage.setItem('larguraMaximaPagina', '7,0')
        localStorage.setItem('saltoLinhaInicio', '0')
        localStorage.setItem('margemEsquerda', '0')
        localStorage.setItem('margemDireita', '0')
        localStorage.setItem('nomeFonte', 'Tahoma')
        localStorage.setItem('tamanhoFonte', '8')
        localStorage.setItem('impressaoItemAgrupado','false')
        localStorage.setItem('impressaoCliente','false')
        localStorage.setItem('impressaoEstabelecimento','false')
        localStorage.setItem('impressaoEstabelecimento','false')

        return true;
    } catch (error) {
        console.log('Erro ao armazenar session')
        return error
    }
}

export { setSessionInStorage, setLocalStorage} 