import React, { useState, useEffect } from 'react';
import Api from '../../services/api';
import { withRouter } from "react-router";

import Swal from "sweetalert2";
import {useToasts } from 'react-toast-notifications';
import { Modal } from "react-bootstrap";
import LoadingOverlay from 'react-loading-overlay';

import DatePicker, { registerLocale } from "react-datepicker";
import InputMask from 'react-input-mask';
import pt from "date-fns/locale/pt-BR";

registerLocale("pt-BR", pt);


const Avaliacoes = () => {
    const { addToast } = useToasts();

    const [ avaliacoes, setAvaliacoes ] = useState([]);
    const [ avaliacoesFiltrada, setAvaliacoesFiltrada ] = useState([]);
    const [ modalAvaliacoes, setModalAvaliacoes ] = useState(false);
    const [ avaliacaoSelecionada, setAvaliacaoSelecionada ] = useState({pedidoHtml: ''});
    const [ resposta, setResposta ] = useState('');
    const [ somaNotas, setSomaNotas ] = useState({comida: [], entrega: [], geral: [], total: []});
    const [ notaLoja, setNotaLoja ] = useState(0);
    const [ qtdePedido, setQtdePedido ] = useState(0);
    const [ periodo, setPeriodo ] = useState(0);

    const [ somenteComentarios, setSomenteComentarios ] = useState(false);
    const [ caNotas, setCaNotas ] = useState(true);
    const [ dataInicial, setDataInicial ] = useState();
    const [ dataFinal, setDataFinal ] = useState(new Date());

    useEffect(() =>{
      getAvaliacao();
    },[]);

  const [filtro, setFiltro] = useState();
  useEffect(() => {
    var avaliacoesOrig = [];
    if(somenteComentarios){
      avaliacoes.forEach(av => {
        if(av.comentario_usuario){
          avaliacoesOrig[avaliacoesOrig.length] = av;
        }
      });
    } else {
      avaliacoesOrig = avaliacoes;
    }

    if (filtro) {
      let fProd = avaliacoesOrig.filter( (item) => {
        return ((JSON.stringify(item)).toLowerCase()).indexOf(filtro.toLowerCase()) !== -1
      })
      setAvaliacoesFiltrada(fProd)
    } else {
      setAvaliacoesFiltrada(avaliacoesOrig)
    }

  }, [avaliacoes, filtro, somenteComentarios])


    function getAvaliacao(){
      setCaNotas(true);
      Api.get("avaliacao/listar/", null, null).then((rps) =>{

        setSomaNotas({
          comida: rps.data.contaComida,
          entrega: rps.data.contaEntrega,
          geral: rps.data.contaGeral,
          total: rps.data.total[0],
        });


        var mediaLoja =  (parseFloat(rps.data.total[0].nota_comida) +
                         parseFloat(rps.data.total[0].nota_entrega) +
                         parseFloat(rps.data.total[0].nota_geral)) /
                         (3 * parseInt(rps.data.total[0].total));

        setNotaLoja(mediaLoja);
        setQtdePedido(rps.data.total[0].total);

        setAvaliacoesFiltrada(rps.data.avaliacao);
        setAvaliacoes(rps.data.avaliacao);

        setCaNotas(false);
      });

    }

    class EstrelaComida extends React.Component{
      renderRow(_item, index){
        return (<i key={index} className="fa fa-star text-warning font-size-lg"></i>)
      }
      render(){
        var rows = []
        for(var i=0; i<avaliacaoSelecionada.nota_comida; i++){
          rows.push(i)
        }
        return (
          <div>
            {rows.map(this.renderRow)}
          </div>
        )
      }
    }

    class EstrelaEntrega extends React.Component{
      renderRow(_item, index){
        return (<i key={index} className="fa fa-star text-warning font-size-lg"></i>)
      }
      render(){
        var rows = []
        for(var i=0; i<avaliacaoSelecionada.nota_entrega; i++){
          rows.push(i)
        }
        return (
          <div>
            {rows.map(this.renderRow)}
          </div>
        )
      }
    }

    class EstrelaCliente extends React.Component{
      renderRow(_item, index){
        return (<i key={index} className="fa fa-star text-warning font-size-lg"></i>)
      }
      render(){
        var rows = []
        for(var i=0; i<avaliacaoSelecionada.nota_geral; i++){
          rows.push(i)
        }
        return (
          <div>
            {rows.map(this.renderRow)}
          </div>
        )
      }
    }

    class EstrelaAvaliacao extends React.Component{
      renderRow(row, index){
        if (row === 'meia')
        return (<i key={index} className="fa fa-star-half-alt text-warning font-size-lg"></i>
        )
        else
        return (<i key={index} className="fa fa-star text-warning font-size-lg"></i>
        )
      }
      render(){
        var rows = []
        var resto = notaLoja % 1

        for(var i=1; i<=notaLoja; i++){
          rows.push(i)
        }

        if (resto > 0)
          rows.push('meia')

        return (
          <>
            {rows.map(this.renderRow)}
          </>
        )

      }
    }

    function abrirModal(avaliacao){

      Api.get('pedidos/listarpedidoavaliacao/' + avaliacao.id_pedido).then(response => {
        if(response.data.status === true){
          setAvaliacaoSelecionada({...avaliacao, pedidoHtml: response.data.pedidos[0].pedido});
          setModalAvaliacoes(true);
        }
      });

    }

    function fecharModal(){
      setModalAvaliacoes(false);
    }

    function salvaResposta(avaliacao) {
      Api.post('avaliacao/atualizar/' + avaliacaoSelecionada?.id_pedido,{resposta:resposta}).then((rps) => {

        addToast("Resposta salva com sucesso!", {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 2000
      })
      setModalAvaliacoes(false);
      getAvaliacao();
      })
    }

    function efetuarPesquisa(){
      setCaNotas(true);

      var data = {
        data_inicio: dataInicial,
        data_final: dataFinal,
      };

      Api.post('avaliacao/listar/',{ periodo:periodo, data }).then((rps) => {
        if (Boolean(rps.data.status) === false) {
          Swal.fire({
            title: "Atenção!",
            icon: "info",
            html: rps.data.mensagem,
            showCloseButton: true,
            showCancelButton: false,
          });

            setNotaLoja(0);
            setQtdePedido(0);

            setSomaNotas({
              comida: [],
              entrega: [],
              geral: [],
              total: [],
            });

            setAvaliacoesFiltrada([]);

            setCaNotas(false);
        }

        setSomaNotas({
          comida: rps.data.contaComida,
          entrega: rps.data.contaEntrega,
          geral: rps.data.contaGeral,
          total: rps.data.total[0],
        });


        var mediaLoja =  (parseFloat(rps.data.total[0].nota_comida) +
                         parseFloat(rps.data.total[0].nota_entrega) +
                         parseFloat(rps.data.total[0].nota_geral)) /
                         (3 * parseInt(rps.data.total[0].total));

        setNotaLoja(mediaLoja);
        setQtdePedido(rps.data.total[0].total);

        setAvaliacoesFiltrada(rps.data.avaliacao);
        setAvaliacoes(rps.data.avaliacao);

        setCaNotas(false);
      })
    }

    return (
    <>
<div>

        <div className="subheader espaco-header-pedidos subheader-transparent mt-3" id="kt_subheader">
          <div className="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
            {/*begin::Details*/}
            <div className="d-flex align-items-center flex-wrap mr-2">
              {/*begin::Title*/}
              <h5 className="text-dark font-weight-bold mt-2 mb-2 mr-5">Avaliações</h5>
              {/*end::Title*/}
              {/*begin::Separator*/}
              <div className="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200" />
              {/*end::Separator*/}
              <div className="d-flex align-items-center mr-5">
                <select onChange={(e) => { setPeriodo(e.target.value)}} className="form-control">
                  <option value="0">Todo o período</option>
                  <option value="7">Últimos 7 dias</option>
                  <option value="30">Últimos 30 dias</option>
                  <option value="60">Últimos 60 dias</option>
                  <option value="90">Últimos 90 dias</option>
                  <option value="PP">Período Personalizado</option>
                </select>
              </div>
              {/*begin::Search Form*/}
              { periodo === "PP" &&
              <div className="d-flex align-items-center" id="kt_subheader_search">
                <div className="input-group" id="kt_daterangepicker_2">
                <DatePicker
                    name="dt_inicio"
                    onChange={setDataInicial}
                    isClearable
                    selectsStart
                    locale="pt-BR"
                    className="form-control"
                    placeholderText="dd/mm/aaaa"
                    selected ={dataInicial}
                    dateFormat="dd/MM/y"
                    startDate={dataInicial}
                    endDate={dataFinal}
                    customInput={
                        <InputMask mask="99/99/9999" />}/>
                &nbsp;&nbsp;
                <DatePicker
                    name="dt_final"
                    onChange={setDataFinal}
                    isClearable
                    locale="pt-BR"
                    className="form-control"
                    placeholderText="dd/mm/aaaa"
                    selected ={dataFinal}
                    dateFormat="dd/MM/y"
                    selectsEnd
                    startDate={dataInicial}
                    endDate={dataFinal}
                    minDate={dataInicial}
                    customInput={
                        <InputMask mask="99/99/9999" />}/>
                </div>
              </div>
              }
              {/*end::Search Form*/}
            </div>
            {/*end::Details*/}
            {/*begin::Toolbar*/}
            <div className="d-flex align-items-center">
              {/*begin::Button*/}
              <button onClick={e => { efetuarPesquisa(); }} className="btn btn-primary pl-10 pr-10 btn-md btn-shadow-hover font-weight-bold"><i className="fas fa-filter" />Filtrar</button>
              {/*end::Button*/}
              {/*begin::Button*/}
            </div>
            {/*end::Toolbar*/}
          </div>
        </div>
        {/*begin::Entry*/}
        <div className="d-flex flex-column-fluid">
          {/*begin::Container*/}
          <div className="container">
            <div className="row text-center">
              <div className="col-xl-12">
                {/*begin::Stats Widget 16*/}
                <div className="card card-custom card-stretch gutter-b">
                  {/*begin::Body*/}
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-6  ">
                        <div className="text-inverse-white">
                          <div className="nota-loja">
                          <LoadingOverlay
                            active={caNotas}
                            spinner
                            text='Carregando...'
                          >
                            {notaLoja.toFixed(1)}
                            <span className="notas">
                              <EstrelaAvaliacao />
                            </span>
                            </LoadingOverlay>
                          </div>
                        </div>
                        <div className="font-weight-bold text-inverse-white font-size-md">Nota Geral da sua loja</div>
                      </div>
                      <div className="col-md-6">
                        <div className="text-inverse-white">
                          <div className="nota-loja">
                          <LoadingOverlay
                            active={caNotas}
                            spinner
                            text='Carregando...'
                          >
                            {qtdePedido}
                          </LoadingOverlay>
                          </div>
                        </div>
                        <div className="font-weight-bold text-inverse-white font-size-md">Total de Avaliações</div>
                      </div>
                    </div>
                  </div>
                  {/*end::Body*/}
                </div>
                {/*end::Stats Widget 16*/}
              </div>
              <div className="col-xl-4">
                {/*begin::Stats Widget 16*/}
                <div className="card card-custom card-stretch gutter-b">
                  {/*begin::Body*/}
                  <div className="card-body">
                    <h5 className="text-dark font-weight-bold mt-2 mb-2 mr-5 text-left">Como estava a comida?</h5>
                    <LoadingOverlay
                      active={caNotas}
                      spinner
                      text='Carregando...'
                    >
                    {
                      somaNotas?.comida.map( (contaComida, index) => {

                        var nota = contaComida?.comida;
                        var estrela = parseFloat(contaComida?.nota_comida);
                        var grafico = nota / qtdePedido * 100;
                        grafico += '%';

                        return(
                          <React.Fragment key={index}>
                          <div className="area-avaliacao">
                            <div className="area-avaliacao-1">
                              {estrela.toFixed(0)} <i className="fa fa-star text-warning font-size-sm" />
                            </div>
                            <div className="area-avaliacao-2">
                              <div className="barra-avaliacao-progresso" style={{width: grafico}} />
                            </div>
                            <div className="area-avaliacao-3">
                              {nota}
                            </div>
                          </div>
                          </React.Fragment>
                        )
                      })
                    }
                   </LoadingOverlay>
                   </div>
                  {/*end::Body*/}
                </div>
                {/*end::Stats Widget 16*/}
              </div>
              <div className="col-xl-4">
                {/*begin::Stats Widget 16*/}
                <div className="card card-custom card-stretch gutter-b">
                  {/*begin::Body*/}
                  <div className="card-body">
                    <h5 className="text-dark font-weight-bold mt-2 mb-2 mr-5 text-left">Chegou no prazo informado?</h5>
                    <LoadingOverlay
                      active={caNotas}
                      spinner
                      text='Carregando...'
                    >
                    {
                      somaNotas?.entrega.map( contaEntrega => {

                        var nota = contaEntrega?.entrega;
                        var estrela = parseFloat(contaEntrega?.nota_entrega);
                        var grafico = nota / qtdePedido * 100;
                        grafico += '%';

                        return(
                          <>
                          <div className="area-avaliacao">
                            <div className="area-avaliacao-1">
                              {estrela.toFixed(0)} <i className="fa fa-star text-warning font-size-sm" />
                            </div>
                            <div className="area-avaliacao-2">
                              <div className="barra-avaliacao-progresso" style={{width: grafico}} />
                            </div>
                            <div className="area-avaliacao-3">
                              {nota}
                            </div>
                          </div>
                          </>
                        )
                      })
                    }
                  </LoadingOverlay>
                </div>
                {/*end::Body*/}
              </div>
              {/*end::Stats Widget 16*/}
              </div>
              <div className="col-xl-4">
                {/*begin::Stats Widget 16*/}
                <div className="card card-custom card-stretch gutter-b">
                  {/*begin::Body*/}
                  <div className="card-body">
                    <h5 className="text-dark font-weight-bold mt-2 mb-2 mr-5 text-left">De acordo com o que o cliente?</h5>
                    <LoadingOverlay
                      active={caNotas}
                      spinner
                      text='Carregando...'
                    >

                    {
                      somaNotas?.geral.map( contaGeral => {

                        var nota = contaGeral?.geral;
                        var estrela = parseFloat(contaGeral?.nota_geral);
                        var grafico = nota / qtdePedido * 100;
                        grafico += '%';

                        return(
                          <>
                          <div className="area-avaliacao">
                            <div className="area-avaliacao-1">
                              {estrela.toFixed(0)} <i className="fa fa-star text-warning font-size-sm" />
                            </div>
                            <div className="area-avaliacao-2">
                              <div className="barra-avaliacao-progresso" style={{width: grafico}} />
                            </div>
                            <div className="area-avaliacao-3">
                              {nota}
                            </div>
                          </div>
                          </>
                        )
                      })
                    }
                    </LoadingOverlay>

                  </div>
                  {/*end::Body*/}
                </div>
                {/*end::Stats Widget 16*/}
              </div>
              <div className="col-md-12">
                {/*begin::Card*/}
                <div className="card card-custom gutter-b">
                  <div className="card-header flex-wrap">
                    {/*begin::Subheader*/}
                    <div className="subheader py-2 py-lg-4 subheader-transparent" id="kt_subheader">
                      <div className="d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
                        {/*begin::Details*/}
                        <div className="d-flex align-items-center flex-wrap mr-2">
                          {/*begin::Title*/}
                          <h5 className="text-dark font-weight-bold mt-2 mb-2 mr-5">Avaliações</h5>
                          {/*end::Title*/}
                          {/*begin::Separator*/}
                          <div className="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200" />
                          {/*end::Separator*/}
                          {/*begin::Search Form*/}
                          <div className="d-flex align-items-center" id="kt_subheader_search">
                            <form className="ml-5">
                              <div className="input-group input-group-sm input-group-solid" style={{maxWidth: '175px'}}>
                                <input type="text" onChange={e => {setFiltro(e.target.value)}} className="form-control" id="kt_subheader_search_form" placeholder="Procurar..." />
                                <div className="input-group-append">
                                  <span className="input-group-text">
                                    <span className="svg-icon">
                                      {/*begin::Svg Icon | path:assets/media/svg/icons/General/Search.svg*/}
                                      <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                        <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                          <rect x={0} y={0} width={24} height={24} />
                                          <path d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z" fill="#000000" fillRule="nonzero" opacity="0.3" />
                                          <path d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z" fill="#000000" fillRule="nonzero" />
                                        </g>
                                      </svg>
                                      {/*end::Svg Icon*/}
                                    </span>
                                    {/*<i className="flaticon2-search-1 icon-sm"></i>*/}
                                  </span>
                                </div>
                              </div>
                            </form>
                            <div className="form-group align-filtro-pedidos">
                              <div className="checkbox-inline">
                                <label className="checkbox checkbox-square checkbox-primary">
                                  <input type="checkbox" checked={somenteComentarios} onChange={e => {setSomenteComentarios(e.target.checked)}} />Apenas com comentário
                                  <span /></label>
                              </div>
                            </div>
                          </div>
                          {/*end::Search Form*/}
                        </div>
                        {/*end::Details*/}
                      </div>
                    </div>
                    {/*end::Subheader*/}
                  </div>
                  <div className="card-body no-padding" style={{overflow: 'auto', display: 'inline-grid'}}>
                    <div className="table-div-header padding-horizontal">
                      <div className="table-div-header-avaliacao-lista">Pedido</div>
                      <div className="table-div-header-avaliacao-lista">Data do Pedido</div>
                      <div className="table-div-header-avaliacao-lista">Avaliação</div>
                      <div className="table-div-header-avaliacao-lista">Data da Avaliação</div>
                      <div className="table-div-header-avaliacao-lista">Comentário</div>
                      <div className="table-div-header-avaliacao-lista">Status</div>
                      <div className="table-div-header-avaliacao-lista">Ações</div>
                    </div>
                    <LoadingOverlay
                            active={caNotas}
                            spinner
                            text='Carregando...'
                          >
                    {
                      avaliacoesFiltrada.map( avaliacao => {

                        var respondido = '';
                        var conta = '';
                        var media = 0;

                        if (avaliacao?.comentario_usuario && avaliacao?.comentario_estabelecimento) {
                          respondido = <span className="label label-success label-pill label-inline mr-2">Respondido</span>;
                        } else if (avaliacao?.comentario_usuario) {
                          respondido = <span className="label label-danger label-pill label-inline mr-2">Sem Resposta</span>;
                        }

                        if (avaliacao?.status_avaliacao === 'CC') {
                          conta = <span className="label label-success label-pill label-inline mr-2">Contabilizado</span>;
                        } else {
                          conta = <span className="label label-warning label-pill label-inline mr-2">Processando</span>
                        }

                        media = (parseFloat(avaliacao.nota_comida) +
                                 parseFloat(avaliacao.nota_entrega) +
                                 parseFloat(avaliacao.nota_geral)) / 3;

                      return(
                        <>


                    <div className="table-item padding-horizontal">
                      <div className="table-avaliacao-lista">
                        #{avaliacao.hash_pedido}
                      </div>
                      <div className="table-avaliacao-lista">
                        {avaliacao.pedido_data_criacao}
                      </div>
                      <div className="table-avaliacao-lista">
                        {media.toFixed(2)} <i className="fa fa-star text-warning font-size-sm" />
                      </div>
                      <div className="table-avaliacao-lista">
                        {avaliacao.data_criacao}
                      </div>
                      <div className="table-avaliacao-lista">
                        {avaliacao.comentario_usuario} {respondido}
                      </div>
                      <div className="table-avaliacao-lista">
                        {conta}
                      </div>
                      <div className="table-avaliacao-lista">
                        <button onClick={e => {abrirModal(avaliacao)}} className="btn btn-light-primary btn-sm btn-shadow-hover font-weight-bold">Ver detalhes</button>
                      </div>
                    </div>

                    </>)
                    })}
                    </LoadingOverlay>
                    {/*Paginação
                    <div className="area-footer-paginacao">
                      <div className="d-flex justify-content-between align-items-center flex-wrap">
                        <div className="d-flex align-items-center py-3">
                          <div className="d-flex align-items-center">
                            <div className="mr-2 text-muted">Carregando...</div>
                            <div className="spinner spinner-primary mr-10" />
                          </div>
                          <select className="form-control form-control-sm font-weight-bold mr-4 border-0 bg-light" style={{width: '75px'}}>
                            <option value={10}>10</option>
                            <option value={20}>20</option>
                            <option value={30}>30</option>
                            <option value={50}>50</option>
                            <option value={100}>100</option>
                          </select>
                          <span className="text-muted">Mostrando 10 de 230 avaliações</span>
                        </div>
                        <div className="d-flex flex-wrap py-2 mr-3">
                          <a href="#" className="btn btn-icon btn-sm btn-light-primary mr-2 my-1">
                            <i className="ki ki-bold-double-arrow-back icon-xs" />
                          </a>
                          <a href="#" className="btn btn-icon btn-sm btn-light-primary mr-2 my-1">
                            <i className="ki ki-bold-arrow-back icon-xs" />
                          </a>
                          <a href="#" className="btn btn-icon btn-sm border-0 btn-hover-primary mr-2 my-1">...</a>
                          <a href="#" className="btn btn-icon btn-sm border-0 btn-hover-primary mr-2 my-1">23</a>
                          <a href="#" className="btn btn-icon btn-sm border-0 btn-hover-primary active mr-2 my-1">24</a>
                          <a href="#" className="btn btn-icon btn-sm border-0 btn-hover-primary mr-2 my-1">25</a>
                          <a href="#" className="btn btn-icon btn-sm border-0 btn-hover-primary mr-2 my-1">26</a>
                          <a href="#" className="btn btn-icon btn-sm border-0 btn-hover-primary mr-2 my-1">27</a>
                          <a href="#" className="btn btn-icon btn-sm border-0 btn-hover-primary mr-2 my-1">28</a>
                          <a href="#" className="btn btn-icon btn-sm border-0 btn-hover-primary mr-2 my-1">...</a>
                          <a href="#" className="btn btn-icon btn-sm btn-light-primary mr-2 my-1">
                            <i className="ki ki-bold-arrow-next icon-xs" />
                          </a>
                          <a href="#" className="btn btn-icon btn-sm btn-light-primary mr-2 my-1">
                            <i className="ki ki-bold-double-arrow-next icon-xs" />
                          </a>
                        </div>
                      </div>
                    </div>
                    */}
                  </div>
                  {/*end::Card*/}
                </div>
              </div>
            </div>
            {/*end::Container*/}
          </div>
          {/*end::Entry*/}
        </div>
        {/*end::Content*/}
      </div>


      <Modal size={'xl'} show={modalAvaliacoes} onHide={() => fecharModal()}>
      <Modal.Header>
      <Modal.Title>Pedido: #{avaliacaoSelecionada?.hash_pedido}</Modal.Title>
          <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={e => {fecharModal()}}>
            <i aria-hidden="true" className="ki ki-close" />
          </button>
      </Modal.Header>
      <div className="row">
        <div className="col-md-6">
          <div className="pedido-area-center">
            <div className="pedido-area" id="style-1">
              <div dangerouslySetInnerHTML={{ __html: avaliacaoSelecionada?.pedidoHtml }} />
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="pedido-area-center">
            <div className="row">
              <div className="col-md-4">
                {/*begin::Comida*/}
									<div className="card card-custom gutter-b card-stretch card-shadowless bg-light">
											{/*begin::Header*/}
											<div className="card-header border-0 pt-5">
												<h3 className="card-title align-items-start flex-column">
													<span className="card-label font-weight-bolder text-dark">Comida</span>
												</h3>
												<div className="">
                          <div className="nota-loja text-center">
                            {avaliacaoSelecionada.nota_comida}
                          </div>
                          <EstrelaComida />
                          <br />
                        </div>
                      </div>
                  </div>
              </div>

              <div className="col-md-4">
                {/*begin::Comida*/}
									<div className="card card-custom gutter-b card-stretch card-shadowless bg-light">
											{/*begin::Header*/}
											<div className="card-header border-0 pt-5">
												<h3 className="card-title align-items-start flex-column">
													<span className="card-label font-weight-bolder text-dark">Entrega</span>
												</h3>
												<div>
                          <div className="nota-loja text-center">
                            {avaliacaoSelecionada.nota_entrega}
                          </div>
                            <EstrelaEntrega />
                            <br />
                        </div>
                      </div>
                  </div>
              </div>

              <div className="col-md-4">
                {/*begin::Comida*/}
									<div className="card card-custom gutter-b card-stretch card-shadowless bg-light">
											{/*begin::Header*/}
											<div className="card-header border-0 pt-5">
												<h3 className="card-title align-items-start flex-column">
													<span className="card-label font-weight-bolder text-dark">Cliente</span>
												</h3>
												<div className="">
                          <div className="nota-loja text-center">
                            {avaliacaoSelecionada.nota_geral}
                          </div>
                            <EstrelaCliente />
                            <br />
                        </div>
                      </div>
                  </div>
              </div>

            </div>










          {/*Sem Resposta do Estabelecimento*/}
          {(avaliacaoSelecionada?.comentario_usuario && avaliacaoSelecionada?.comentario_estabelecimento === '') && <>

            <h4>Comentário</h4>
            {avaliacaoSelecionada?.comentario_usuario}

            <br /><br />

            <h4>Resposta</h4>
            <textarea onChange={(e) => { setResposta(e.target.value)}} className="form-control form-control-lg form-control-solid" type="text" />
          </>
          }
          {/*Respondido*/}
          {(avaliacaoSelecionada?.comentario_usuario && avaliacaoSelecionada?.comentario_estabelecimento) && <>
          <h4>Comentário</h4>
            {avaliacaoSelecionada?.comentario_usuario}

            <br /><br />

            <h4>Resposta</h4>
            {avaliacaoSelecionada?.comentario_estabelecimento}

          </>
          }
          {/*Sem Comentarios*/}
            {avaliacaoSelecionada?.comentario_usuario === '' &&
              <h4>Usuário não deixou comentário</h4>
            }
            </div>
        </div>
      </div>

      <Modal.Footer>
        <button type="button" onClick={e => { fecharModal(); }} className="btn btn-secondary font-weight-bold" data-dismiss="modal"><i className="fas fa-door-open" />Fechar</button>
        {avaliacaoSelecionada?.comentario_usuario &&
        <button type="button" onClick={e => { salvaResposta(); }} className="btn btn-success font-weight-bold"><i className="fas fa-save" />Salvar</button>
}
      </Modal.Footer>
    </Modal>
    </>
    )

}

export default withRouter(Avaliacoes);
