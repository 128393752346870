import React, { useState, useEffect } from 'react';
import Api from '../../../../services/api';
import { withRouter } from "react-router";

import Swal from "sweetalert2";
import { useToasts } from 'react-toast-notifications';
import LoadingOverlay from 'react-loading-overlay';
import CpfCnpjInput from 'react-cpf-cnpj-input';
import Select from 'react-select';

import InputMask from 'react-input-mask';
import "react-datepicker/dist/react-datepicker.css";
import DatePicker, { registerLocale } from "react-datepicker";
import pt from "date-fns/locale/pt-BR"; 
registerLocale("pt-BR", pt);

const FCDados = () => {
  const { addToast } = useToasts();
  const [ caProcessa, setCaProcessa ] = useState(false);
  const [ pagOnCadastro, setPagOnCadastro ] = useState([]);
  const [ pagOnMCCs, setPagOnMCCs ] = useState([]);
  const [ caCep, setCaCep ] = useState(false);
  const [ editaDados, setEditaDados ] = useState(true);
  const [ dataNascimento, setDataNascimento ] = useState();
  const [ dataCriacao, setDataCriacao ] = useState();

  useEffect(() => {
    getListarMCCs();
    getPagOnCadastro();
  }, []);

  function getPagOnCadastro(){
    var data = {
      id_estabelecimento: localStorage.getItem('id_estabelecimento')
    };

    Api.post('pagamentoonline/listarPagOnCadastro', data).then(rps => {
      
      setPagOnCadastro(rps.data.obj);
      setDataNascimento(new Date(rps.data.obj.pro_nascimento * 1000));
      setDataCriacao(new Date(rps.data.obj.data_cadastro * 1000));
    });
  }

  function buscaCep(preenche, pesquisa){
    setCaCep(true);
    Api.get("estabelecimento/consultaCep/"+pesquisa).then((rps) => {
      
      if (rps.data.erro === true) {
        Swal.fire({
          title: "Erro!",
          icon: "error",
          html: "CEP não encontado.",
          showCloseButton: true,
          showCancelButton: false,
        });
        
        if (preenche === "cep_pagon_estabelecimento") {
          setPagOnCadastro({...pagOnCadastro, est_endereco: '', est_cidade: '',
            est_estado: '', est_bairro: '', est_complemento: ''})
        } else if (preenche === "cep_pagon_proprietario") {
          setPagOnCadastro({...pagOnCadastro, pro_endereco: '', pro_cidade: '',
            pro_estado: '', pro_bairro: '', pro_complemento: ''})
        }
      } else {
        if (preenche === "cep_pagon_estabelecimento") {
          setPagOnCadastro({...pagOnCadastro, est_endereco: rps.data.logradouro, 
            est_cidade: rps.data.localidade,
            est_estado: rps.data.uf,
            est_bairro: rps.data.bairro,
            est_complemento: rps.data.complemento})
        } else if (preenche === "cep_pagon_proprietario") {
          setPagOnCadastro({...pagOnCadastro, pro_endereco: rps.data.logradouro, 
            pro_cidade: rps.data.localidade,
            pro_estado: rps.data.uf,
            pro_bairro: rps.data.bairro,
            pro_complemento: rps.data.complemento})
        }    
      }
      setCaCep(false);
    })
  }

  function getListarMCCs() {
    
    Api.get("pagamentoonline/listarMccs").then(rps => {
      var mccs = rps.data.obj;
      var nMCCs = [];
      mccs.forEach(e => {
        nMCCs[nMCCs.length] = {value: e.code, label: e.category + ' => ' + e.description};
      })      
      setPagOnMCCs(nMCCs);      
    })
  }

  function salvarCadPagOn(){
    const pagCad = JSON.parse(JSON.stringify(pagOnCadastro));
    pagCad.pro_nascimento = dataNascimento;
    pagCad.dataCriacao = dataCriacao;
    pagCad.id_estabelecimento = localStorage.getItem('id_estabelecimento');
   
    Api.post('pagamentoonline/adicionar', pagCad).then(rps => {
      if (rps.data.status === true) {
        addToast(rps.data.mensagem, {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 2000
        });
        setEditaDados(true);
      } else {
        Swal.fire({
          title: "Erro!",
          icon: "error",
          html: rps.data.erros,
          showCloseButton: true,
          showCancelButton: false,
        });
      }
    });
  }
      
  return (
    <>

  <div className="subheader espaco-header-pedidos subheader-transparent" id="kt_subheader">
    <div className="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
      {/*begin::Details*/}
      <div className="d-flex align-items-center flex-wrap mr-2">
        {/*begin::Title*/}
        <h5 className="text-dark font-weight-bold mt-2 mr-5">Financeiro</h5>
        {/*end::Title*/}
        {/*begin::Separator*/}
        <div className="subheader-separator subheader-separator-ver mt-2 mb-2 bg-gray-200" />
        {/*end::Separator*/}
        {/*begin::Title*/}
        <h6 className="text-dark mt-2 mr-5 ml-5">Meu Cadastro</h6>
        {/*end::Title*/}
        {/*begin::Separator*/}
        <div className="subheader-separator subheader-separator-ver mt-2 mb-2 bg-gray-200" />
        {/*end::Separator*/}
        {/*begin::Title*/}
        <span className="breadcrumb breadcrumb-transparent mt-5 mr-5">Dados Cadastrais</span>
        {pagOnCadastro?.zoop_status === 'PENDING' && <span class="label label-warning label-inline mr-2">Pagamento Online - Pendente</span>}
        {(pagOnCadastro?.zoop_status === 'ENABLED' || pagOnCadastro?.zoop_status === 'ACTIVATED') && <span class="label label-success label-inline mr-2">Pagamento Online - Ativo</span>}
        <span className="label label-danger label-inline ml-2">Dúvidas ligue: (27) 4042-1580</span>
        {/*end::Title*/}
      </div>
      {/*end::Details*/}
      {/*begin::Toolbar*/}
      <div className="d-flex align-items-center">
        <button className="btn btn-warning mt-5" onClick={e => {setEditaDados(false)}}><i className="far fa-edit" />Editar</button>
      </div>
      {/*end::Toolbar*/}
    </div>
  </div>
        
  {/*begin::Entry*/}
  <div className="d-flex flex-column-fluid mb-10">
    {/*begin::Container*/}
    <div className="container">
      <div className="card card-custom">
        <div className="ml-10 mr-10 mt-10 mb-10">   

        {/*begin::Group - Pagamento Online - Cadastro*/}
            <div className="row">
                <div className="col-md-12">
                  <h4 className="bg-secondary pt-2 pl-2 pb-2">Tipo do Cadastro</h4>
                </div>
              </div>

              <div className="row">
                <div className="form-group col-md-6">
                  <label>Tipo de Pessoa <span class="text-danger">*</span></label>
                  <select className="form-control" 
                          value={pagOnCadastro?.tipo} 
                          onChange={(e) => { setPagOnCadastro({...pagOnCadastro, tipo: e.target.value}) }}
                          disabled={editaDados}>
                    <option value=""></option>
                    <option value="F">Física</option>
                    <option value="J">Jurídica</option>
                  </select>
                </div>
                
              </div>
              
              <div className="row">
                <div className="col-md-12">
                  <h4 className="bg-secondary pt-2 pl-2 pb-2">Estabelecimento</h4>
                </div>
              </div>
              
              <div className="row">
                <div className="form-group col-md-6">
                    <h6><b>Dados Cadastrais</b></h6>
                    <label>CNPJ {pagOnCadastro?.tipo == 'J' && <span class="text-danger">*</span>}</label>
                    <CpfCnpjInput
                      type="cnpj"
                      maskField="_"
                      className="form-control"
                      value={pagOnCadastro?.est_cnpj}
                      onChangeText={(e) => { setPagOnCadastro({...pagOnCadastro, est_cnpj: e}) }} 
                      disabled={pagOnCadastro?.tipo === 'F' || editaDados === true ? true : false}
                      /> 
                    
                    <label>Razão Social/Nome Completo <span class="text-danger">*</span></label>
                    <input className="form-control" 
                           disabled={editaDados}
                           value={pagOnCadastro?.est_razao_social} 
                           onChange={(e) => { setPagOnCadastro({...pagOnCadastro, est_razao_social: e.target.value}) }}/>

                    <label>Data de abertura</label><br />
                    <DatePicker 
                        name="dt_inicio"
                        onChange={setDataCriacao}
                        locale="pt-BR"
                        className="form-control"
                        placeholderText="dd/mm/aaaa" 
                        selected={dataCriacao}
                        dateFormat="dd/MM/y"
                        disabled={editaDados}
                        customInput={
                          <InputMask mask="99/99/9999" />}/><br />

                    <label>Categoria do Negócio <span class="text-danger">*</span></label>
                    <Select
                      options={pagOnMCCs}
                      isDisabled={editaDados}
                      placeholder="Selecione..."
                      value={pagOnMCCs.find(
                        x => x.value === pagOnCadastro?.est_cat_negocio
                      )}
                      onChange={(e) => { setPagOnCadastro({...pagOnCadastro, est_cat_negocio: e.value}) }} 
                    />
                    
                    <label>E-mail <span class="text-danger">*</span></label>
                    <input className="form-control"
                           disabled={editaDados} 
                           value={pagOnCadastro?.est_email} 
                           onChange={(e) => { setPagOnCadastro({...pagOnCadastro, est_email: e.target.value}) }}/>
                
                    <label>Nome Fantasia (usado na identificação na fatura do cartão)</label>
                    <input className="form-control"
                           disabled={editaDados} 
                           value={pagOnCadastro?.est_nome_fantasia} 
                           onChange={(e) => { setPagOnCadastro({...pagOnCadastro, est_nome_fantasia: e.target.value}) }}/>
                
                    <label>Telefone <span class="text-danger">*</span></label>
                    <InputMask mask="(99)99999-9999"
                           className="form-control"
                           disabled={editaDados} 
                           value={pagOnCadastro?.est_telefone} 
                           onChange={(e) => { setPagOnCadastro({...pagOnCadastro, est_telefone: e.target.value}) }}/>
                </div>

                <div className="form-group col-md-6">
                  <h6><b>Endereço</b></h6>
                    <label>CEP <span class="text-danger">*</span></label>
                    <LoadingOverlay
                      active={caCep}
                      spinner
                      text='Carregando...'
                    > 
                    <div className="input-group">
											<input type="text" class="form-control" disabled={editaDados} placeholder="CEP" onChange={(e) => { setPagOnCadastro({...pagOnCadastro, est_cep: e.target.value}) }} value={pagOnCadastro?.est_cep} maxLength="8" />
												<div className="input-group-append">
													<button className="btn btn-light-primary" disabled={editaDados} type="button" onClick={(e) => {buscaCep("cep_pagon_estabelecimento", pagOnCadastro?.est_cep)}}>Pesquisar</button>
												</div>
										</div>
                    </LoadingOverlay>               
                    <label>Endereço <span class="text-danger">*</span></label>
                    <input className="form-control"
                           disabled={editaDados} 
                           value={pagOnCadastro?.est_endereco} 
                           onChange={(e) => { setPagOnCadastro({...pagOnCadastro, est_endereco: e.target.value}) }}/>
                
                    <label>Número <span class="text-danger">*</span></label>
                    <input className="form-control"
                           disabled={editaDados} 
                           value={pagOnCadastro?.est_numero} 
                           onChange={(e) => { setPagOnCadastro({...pagOnCadastro, est_numero: e.target.value}) }}/>
                
                    <label>Complemento</label>
                    <input className="form-control"
                           disabled={editaDados} 
                           value={pagOnCadastro?.est_complemento} 
                           onChange={(e) => { setPagOnCadastro({...pagOnCadastro, est_complemento: e.target.value}) }}/>
                
                    <label>Bairro <span class="text-danger">*</span></label>
                    <input className="form-control"
                           disabled={editaDados} 
                           value={pagOnCadastro?.est_bairro} 
                           onChange={(e) => { setPagOnCadastro({...pagOnCadastro, est_bairro: e.target.value}) }}/> 
                
                
                    <label>Cidade <span class="text-danger">*</span></label>
                    <input className="form-control"
                           disabled={editaDados} 
                           value={pagOnCadastro?.est_cidade} 
                           onChange={(e) => { setPagOnCadastro({...pagOnCadastro, est_cidade: e.target.value}) }}/>
                
                    <label>Estado <span class="text-danger">*</span></label>
                    <input className="form-control"
                           disabled={editaDados} 
                           value={pagOnCadastro?.est_estado} 
                           onChange={(e) => { setPagOnCadastro({...pagOnCadastro, est_estado: e.target.value}) }}/>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <h4 className="bg-secondary pt-2 pl-2 pb-2">Redes Sociais</h4>
                </div>
              </div>

              <div className="row">
                <div className="form-group col-md-6">
                    <label>Site</label>
                    <input className="form-control"
                           disabled={editaDados} 
                           value={pagOnCadastro?.est_site} 
                           onChange={(e) => { setPagOnCadastro({...pagOnCadastro, est_site: e.target.value}) }}/>

                    <label>Facebook</label>
                    <input className="form-control"
                           disabled={editaDados} 
                           value={pagOnCadastro?.est_facebook} 
                           onChange={(e) => { setPagOnCadastro({...pagOnCadastro, est_facebook: e.target.value}) }}/>
                </div>

                <div className="form-group col-md-6">
                    <label>Twitter</label>
                    <input className="form-control"
                           disabled={editaDados} 
                           value={pagOnCadastro?.est_twitter} 
                           onChange={(e) => { setPagOnCadastro({...pagOnCadastro, est_twitter: e.target.value}) }}/>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <h4 className="bg-secondary pt-2 pl-2 pb-2">Proprietário</h4>
                </div>
              </div>

              <div className="row">
                <div className="form-group col-md-6">
                    <h6><b>Dados Cadastrais</b></h6>
                    <label>CPF <span class="text-danger">*</span></label>
                    <CpfCnpjInput
                      type="cpf"
                      disabled={editaDados}
                      maskField="_"
                      className="form-control"
                      value={pagOnCadastro?.pro_cpf}
                      onChangeText={(e) => { setPagOnCadastro({...pagOnCadastro, pro_cpf: e}) }} 
                      />                          

                    <label>E-mail <span class="text-danger">*</span></label>
                    <input className="form-control"
                           disabled={editaDados} 
                           value={pagOnCadastro?.pro_email} 
                           onChange={(e) => { setPagOnCadastro({...pagOnCadastro, pro_email: e.target.value}) }}/>

                    <label>Nome <span class="text-danger">*</span></label>
                    <input className="form-control"
                           disabled={editaDados} 
                           value={pagOnCadastro?.pro_nome} 
                           onChange={(e) => { setPagOnCadastro({...pagOnCadastro, pro_nome: e.target.value}) }}/>

                    <label>Data de nascimento <span class="text-danger">*</span></label><br />
                    <DatePicker 
                        name="dt_inicio"
                        onChange={setDataNascimento}
                        isClearable
                        disabled={editaDados}
                        selectsStart
                        locale="pt-BR"
                        className="form-control"
                        placeholderText="dd/mm/aaaa" 
                        selected={dataNascimento}
                        dateFormat="dd/MM/y"
                        customInput={
                          <InputMask mask="99/99/9999" />}/><br />
                  
                    <label>Telefone <span class="text-danger">*</span></label>
                    <InputMask mask="(99)99999-9999"
                           className="form-control"
                           disabled={editaDados} 
                           value={pagOnCadastro?.pro_telefone} 
                           onChange={(e) => { setPagOnCadastro({...pagOnCadastro, pro_telefone: e.target.value}) }}/>
                </div>

                <div className="form-group col-md-6">
                  <h6><b>Endereço</b></h6>
                    <label>CEP <span class="text-danger">*</span></label>
                    <LoadingOverlay
                      active={caCep}
                      spinner
                      text='Carregando...'
                    > 
                    <div className="input-group">
											<input type="text" class="form-control" disabled={editaDados} placeholder="CEP" onChange={(e) => { setPagOnCadastro({...pagOnCadastro, pro_cep: e.target.value}) }} value={pagOnCadastro?.pro_cep} maxLength="8"/>
												<div className="input-group-append">
													<button className="btn btn-light-primary" disabled={editaDados} type="button" onClick={(e) => {buscaCep("cep_pagon_proprietario", pagOnCadastro?.pro_cep)}}>Pesquisar</button>
												</div>
										</div>
                    </LoadingOverlay> 
                
                    <label>Endereço <span class="text-danger">*</span></label>
                    <input className="form-control"
                           disabled={editaDados} 
                           value={pagOnCadastro?.pro_endereco} 
                           onChange={(e) => { setPagOnCadastro({...pagOnCadastro, pro_endereco: e.target.value}) }}/>
                
                    <label>Número <span class="text-danger">*</span></label>
                    <input className="form-control"
                           disabled={editaDados} 
                           value={pagOnCadastro?.pro_numero} 
                           onChange={(e) => { setPagOnCadastro({...pagOnCadastro, pro_numero: e.target.value}) }}/>
                
                    <label>Complemento</label>
                    <input className="form-control"
                           disabled={editaDados} 
                           value={pagOnCadastro?.pro_complemento} 
                           onChange={(e) => { setPagOnCadastro({...pagOnCadastro, pro_complemento: e.target.value}) }}/>
                
                    <label>Bairro <span class="text-danger">*</span></label>
                    <input className="form-control"
                           disabled={editaDados} 
                           value={pagOnCadastro?.pro_bairro} 
                           onChange={(e) => { setPagOnCadastro({...pagOnCadastro, pro_bairro: e.target.value}) }}/> 
                
                
                    <label>Cidade <span class="text-danger">*</span></label>
                    <input className="form-control"
                           disabled={editaDados} 
                           value={pagOnCadastro?.pro_cidade} 
                           onChange={(e) => { setPagOnCadastro({...pagOnCadastro, pro_cidade: e.target.value}) }}/>
                
                    <label>Estado <span class="text-danger">*</span></label>
                    <input className="form-control"
                           disabled={editaDados} 
                           value={pagOnCadastro?.pro_estado} 
                           onChange={(e) => { setPagOnCadastro({...pagOnCadastro, pro_estado: e.target.value}) }}/>
                </div>
              </div>
            {/*end::Group - Pagamento Online - Cadastro*/}     
        </div>
        <div class="card-footer text-right">
          <button type="button" onClick={e => { salvarCadPagOn(); }} className="btn btn-success font-weight-bold">Salvar</button>
        </div>
      </div>
    </div>
  </div>
                  
            
  </>         
        
  );
}

export default withRouter(FCDados);                  