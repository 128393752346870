/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import CurrencyInput from "react-currency-input";
import LoadingOverlay from "react-loading-overlay";

import ModalUpdate from "./ModalUpdate";
import AsyncSelectProductsByEAN from './Select';
import DisponibilidadeComp from "../../disponibilidade";
import SortableComposition from "../SortableComposition";
import SortableVariation from "../SortableVariation";
import SortableItemSize from "../SortableItemSize";
import MudaStatus from "../../../../helpers/mudaStatus";

// import { Container } from './styles';

function ModalProduct({
  modalProducts,
  handleCloseModalProduto,
  selectedProduct,
  refImagem,
  handleImageChange,
  selectedCategory,
  setSelectedProduct,
  frmEdtTamanho,
  formTamanho,
  setFormTamanho,
  setFormVariacoes,
  sendFormTamanho,
  setFrmEdtTamanho,
  caProdutoSelecionado,
  editarTamanho,
  excluirTamanho,
  formIngredientes,
  setFormIngredientes,
  ingredientes,
  sendFormIngredientes,
  formVariacoes,
  produtoTamanhos,
  variacoes,
  sendFormVariacoes,
  onSortEndItemSize,
  onSortEndComposition,
  excluirComposicao,
  onSortEndVariation,
  editarVariacao,
  duplicarVariacao,
  excluirVariacao,
  setStringDisponibilidadeProduto,
  salvarProduto,
}) {
  const [isOpen, setOpen] = React.useState(false);
  const [productToReplace, setProductToReplace] = React.useState(null);

  function toggleModal() {
    setOpen(open => !open);
  }

  return (
    <>
      <ModalUpdate
        isOpen={isOpen}
        toggleModal={toggleModal}
        product={productToReplace}
        selectedProduct={selectedProduct}
        onChange={setSelectedProduct}
      />
      <Modal
        size={"xl"}
        show={modalProducts}
        onHide={() => handleCloseModalProduto()}
      >
        <Modal.Header>
          <Modal.Title>{selectedProduct?.titulo}</Modal.Title>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={(e) => {
              handleCloseModalProduto();
            }}
          >
            <i aria-hidden="true" className="ki ki-close" />
          </button>
        </Modal.Header>
        <ul className="nav nav-tabs nav-bold nav-tabs-line p-l-25">
          <li className="nav-item">
            <a
              className="nav-link active"
              data-toggle="tab"
              href="#informacoes"
            >
              <span className="nav-icon">
                <i className="fab fa-wpforms" />
              </span>
              <span className="nav-text">Informações</span>
            </a>
          </li>
          {selectedProduct.tabsAllowed === true && (
            <>
              <li className="nav-item">
                <a className="nav-link" data-toggle="tab" href="#composicao">
                  <span className="nav-icon">
                    <i className="fab fa-hubspot" />
                  </span>
                  <span className="nav-text">Composição</span>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" data-toggle="tab" href="#variacoes">
                  <span className="nav-icon">
                    <i className="fab fa-buffer" />
                  </span>
                  <span className="nav-text">Variações</span>
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  data-toggle="tab"
                  href="#disponibilidade"
                >
                  <span className="nav-icon">
                    <i className="far fa-clock" />
                  </span>
                  <span className="nav-text">Disponibilidade</span>
                </a>
              </li>
            </>
          )}
          {selectedProduct.tabsAllowed === false && (
            <>
              <li disabled={true} className="nav-item">
                <a className="nav-link">
                  <span className="nav-icon">
                    <i className="fab fa-hubspot" />
                  </span>
                  <span className="nav-text">Composição</span>
                </a>
              </li>
              <li disabled={true} className="nav-item">
                <a className="nav-link">
                  <span className="nav-icon">
                    <i className="fab fa-buffer" />
                  </span>
                  <span className="nav-text">Variações</span>
                </a>
              </li>
              <li disabled={true} className="nav-item">
                <a className="nav-link">
                  <span className="nav-icon">
                    <i className="far fa-clock" />
                  </span>
                  <span className="nav-text">Disponibilidade</span>
                </a>
              </li>
            </>
          )}
        </ul>
        <div className="modal-body">
          <div className="tab-content">
            <div
              className="tab-pane fade show active"
              id="informacoes"
              role="tabpanel"
              aria-labelledby="informacoes"
            >
              {/*begin::Group*/}
              <div className="row area-imagem-descricao">
                <div className="col-md-4 col-sm-12">
                  <div className="area-imagem dropzone--squared menu-category-drawer-item-details-tab__image">
                    <div
                      className="dropzone__area"
                      aria-disabled="false"
                      style={{ position: "relative" }}
                      onClick={(e) => {
                        if (selectedProduct.tabsAllowed === true) {
                          refImagem.current.click();
                        } else {
                          alert(
                            "Salve o produto antes de cadastrar uma imagem"
                          );
                        }
                      }}
                    >
                      <div className="dropzone-preview dropzone__preview">
                        <img
                          className="dropzone-preview__image"
                          src={
                            selectedProduct?.foto
                              ? selectedProduct?.foto
                              : "assets/media/no-img2.jpg"
                          }
                          alt=""
                        />
                      </div>
                      <div className="dropzone-placeholder dropzone__placeholder">
                        <span>Trocar imagem</span>
                      </div>
                      <input
                        hidden
                        type="file"
                        ref={refImagem}
                        name="imagemProduto"
                        onChange={(e) => handleImageChange(e)}
                        accept=".png, .jpg, .jpeg"
                        className="classeInputImage"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-8">
                  <AsyncSelectProductsByEAN
                    label="Buscar produtos"
                    selectedProduct={selectedProduct}
                    onChange={setSelectedProduct}
                    setOpen={setOpen}
                    setProductToReplace={setProductToReplace}
                  />
                </div>


              </div>
              <div className="row">
                <div className="col-4"></div>
                <div className="col-3">
                  <div className="form-group">
                    <label>Categoria</label>
                    <input
                      value={selectedCategory?.nome_categoria}
                      className="form-control form-control-lg form-control-solid"
                      type="text"
                      disabled
                    />
                  </div>
                </div>
                <div className="col-5">
                  <div className="form-group">
                    <label>Nome do Produto</label>
                    <input
                      value={selectedProduct?.nome_prod}
                      onChange={(e) =>
                        setSelectedProduct({
                          ...selectedProduct,
                          nome_prod: e.target.value,
                        })
                      }
                      className="form-control form-control-lg form-control-solid"
                      type="text"
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div
                  className="col-md-4 col-sm-12"
                  style={{ zIndex: -1 }}
                ></div>
                <div className="col-8">
                  <div className="form-group">
                    <label>Descrição</label>
                    <textarea
                      value={selectedProduct?.descricao}
                      onChange={(e) =>
                        setSelectedProduct({
                          ...selectedProduct,
                          descricao: e.target.value,
                        })
                      }
                      className="form-control form-control-lg form-control-solid"
                      type="text"
                    />
                  </div>
                </div>
                <div className="col-4">
                  <div className="form-group">
                    <label>Este produto possui mais tamanhos?</label>
                    <div className="checkbox-inline check-input-area">
                      <label className="checkbox checkbox-square checkbox-primary m-t-3">
                        <input
                          type="checkbox"
                          checked={selectedProduct?.multipleSize}
                          onChange={(e) => {
                            if (selectedProduct.tabsAllowed === true) {
                              setSelectedProduct({
                                ...selectedProduct,
                                multipleSize: !selectedProduct.multipleSize,
                              });
                            } else {
                              alert(
                                "Salve o produto antes de cadastrar tamanhos!"
                              );
                            }
                          }}
                        />
                        Sim!
                        <span />
                      </label>
                    </div>
                  </div>
                </div>
                {selectedProduct?.multipleSize === false && (
                  <>
                    <div className="col-4">
                      <div className="form-group">
                        <label>Preço</label>
                        <CurrencyInput
                          maxLength={6}
                          precision={"2"}
                          value={selectedProduct?.preco}
                          onChangeEvent={(e) =>
                            setSelectedProduct({
                              ...selectedProduct,
                              preco: e.target.value,
                            })
                          }
                          decimalSeparator="."
                          className="form-control form-control-lg form-control-solid"
                          type="text"
                        />
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="form-group">
                        <label>Preço Promocional</label>
                        <CurrencyInput
                          maxLength={6}
                          precision={"2"}
                          value={selectedProduct?.novo_preco}
                          onChangeEvent={(e) =>
                            setSelectedProduct({
                              ...selectedProduct,
                              novo_preco: e.target.value,
                            })
                          }
                          decimalSeparator="."
                          className="form-control form-control-lg form-control-solid"
                          type="text"
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
              {/*end::Group*/}
              {selectedProduct?.multipleSize === true && (
                <>
                  <hr />
                  <div className="row row-adjust-flex row-adjust-inf">
                    {/* Adicionar novo tamanho */}
                    <div className="area-top-header-informacoes">
                      <div className="tamanhos-item-area-info">
                        <div className="form-group">
                          <label>Tamanho</label>
                          <input
                            value={formTamanho?.nome}
                            onChange={(e) =>
                              setFormTamanho({
                                ...formTamanho,
                                nome: e.target.value,
                              })
                            }
                            className="form-control form-control-solid"
                            type="text"
                          />
                        </div>
                      </div>
                      <div className="tamanhos-item-area-info">
                        <div className="form-group">
                          <label>Preço</label>
                          <CurrencyInput
                            maxLength={6}
                            precision={"2"}
                            value={formTamanho?.preco}
                            onChangeEvent={(e) =>
                              setFormTamanho({
                                ...formTamanho,
                                preco: e.target.value,
                              })
                            }
                            decimalSeparator="."
                            className="form-control form-control-lg form-control-solid"
                            type="text"
                          />
                        </div>
                      </div>
                      <div className="tamanhos-item-area-info">
                        <button
                          onClick={(e) => {
                            sendFormTamanho(false);
                          }}
                          className="btn btn-primary font-weight-bolder btn-md btn-block mt-10"
                        >
                          Incluir
                        </button>
                      </div>
                    </div>

                    <div className="table-div-header padding-horizontal area-margin-info mt-5">
                      <div className="table-div-header-item-produto-tamanhos" />
                      <div className="table-div-header-item-produto-tamanhos">
                        Tamanho
                      </div>
                      <div className="table-div-header-item-produto-tamanhos">
                        Preço
                      </div>
                      <div className="table-div-header-item-produto-tamanhos text-center">
                        Status
                      </div>
                      <div className="table-div-header-item-produto-tamanhos text-center">
                        Ações
                      </div>
                    </div>
                    <LoadingOverlay
                      active={caProdutoSelecionado}
                      spinner
                      text="Carregando..."
                    >
                      <SortableItemSize
                        selectedProductTamanhos={selectedProduct?.tamanhos}
                        onSortEnd={onSortEndItemSize}
                        //
                        MudaStatus={MudaStatus}
                        setFrmEdtTamanho={setFrmEdtTamanho}
                        frmEdtTamanho={frmEdtTamanho}
                        sendFormTamanho={sendFormTamanho}
                        editarTamanho={editarTamanho}
                        excluirTamanho={excluirTamanho}
                      />
                    </LoadingOverlay>
                  </div>
                </>
              )}
            </div>
            <div
              className="tab-pane fade"
              id="composicao"
              role="tabpanel"
              aria-labelledby="composicao"
            >
              <div className="area-top-header-composicao">
                <div className="composicao-item-area">
                  <div className="form-group">
                    <label>Qtd.</label>
                    <input
                      value={formIngredientes?.quantidade}
                      onChange={(e) => {
                        setFormIngredientes({
                          ...formIngredientes,
                          quantidade: e.target.value,
                        });
                      }}
                      className="form-control form-control-solid form-small"
                      type="number"
                    />
                  </div>
                </div>
                <div className="composicao-item-area">
                  <div className="form-group">
                    <label>Ingrediente</label>
                    <Select
                      options={ingredientes}
                      value={ingredientes.find(
                        (x) => x.value === formIngredientes?.id_estoque
                      )}
                      onChange={(event) => {
                        setFormIngredientes({
                          ...formIngredientes,
                          id_estoque: event.value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="composicao-item-area">
                  <div className="form-group">
                    <label>Removível?</label>
                    <div className="checkbox-inline">
                      <label className="checkbox checkbox-square checkbox-primary m-t-3">
                        <input
                          checked={formIngredientes?.removivel}
                          onChange={(e) => {
                            setFormIngredientes({
                              ...formIngredientes,
                              removivel: e.target.checked,
                            });
                          }}
                          type="checkbox"
                        />
                        Sim
                        <span />
                      </label>
                    </div>
                  </div>
                </div>
                <div className="composicao-item-area">
                  <button
                    onClick={(e) => {
                      sendFormIngredientes();
                    }}
                    className="btn btn-primary font-weight-bolder btn-lg btn-block m-t-11"
                  >
                    Incluir
                  </button>
                </div>
              </div>
              <div className="table-div-header padding-horizontal area-adjust-composicao">
                <div className="table-div-header-item-produto-composicao" />
                <div className="table-div-header-item-produto-composicao">
                  Qtd.
                </div>
                <div className="table-div-header-item-produto-composicao">
                  Ingrediente
                </div>
                <div className="table-div-header-item-produto-composicao">
                  Removível
                </div>
                <div className="table-div-header-item-produto-composicao">
                  Ações
                </div>
              </div>
              <LoadingOverlay
                active={caProdutoSelecionado}
                spinner
                text="Carregando..."
              >
                <SortableComposition
                  selectedProductIngredients={selectedProduct?.ingredientes}
                  onSortEnd={onSortEndComposition}
                  axis="y"
                  helperClass="dragclass"
                  useDragHandle={true}
                  //
                  excluirComposicao={excluirComposicao}
                />
              </LoadingOverlay>
            </div>
            <div
              className="tab-pane fade"
              id="variacoes"
              role="tabpanel"
              aria-labelledby="variacoes"
            >
              <div className="area-top-header-composicao">
                <div className="variacoes-item-area">
                  <div className="form-group">
                    <label>Variação Global</label>
                    <Select
                      options={variacoes}
                      value={variacoes.find(
                        (x) => x.value === formVariacoes?.id_variacao
                      )}
                      onChange={(event) => {
                        setFormVariacoes({
                          ...formVariacoes,
                          id_variacao: event.value,
                          nome_variacao_avulsa: "",
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="variacoes-item-area">
                  <div className="form-group">
                    <label>Variação Avulsa</label>
                    <input
                      value={formVariacoes?.nome_variacao_avulsa}
                      onChange={(e) => {
                        setFormVariacoes({
                          ...formVariacoes,
                          nome_variacao_avulsa: e.target.value,
                          id_variacao: 0,
                        });
                      }}
                      className="form-control form-control-solid form-small"
                      type="text"
                    />
                  </div>
                </div>
                <div className="variacoes-item-area">
                  <div className="form-group">
                    <label>Vínculo/tamanho</label>
                    <Select
                      options={produtoTamanhos}
                      value={produtoTamanhos.find(
                        (x) => x.value === formVariacoes?.id_cardapio_tamanho
                      )}
                      onChange={(event) => {
                        setFormVariacoes({
                          ...formVariacoes,
                          id_cardapio_tamanho: event.value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="variacoes-item-area d-flex align-items-center justify-content-center">
                  <button
                    onClick={(e) => {
                      sendFormVariacoes();
                    }}
                    data-toggle="modal"
                    data-target="#ModalProdutoVariacoes"
                    className="btn btn-primary font-weight-bolder btn-lg ml-10"
                  >
                    Incluir
                  </button>
                </div>
              </div>
              <div className="table-div-header padding-horizontal area-adjust-composicao">
                <div className="table-div-header-item-produto-variacoes" />
                <div className="table-div-header-item-produto-variacoes">
                  Variações
                </div>
                <div className="table-div-header-item-produto-variacoes">
                  Escolha Min./Máx
                </div>
                <div className="table-div-header-item-produto-variacoes">
                  Cálculo
                </div>
                <div className="table-div-header-item-produto-variacoes">
                  Vínculo
                </div>
                <div className="table-div-header-item-produto-variacoes">
                  Status
                </div>
                <div className="table-div-header-item-produto-variacoes">
                  Ações
                </div>
              </div>
              <LoadingOverlay
                active={caProdutoSelecionado}
                spinner
                text="Carregando..."
              >
                <SortableVariation
                  selectedProductVariacoes={selectedProduct?.variacoes}
                  onSortEnd={onSortEndVariation}
                  editarVariacao={editarVariacao}
                  duplicarVariacao={duplicarVariacao}
                  excluirVariacao={excluirVariacao}
                  MudaStatus={MudaStatus}
                />
              </LoadingOverlay>
            </div>
            <div
              className="tab-pane fade"
              id="disponibilidade"
              role="tabpanel"
              aria-labelledby="disponibilidade"
            >
              <p className="font-size-h5">
                Aqui você define em que momentos os clientes poderão comprar
                este item
              </p>
              <br />
              <DisponibilidadeComp
                disponibilidadeOriginalObj={selectedProduct?.disponibilidade}
                setDisponibilidadeString={setStringDisponibilidadeProduto}
              />
            </div>
          </div>
        </div>
        <Modal.Footer>
          <button
            type="button"
            onClick={(e) => {
              handleCloseModalProduto();
            }}
            className="btn btn-light-primary font-weight-bold"
            data-dismiss="modal"
          >
            Fechar
          </button>
          <button
            type="button"
            onClick={(e) => {
              salvarProduto();
            }}
            className="btn btn-success font-weight-bold"
          >
            Salvar
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ModalProduct;
