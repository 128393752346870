import React, { useState, useEffect } from 'react';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import arrayMove from "array-move";
import Api from '../../services/api';
import { withRouter } from "react-router";

import Swal from "sweetalert2";
import {useToasts } from 'react-toast-notifications';
import 'react-tabs/style/react-tabs.css';

import LoadingOverlay from 'react-loading-overlay';
import CurrencyInput from 'react-currency-input';
import InputMask from "react-input-mask";
import Select from "react-dropdown-select";

import { Modal, Tabs, Tab } from "react-bootstrap";
import Axios from 'axios';


const ProductsOld = () => {

  const { addToast } = useToasts();
  const [searchParameter, setSearchParameter] = useState('');

  const [ defaultImage, setDefaultImage ] = useState("assets/media/no-img.jpg")


  // Variações
  const [ productVariables, setProductVariables ] = useState([]);
  const [ variables, setVariables ] = useState([]);

  const [ globalVariableId, setGlobalVariableId ] = useState('');

  const [ detachedVariableName, setDetachedVariableName ] = useState(false);
  const [ minChoice, setMinChoice ] = useState('');
  const [ maxChoice, setMaxChoice ] = useState('');
  const [ priceCalc, setPriceCalc ] = useState('');

  // Tamanhos
  const [ sizes, setSizes ] = useState([]);
  const [ size, setSize ] = useState(
    { size: '', price: '', status: 'S'}
  )

  // Composição
  const [ stock, setStock ] = useState([]);

  const [ compositions, setCompositions ] = useState([]);
  const [ composition, setComposition] = useState([
    { id: '', qtd: '', items: '', removable: 1}
  ])

  const [ , setCardapioDados] = useState({
      deletado: '',
      descricao: '',
      descricao_sis: '',
      disponibilidade: {
        do: { initAt: '', endAt: '', initAt2: '', endAt2: ''},
        qa: { initAt: '', endAt: '', initAt2: '', endAt2: ''},
        qi: { initAt: '', endAt: '', initAt2: '', endAt2: ''},
        sb: { initAt: '', endAt: '', initAt2: '', endAt2: ''},
        sg: { initAt: '', endAt: '', initAt2: '', endAt2: ''},
        sx: { initAt: '', endAt: '', initAt2: '', endAt2: ''},
        te: { initAt: '', endAt: '', initAt2: '', endAt2: ''}
      },
      foto: '',
      id_cardapio: '',
      id_cardapio_categoria: '',
      id_estabelecimento: '',
      ingredientes: {},
      nome_prod: '',
      novo_preco: '',
      ordem: '',
      preco: '',
      status: '',
      tamanhos: {},
      variacoes: {}
    }
  );

  // PRODUCT ACTIONS
  const [ products, setProducts ] = useState([]);
  const [ productModalTitle, setProductModalTitle ] = useState('');
  const [ productId ,setProductId ] = useState('');
  const [ productModalVisibility ,setProductModalVisibility ] = useState(false);

  const [ productLoading, setProductLoading ] = useState(true);

  //controla as tabs, melhorar nome
  const [ allowTabs, setAllowTabs ] = useState(true);

  // Product FORM
  const [ productName ,setProductName ] = useState('');
  const [ productStatus ,setProductStatus ] = useState('A');
  const [ productDescription, setProductDescription ] = useState('');
  const [ productPrice, setProductPrice ] = useState('');
  const [ productNewPrice, setProductNewPrice ] = useState('');

  // Disponibilidade Periodo - 1
  const [sundayInit_Hour, setSundayInit_Hour] = useState('');
  const [sunday_1_active, setSunday_1_active] = useState(false);
  const [mondayInit_Hour, setMondayInit_Hour] = useState('');
  const [monday_1_active, setMonday_1_active] = useState(false);
  const [tuesdayInit_Hour, setTuesdayInit_Hour] = useState('');
  const [tuesday_1_active, setTuesday_1_active] = useState(false);
  const [wednesdayInit_Hour, setWednesdayInit_Hour] = useState('');
  const [wednesday_1_active, setWednesday_1_active] = useState(false);
  const [thursdayInit_Hour, setThursdayInit_Hour] = useState('');
  const [thursday_1_active, setThursday_1_active] = useState(false);
  const [fridayInit_Hour, setFridayInit_Hour] = useState('');
  const [friday_1_active, setFriday_1_active] = useState(false);
  const [saturdayInit_Hour, setSaturdayInit_Hour] = useState('');
  const [saturday_1_active, setSaturday_1_active] = useState(false);
  // ás
  const [sundayFinish_Hour, setSundayFinish_Hour] = useState('');
  const [mondayFinish_Hour, setMondayFinish_Hour] = useState('');
  const [tuesdayFinish_Hour, setTuesdayFinish_Hour] = useState('');
  const [wednesdayFinish_Hour, setWednesdayFinish_Hour] = useState('');
  const [thursdayFinish_Hour, setThursdayFinish_Hour] = useState('');
  const [fridayFinish_Hour, setFridayFinish_Hour] = useState('');
  const [saturdayFinish_Hour, setSaturdayFinish_Hour] = useState('');

  // Disponibilidade Periodo - 2
  const [sundayInit_Hour_2, setSundayInit_Hour_2] = useState('');
  const [sunday_2_active, setSunday_2_active] = useState(false);
  const [mondayInit_Hour_2, setMondayInit_Hour_2] = useState('');
  const [monday_2_active, setMonday_2_active] = useState(false);
  const [tuesdayInit_Hour_2, setTuesdayInit_Hour_2] = useState('');
  const [tuesday_2_active, setTuesday_2_active] = useState(false);
  const [wednesdayInit_Hour_2, setWednesdayInit_Hour_2] = useState('');
  const [wednesday_2_active, setWednesday_2_active] = useState(false);
  const [thursdayInit_Hour_2, setThursdayInit_Hour_2] = useState('');
  const [thursday_2_active, setThursday_2_active] = useState(false);
  const [fridayInit_Hour_2, setFridayInit_Hour_2] = useState('');
  const [friday_2_active, setFriday_2_active] = useState(false);
  const [saturdayInit_Hour_2, setSaturdayInit_Hour_2] = useState('');
  const [saturday_2_active, setSaturday_2_active] = useState(false);
  // ás
  const [sundayFinish_Hour_2, setSundayFinish_Hour_2] = useState('');
  const [mondayFinish_Hour_2, setMondayFinish_Hour_2] = useState('');
  const [tuesdayFinish_Hour_2, setTuesdayFinish_Hour_2] = useState('');
  const [wednesdayFinish_Hour_2, setWednesdayFinish_Hour_2] = useState('');
  const [thursdayFinish_Hour_2, setThursdayFinish_Hour_2] = useState('');
  const [fridayFinish_Hour_2, setFridayFinish_Hour_2] = useState('');
  const [saturdayFinish_Hour_2, setSaturdayFinish_Hour_2] = useState('');

  // CATEGORY ACTIONS
  const [ categories, setCategories] = useState([]);
  const [ category, setCategory ] = useState({ id: '', name: '', status: 'A' });
  const [ categoryId, setCategoryId] = useState('');
  const [ categoryLoading, setCategoryLoading ] = useState(true)

  // CATEGORY MODAL CONTROL
  const [ categoryModalVisibility, setCategoryModalVisibility] = useState(false);
  const [ categoryModalTitle, setCategoryModalTitle] = useState('');


  // Melhorar
  const [ productVariationVisibility, setProductVariationVisibility ] = useState(false)

  // --------------------------------- handles ---------------------------------
  const DragHandle = SortableHandle(() => <i style={{cursor: 'move'}} className="fas fa-bars f16" />);

  const handleProductModalClose = () => {
    setProductModalVisibility(false);
    setProductModalTitle('');
    setProductId('');
    setAllowTabs(true);
    setDefaultImage("assets/media/no-img.jpg")
  }

  const handleProductModalShow = (id) => {
    if (id) {
      setProductModalTitle('Atualizar Produto ' + id);
      setProductId(id);
      getThisProductData(id);
      getProductsComposition(id);
      getProductsSizes(id);
      setProductModalVisibility(true);
      getCategoryCardapio(id);
      setAllowTabs(false);
      getVariacoes()
    } else {
      if (categoryId){
        let id = categoryId;
        getThisCategory(id)
        setProductName('');
        setProductStatus('S');
        setProductModalTitle('Adicionar Produto ');
        setProductModalVisibility(true);

      } else {
        Swal.fire({
          title: "Atenção!",
          icon: "info",
          html: 'Selecione uma categoria para adicionar um produto' + categoryId,
          showCloseButton: true,
          showCancelButton: false
        });
      }
    }
  }

  // --------------------------------- handles ---------------------------------
  useEffect(() =>{
    getProductCategories();
    getStock();
    // getProductsComposition();
  }, []);


  // -----------------------------------------------------------------------------------------------------------


  //INICIO SORTABLE ITEM (estoque) VARIACOES
  function SortableComponentEstoqueVariacoes() {
    const SortableListEstoqueVariacoes = SortableContainer(({ items }) => {
      return (
        <div>
          {items.map((value, index) => (
            <SortableVariacaoEstoqueItem
              key={`item-${value.id_variacao}`}
              index={index}
              item={value}
            />
          ))}
        </div>
      );
    });


    function onSortEnd({ oldIndex, newIndex }) {
      let newOrder = arrayMove(productVariables, oldIndex, newIndex)
      setVariables(newOrder);
      // setOrderCategories(newOrder)
    }

    return (
      <SortableListEstoqueVariacoes
        items={productVariables}
        onSortEnd={onSortEnd}
        axis="y"
        helperClass="dragclass"
        useDragHandle={true}
      />
    );
  }

  const SortableVariacaoEstoqueItem = SortableElement(({ item }) => (
    <>
      <div className="table-item padding-horizontal area-margin-composicao">
          <div className="table-item-produto-variacoes-modal">
            <i className="fas fa-bars f16" />
          </div>
          <div className="table-item-produto-variacoes-modal">
            Adicionais (Lanches)
          </div>
          <div className="table-item-produto-variacoes-modal">
            <input className="form-control form-small" type="text" />
          </div>
          <div className="table-item-produto-variacoes-modal">
            <select className="form-control select-size-pdg">
              <option data-content="<i class='fa fa-circle icone-status cor-verde'></i> Ativo">Ativo</option>
              <option data-content="<i class='fa fa-circle icone-status cor-amarelo'></i> Em Falta">Em Falta</option>
              <option data-content="<i class='fa fa-circle icone-status cor-vermelho'></i> Oculto">Oculto</option>
            </select>
          </div>
          <div className="table-item-produto-variacoes-modal">
            <a href="javascript:;" className="btn btn-sm btn-clean btn-icon" title="Apagar Opção">
              <i className="fa fa-trash" />
            </a>
          </div>
        </div>
      </>
  ));

  // -------------------------------------- INICIO Funções de Variações --------------------------------------


  //Criar uma variacao

  //Excluir uma variacao
  async function deleteGlobalVariable(id) {
    console.log(id)
    const response = await Api.get('cardapiovariacoes/excluir', {id_cardapio_variacao: id});
    console.log(response)
    if (response.data.status !== undefined && response.data.status === true) {
      addToast(response.data.mensagem, {
        appearance: "success",
        autoDismiss: true,
        autoDismissTimeout: 2000
      });
    } else {
      Swal.fire({
        title: "Atenção!",
        icon: "info",
        html: response.data.mensagem,
        showCloseButton: true,
        showCancelButton: false
      });
    }
    getVariacoes()
  }

  //Listar TODAS variacoes
  async function getVariacoes() {
    const response = await Api.get('variacoes/listar');
    if(response.status === 200) {
      setVariables(response.data.variacoes)
    }

  }

  // async function getVariacoesDeUmCardapio(){
  //   const response = await Api.get('cardapiovariacoes/listar/' + productId + '/' )
  // }

  //Listar UMA Variacao

  //Alterar status de UMA Variacao

  //Ordem de variacoes

  //INICIO SORTABLE VARIACOES
  function SortableComponentVariacoes() {
    const SortableListVariacoes = SortableContainer(({ items }) => {
      return (
        <div>
          {items.map((value, index) => (
            <SortableVariacaoItem
              key={`item-${value.id_cardapio_variacao}`}
              index={index}
              item={value}
            />
          ))}
        </div>
      );
    });


    function onSortEnd({ oldIndex, newIndex }) {
      let newOrder = arrayMove(productVariables, oldIndex, newIndex)
      setVariables(newOrder);
      // setOrderCategories(newOrder)
    }

    return (
      <SortableListVariacoes
        items={productVariables}
        onSortEnd={onSortEnd}
        axis="y"
        helperClass="dragclass"
        useDragHandle={true}
      />
    );
  }

  const SortableVariacaoItem = SortableElement(({ item }) => (
    <div key={item.id_cardapio_variacao} className="table-item padding-horizontal area-margin-composicao">
      <div className="table-item-produto-variacoes">
        <DragHandle />
      </div>
      <div className="table-item-produto-variacoes">
        {item.nome_variacao}
      </div>
        <div className="table-item-produto-variacoes">
          {item.escolha_minima} / {item.escolha_maxima}
        </div>
        <div className="table-item-produto-variacoes">
          {item.calculo_preco}
        </div>
        <div className="table-item-produto-variacoes">
        { item.tamanho_nome ? item.tamanho_nome : 'Todos' }
        </div>
        <div className="table-item-produto-variacoes">
          <select value={item.status} className="form-control select-size-pdg">
            <option value={'S'} data-content="<i class='fa fa-circle icone-status cor-verde'></i> Ativo">Ativo</option>
            <option value={'F'} data-content="<i class='fa fa-circle icone-status cor-amarelo'></i> Em Falta">Em Falta</option>
            <option value={'O'} data-content="<i class='fa fa-circle icone-status cor-vermelho'></i> Oculto">Oculto</option>
          </select>
        </div>
        <div className="table-item-produto-variacoes">
          <div onClick={(e) => alert(item.id_cardapio_variacao)} className="btn btn-sm btn-clean btn-icon" title="Editar Variação">
            <i className="fa fa-edit" />
          </div>
          <div onClick={(e) => deleteGlobalVariable(item.id_cardapio_variacao)} className="btn btn-sm btn-clean btn-icon" title="Apagar Variação">
            <i className="fa fa-trash" />
          </div>
        </div>
    </div>
  ));



  async function saveDetachedVariable () {
    const response = await Api.post('variacoes/adicionar/', {
      id_variacao_categoria: '-2',
      id_cardapio: productId,
      id_cardapio_tamanho: 0,
      nome_variacao:  detachedVariableName,
      escolha_minima: minChoice,
      escolha_maxima: maxChoice,
      calculo_preco: priceCalc
    });
    console.log('VARIACAO AVULSAA', response);
    if (response.data.status !== undefined && response.data.status === true) {
      addToast(response.data.mensagem, {
        appearance: "success",
        autoDismiss: true,
        autoDismissTimeout: 2000
      });
    } else {
      Swal.fire({
        title: "Atenção!",
        icon: "info",
        html: response.data.mensagem,
        showCloseButton: true,
        showCancelButton: false
      });
    }
    // getVariacoes()
  }

  async function saveGlobalVariable () {
    const response = await Api.post('cardapiovariacoes/adicionar', {
      id_cardapio: productId,
      id_variacao: globalVariableId,
      id_cardapio_tamanho: 0
    });
    if (response.data.status !== undefined && response.data.status === true) {
      addToast(response.data.mensagem, {
        appearance: "success",
        autoDismiss: true,
        autoDismissTimeout: 2000
      });
    } else {
      Swal.fire({
        title: "Atenção!",
        icon: "info",
        html: response.data.mensagem,
        showCloseButton: true,
        showCancelButton: false
      });
    }
    getVariacoes()
  }


  function handleSetGlobalVariable (nome_variacao, id_variacao) {
      setDetachedVariableName('');
      setGlobalVariableId(id_variacao);
  }

  function handleSetDetachedVariable(nome_variacao) {
      setDetachedVariableName(nome_variacao);
      setGlobalVariableId('');
  }

  const VariablesActionButtons = () => {
    if (detachedVariableName === false){
      return (
        <div onClick={() => setProductVariationVisibility(true)} className="btn btn-primary font-weight-bolder btn-lg btn-block m-t-11">
          Incluir - A
        </div>
      )
    }
    return (
      <div onClick={() => saveGlobalVariable()} className="btn btn-primary font-weight-bolder btn-lg btn-block m-t-11">
        Incluir - G
      </div>
    )

  }

  async function getThisProductData(id) {
    const response = await Api.get('cardapio/listar/' + categoryId + '/' + id);
    setDefaultImage(response.data.cardapio[0].foto)
    if( response.status === 200) {
      setProductVariables(response.data.cardapio[0].variacoes)
    }

    console.log('DADOS DO CARDAPIO', response.data.cardapio[0])
  }
  // -------------------------------------- FIM Funções de Variações --------------------------------------


  // -------------------------------------- INICIO Funções do Cardápio --------------------------------------
  async function getCategoryCardapio(id){
    const response = await Api.get("/cardapio/listar/" + categoryId + "/" + id + '')
    let cardapio = response.data.cardapio[0]
    setCardapioDados({
      id_cardapio: cardapio.id_cardapio,
      id_cardapio_categoria: cardapio.id_cardapio_categoria
    })
  }

  async function duplicateProduct (id_cardapio) {
    const response = await Api.post('cardapio/duplicar', { id_cardapio: id_cardapio});
    if (response.data.status !== undefined && response.data.status === true) {
      addToast(response.data.mensagem, {
        appearance: "success",
        autoDismiss: true,
        autoDismissTimeout: 2000
      });
    } else {
      Swal.fire({
        title: "Atenção!",
        icon: "info",
        html: response.data.mensagem,
        showCloseButton: true,
        showCancelButton: false
      });
    }
    getProductCategories(categoryId)
  }
  // -------------------------------------- FIM Funções do Tamanho --------------------------------------

  // -------------------------------------- INICIO Funções do Tamanho --------------------------------------


  async function getProductsSizes(id_cardapio){
    const response = await Api.get('cardapiotamanhos/listar/' + id_cardapio)
       if (response.data.status !== undefined && response.data.status === true) {
          setSizes(response.data.cardapiotamanhos)
       }
  }

  async function deleteSize(id_cardapio_tamanho){
    const response = await Api.post('cardapiotamanhos/excluir', { id_cardapio_tamanho: id_cardapio_tamanho });
    if (response.data.status !== undefined && response.data.status === true) {
      addToast(response.data.mensagem, {
        appearance: "success",
        autoDismiss: true,
        autoDismissTimeout: 2000
      });
    } else {
      Swal.fire({
        title: "Atenção!",
        icon: "info",
        html: response.data.mensagem,
        showCloseButton: true,
        showCancelButton: false
      });
    }
    getProductsSizes(productId)
  }

  async function insertSize(){
    const response = await Api.post('cardapiotamanhos/adicionar', {
      id_cardapio: productId,
      nome: size['size'],
      preco: size['price'],
      status: size['status']
    });
    if (response.data.status !== undefined && response.data.status === true) {
      addToast(response.data.mensagem, {
        appearance: "success",
        autoDismiss: true,
        autoDismissTimeout: 2000
      });
      // Reload dos tamanhos
    } else {
      Swal.fire({
        title: "Atenção!",
        icon: "info",
        html: response.data.mensagem,
        showCloseButton: true,
        showCancelButton: false
      });
    }
    getProductsSizes(productId)
  }

  async function setOrderSizes(id_cardapio, new_order){
    const response = await Api.post("cardapiotamanhos/ordem", {id_cardapio: id_cardapio, ordem: new_order});
    if (response.data.status !== undefined && response.data.status === true) {
      addToast(response.data.mensagem, {
        appearance: "success",
        autoDismiss: true,
        autoDismissTimeout: 2000
      });
    } else {
      Swal.fire({
        title: "Atenção!",
        icon: "info",
        html: response.data.mensagem,
        showCloseButton: true,
        showCancelButton: false
      });
    }
  }

  function SortableSizeComponent() {
    const SortableListSize = SortableContainer(({ items }) => {
      return (
        <div>
          {items.map((value, index) => (
            <SortableTamanhoComponent
              key={`item-${value.id_cardapio_tamanho}`}
              index={index}
              item={value}
            />
          ))}
        </div>
      );
    });

    function onSortEnd({ oldIndex, newIndex }) {
      let newOrder = arrayMove(sizes, oldIndex, newIndex)
      setSizes(newOrder);
      setOrderSizes(productId, newOrder)
    }

    return (
      <SortableListSize
        items={sizes}
        onSortEnd={onSortEnd}
        axis="y"
        helperClass="dragclass"
        useDragHandle={true}
      />
    );
  }
  const SortableTamanhoComponent = SortableElement(({ item }) => (
    <div className="table-item padding-horizontal area-margin-composicao">
        <div className="table-item-produto-tamanhos">
          <DragHandle />
        </div>
        <div className="table-item-produto-tamanhos">
          <input value={item.nome} className="form-control form-small" type="text" />
        </div>
        <div className="table-item-produto-tamanhos">
          <input value={item.preco} className="form-control form-small" type="text" />
        </div>
        <div className="table-item-produto-tamanhos">
          <select value={item.status} onChange={ (e) => console.log(e.target.value)} className="form-control select-size-pdg">
            <option value={'S'} data-content="<i class='fa fa-circle icone-status cor-verde'></i> Ativo">Ativo</option>
            <option value={'O'} data-content="<i class='fa fa-circle icone-status cor-amarelo'></i> Em Falta">Em Falta</option>
            <option value={'F'} data-content="<i class='fa fa-circle icone-status cor-vermelho'></i> Oculto">Oculto</option>
          </select>
        </div>
        <div className="table-item-produto-tamanhos">
          <div onClick={() => deleteSize(item.id_cardapio_tamanho)} className="btn btn-sm btn-clean btn-icon" title="Apagar Composição">
            <i className="fa fa-trash" />
          </div>
        </div>
      </div>
  ));

  // -------------------------------------- FIM Funções do Tamanho --------------------------------------

  // -------------------------------------- INICIO Funções da Composição --------------------------------------

  function VisibleCompositionsComponent (){
    if(compositions){
      return <SortableCompositionComponent />
    }
    return <h1> Não há composição </h1>
  }

  function handleComposition () {
    if (composition['removable'] === 0) {
      setComposition({...composition, removable: 1 })
    } else{
      setComposition({...composition, removable: 0 })
    }
  }

  async function setOrderComposition(id_cardapio, new_order){
    const response = await Api.post("cardapioestoque/ordem", {id_cardapio: id_cardapio, ordem: new_order});
    if (response.data.status !== undefined && response.data.status === true) {
      addToast(response.data.mensagem, {
        appearance: "success",
        autoDismiss: true,
        autoDismissTimeout: 2000
      });
    } else {
      Swal.fire({
        title: "Atenção!",
        icon: "info",
        html: response.data.mensagem,
        showCloseButton: true,
        showCancelButton: false
      });
    }
  }
  async function deleteProductComposition(id_cardapio_estoque){
    const response = await Api.post('cardapioestoque/excluir', { id_cardapio_estoque: id_cardapio_estoque })
    if (response.data.status !== undefined && response.data.status === true) {
      addToast(response.data.mensagem, {
        appearance: "success",
        autoDismiss: true,
        autoDismissTimeout: 2000
      });
    } else {
      Swal.fire({
        title: "Atenção!",
        icon: "info",
        html: response.data.mensagem,
        showCloseButton: true,
        showCancelButton: false
      });
    }
    getProductsComposition(productId)
  }
  async function getProductsComposition(id_cardapio){
    const response = await Api.get('cardapioestoque/listar/' + id_cardapio)
    if (response.data.status !== undefined && response.data.status === true) {
      setCompositions(response.data.cardapioestoque)
    }
  }
  function SortableCompositionComponent() {
    const SortableListComposition = SortableContainer(({ items }) => {
      return (
        <div>
          {items.map((value, index) => (
            <SortableCompositionItem
              key={`item-${value.id_cardapio_estoque}`}
              index={index}
              item={value}
            />
          ))}
        </div>
      );
    });

    function onSortEnd({ oldIndex, newIndex }) {
      let newOrder = arrayMove(compositions, oldIndex, newIndex)
      setCompositions(newOrder);
      setOrderComposition(productId, newOrder)
    }

    return (
      <SortableListComposition
        items={compositions}
        onSortEnd={onSortEnd}
        axis="y"
        helperClass="dragclass"
        useDragHandle={true}
      />
    );
  }
  const SortableCompositionItem = SortableElement(({ item }) => (
      <div key={item.id_cardapio_estoque} className="table-item padding-horizontal area-margin-composicao">
        <div className="table-item-produto-composicao">
          <DragHandle />
        </div>
        <div className="table-item-produto-composicao">
          {item.quantidade}
        </div>
        <div className="table-item-produto-composicao">
          {item.nome_item}
        </div>
        <div className="table-item-produto-composicao">
          <div className="checkbox-inline">
            <label className="checkbox checkbox-square checkbox-primary m-t-3">
              <input type="checkbox" defaultChecked="checked" />Sim
              <span /></label>
          </div>
        </div>
        <div className="table-item-produto-composicao">
          <div onClick={() => deleteProductComposition(item.id_cardapio_estoque)} className="btn btn-sm btn-clean btn-icon" title="Apagar Composição">
            <i className="fa fa-trash" />
          </div>
        </div>
      </div>
  ));

  async function getStock(){
    const response = await Api.get("estoque/listar");
    if (response.data.status !== undefined && response.data.status === true) {
      setStock(response.data.estoque)
    }
  }

  async function insertComposition(){
    const response = await Api.post("cardapioestoque/adicionar", {
      quantidade: composition['qtd'],
      id_estoque: composition['items'],
      removivel: composition['removable'],
      id_cardapio: productId
    });
    if (response.data.status !== undefined && response.data.status === true) {
      addToast(response.data.mensagem, {
        appearance: "success",
        autoDismiss: true,
        autoDismissTimeout: 2000
      });
    } else {
      Swal.fire({
        title: "Atenção!",
        icon: "info",
        html: response.data.mensagem,
        showCloseButton: true,
        showCancelButton: false
      });
    }
    getProductsComposition(productId)
  }


  // -------------------------------------- FIM Funções da Composição --------------------------------------
  // -------------------------------------- INICIO Funções do Produto --------------------------------------

  async function handleImageChange(e) {
    const form_data = new FormData();
    form_data.append('arquivo', e.target.files[0]);
    form_data.append('id_cardapio', productId);
    const cf = {
        baseURL: Api.defaults.baseURL,
        headers: {
          'content-type': 'multipart/form-data',
          //'Authorization' : 'Basic ' +  sessionStorage.getItem("hash") ? sessionStorage.getItem("hash") : 'null'
          'Authorization' : Api.defaults.headers['Authorization']
      }
    }
    const response = await Axios.post('cardapio/foto', form_data, cf );
    if (response.data.status !== undefined && response.data.status === true) {
      addToast(response.data.mensagem, {
        appearance: "success",
        autoDismiss: true,
        autoDismissTimeout: 2000
      });
    } else {
      Swal.fire({
        title: "Atenção!",
        icon: "info",
        html: response.data.mensagem,
        showCloseButton: true,
        showCancelButton: false
      });
    }
    setDefaultImage(response.data.url_imagem)
  }

  async function setOrderProducts(newOrder) {
    const response = await Api.post("cardapio/ordem", { ordem :  newOrder});
    if (response.data.status !== undefined && response.data.status === true) {
      addToast('Upload da imagem com sucesso', {
        appearance: "success",
        autoDismiss: true,
        autoDismissTimeout: 2000
      });
    } else {
      Swal.fire({
        title: "Atenção!",
        icon: "info",
        html: response.data.mensagem,
        showCloseButton: true,
        showCancelButton: false
      });
    }
  }

  async function deleteThisProduct(id, categoryId){
    const response = await Api.post("cardapio/excluir", {
      id_cardapio: id
    });
    if (response.data.status !== undefined && response.data.status === true) {
      addToast(response.data.mensagem, {
        appearance: "success",
        autoDismiss: true,
        autoDismissTimeout: 2000
      });
    } else {
      Swal.fire({
        title: "Atenção!",
        icon: "info",
        html: response.data.mensagem,
        showCloseButton: true,
        showCancelButton: false
      });
      getProductsWithThisCategory(categoryId)
    }
  }

  async function insertNewProduct(){
    const response = await Api.post("cardapio/adicionar", {
      id_cardapio_categoria : categoryId,
      nome_prod : productName,
      descricao : productDescription,
      status : productStatus,
      preco : productPrice,
      novo_preco : productNewPrice,
      arquivo: '',
      disponibilidade : {
        "do": {
          "initAt": sundayInit_Hour,
          "endAt": sundayFinish_Hour,
          "initAt2": sundayInit_Hour_2,
          "endAt2": sundayFinish_Hour_2
        },
        "sg": {
          "initAt": mondayInit_Hour,
          "endAt": mondayFinish_Hour,
          "initAt2": mondayInit_Hour_2,
          "endAt2": mondayFinish_Hour_2
        },
        "te": {
          "initAt": tuesdayInit_Hour,
          "endAt": tuesdayFinish_Hour,
          "initAt2": tuesdayInit_Hour_2,
          "endAt2": tuesdayFinish_Hour_2
        },
        "qa": {
          "initAt": wednesdayInit_Hour,
          "endAt": wednesdayFinish_Hour,
          "initAt2": wednesdayInit_Hour_2,
          "endAt2": wednesdayFinish_Hour_2
        },
        "qi": {
          "initAt": thursdayInit_Hour,
          "endAt": thursdayFinish_Hour,
          "initAt2": thursdayInit_Hour_2,
          "endAt2": thursdayFinish_Hour_2
        },
        "sx": {
          "initAt": fridayInit_Hour,
          "endAt": fridayFinish_Hour,
          "initAt2": fridayInit_Hour_2,
          "endAt2": fridayFinish_Hour_2
        },
        "sb": {
          "initAt": saturdayInit_Hour,
          "endAt": saturdayFinish_Hour,
          "initAt2": saturdayInit_Hour_2,
          "endAt2": saturdayFinish_Hour_2
        }
      }
    })
    if (response.data.status !== undefined && response.data.status === true) {
      addToast(response.data.mensagem, {
        appearance: "success",
        autoDismiss: true,
        autoDismissTimeout: 2000
      });
      setAllowTabs(false);
      getProductsWithThisCategory(categoryId)
    } else {
      Swal.fire({
        title: "Atenção!",
        icon: "info",
        html: response.data.mensagem,
        showCloseButton: true,
        showCancelButton: false
      });
    }
    setProductId(response.data.id_cardapio)
  }

  async function getProductsWithThisCategory(categoryId){
    setProductLoading(true)
    getThisCategory(categoryId)
    const response = await Api.get("cardapio/listar/" + categoryId);
    if (response.data.status !== undefined && response.data.status === true) {
      setProducts(response.data.cardapio);
      setTimeout(setProductLoading(false))
    }
    console.log('Items cardapio pela Categoria selecionada', response.data.cardapio)
  }

  async function updateProductStatus(id, status) {
    const response = await Api.post("cardapio/mudaSts", { id_cardapio: id, status: status});
    if (response.data.status !== undefined && response.data.status === true) {
      addToast(response.data.mensagem, {
        appearance: "success",
        autoDismiss: true,
        autoDismissTimeout: 2000
      });
    } else {
      Swal.fire({
        title: "Atenção!",
        icon: "info",
        html: response.data.mensagem,
        showCloseButton: true,
        showCancelButton: false
      });
    }
    getProductsWithThisCategory(categoryId)
  }

  //INICIO SORTABLE PRODUTOS
  function SortableComponentProduct() {
    const SortableListProduct = SortableContainer(({ items }) => {
      return (
        <div>
          {items.map((value, index) => (
            <SortableProductItem
              key={`item-${value.id_cardapio}`}
              index={index}
              item={value}
            />
          ))}
        </div>
      );
    });


     function onSortEnd({ oldIndex, newIndex }) {
      let newOrder = arrayMove(products, oldIndex, newIndex)
      setProducts(newOrder);
      setOrderProducts(newOrder)
    }

    return (
      <SortableListProduct
        items={FilteredProducts}
        onSortEnd={onSortEnd}
        axis="y"
        helperClass="dragclass"
        useDragHandle={true}
      />
    );
  }

  const SortableProductItem = SortableElement(({ item }) => (
    <div key={item.id_cardapio} className="table-item padding-horizontal">
      <div className="table-item-produto">
        <DragHandle />
      </div>
      <div className="table-item-produto">
        <img src={item.foto ? item.foto : "assets/media/no-img.jpg"} alt="produto" className="foto-produto" />
      </div>
      <div className="table-item-produto">
        <strong>{item.nome_prod}</strong>
        <br /> Não há ingredientes para esse produto
      </div>
      <div className="table-item-produto">
        <span className="cor-verde">R$ {item.preco}</span>
      </div>
      <div className="table-item-produto">
        <select value={item.status} onChange={(e) => updateProductStatus(item.id_cardapio, e.target.value)} className="form-control select-size-pdg">
          <option value={"S"} data-content="<i class='fa fa-circle icone-status cor-verde'></i> Ativo">Ativo</option>
          <option value={"F"} data-content="<i class='fa fa-circle icone-status cor-amarelo'></i> Em Falta">Em Falta</option>
          <option value={"O"} data-content="<i class='fa fa-circle icone-status cor-vermelho'></i> Oculto">Oculto</option>
        </select>
      </div>
      <div className="table-item-produto">
        <div onClick={()=> duplicateProduct(item.id_cardapio)} className="btn btn-sm btn-clean btn-icon" title="Clonar Produto">
          <i className="fa fa-clone" />
        </div>
        <div onClick={()=> handleProductModalShow(item.id_cardapio)} className="btn btn-sm btn-clean btn-icon" title="Editar Produto">
          <i className="fa fa-edit" />
        </div>
        <div onClick={() => {
            Swal.fire({
              title: "Você tem certeza?",
              text: "Não será possível reverter!",
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Sim deletar.",
              cancelButtonText: "Cancelar"
            }).then(result => {
              if (result.value) {
                deleteThisProduct(item.id_cardapio, categoryId)
              }
            });
          }}
          className="btn btn-sm btn-clean btn-icon" title="Apagar Produto">
          <i className="fa fa-trash" />
        </div>
      </div>
    </div>
  ));
  //FIM SORTABLE PRODUTOS

  // Botões dinamicos do MODAL PRODUTOS
  const ProductButtonActions = (props) => {
    if(props.id && productId){
      return (
        <>
          <button onClick={() => handleProductModalClose()} type="button" className="btn btn-light-primary font-weight-bold">Fechar</button>
          <button onClick={() => updateCategory(props.id)} type="button" className="btn btn-primary font-weight-bold">Atualizar</button>
        </>
      )
    }
    return (
      <>
        <button onClick={() => handleProductModalClose()} type="button" className="btn btn-light-primary font-weight-bold">Fechar</button>
        <button onClick={() => insertNewProduct()} type="button" className="btn btn-primary font-weight-bold">Salvar</button>
      </>
    )
  }

  const FilteredProducts = products.filter( (item) => {
    return item.nome_prod.indexOf(searchParameter) !== -1
    }
  )
  // -------------------------------------- FIM Funções do Produto --------------------------------------
  // -------------------------------------- FIM Funções da Categoria --------------------------------------

  const handleCategoryModalClose = () => {
    setCategoryModalVisibility(false);
    setCategoryModalTitle('');
    setCategoryId('');
  }
  const handleCategoryModalShow = (id) => {
    if (id) {
      getThisCategory(id)
      setCategoryModalTitle('Atualizar Categoria ' + id);
      setCategoryModalVisibility(true);
    } else {

      setCategory({...category, id:'', name: '', status: 'S'})
      setCategoryModalTitle('Adicionar Categoria ');
      setCategoryModalVisibility(true);
    }
  }

  async function getProductCategories(){
    const response = await Api.get("cardapiocategorias/listar");
    if (response.data.status !== undefined && response.data.status === true) {
      setCategories(response.data.categorias);
    }
    setCategoryLoading(false)
  }
  //Salvar nova categoria
  async function saveNewCategory() {
    // Aguardando URL do back-end
    const response = await Api.post("cardapiocategorias/adicionar", {
      nome_categoria: category['name'],
      status: category['status']
    });
    if (response.data.status !== undefined && response.data.status === true) {
      addToast(response.data.mensagem, {
        appearance: "success",
        autoDismiss: true,
        autoDismissTimeout: 2000
      });
      getProductCategories();
      handleCategoryModalClose();
    } else {
      Swal.fire({
        title: "Atenção!",
        icon: "info",
        html: response.data.mensagem,
        showCloseButton: true,
        showCancelButton: false
      });
    }
  }

  //Listar uma categoria (preparar para update)
  async function getThisCategory(id){
    setCategoryId(id);
    const response = await Api.get("cardapiocategorias/listar/" + id);
    if (response.data.status !== undefined && response.data.status === true) {
      setCategory({ id: response.data.categorias[0].id_cardapio_categoria, name: response.data.categorias[0].nome_categoria, status: response.data.categorias[0].status})
      setCategoryId(response.data.categorias[0].id_cardapio_categoria)
    }
  }
  //Atualizar uma categoria
  async function updateCategory(id) {
    // Aguardando URL do back-end
    const response = await Api.post("cardapiocategorias/adicionar/" + id, {
      nome_categoria: category['name'],
      status: category['status']
    });
    if (response.data.status !== undefined && response.data.status === true) {
      addToast(response.data.mensagem, {
        appearance: "success",
        autoDismiss: true,
        autoDismissTimeout: 2000
      });
      getProductCategories();
      handleCategoryModalClose();
    } else {
      Swal.fire({
        title: "Atenção!",
        icon: "info",
        html: response.data.mensagem,
        showCloseButton: true,
        showCancelButton: false
      });
    }
  }

  async function setOrderCategories(newOrder) {
    const response = await Api.post("cardapiocategorias/ordem", { ordem :  newOrder});
    if (response.data.status !== undefined && response.data.status === true) {
      addToast(response.data.mensagem, {
        appearance: "success",
        autoDismiss: true,
        autoDismissTimeout: 2000
      });
    } else {
      Swal.fire({
        title: "Atenção!",
        icon: "info",
        html: response.data.mensagem,
        showCloseButton: true,
        showCancelButton: false
      });
    }
  }

  //INICIO SORTABLE CATEGORIA
  function SortableComponentCategory() {
    const SortableListCategory = SortableContainer(({ items }) => {
      return (
        <div>
          {items.map((value, index) => (
            <SortableCategoryItem
              key={`item-${value.id_cardapio_categoria}`}
              index={index}
              item={value}
            />
          ))}
        </div>
      );
    });


    function onSortEnd({ oldIndex, newIndex }) {
      let newOrder = arrayMove(categories, oldIndex, newIndex)
      setCategories(newOrder);
      setOrderCategories(newOrder)
    }

    return (
      <SortableListCategory
        items={categories}
        onSortEnd={onSortEnd}
        axis="y"
        helperClass="dragclass"
        useDragHandle={true}
      />
    );
  }
  const SortableCategoryItem = SortableElement(({ item }) => (
      <div onClick={()=> getProductsWithThisCategory(item.id_cardapio_categoria)} className="table-item no-padding padding-horizontal">
          <div className="table-item-ind-categoria table-item-center-midle">
            <DragHandle />
          </div>
          <div className="table-item-ind-categoria table-item-center-midle">
            {item.nome_categoria}
          </div>
          <div className="table-item-ind-categoria">
            <div onClick={() => handleCategoryModalShow(item.id_cardapio_categoria)} className="btn btn-sm btn-clean btn-icon" title="Editar Categoria">
              <i className="fa fa-edit" />
            </div>
          </div>
      </div>
  ));
  //FIM SORTABLE CATEGORIA

  // Botões dinamicos do MODAL CATEGORIA
  const CategoryButtonActions = (props) => {
    if(props.id){
      return (
        <>
          <button onClick={() => handleCategoryModalClose()} type="button" className="btn btn-light-primary font-weight-bold">Fechar</button>
          <button onClick={() => updateCategory(props.id)} type="button" className="btn btn-primary font-weight-bold">Atualizar</button>
        </>
      )
    }
    else {
      return (
        <>
          <button onClick={() => handleCategoryModalClose()} type="button" className="btn btn-light-primary font-weight-bold">Fechar</button>
          <button onClick={() => saveNewCategory()} type="button" className="btn btn-primary font-weight-bold">Salvar</button>
        </>
      )
    }

  }
  // -------------------------------------- FIM Funções da Categoria --------------------------------------
    return (
        <>
        <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
          <div className="d-flex flex-column-fluid">
          <div className="container">
            <div className="row">
              <div className="col-md-4">
                <div className="card card-custom gutter-b">
                  <div className="card-header flex-wrap">
                    <div className="subheader py-2 py-lg-4 subheader-transparent" id="kt_subheader">
                      <div className="d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
                        <div className="d-flex align-items-center flex-wrap mr-2">
                          <h5 className="text-dark font-weight-bold mt-2 mb-2 mr-5">Categorias</h5>
                          <div className="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200" />
                        </div>
                      </div>
                    </div>
                    <div className="card-toolbar">
                      <div onClick={() => handleCategoryModalShow()} className="btn btn-primary font-weight-bolder">
                        Adicionar
                      </div>
                    </div>
                  </div>
                  <div className="card-body no-padding">
                    <LoadingOverlay
                      active={categoryLoading}
                      spinner
                      text='Carregando...'
                      >
                      <SortableComponentCategory />
                    </LoadingOverlay>
                  </div>
                </div>
              </div>
              <div className="col-md-8">
                <div className="card card-custom gutter-b">
                  <div className="card-header flex-wrap">
                    <div className="subheader py-2 py-lg-4 subheader-transparent" id="kt_subheader">
                      <div className="d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
                        <div className="d-flex align-items-center flex-wrap mr-2">
                          <h5 className="text-dark font-weight-bold mt-2 mb-2 mr-5">Produtos</h5>
                          <div className="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200" />
                          <div className="d-flex align-items-center" id="kt_subheader_search">
                            <span className="text-dark-50 font-weight-bold" id="kt_subheader_total">{products ? products.length : 0} Itens</span>
                            <form className="ml-5">
                              <div className="input-group input-group-sm input-group-solid" style={{maxWidth: '175px'}}>
                                <input value={searchParameter} onChange={(e)=> setSearchParameter(e.target.value)} type="text" className="form-control" id="kt_subheader_search_form" placeholder="Procurar..." />
                                <div className="input-group-append">
                                  <span className="input-group-text">
                                    <span className="svg-icon">
                                      <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                        <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                          <rect x={0} y={0} width={24} height={24} />
                                          <path d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z" fill="#000000" fillRule="nonzero" opacity="0.3" />
                                          <path d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z" fill="#000000" fillRule="nonzero" />
                                        </g>
                                      </svg>
                                    </span>
                                  </span>
                                </div>
                              </div>
                            </form>

                          </div>
                          <span className="badge">{ category['name'] ? category['name'] : 'Nenhuma categoria selecionada'}</span>
                        </div>
                      </div>
                    </div>
                    <div className="card-toolbar">
                      <div onClick={() => handleProductModalShow()} className="btn btn-primary font-weight-bolder">
                        Adicionar Produto
                      </div>
                    </div>
                  </div>
                  <div className="card-body no-padding">
                    <div className="table-div-header padding-horizontal">
                      <div className="table-div-header-item-produto" />
                      <div className="table-div-header-item-produto">Foto</div>
                      <div className="table-div-header-item-produto">Produto</div>
                      <div className="table-div-header-item-produto">Preço</div>
                      <div className="table-div-header-item-produto">Status</div>
                      <div className="table-div-header-item-produto">Ações</div>
                    </div>
                    <LoadingOverlay
                      active={productLoading}
                      spinner
                      text='Carregando...'
                      >
                      <SortableComponentProduct />
                    </LoadingOverlay>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>


      {/* ----------------------------------------------- MODAL ------------------------------------------------------------ */}
      {/* ------------------------------------------------------------------------------------------------------------------ */}
      {/* ------------------------------------------------------------------------------------------------------------------ */}
      {/* ------------------------------------------------------------------------------------------------------------------ */}
      {/* ------------------------------------------------------------------------------------------------------------------ */}
      {/* ------------------------------------------------------------------------------------------------------------------ */}

        <Modal show={categoryModalVisibility} onHide={handleCategoryModalClose}>
          <Modal.Header closeButton>
          <Modal.Title>{categoryModalTitle ? categoryModalTitle : ''}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <label>Nome da Categoria</label>
              <input value={category['name' ]} onChange={(e) => setCategory({...category, name: e.target.value})} type="text" className="form-control" />
            </div>
            <label>Status</label>
            <select value={category['status']} onChange={(e) => setCategory({...category, status: e.target.value})} className="form-control">
              <option value={'S'} data-content="<i class='fa fa-circle icone-status cor-verde'></i> Ativo">Ativo</option>
              <option value={'F'} data-content="<i class='fa fa-circle icone-status cor-amarelo'></i> Em Falta">Em Falta</option>
              <option value={'O'} data-content="<i class='fa fa-circle icone-status cor-vermelho'></i> Oculto">Oculto</option>
            </select>
          </Modal.Body>
          <Modal.Footer>
            <CategoryButtonActions id={category['id']}/>
          </Modal.Footer>
        </Modal>

        <Modal size={'xl'} show={productModalVisibility} onHide={handleCategoryModalClose}>
          <Modal.Header closeButton>
            <Modal.Title>{productModalTitle ? productModalTitle : ''}  <small> - {category['name'] ? category['name'] : ' Categoria não selecionada'}</small></Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <Tabs defaultActiveKey="informacoes" id="uncontrolled-tab-example">
            <Tab eventKey="informacoes" title={
                <>
                  <span className="nav-icon">
                    <i className="flaticon2-chat-1" />
                  </span>
                  <span className="nav-text">Informações</span>
                </>
              }>
              <div className="form-group row" style={{height: '100px'}}>
                <div className="col-2" style={{width: '220px', maxWidth: '220px'}}>
                  <div className="image-input image-input-empty image-input-outline" id="kt_user_edit_avatar" style={{backgroundImage: 'url('+ defaultImage +')'}}>
                    <div className="image-input-wrapper imagem-produto-change" />

                    <label className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow" data-action="change" data-toggle="tooltip" title data-original-title="Change avatar">
                      <i className="fa fa-pen icon-sm text-muted" />
                      <input disabled={allowTabs} type="file" onChange={(e) => handleImageChange(e)} accept=".png, .jpg, .jpeg" />
                      <input type="hidden" name="profile_avatar_remove" />
                    </label>
                    <span className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow" data-action="cancel" data-toggle="tooltip" title="Cancel avatar">
                      <i className="ki ki-bold-close icon-xs text-muted" />
                    </span>
                    <span className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow" data-action="remove" data-toggle="tooltip" title="Remove avatar">
                      <i className="ki ki-bold-close icon-xs text-muted" />
                    </span>
                  </div>
                  { allowTabs ? 'É possível inserir uma imagem do produto apenas se os dados essenciais foram preenchidos' : ''}
                </div>
                <div className="col-3">
                  <div className="form-group">
                    <label>Categoria - {category['name'] ? category['name'] : ' Categoria não selecionada'}</label>
                    <input disabled value={categoryId} onChange={(e)=> setCategoryId(e.target.value)} className="form-control form-control-lg form-control-solid" type="text" />
                  </div>
                </div>
                <div className="col-7">
                  <div className="form-group">
                    <label>Nome do Produto</label>
                    <input value={productName} onChange={(e)=> setProductName(e.target.value)} className="form-control form-control-lg form-control-solid" type="text" />
                  </div>
                </div>
              </div>
              <div className="form-group row">
                <div className="col-2" style={{width: '200px', maxWidth: '200px'}}>
                </div>
                <div className="col-10">
                  <div className="form-group">
                    <label>Descrição</label>
                    <textarea value={productDescription} onChange={(e) => setProductDescription(e.target.value)} className="form-control form-control-lg form-control-solid" type="text" />
                  </div>
                </div>
              </div>
              <div className="form-group row">
                <div className="col-2" style={{width: '200px', maxWidth: '200px'}}>
                </div>
                <div className="col-4">
                  <div className="form-group">
                    <label>Preço - {productPrice}</label>
                    <CurrencyInput maxLength={6} precision={"2"}  value={productPrice} onChangeEvent={(e) => setProductPrice(e.target.value)} decimalSeparator="." className="form-control form-control-lg form-control-solid" type="text"/>

                  </div>
                </div>
                <div className="col-4">
                  <div className="form-group">
                    <label>Preço Promocional</label>
                    <CurrencyInput maxLength={6} precision={"2"}  value={productNewPrice} onChangeEvent={(e) => setProductNewPrice(e.target.value)} decimalSeparator="." className="form-control form-control-lg form-control-solid" type="text"/>
                  </div>
                </div>
                <div className="col-2">
                  <div className="form-group">
                    <label>Status</label>
                    <select value={productStatus} onChange={(e)=> setProductStatus(e.target.value)} className="form-control form-control-lg form-control-solid">
                        <option value={"S"}data-content="<i className='fa fa-circle icone-status cor-verde'></i> Ativo">Ativo</option>
                        <option value={"O"}data-content="<i className='fa fa-circle icone-status cor-vermelho'></i> Oculto">Oculto</option>
                        <option value={"F"}data-content="<i className='fa fa-circle icone-status cor-amarelo'></i> Em Falta">Em Falta</option>
                    </select>
                  </div>
                </div>
              </div>

            </Tab>
            <Tab eventKey="composicao" title={
              <div>
                <span className="nav-icon">
                  <i className="flaticon2-drop" />
                </span>
                  <span className="nav-text">Composição</span>
              </div>
            } disabled={allowTabs}>
                <div className="area-top-header-composicao">
                  <div className="composicao-item-area">
                    <div className="form-group">
                      <label>Qtd.</label>
                      <input value={composition['qtd']} onChange={(e) => setComposition({...composition, qtd: e.target.value})} className="form-control form-control-solid form-small" type="number" />
                    </div>
                  </div>
                  <div className="composicao-item-area">
                    <div className="form-group">
                    <label>Ingrediente</label>
                      <Select placeholder={"Digite o nome de um item"} onChange={(e) => setComposition({...composition, items: e[0].id_estoque})} labelField={'nome_item'} valueField={'nome_item '} searchBy={'nome_item'} className="form-control form-control-solid form-small" options={stock}  />
                    </div>
                  </div>
                  <div className="composicao-item-area">
                    <div className="form-group">
                      <label>Removível?</label>
                      <div className="checkbox-inline">
                        <label className="checkbox checkbox-square checkbox-primary m-t-3">
                          <input value={composition['removable']} onChange={(e) => handleComposition()} type="checkbox" defaultChecked="checked" />Sim
                          <span /></label>
                      </div>
                    </div>
                  </div>
                  <div className="composicao-item-area">
                    <div onClick={() => insertComposition()} className="btn btn-primary font-weight-bolder btn-lg btn-block m-t-11">
                      Incluir
                    </div>
                  </div>
                </div>

            <div className="table-div-header padding-horizontal area-adjust-composicao">
              <div className="table-div-header-item-produto-composicao" />
              <div className="table-div-header-item-produto-composicao">Qtd.</div>
              <div className="table-div-header-item-produto-composicao">Ingrediente</div>
              <div className="table-div-header-item-produto-composicao">Removível</div>
              <div className="table-div-header-item-produto-composicao">Ações</div>
            </div>
            <div>
              <VisibleCompositionsComponent />
            </div>


            </Tab>
            <Tab eventKey="tamanhos" title={
              <>
                <span className="nav-icon">
                  <i className="flaticon2-drop" />
                </span>
                <span className="nav-text">Tamanhos</span>
              </>
            } disabled={allowTabs}>
               <div className="area-top-header-composicao">
                  <div className="tamanhos-item-area">
                    <div className="form-group">
                    <label>Tamanho</label>
                      <input value={size['size']} onChange={(e)=> setSize( {...size, size: e.target.value }) } className="form-control form-control-solid form-small" type="text" />
                    </div>
                  </div>
                  <div className="tamanhos-item-area">
                    <div className="form-group">
                      <label>Preço</label>
                      <CurrencyInput maxLength={6} value={size['price']} precision={"2"} onChangeEvent={(e) => setSize({...size, price: e.target.value})} decimalSeparator="." className="form-control form-control-solid form-small" type="text"/>
                    </div>
                  </div>

                    <div className="form-group">
                      <select value={size['status']} onChange={(e) => setSize({...size, status: e.target.value })} className="form-control select-size-pdg">
                        <option value={'S'} data-content="<i class='fa fa-circle icone-status cor-verde'></i> Ativo">Ativo</option>
                        <option value={'O'} data-content="<i class='fa fa-circle icone-status cor-amarelo'></i> Em Falta">Em Falta</option>
                        <option value={'F'} data-content="<i class='fa fa-circle icone-status cor-vermelho'></i> Oculto">Oculto</option>
                      </select>
                    </div>

                  <div className="tamanhos-item-area">
                    <div onClick={() => insertSize()} className="btn btn-primary font-weight-bolder btn-lg btn-block m-t-11">
                      Incluir
                    </div>
                  </div>
                </div>
                <div className="table-div-header padding-horizontal area-adjust-composicao">
                  <div className="table-div-header-item-produto-tamanhos" />
                  <div className="table-div-header-item-produto-tamanhos">Tamanho</div>
                  <div className="table-div-header-item-produto-tamanhos">Preço</div>
                  <div className="table-div-header-item-produto-tamanhos">Status</div>
                  <div className="table-div-header-item-produto-tamanhos">Ações</div>
                </div>
                <SortableSizeComponent />
            </Tab>
            <Tab eventKey="variacoes" title={
              <>
                <span className="nav-icon">
                  <i className="flaticon2-drop" />
                </span>
                <span className="nav-text">Variações</span>
              </>
            } disabled={allowTabs}>
            <div className="area-top-header-composicao">
                  <div className="variacoes-item-area">
                    <div className="form-group">
                      <label>Variação Global</label>
                      <Select placeholder={"Digite o nome de um item"}
                      onChange={(e) => handleSetGlobalVariable(e[0].nome_variacao, e[0].id_variacao)}

                      labelField={'nome_variacao'}
                      valueField={'id_variacao'}
                      searchBy={'nome_variacao'}
                      className="form-control form-control-solid form-small"
                      options={variables}  />
                    </div>
                  </div>

                  <div className="variacoes-item-area">
                    <div className="form-group">
                      <label> Variação Avulsa</label>
                      <input value={detachedVariableName} onChange={(e) => handleSetDetachedVariable(e.target.value)}  className="form-control form-control-solid form-small" type="text" />
                    </div>
                  </div>

                  {/* <div className="variacoes-item-area">
                    <div className="form-group">
                      <label>TAMANHO {globalVariableId}</label>
                      <Select placeholder={"Selecione"}
                      onChange={(e) => setSizeForVariable(e[0].id_cardapio_tamanho)}
                      labelField={'nome'}
                      valueField={'id_cardapio_tamanho'}
                      searchBy={'nome'}
                      className="form-control form-control-solid form-small"
                      options={sizes}  />
                    </div>
                  </div> */}

                  <div className="variacoes-item-area">
                    <VariablesActionButtons />
                  </div>
                </div>
                <div className="table-div-header padding-horizontal area-adjust-composicao">
                  <div className="table-div-header-item-produto-variacoes" />
                  <div className="table-div-header-item-produto-variacoes">Variações</div>
                  <div className="table-div-header-item-produto-variacoes">Escolha Min./Máx</div>
                  <div className="table-div-header-item-produto-variacoes">Cálculo</div>
                  <div className="table-div-header-item-produto-variacoes">Vínculo</div>
                  <div className="table-div-header-item-produto-variacoes">Status</div>
                  <div className="table-div-header-item-produto-variacoes">Ações</div>
                </div>
              <div>
                <SortableComponentVariacoes />
              </div>


            </Tab>
            <Tab eventKey="disponibilidade" title={
              <>
                <span className="nav-icon">
                  <i className="far fa-clock" />
                </span>
                <span className="nav-text">Disponibilidade</span>
              </>
            }>
              <div className="table-div-header padding-horizontal area-adjust-composicao">
                <div className="table-div-header-item-produto-disponibilidade" />
                <div className="table-div-header-item-produto-disponibilidade">1º Período</div>
                <div className="table-div-header-item-produto-disponibilidade">2º Período</div>
              </div>
              {/* Domingo */}
              <div className="table-item padding-horizontal area-margin-composicao">
                <div className="table-item-produto-disponibilidade">
                  Domingo
                </div>
                <div className="table-item-produto-disponibilidade">
                  <label className="checkbox checkbox-square checkbox-primary m-t-3">
                    <input value={sunday_1_active} onChange={(e) => setSunday_1_active(!sunday_1_active)} type="checkbox" defaultChecked="checked" />
                    <span /></label>
                  <InputMask disabled={sunday_1_active} value={sundayInit_Hour} onChange={(e) => setSundayInit_Hour(e.target.value)} mask={"99:99"} className="form-control form-small form-disponibilidade" type="text" />
                  <span className="disponibilidade-as">às</span>
                  <InputMask disabled={sunday_1_active} value={sundayFinish_Hour} onChange={(e) => setSundayFinish_Hour(e.target.value)} mask={"99:99"} className="form-control form-small form-disponibilidade" type="text" />
                </div>
                <div className="table-item-produto-disponibilidade">
                  <label className="checkbox checkbox-square checkbox-primary m-t-3">
                    <input value={sunday_2_active} onChange={(e) => setSunday_2_active(!sunday_2_active)} type="checkbox" defaultChecked="checked" />
                    <span /></label>
                  <InputMask disabled={sunday_2_active}  value={sundayInit_Hour_2} onChange={(e) => setSundayInit_Hour_2(e.target.value)} mask={"99:99"} className="form-control form-small form-disponibilidade" type="text" />
                  <span className="disponibilidade-as">às</span>
                  <InputMask disabled={sunday_2_active}  value={sundayFinish_Hour_2} onChange={(e) => setSundayFinish_Hour_2(e.target.value)} mask={"99:99"} className="form-control form-small form-disponibilidade" type="text" />
                </div>
              </div>
              {/* Segunda */}
              <div className="table-item padding-horizontal area-margin-composicao">
                <div className="table-item-produto-disponibilidade">
                  Segunda-Feira
                </div>
                <div className="table-item-produto-disponibilidade">
                  <label className="checkbox checkbox-square checkbox-primary m-t-3">
                    <input value={monday_1_active} onChange={(e) => setMonday_1_active(!monday_1_active)} type="checkbox" defaultChecked="checked" />
                    <span /></label>
                    <InputMask disabled={monday_1_active} value={mondayInit_Hour} onChange={(e) => setMondayInit_Hour(e.target.value)} mask={"99:99"} className="form-control form-small form-disponibilidade" type="text" />
                    <span className="disponibilidade-as">às</span>
                    <InputMask disabled={monday_1_active} value={mondayFinish_Hour} onChange={(e) => setMondayFinish_Hour(e.target.value)} mask={"99:99"} className="form-control form-small form-disponibilidade" type="text" />
                </div>
                <div className="table-item-produto-disponibilidade">
                    <label className="checkbox checkbox-square checkbox-primary m-t-3">
                    <input value={monday_2_active} onChange={(e) => setMonday_2_active(!monday_2_active)} type="checkbox" defaultChecked="checked" />
                    <span /></label>
                    <InputMask disabled={monday_2_active} value={mondayInit_Hour_2} onChange={(e) => setMondayInit_Hour_2(e.target.value)} mask={"99:99"} className="form-control form-small form-disponibilidade" type="text" />
                    <span className="disponibilidade-as">às</span>
                    <InputMask disabled={monday_2_active} value={mondayFinish_Hour_2} onChange={(e) => setMondayFinish_Hour_2(e.target.value)} mask={"99:99"} className="form-control form-small form-disponibilidade" type="text" />
                </div>
              </div>
              {/* Terça */}
              <div className="table-item padding-horizontal area-margin-composicao">
                <div className="table-item-produto-disponibilidade">
                  Terça-Feira
                </div>
                <div className="table-item-produto-disponibilidade">
                    <label className="checkbox checkbox-square checkbox-primary m-t-3">
                    <input value={tuesday_1_active} onChange={(e) => setTuesday_1_active(!tuesday_1_active)} type="checkbox" defaultChecked="checked" />
                    <span /></label>
                    <InputMask disabled={tuesday_1_active} value={tuesdayInit_Hour} onChange={(e) => setTuesdayInit_Hour(e.target.value)} mask={"99:99"} className="form-control form-small form-disponibilidade" type="text" />
                    <span className="disponibilidade-as">às</span>
                    <InputMask disabled={tuesday_1_active} value={tuesdayFinish_Hour} onChange={(e) => setTuesdayFinish_Hour(e.target.value)} mask={"99:99"} className="form-control form-small form-disponibilidade" type="text" />
                </div>
                <div className="table-item-produto-disponibilidade">
                    <label className="checkbox checkbox-square checkbox-primary m-t-3">
                    <input value={tuesday_2_active} onChange={(e) => setTuesday_2_active(!tuesday_2_active)}  type="checkbox" defaultChecked="checked" />
                    <span /></label>
                    <InputMask disabled={tuesday_2_active} value={tuesdayInit_Hour_2} onChange={(e) => setTuesdayInit_Hour_2(e.target.value)} mask={"99:99"} className="form-control form-small form-disponibilidade" type="text" />
                    <span className="disponibilidade-as">às</span>
                    <InputMask disabled={tuesday_2_active} value={tuesdayFinish_Hour_2} onChange={(e) => setTuesdayFinish_Hour_2(e.target.value)} mask={"99:99"} className="form-control form-small form-disponibilidade" type="text" />
                </div>
              </div>
              {/* Quarta */}
              <div className="table-item padding-horizontal area-margin-composicao">
                <div className="table-item-produto-disponibilidade">
                  Quarta-Feira
                </div>
                <div className="table-item-produto-disponibilidade">
                    <label className="checkbox checkbox-square checkbox-primary m-t-3">
                    <input value={wednesday_1_active} onChange={(e) => setWednesday_1_active(!wednesday_1_active)} type="checkbox" defaultChecked="checked" />
                    <span /></label>
                    <InputMask disabled={wednesday_1_active} value={wednesdayInit_Hour} onChange={(e) => setWednesdayInit_Hour(e.target.value)} mask={"99:99"} className="form-control form-small form-disponibilidade" type="text" />
                    <span className="disponibilidade-as">às</span>
                    <InputMask disabled={wednesday_1_active} value={wednesdayFinish_Hour} onChange={(e) => setWednesdayFinish_Hour(e.target.value)} mask={"99:99"} className="form-control form-small form-disponibilidade" type="text" />
                </div>
                <div className="table-item-produto-disponibilidade">
                    <label className="checkbox checkbox-square checkbox-primary m-t-3">
                    <input value={wednesday_2_active} onChange={(e) => setWednesday_2_active(!wednesday_2_active)} type="checkbox" defaultChecked="checked" />
                    <span /></label>
                    <InputMask disabled={wednesday_2_active} value={wednesdayInit_Hour_2} onChange={(e) => setWednesdayInit_Hour_2(e.target.value)} mask={"99:99"} className="form-control form-small form-disponibilidade" type="text" />
                    <span className="disponibilidade-as">às</span>
                    <InputMask disabled={wednesday_2_active} value={wednesdayFinish_Hour_2} onChange={(e) => setWednesdayFinish_Hour_2(e.target.value)} mask={"99:99"} className="form-control form-small form-disponibilidade" type="text" />
                </div>
              </div>
              {/* Quinta */}
              <div className="table-item padding-horizontal area-margin-composicao">
                <div className="table-item-produto-disponibilidade">
                  Quinta-Feira
                </div>
                <div className="table-item-produto-disponibilidade">
                    <label className="checkbox checkbox-square checkbox-primary m-t-3">
                    <input value={thursday_1_active} onChange={(e) => setThursday_1_active(!thursday_1_active)}  type="checkbox" defaultChecked="checked" />
                    <span /></label>
                    <InputMask disabled={thursday_1_active} value={thursdayInit_Hour} onChange={(e) => setThursdayInit_Hour(e.target.value)} mask={"99:99"} className="form-control form-small form-disponibilidade" type="text" />
                    <span className="disponibilidade-as">às</span>
                    <InputMask disabled={thursday_1_active} value={thursdayFinish_Hour} onChange={(e) => setThursdayFinish_Hour(e.target.value)} mask={"99:99"} className="form-control form-small form-disponibilidade" type="text" />
                </div>
                <div className="table-item-produto-disponibilidade">
                    <label className="checkbox checkbox-square checkbox-primary m-t-3">
                    <input value={thursday_2_active} onChange={(e) => setThursday_2_active(!thursday_2_active)}  type="checkbox" defaultChecked="checked" />
                    <span /></label>
                    <InputMask disabled={thursday_2_active}  value={thursdayInit_Hour_2} onChange={(e) => setThursdayInit_Hour_2(e.target.value)} mask={"99:99"} className="form-control form-small form-disponibilidade" type="text" />
                    <span className="disponibilidade-as">às</span>
                    <InputMask disabled={thursday_2_active}  value={thursdayFinish_Hour_2} onChange={(e) => setThursdayFinish_Hour_2(e.target.value)} mask={"99:99"} className="form-control form-small form-disponibilidade" type="text" />
                </div>
              </div>
              {/* Sexta */}
              <div className="table-item padding-horizontal area-margin-composicao">
                <div className="table-item-produto-disponibilidade">
                  Sexta-Feira
                </div>
                <div className="table-item-produto-disponibilidade">
                    <label className="checkbox checkbox-square checkbox-primary m-t-3">
                    <input value={friday_1_active} onChange={(e) => setFriday_1_active(!friday_1_active)} type="checkbox" defaultChecked="checked" />
                    <span /></label>
                    <InputMask disabled={friday_1_active} value={fridayInit_Hour} onChange={(e) => setFridayInit_Hour(e.target.value)} mask={"99:99"} className="form-control form-small form-disponibilidade" type="text" />
                    <span className="disponibilidade-as">às</span>
                    <InputMask disabled={friday_1_active} value={fridayFinish_Hour} onChange={(e) => setFridayFinish_Hour(e.target.value)} mask={"99:99"} className="form-control form-small form-disponibilidade" type="text" />
                </div>
                <div className="table-item-produto-disponibilidade">
                    <label className="checkbox checkbox-square checkbox-primary m-t-3">
                    <input value={friday_2_active} onChange={(e) => setFriday_2_active(!friday_2_active)} type="checkbox" defaultChecked="checked" />
                    <span /></label>
                    <InputMask disabled={friday_2_active} value={fridayInit_Hour_2} onChange={(e) => setFridayInit_Hour_2(e.target.value)} mask={"99:99"} className="form-control form-small form-disponibilidade" type="text" />
                    <span className="disponibilidade-as">às</span>
                    <InputMask disabled={friday_2_active} value={fridayFinish_Hour_2} onChange={(e) => setFridayFinish_Hour_2(e.target.value)} mask={"99:99"} className="form-control form-small form-disponibilidade" type="text" />
                </div>
              </div>
              {/* Sábado */}
              <div className="table-item padding-horizontal area-margin-composicao">
                <div className="table-item-produto-disponibilidade">
                  Sábado
                </div>
                <div className="table-item-produto-disponibilidade">
                    <label className="checkbox checkbox-square checkbox-primary m-t-3">
                    <input value={saturday_1_active} onChange={(e) => setSaturday_1_active(!saturday_1_active)}  type="checkbox" defaultChecked="checked" />
                    <span /></label>
                    <InputMask disabled={saturday_1_active} value={saturdayInit_Hour} onChange={(e) => setSaturdayInit_Hour(e.target.value)} mask={"99:99"} className="form-control form-small form-disponibilidade" type="text" />
                    <span className="disponibilidade-as">às</span>
                    <InputMask disabled={saturday_1_active} value={saturdayFinish_Hour} onChange={(e) => setSaturdayFinish_Hour(e.target.value)} mask={"99:99"} className="form-control form-small form-disponibilidade" type="text" />
                </div>
                <div className="table-item-produto-disponibilidade">
                    <label className="checkbox checkbox-square checkbox-primary m-t-3">
                    <input value={saturday_2_active} onChange={(e) => setSaturday_2_active(!saturday_2_active)}  type="checkbox" defaultChecked="checked" />
                    <span /></label>
                    <InputMask disabled={saturday_2_active} value={saturdayInit_Hour_2} onChange={(e) => setSaturdayInit_Hour_2(e.target.value)} mask={"99:99"} className="form-control form-small form-disponibilidade" type="text" />
                    <span className="disponibilidade-as">às</span>
                    <InputMask disabled={saturday_2_active} value={saturdayFinish_Hour_2} onChange={(e) => setSaturdayFinish_Hour_2(e.target.value)} mask={"99:99"} className="form-control form-small form-disponibilidade" type="text" />
                </div>
              </div>

            </Tab>
          </Tabs>
         </Modal.Body>
          <Modal.Footer>
            <ProductButtonActions id={productId}/>
          </Modal.Footer>
        </Modal>

        <Modal size={'xl'} show={productVariationVisibility} onHide={handleCategoryModalClose}>
          <Modal.Header closeButton>
            <Modal.Title><h5 className="modal-title">Variações</h5></Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <div className="area-top-header-variacao">
            <div className="row">
              <div className="col-7">
                <div className="form-group">
                  <label>Nome da Variação {detachedVariableName}</label>
                  <input value={detachedVariableName} onChange={(e) => setDetachedVariableName(e.target.value)} className="form-control form-control-solid form-small" type="text" />
                </div>
              </div>
              <div className="col-5">
                <div className="form-group">
                  <label>Vínculo</label>
                  <input className="form-control form-control-solid form-small" type="text" />
                </div>
              </div>
              <div className="col-3">
                <div className="form-group">
                  <label>Escolha Mín.</label>
                  <input value={minChoice} onChange={(e) => setMinChoice(e.target.value)} className="form-control form-control-solid form-small" type="text" />
                </div>
              </div>
              <div className="col-3">
                <div className="form-group">
                  <label>Escolha Máx.</label>
                  <input value={maxChoice} onChange={(e) => setMaxChoice(e.target.value)} className="form-control form-control-solid form-small" type="text" />
                </div>
              </div>
              <div className="col-2 col-calculo-preco">
                <div className="form-group">
                  <label>Cálculo do Preço</label>
                  <select value={priceCalc} onChange={(e) => setPriceCalc(e.target.value)} className="form-control select-size-pdg">
                    <option value={'mp'}>Maior Preço</option>
                    <option value={'pm'}>Menor Preço</option>
                    <option value={'mm'}>Média Preços</option>
                    <option value={'st'}>Soma Total</option>
                    <option value={'in'}>Incremental</option>
                  </select>
                </div>
              </div>
              <div className="col-2">
                <div onClick={() => saveDetachedVariable()} className="btn btn-primary font-weight-bolder btn-lg btn-block m-t-11">
                  Salvar
                </div>
              </div>
              <hr style={{width: '100%'}} />
              <div className="col-3">
                <div className="form-group">
                  <label>Item (Estoque)</label>
                  <input className="form-control form-control-solid form-small" type="text" />
                </div>
              </div>
              <div className="col-3">
                <div className="form-group">
                  <label>Item Avulso</label>
                  <input className="form-control form-control-solid form-small" type="text" />
                </div>
              </div>
              <div className="col-3">
                <div className="form-group">
                  <label>Preço</label>
                  <input className="form-control form-control-solid form-small" type="text" />
                </div>
              </div>
              <div className="col-3">
                <div data-toggle="modal" data-target="#ModalProdutoVariacoes" className="btn btn-primary font-weight-bolder btn-lg btn-block m-t-11">
                  Incluir
                </div>
              </div>
            </div>
          </div>
          <div className="table-div-header padding-horizontal area-adjust-composicao">
            <div className="table-div-header-item-produto-variacoes-modal" />
            <div className="table-div-header-item-produto-variacoes-modal">Item</div>
            <div className="table-div-header-item-produto-variacoes-modal">Preço</div>
            <div className="table-div-header-item-produto-variacoes-modal">Status</div>
            <div className="table-div-header-item-produto-variacoes-modal">Ações</div>
          </div>
          <div>
            <SortableComponentEstoqueVariacoes />
          </div>

          </Modal.Body>
          <Modal.Footer>
              <button onClick={()=> setProductVariationVisibility(false)} type="button" className="btn btn-light-primary font-weight-bold">Fechar</button>
              <button type="button" className="btn btn-primary font-weight-bold">Salvar</button>
          </Modal.Footer>
        </Modal>

      {/* ----------------------------------------------- FIM MODAL ------------------------------------------------------------ */}
      {/* ------------------------------------------------------------------------------------------------------------------ */}
      {/* ------------------------------------------------------------------------------------------------------------------ */}
      {/* ------------------------------------------------------------------------------------------------------------------ */}
      {/* ------------------------------------------------------------------------------------------------------------------ */}
      {/* ------------------------------------------------------------------------------------------------------------------ */}
        </>
    )
};
export default withRouter(ProductsOld);
