import * as S from "./styles";

export const ChatMessage = ({ message, date, type }) => {
  const formattedHour = () => {
    const parseDate = new Date(date);

    const hour = parseDate.getHours();
    const minutes = parseDate.getMinutes();

    if (minutes < 10) {
      return `${hour}:0${minutes}`;
    }

    return `${hour}:${minutes}`;
  };

  if (!message || !date) {
    return null;
  }

  return (
    <S.Container type={type}>
      <p>{message}</p>
      <span>{formattedHour()}</span>
    </S.Container>
  );
};
