import React from 'react';
import AsyncSelect from 'react-select/async';

import * as S from './styles';
import { useProduct } from './useProduct';

const customStyles = {
    control: (provided) => ({
        ...provided,
        fontFamily: 'Poppins',
        borderRadius: 8,
        backgroundColor: '#F3F6F9',
        borderColor: "transparent",
        height: 42,
        '&:hover': {
            border: "1px solid transparent",
        },
    }),
    option: (styles, { isFocused }) => ({
        ...styles,
        fontFamily: 'Poppins',
        backgroundColor: isFocused ? "#F64E60" : null,
        color: isFocused ? "#fff" : null
    }),
};

const findProduct = (data, id) => {
  return data.find((i) => i.id_produto === id);
};

export const Select = React.forwardRef(({ label = "", options, selectedProduct, onChange, setOpen, setProductToReplace, ...rest }, ref) => {
  const { handleSelectData, loading, selectData } = useProduct();
  const handleChange = ({ value }) => {
    const product = findProduct(selectData, value);

    if (!selectedProduct.id_cardapio) {
      const onChangeData = {
        ...selectedProduct,
        id_produto: product.id_produto,
        nome_prod: product.nome_produto,
        descricao: product.descricao,
        foto: product.foto,
      };

      onChange(onChangeData);
    } else {
      setOpen(true);
      setProductToReplace(product);
    }
  };


  return (
    <>
      <S.Container>
          <S.Label>{label}</S.Label>
          <AsyncSelect
              options={options}
              ref={ref}
              styles={customStyles}
              onChange={handleChange}
              cacheOptions
              noOptionsMessage={() => "Nenhum produto encontrado"}
              isLoading={loading}
              defaultOptions={selectData}
              loadOptions={handleSelectData}
              theme={theme => ({
                  ...theme,
                  colors: {
                      ...theme.colors,
                      primary50: 'transparent',
                      primary: '#E8ECF0',
                  },
              })}
              {...rest}
          />
      </S.Container>

    </>
    );
});

export default Select;
