import React, { useEffect, useCallback, useState, useRef } from "react";
import { v4 as uuidV4 } from "uuid";
import { ChatService } from "../../services/ChatService";

import { IoMdClose } from "react-icons/io";
import { AiOutlineExpandAlt } from "react-icons/ai";
import { FiMinimize2 } from "react-icons/fi";

import io from "socket.io-client";

import * as S from "./styles";

import { createPortal } from "react-dom";
import { ChatMessage } from "../ChatMessage";
import { useChat } from "../../hooks/useChat";
import { BsChevronDown } from "react-icons/bs";

const DisplayModeIcon = {
  expand: <FiMinimize2 color="#FF3333" size={20} />,
  minimize: <AiOutlineExpandAlt color="#FF3333" size={20} />,
};

export function Chat({ clientName, token, orderInfo }) {
  const [socket] = useState(
    io(process.env.REACT_APP_SOCKETURL, {
      transports: ["websocket"],
      jsonp: false,
    })
  );

  const [displayMode, setDisplayMode] = useState("expand");

  const [conversationId, setConversationId] = useState("");
  const [messages, setMessages] = useState([]);
  const [textInput, setTextInput] = useState("");
  const [messageReceived, setMessageReceived] = useState([]);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [sendError, setSendError] = useState(false);
  const [isNotification, setIsNotification] = useState(false);

  const [currentPositionScroll, setCurrentPositionScroll] = useState(0);
  const [isDisplayBackButton, setIsDisplayBackButton] = useState(false);

  const { closeChat, audio } = useChat();

  const messagesListRef = useRef(null);
  const mainRef = useRef(null);

  useEffect(() => {
    if (displayMode === "minimize") {
      setIsNotification(true);
      audio.play();
    }
    setMessages((state) => [...state, messageReceived]);
    scrollToBottom({ smooth: false });
  }, [messageReceived]);

  useEffect(() => {
    createSocket();
  }, [socket]);

  useEffect(() => {
    if (!loading) {
      scrollToBottom({ smooth: false });
    }
  }, [loading]);

  const scrollToBottom = ({ smooth }) => {
    if (smooth) {
      messagesListRef.current?.scrollIntoView({
        block: "end",
        inline: "nearest",
        behavior: "smooth",
      });
      return;
    }
    messagesListRef.current?.scrollIntoView({
      block: "end",
      inline: "nearest",
    });
  };

  const handleChatScroll = (event) => {
    const currentPosition = event.currentTarget.scrollTop;
    const fullAreaScroll =
      mainRef.current.scrollHeight - mainRef.current.clientHeight;

    setCurrentPositionScroll(currentPosition);

    if (currentPositionScroll > currentPosition) {
      setIsDisplayBackButton(true);
      return;
    }

    if (Math.round(currentPosition) === fullAreaScroll) {
      setIsDisplayBackButton(false);
    }
  };

  async function createSocket() {
    if (socket.connected) {
      onSocketConnection();
      return;
    }

    socket.on("connect", () => {
      onSocketConnection();
    });
  }

  async function onSocketConnection() {
    setLoading(true);

    if (!socket.connected) {
      setError("Ocorreu um erro ao abrir seu chat :(");
      return;
    }

    const formConnection = {
      merchant_socket_id: socket.id,
      id_usuario: orderInfo.id_usuario,
      id_estabelecimento: orderInfo.id_estabelecimento,
      id_pedido: orderInfo.id_pedido,
    };

    const response = await ChatService.establishConnection(formConnection);

    if (!response.ok) {
      return;
    }

    if (response.data.data.conversation_id) {
      setConversationId(response.data.data.conversation_id);
      setMessages(response.data.data.messages);

      setLoading(false);
      socket.on("client-merchant-message", function (message) {
        setMessageReceived(message);
      });
      return;
    }

    setLoading(false);
  }

  const sendSocket = useCallback(
    async (newMessage) => {
      setSendError(false);

      try {
        const responseMessageEmitted = await socket.emit(
          "merchant-client-message",
          {
            conversation_id: conversationId,
            message: newMessage,
          }
        );

        if (responseMessageEmitted.error) {
          setSendError(true);
          return false;
        }

        return true;
      } catch {
        setSendError(true);
      }
    },
    [conversationId, socket]
  );

  const toggleOpen = () => {
    if (displayMode === "expand") {
      setDisplayMode("minimize");
      return;
    }

    setIsNotification(false);
    setDisplayMode("expand");
  };

  const handleClose = () => {
    closeChat(orderInfo.id_pedido);
  };

  const renderMessagesShimmer = useCallback(() => {
    return (
      <>
        <S.MessageContainer type={"client"} className="contentMessage">
          <S.MessageShimmer>
            <S.Shimmer />
          </S.MessageShimmer>
        </S.MessageContainer>
        <S.MessageContainer type={"company"} className="contentMessage">
          <S.MessageShimmer>
            <S.Shimmer />
          </S.MessageShimmer>
        </S.MessageContainer>
        <S.MessageContainer type={"client"} className="contentMessage">
          <S.MessageShimmer>
            <S.Shimmer />
          </S.MessageShimmer>
        </S.MessageContainer>
        <S.MessageContainer type={"company"} className="contentMessage">
          <S.MessageShimmer>
            <S.Shimmer />
          </S.MessageShimmer>
        </S.MessageContainer>
      </>
    );
  }, []);

  const getMessageType = useCallback(
    (userId) => {
      if (userId === orderInfo.id_usuario) {
        return "client";
      }

      return "company";
    },
    [orderInfo.id_usuario]
  );

  const onSend = useCallback(
    async (newMessage) => {
      const formattedNewMessage = {
        text: newMessage,
        user: {
          _id: orderInfo.id_estabelecimento,
        },
        createdAt: new Date().toISOString(),
        _id: uuidV4(),
      };

      const isSend = await sendSocket(formattedNewMessage);

      if (!isSend) {
        return;
      }

      setMessages((state) => [...state, formattedNewMessage]);
      setTextInput("");
    },
    [orderInfo.id_estabelecimento, sendSocket]
  );

  const handleOnChangeInput = (inputText) => {
    if (inputText.length <= 400) {
      setTextInput(inputText);
    }
  };

  const handleSendMessage = async () => {
    if (!textInput) {
      return;
    }

    await onSend(textInput);
    scrollToBottom({ smooth: false });
  };

  const handleKeyDownToSubmitFilter = (event) => {
    if (event.code === "Enter") {
      handleSendMessage();
    }
  };

  const renderMessages = useCallback(() => {
    return (
      <>
        {messages.map((messageChat) => (
          <S.MessageContainer
            type={getMessageType(messageChat?.user?._id)}
            className="contentMessage"
          >
            <ChatMessage
              message={messageChat.text}
              date={messageChat.createdAt}
              type={getMessageType(messageChat?.user?._id)}
            />
          </S.MessageContainer>
        ))}
      </>
    );
  }, [getMessageType, messages]);

  if (error) {
    return null;
  }

  return createPortal(
    <S.Container>
      <S.Header notification={isNotification}>
        <span>
          {clientName} #{token}
        </span>

        <aside>
          <button onClick={toggleOpen}>{DisplayModeIcon[displayMode]}</button>
          {displayMode === "expand" && (
            <button onClick={handleClose}>
              <IoMdClose color="#FF3333" size={20} />
            </button>
          )}
        </aside>
      </S.Header>

      <S.Content visible={displayMode === "expand"}>
        <S.Main ref={mainRef} onScroll={handleChatScroll}>
          {loading && renderMessagesShimmer()}
          {renderMessages()}
          {isDisplayBackButton && (
            <S.CurrentMessageButton
              onClick={() => scrollToBottom({ smooth: true })}
            >
              <BsChevronDown color="#ff3333" size={20} />
            </S.CurrentMessageButton>
          )}
          <S.GhostScroll ref={messagesListRef} />
        </S.Main>

        <S.Footer>
          <S.InputWrapper>
            <input
              value={textInput}
              type="text"
              placeholder="Digite sua mensagem"
              onFocus={() => setIsNotification(false)}
              onChange={(e) => handleOnChangeInput(e.target.value)}
              onKeyDown={handleKeyDownToSubmitFilter}
            />
            <button onClick={handleSendMessage}>Enviar</button>
          </S.InputWrapper>
          <S.ContentCounter>
            <p>{textInput.length}/400</p>
          </S.ContentCounter>
        </S.Footer>
      </S.Content>
    </S.Container>,
    document.getElementById("chat-root")
  );
}
