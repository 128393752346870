import React, { useState, useEffect } from 'react';
import Api from '../../../services/api';
import { withRouter } from "react-router";

import Swal from "sweetalert2";
import { useToasts } from 'react-toast-notifications';
import LoadingOverlay from 'react-loading-overlay';
import CpfCnpjInput from 'react-cpf-cnpj-input';
import Select from 'react-select';

import InputMask from 'react-input-mask';
import "react-datepicker/dist/react-datepicker.css";
import DatePicker, { registerLocale } from "react-datepicker";
import pt from "date-fns/locale/pt-BR"; 
registerLocale("pt-BR", pt);

const PagamentoOnline = () => {
  const { addToast } = useToasts();
  const [ caProcessa, setCaProcessa ] = useState(false);
  const [ estabelecimento, setEstabelecimento ] = useState([]);

  useEffect(() => {
    getEstabelecimento();
  }, []);

  function getEstabelecimento(){
    var data = {
      id_estabelecimento: localStorage.getItem('id_estabelecimento')
    };

    Api.post('estabelecimento/listarStatusPagOn', data).then(rps => {
      
      setEstabelecimento(rps.data.obj);
    });
  }

  async function updateStoreStatus(status) {
  
    var pgtoStatus;

    if (status === true) {
      pgtoStatus = 1;
    } else {
      pgtoStatus = 0;
    }

    console.log(pgtoStatus);

    const response = await Api.post("pagamentoonline/mudarStatus", {
      id_estabelecimento: localStorage.getItem('id_estabelecimento'),
      aceita_pgto_online: pgtoStatus,
    });
    if (response.data.status !== undefined && response.data.status === true) {
      addToast(response.data.mensagem, {
        appearance: "success",
        autoDismiss: true,
        autoDismissTimeout: 2000,
      });
      getEstabelecimento();      
    } else {
      Swal.fire({
        title: "Atenção!",
        icon: "info",
        html: response.data.mensagem,
        showCloseButton: true,
        showCancelButton: false,
      });
    }
  }
      
  return (
    <>

  <div className="subheader espaco-header-pedidos subheader-transparent" id="kt_subheader">
    <div className="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
      {/*begin::Details*/}
      <div className="d-flex align-items-center flex-wrap mr-2 mt-7">
        {/*begin::Title*/}
        <h5 className="text-dark font-weight-bold mr-5">Financeiro</h5>
        {/*end::Title*/}
        {/*begin::Separator*/}
        <div className="subheader-separator subheader-separator-ver mb-2 bg-gray-200" />
        {/*end::Separator*/}
        {/*begin::Title*/}
        <h6 className="text-dark mr-5 ml-5">Pagamento Online</h6>
        {/*end::Title*/}
        {/*begin::Title*/}
        
        {/*end::Title*/}
      </div>
      {/*end::Details*/}
      {/*begin::Toolbar*/}
      <div className="d-flex align-items-center mt-7">
        {estabelecimento?.aceita_pgto_online === "0" && <span class="label label-warning label-inline mr-2">Não Aceita Pagamento Online</span>}
        {estabelecimento?.aceita_pgto_online === "1" && <span class="label label-success label-inline mr-2">Aceita Pagamento Online</span>}
        <span className="label label-danger label-inline ml-2">Dúvidas ligue: (27) 4042-1580</span>
      </div>
      {/*end::Toolbar*/}
    </div>
  </div>
        
  {/*begin::Entry*/}
  <div className="d-flex flex-column-fluid mb-10">
    {/*begin::Container*/}
    <div className="container">
      <div className="card card-custom">
        <div className="ml-10 mr-10 mt-10 mb-10">   
        {/*begin::Group - Pagamento Online - Cadastro*/}


        <span className="switch switch-danger switch-md switch-icon">
          <label>
            <input
              checked={estabelecimento?.aceita_pgto_online == "1"}
              type="checkbox"
              onClick={(e) => updateStoreStatus(e.target.checked)}
              name="select"
            />
            <span></span>
          </label>
        </span>
              
        {/*end::Group - Pagamento Online - Cadastro*/}     
        </div>
      </div>
    </div>
  </div>
                  
            
  </>         
        
  );
}

export default withRouter(PagamentoOnline);                  