import React, { useState, useEffect, useCallback } from "react";

import Swal from "sweetalert2";
import { useToasts } from "react-toast-notifications";

//Socket.io
import io from "socket.io-client";

import Api from "../../services/api";

export default function Header({ storeDetails, testeLucas, storeFaturas }) {
  const { addToast } = useToasts();

  const [statusText, setStatusText] = useState();
  const [merchantName, setMerchantName] = useState('');
  const [merchantLogo, setMerchantLogo] = useState(null);

  const getStoreStatus = useCallback(async () => {
    const estabelecimento = await storeDetails();
    storeFaturas();

    if (estabelecimento && estabelecimento["fechado"]) {
      setStatusText("Loja fechada");
    } else {
      setStatusText("Loja aberta");
    }

    if (estabelecimento["logotipo"] != null) {
      setMerchantLogo(estabelecimento["logotipo"]);
    }

    setMerchantName(estabelecimento.nome);
  }, [storeDetails]);

  async function updateStoreStatus(status) {
    if (status) {
      setStatusText("Loja aberta");
    } else {
      setStatusText("Loja fechada");
    }
    const response = await Api.post("estabelecimento/mudaAberturaFechamento", {
      fechado: status ? "N" : "S",
    });
    if (response.data.status !== undefined && response.data.status === true) {
      addToast(response.data.mensagem, {
        appearance: "success",
        autoDismiss: true,
        autoDismissTimeout: 2000,
      });
    } else {
      Swal.fire({
        title: "Atenção!",
        icon: "info",
        html: response.data.mensagem,
        showCloseButton: true,
        showCancelButton: false,
      });
    }
  }

  function openConnectionWithSocket() {
    const channel = localStorage.getItem("canal_socket");
    const url = localStorage.getItem("url_socket");
    const socket = io(url);
    socket.on("connect", (data) => {
      socket.emit("room", channel);
    });
    socket.on("disconnect", (data) => { });
    socket.on("loja", (data) => {
      if (data.fechado) {
        setStatusText("Loja fechada");
      } else {
        setStatusText("Loja aberta");
      }
    });
  }

  useEffect(() => {
    getStoreStatus();
  }, [getStoreStatus]);

  useEffect(() => {
    openConnectionWithSocket();
  }, []);

  return (
    <>
      <div id="kt_header" className="header header-fixed" >
        {/*begin::Container*/}
        <div className="container items-header" >
          {/*begin::Left*/}
          <div className="d-flex align-items-center">
            {/*begin::Aside Toggle*/}
            <button
              onClick={(e) => {
                testeLucas();
              }}
              className="btn btn-icon aside-toggle ml-n3 mr-10"
            >
              <span className="svg-icon svg-icon-xxl svg-icon-dark-75 icon-menu-ajust">
                {/*begin::Svg Icon | path:assets/media/svg/icons/Text/Align-left.svg*/}
                <i className="icon-xl fas fa-align-justify" />
              </span>
            </button>
            {/*end::Aside Toggle*/}
            {/*begin::Logo*/}
            <a href="index.html">
              {Number(localStorage.getItem("pedeai_id")) ? (
                <img
                  alt="Logo pede.ai"
                  src="assets/media/logos/pedeai.png"
                  className="logo-sticky max-h-45px"
                />
              ) : (
                <img
                  alt="Logo"
                  src="assets/media/logos/aiboo.png"
                  className="logo-sticky max-h-45px"
                />
              )}
            </a>
            {/*end::Logo*/}
          </div>
          {/*end::Left*/}
          <div className="d-flex ">
            <a className="dados-empresa">
              {merchantLogo && (
                <img alt="Logo" src={merchantLogo} className="logo-no-menu" />
              )}
              <span className="nome-empresa">{merchantName}</span>
            </a>
          </div>

          {/*begin::Topbar*/}
          <div className="topbar" >
            <div className="topbar-item">
              <div className="botao-switch">
                <span className="switch switch2 switch-sm">
                  <label>
                    <span className="texto-aberto">{statusText}</span>
                    <input
                      checked={statusText === "Loja aberta"}
                      type="checkbox"
                      onClick={(e) => updateStoreStatus(e.target.checked)}
                      name="select"
                    />
                    <span style={{ marginTop: "5px", marginRight: "5px" }} />
                  </label>
                </span>
              </div>
            </div>
          </div>
        </div>
        {/*end::Container*/}
      </div>
    </>
  );
}
