import React from "react";
import { Modal } from "react-bootstrap";
import Select from "react-select";

import DisponibilidadeComp from "../../disponibilidade";

// import { Container } from './styles';

function ModalCategory({ modalCategorias, salvarCategoria, handleCloseCategorias, formCategorias, setFormCategorias, statusVar, setStringDisponibilidadeCategoria }) {
  return (
    <>
      <Modal
        size={"lg"}
        show={modalCategorias}
        onHide={() => handleCloseCategorias()}
      >
        <Modal.Header>
          <Modal.Title>{formCategorias?.titulo}</Modal.Title>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={(e) => {
              handleCloseCategorias();
            }}
          >
            <i aria-hidden="true" className="ki ki-close" />
          </button>
        </Modal.Header>
        <div>
          <ul className="nav nav-tabs nav-bold nav-tabs-line p-l-25">
            <li className="nav-item">
              <a
                className="nav-link active"
                data-toggle="tab"
                href="#informacoes-categoria"
              >
                <span className="nav-icon">
                  <i className="fab fa-wpforms" />
                </span>
                <span className="nav-text">Informações</span>
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                data-toggle="tab"
                href="#disponibilidade-categoria"
              >
                <span className="nav-icon">
                  <i className="far fa-clock" />
                </span>
                <span className="nav-text">Disponibilidade</span>
              </a>
            </li>
          </ul>
          <div className="modal-body">
            <div className="tab-content">
              <div
                className="tab-pane fade show active"
                id="informacoes-categoria"
                role="tabpanel"
                aria-labelledby="informacoes"
              >
                <div className="form-group">
                  <label>Tipo - (em breve)</label>
                  <select disabled={true} className="form-control">
                    <option>Produto Normal</option>
                    <option>Pizza</option>
                  </select>
                </div>
                <div className="form-group">
                  <label>Nome da Categoria</label>
                  <input
                    value={formCategorias?.nome_categoria}
                    onChange={(e) => {
                      setFormCategorias({
                        ...formCategorias,
                        nome_categoria: e.target.value,
                      });
                    }}
                    type="text"
                    className="form-control"
                    placeholder
                  />
                </div>
                <div className="form-group">
                  <label>Status</label>
                  <Select
                    options={statusVar}
                    value={statusVar.find(
                      (x) => x.value === formCategorias?.status
                    )}
                    onChange={(event) => {
                      setFormCategorias({
                        ...formCategorias,
                        status: event.value,
                      });
                    }}
                  />
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="disponibilidade-categoria"
                role="tabpanel"
                aria-labelledby="disponibilidade"
              >
                <p className="font-size-h5">
                  Aqui você define em que momentos os clientes poderão comprar
                  itens dessa categoria
                </p>
                <br />
                <DisponibilidadeComp
                  disponibilidadeOriginalObj={formCategorias?.disponibilidade}
                  setDisponibilidadeString={setStringDisponibilidadeCategoria}
                />
              </div>
            </div>
          </div>
        </div>

        <Modal.Footer>
          <button
            type="button"
            onClick={(e) => {
              handleCloseCategorias();
            }}
            className="btn btn-light-primary font-weight-bold"
            data-dismiss="modal"
          >
            Fechar
          </button>
          <button
            type="button"
            onClick={(e) => {
              salvarCategoria();
            }}
            className="btn btn-primary font-weight-bold"
          >
            Salvar
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ModalCategory;
