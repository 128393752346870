import { createContext, useState, useEffect } from "react";

const UpdateContext = createContext({});

export const UpdateProvider = ({ children }) => {
  const [text, setText] = useState("");
  const [progressPercent, setProgressPercent] = useState(0);
  const [updateAvailable, setUpdateAvailable] = useState(false);

  useEffect(() => {
    if (window.electron) {
      window.electron?.receive("update-available", (updateAvailable) => {
        console.log(updateAvailable);
        setUpdateAvailable(updateAvailable);
      });

      window.electron?.receive("update-message", (text) => {
        setText(text);
      });

      window.electron?.receive("update-progress", (progressObj) => {
        setProgressPercent(Math.round(progressObj.percent));
      });
    }
  }, []);

  return (
    <UpdateContext.Provider value={{ text, progressPercent, updateAvailable }}>
      {children}
    </UpdateContext.Provider>
  );
};

export default UpdateContext;
