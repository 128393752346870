import React, { useState, useEffect } from 'react';
import Api from '../../services/api';
import { withRouter } from "react-router";
import Swal from "sweetalert2";

import { useToasts } from 'react-toast-notifications';

import impressoras from '../../utils/impressora'
//import { ipcRenderer } from 'electron';

//const ipcRenderer = window.require("electron").ipcRenderer;  //contextIsolation: false
//var fs = require("fs")  //contextIsolation: false

const ConfiguracoesImpressoras = () => {
  const { addToast } = useToasts();

  const [optImpressoras, setOptImpressoras] = useState([]);
  const [categorias, setCategorias] = useState([]);
  const [defaultPrinter, setDefaultPrinter] = useState(null);

  const [impressaoLayout, setImpressaoLayout] = useState("1");
  const [impressorasCaixa, setImpressorasCaixa] = useState([0]);
  const [impressaoProducao, setImpressaoProducao] = useState(false);
  const [impressaoEstabelecimento, setImpressaoEstabelecimento] = useState(false);
  const [impressaoCliente, setImpressaoCliente] = useState(false);
  const [impressaoItemAgrupado, setImpressaoItemAgrupado] = useState(false);
  const [impressorasProducao, setImpressorasProducao] = useState([]);
  const [autoAceiteImprimir, setAutoAceiteImprimir] = useState(false);
  const [qtdeCopia, setQtdeCopia] = useState("1");
  const [metodoImpressao, setMetodoImpressao] = useState("aiboo");
  const [larguraMaximaPagina, setLarguragMaximaPagina] = useState("1");
  const [saltoLinhaInicio, setSaltoLinhaInicio] = useState("1");
  const [margemEsquerda, setMargemEsquerda] = useState("1");
  const [margemDireita, setMargemDireita] = useState("1");
  const [nomeFonte, setNomeFonte] = useState("1");
  const [tamanhoFonte, setTamanhoFonte] = useState("1");
  const [delay, setDelay] = useState("1000");

  //qz
  const [qzCertificate, setQzCertificate] = useState("");
  const [qzPrivate, setQzPrivate] = useState("");

  const [mudou, setMudou] = useState(false);

  useEffect(() => {
    getDefaults();
    getCategories();
    getAtualizarConfiguracoes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getAtualizarConfiguracoes() {
    Api.get("estabelecimentosimpressao/listar").then((rps) => {
      if (rps.data.status !== undefined && rps.data.status === true && rps.data.obj.sincronizado === 'N') {
        Swal.fire({
          icon: "question",
          title: 'Confirmação',
          html: 'A equipe Aiboo, sugere sincronizar as configurações de impressão com a nuvem! Deseja aceitar?',
          showCancelButton: true,
          confirmButtonText: `Sim, Confirmar!`,
          cancelButtonText: 'Não, Cancelar.'
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {

            Api.get('estabelecimentosimpressao/atualizar').then(rps => {

              getAtualizarConfiguracoes();
            })
          }
        })
      }
    });
  }

  function lucasLindo() {
    //alert("oi");
    console.log(window.electron);

    //console.log(ipcRenderer.sendSync("impressoras", {type: 'list'})) // //contextIsolation: false
  }

  function getDefaults() {
    let imp;
    if (localStorage.getItem('impressora')) {
      imp = localStorage.getItem('impressora');
      setDefaultPrinter(imp);
    }
    if (localStorage.getItem('delay')){
      imp = localStorage.getItem('delay');
      setDelay(imp);
    }
    if (localStorage.getItem('impressaoLayout')) {
      imp = localStorage.getItem('impressaoLayout');
      setImpressaoLayout(imp);
    }
    if (localStorage.getItem('qtdeCopia')) {
      imp = localStorage.getItem('qtdeCopia');
      setQtdeCopia(imp);
    }
    if (localStorage.getItem('larguraMaximaPagina')) {
      imp = localStorage.getItem('larguraMaximaPagina');
      setLarguragMaximaPagina(imp);
    }
    if (localStorage.getItem('metodoImpressao')) {
      imp = localStorage.getItem('metodoImpressao');
      setMetodoImpressao(imp);
    }
    if (localStorage.getItem('saltoLinhaInicio')) {
      imp = localStorage.getItem('saltoLinhaInicio');
      setSaltoLinhaInicio(imp);
    }
    if (localStorage.getItem('margemEsquerda')) {
      imp = localStorage.getItem('margemEsquerda');
      setMargemEsquerda(imp);
    }
    if (localStorage.getItem('margemDireita')) {
      imp = localStorage.getItem('margemDireita');
      setMargemDireita(imp);
    }
    if (localStorage.getItem('nomeFonte')) {
      imp = localStorage.getItem('nomeFonte');
      setNomeFonte(imp);
    }
    if (localStorage.getItem('tamanhoFonte')) {
      imp = localStorage.getItem('tamanhoFonte');
      setTamanhoFonte(imp);
    }
    if (localStorage.getItem('impressorasCaixa')) {
      imp = localStorage.getItem('impressorasCaixa');
      setImpressorasCaixa(JSON.parse(imp));
    }
    if (localStorage.getItem('impressaoProducao')) {
      imp = localStorage.getItem('impressaoProducao');
      if (imp === 'true') {
        setImpressaoProducao(true);
      }
    }
    if (localStorage.getItem('impressaoEstabelecimento')) {
      imp = localStorage.getItem('impressaoEstabelecimento');
      if (imp === 'true') {
        setImpressaoEstabelecimento(true);
      }
    }
    if (localStorage.getItem('impressaoCliente')) {
      imp = localStorage.getItem('impressaoCliente');
      if (imp === 'true') {
        setImpressaoCliente(true);
      }
    }
    if (localStorage.getItem('impressaoItemAgrupado')) {
      imp = localStorage.getItem('impressaoItemAgrupado');
      if (imp === 'true') {
        setImpressaoItemAgrupado(true);
      }
    }
    if (localStorage.getItem('impressorasProducao')) {
      imp = localStorage.getItem('impressorasProducao');
      setImpressorasProducao(JSON.parse(imp));
    }
    if (localStorage.getItem('autoAceiteImprimir')) {
      const autoAceiteImprimirStorage = localStorage.getItem('autoAceiteImprimir');
      if (autoAceiteImprimirStorage === 'true') {
        setAutoAceiteImprimir(true);
      }
    }

    //qz
    if (localStorage.getItem('qzCertificate')) {
      imp = localStorage.getItem('qzCertificate');
      setQzCertificate(imp);
    }
    if (localStorage.getItem('qzPrivate')) {
      imp = localStorage.getItem('qzPrivate');
      setQzPrivate(imp);
    }

    getPrinters();
  }

  function salvarConfiguracoes() {
    localStorage.setItem('impressora', defaultPrinter);
    localStorage.setItem('delay', delay);
    localStorage.setItem('impressaoLayout', impressaoLayout);
    localStorage.setItem('impressorasCaixa', JSON.stringify(impressorasCaixa));
    localStorage.setItem('impressaoProducao', impressaoProducao);
    localStorage.setItem('impressaoEstabelecimento', impressaoEstabelecimento);
    localStorage.setItem('impressaoCliente', impressaoCliente);
    localStorage.setItem('impressaoItemAgrupado', impressaoItemAgrupado);
    localStorage.setItem('impressorasProducao', JSON.stringify(impressorasProducao));
    localStorage.setItem('autoAceiteImprimir', autoAceiteImprimir);
    localStorage.setItem('qtdeCopia', qtdeCopia);
    localStorage.setItem('larguraMaximaPagina', larguraMaximaPagina);
    localStorage.setItem('metodoImpressao', metodoImpressao);
    localStorage.setItem('saltoLinhaInicio', saltoLinhaInicio);
    localStorage.setItem('margemEsquerda', margemEsquerda);
    localStorage.setItem('margemDireita', margemDireita);
    localStorage.setItem('nomeFonte', nomeFonte);
    localStorage.setItem('tamanhoFonte', tamanhoFonte);

    //qz
    if(metodoImpressao!='qz'){
      localStorage.setItem('qzCertificate', "");
      setQzCertificate("");
      localStorage.setItem('qzPrivate', "");
      setQzPrivate("");
    } else {
      localStorage.setItem('qzCertificate', qzCertificate);
      localStorage.setItem('qzPrivate', qzPrivate);
    }

    var data = {
      defaultPrinter: defaultPrinter,
      impressaoLayout: impressaoLayout,
      delay: delay,
      //impressorasCaixa: JSON.stringify(impressorasCaixa),
      impressaoProducao: impressaoProducao,
      impressaoEstabelecimento: impressaoEstabelecimento,
      impressaoCliente: impressaoCliente,
      impressaoItemAgrupado: impressaoItemAgrupado,
      //impressorasProducao: JSON.stringify(impressorasProducao),
      autoAceiteImprimir: autoAceiteImprimir,
      qtdeCopia: qtdeCopia,
      larguraMaximaPagina: larguraMaximaPagina,
      metodoImpressao: metodoImpressao,
      saltoLinhaInicio: saltoLinhaInicio,
      margemEsquerda: margemEsquerda,
      margemDireita: margemDireita,
      nomeFonte: nomeFonte,
      tamanhoFonte: tamanhoFonte,
      id_estabelecimento: localStorage.getItem('id_estabelecimento')
    };

    Api.post('estabelecimentosimpressao/adicionar', data).then((rps) => {
      if (rps.data.status == false) {
        Swal.fire({
          title: "Atenção!",
          icon: "info",
          html: rps.data.erro,
          showCloseButton: true,
          showCancelButton: false,
        });
      } else {
        addToast('Configurações salvas com sucesso', {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 5000
        });
        setMudou(false);
      }
      getPrinters();
    });
  }

  function getPrinters() {
    var nImpressoras = [];
    impressoras.getPrinters(rps => {
      if (rps !== null) {
        nImpressoras[nImpressoras.length] = { value: null, label: 'Nenhuma' };
        nImpressoras[nImpressoras.length] = { value: 0, label: 'Impressora padrão' };
        rps.forEach(e => {
          nImpressoras[nImpressoras.length] = { value: e.nome, label: e.nome };
        })
        setOptImpressoras(nImpressoras);
      } else {
        nImpressoras[nImpressoras.length] = { value: null, label: 'Problemas no módulo de impressão.' };
      }
    });
  }

  function adicionaRemoveImpressaoCaixa(adicionar, impressora) {
    var impressoras = impressorasCaixa;

    if (adicionar === true) {
      if (!impressoras.includes(impressora)) {
        impressoras[impressoras.length] = impressora;
      }
    } else {
      var nImpressoras = [];
      impressoras.forEach(imp => {
        // eslint-disable-next-line eqeqeq
        if (imp != impressora) {
          nImpressoras[nImpressoras.length] = imp;
        }
      });
      impressoras = nImpressoras;
    }

    setImpressorasCaixa(impressoras);
  }

  function mudaImpressoraCategoria(categoria, impressora, agrupamento) {
    setMudou(true);
    if (impressorasProducao[categoria] === undefined) {
      setImpressorasProducao({
        ...impressorasProducao,
        [categoria]: {}
      });
    }
    if (impressora != null) {
      setImpressorasProducao({
        ...impressorasProducao,
        [categoria]: {
          ...impressorasProducao[categoria],
          impressora: impressora
        }
      });
    }
    if (agrupamento != null) {
      setImpressorasProducao({
        ...impressorasProducao,
        [categoria]: {
          ...impressorasProducao[categoria],
          agrupamento: agrupamento
        }
      });
    }
  }

  //Categorias dos produtos
  function getCategories() {
    Api.get("cardapiocategorias/listar").then((response) => {
      if (response.data.status !== undefined && response.data.status === true) {
        setCategorias(response.data.categorias);
      }
    });
  }

  //Sincronizar
  function sincronizarNuvem() {
    Api.get("estabelecimentosimpressao/listar").then((rps) => {
      if (rps.data.status !== undefined && rps.data.status === true) {
        localStorage.setItem('impressora', rps.data.obj.impressora);
        localStorage.setItem('impressaoLayout', rps.data.obj.impressaoLayout);
        //localStorage.setItem('impressorasCaixa', JSON.stringify(rps.data.obj.impressorasCaixa));
        localStorage.setItem('impressaoProducao', rps.data.obj.impressaoProducao);
        localStorage.setItem('impressaoEstabelecimento', rps.data.obj.impressaoEstabelecimento);
        localStorage.setItem('impressaoCliente', rps.data.obj.impressaoCliente);
        localStorage.setItem('impressaoItemAgrupado', rps.data.obj.impressaoItemAgrupado);
        //localStorage.setItem('impressorasProducao', JSON.stringify(rps.data.obj.impressorasProducao));
        localStorage.setItem('autoAceiteImprimir', rps.data.obj.autoAceiteImprimir);
        localStorage.setItem('qtdeCopia', rps.data.obj.qtdeCopia);
        localStorage.setItem('larguraMaximaPagina', rps.data.obj.larguraMaximaPagina);
        localStorage.setItem('metodoImpressao', rps.data.obj.metodoImpressao);
        localStorage.setItem('saltoLinhaInicio', rps.data.obj.saltoLinhaInicio);
        localStorage.setItem('margemEsquerda', rps.data.obj.margemEsquerda);
        localStorage.setItem('margemDireita', rps.data.obj.margemDireita);
        localStorage.setItem('nomeFonte', rps.data.obj.nomeFonte);
        localStorage.setItem('tamanhoFonte', rps.data.obj.tamanhoFonte);

        getDefaults();

        addToast('Configurações salvas com sucesso', {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 5000
        });
      } else {
        Swal.fire({
          title: "Atenção!",
          icon: "warning",
          html: "Sua loja não possui nenhuma configuração salva em nuvem. <br /><br />Configure sua loja conforme a sua necessidade e clique no botão 'Salvar Alterações'.",
          showCloseButton: true,
          showCancelButton: false,
        });
      }
    });
  }

  return (
    <>

      {/*begin::Entry*/}
      <div className="d-flex flex-column-fluid">
        {/*begin::Container*/}
        <div className="container">
          <ul className="nav nav-tabs nav-tabs-line nav-tabs-line-3x mb-5">
            <li className="nav-item">
              <a className="nav-link active" data-toggle="tab" href="#kt_tab_pane_1_4">IMPRESSORA(S)</a>
            </li>
            {/*
            <li className="nav-item">
            <a className="nav-link" disabled>LAYOUT DE IMPRESSÃO (Breve)</a>
            </li>
            */}
          </ul>
          <div className="tab-content">
            {/*<button onClick={e=>{salvarConfiguracoes()}}>
                      SALVAR CONFIGURAÇÕES
          </button>*/}
            {/*<button onClick={(e) => { lucasLindo(); }}>ahuashuasua</button>*/}

            <div className="tab-pane fade active show" id="kt_tab_pane_1_4" role="tabpanel" aria-labelledby="kt_tab_pane_1_4">
              <div className="card card-custom">
                <div className="card-header">
                  <div className="card-title">
                    <h5 className="text-dark font-weight-bold mt-2 mb-2 mr-5">Configuração de Impressão</h5>
                  </div>
                  <div className="card-toolbar">
                    <button className="btn btn-md btn-info mt-2 mb-2" onClick={e => { sincronizarNuvem() }}><i className="fas fa-cloud-download-alt" />Sincronizar com as configurações da nuvem</button>
                  </div>
                </div>
                <div className="area-auto-open m-10">
                  <div className="row">
                  <div className="form-group col-md-8">
                      <h4>Impressora Padrão</h4>
                      <select className="form-control" value={defaultPrinter} onChange={(e) => { setMudou(true); setDefaultPrinter(e.target.value) }}>
                        {optImpressoras.map((item) => {
                          return (
                            <option value={item.value}>{item.label}</option>
                          )
                        })}
                      </select>
                    </div>

                    <div className="form-group col-md-4">
                      <h4>Delay</h4>
                      <select className="form-control" value={delay} onChange={(e) => { setMudou(true); setDelay(e.target.value) }}>
                        <option value="1000">1 Segundo</option>
                        <option value="2000">2 Segundos</option>
                        <option value="3000">3 Segundos</option>
                        <option value="4000">4 Segundos</option>
                        <option value="5000">5 Segundos</option>
                        <option value="6000">6 Segundos</option>
                        <option value="7000">7 Segundos</option>
                        <option value="8000">8 Segundos</option>
                        <option value="9000">9 Segundos</option>
                        <option value="10000">10 Segundos</option>
                     </select>
                    </div>
                  </div>

                  <div className="row">
                    <div className="form-group col-md-6">
                      <h4>Quantidade de Cópias</h4>
                      <input className="form-control" disabled={metodoImpressao == 'qz'} value={qtdeCopia} onChange={(e) => { setMudou(true); setQtdeCopia(e.target.value) }} />
                    </div>


                    <div className="form-group col-md-6">
                      <h4>Método de impressão</h4>
                      <select className="form-control" value={metodoImpressao} onChange={(e) => { setMudou(true); setMetodoImpressao(e.target.value) }}>
                        <option value="aiboo">Aiboo Printer</option>
                        <option value="qz">QZ Printer</option>
                        <option value="nat">Nativo</option>
                      </select>
                    </div>
                  </div>

                  <div className="row">
                    <div className="form-group col-md-6">
                      <h4>Largura Máxima da Página</h4>
                      <select className="form-control" disabled={metodoImpressao == 'qz'} value={larguraMaximaPagina} onChange={(e) => { setMudou(true); setLarguragMaximaPagina(e.target.value) }}>
                        <option value="4,8">58mm</option>
                        <option value="7,0">80mm</option>
                      </select>
                    </div>

                    <div className="form-group col-md-6">
                      <h4 className="col-md-5">Salto de linhas no Inicio</h4>
                      <input className="form-control" disabled={metodoImpressao == 'qz'} value={saltoLinhaInicio} onChange={(e) => { setMudou(true); setSaltoLinhaInicio(e.target.value) }} />
                    </div>
                  </div>

                  <div className="row">
                    <div className="form-group col-md-6">
                      <h4>Margem Esquerda</h4>
                      <input className="form-control" disabled={metodoImpressao == 'qz'} value={margemEsquerda} onChange={(e) => { setMudou(true); setMargemEsquerda(e.target.value) }} />
                    </div>

                    <div className="form-group col-md-6">
                      <h4>Margem Direita</h4>
                      <input className="form-control" disabled={metodoImpressao == 'qz'} value={margemDireita} onChange={(e) => { setMudou(true); setMargemDireita(e.target.value) }} />
                    </div>
                  </div>

                  <div className="row">
                    <div className="form-group col-md-6">
                      <h4>Fonte do Texto</h4>
                      <select className="form-control" disabled={metodoImpressao == 'qz'} value={nomeFonte} onChange={(e) => { setMudou(true); setNomeFonte(e.target.value) }}>
                        <option value="Arial">Arial</option>
                        <option value="Courier New">Courier New</option>
                        <option value="Lucida Sans">Lucida Sans</option>
                        <option value="Roboto">Roboto</option>
                        <option value="Tahoma">Tahoma</option>
                        <option value="Times New Roman">Times New Roman</option>
                        <option value="Verdana">Verdana</option>
                      </select>
                    </div>

                    <div className="form-group col-md-6">
                      <h4>Tamanho da Fonte</h4>
                      <select className="form-control" disabled={metodoImpressao == 'qz'} value={tamanhoFonte} onChange={(e) => { setMudou(true); setTamanhoFonte(e.target.value) }}>
                        <option value="8">8</option>
                        <option value="10">10</option>
                        <option value="12">12</option>
                        <option value="14">14</option>
                        <option value="16">16</option>
                      </select>
                    </div>
                  </div>

                  <hr />
                  <h4>Layout de impressão Caixa</h4>
                  <div className="form-group">
                    <select className="form-control" value={impressaoLayout} onChange={(e) => { setMudou(true); setImpressaoLayout(e.target.value) }}>
                      <option value="1">1 - Completo</option>
                      <option value="2">2 - Resumido</option>
                    </select>
                  </div>
                  <hr />

                  {metodoImpressao == 'qz' &&
                    <div>
                      <h4>QZ CERTIFICADO</h4>
                      <div className="form-group">
                        DIGITAL CERTIFICATE<br />
                        <textarea onChange={(e) => { setMudou(true); setQzCertificate(e.target.value) }} value={qzCertificate} className="form-control form-control-lg form-control-solid" type="text" />
                        <br />
                        <br />
                        PRIVATE<br />
                        <textarea onChange={(e) => { setMudou(true); setQzPrivate(e.target.value) }} value={qzPrivate} className="form-control form-control-lg form-control-solid" type="text" />
                      </div>
                      <hr />
                    </div>
                  }





                  <div className="mt-10">
                    <h4>Impressão Caixa</h4>
                    <p>Escolha a(s) impressora(s) que deseja que a impressão completa do pedido seja realizada.</p>
                    {/*}
                    <div className="form-group">
                      <select className="form-control selectpicker">
                        <option>Impressora Padrão </option>
                        <option>POS 58</option>
                      </select>
                    </div>

                    <div className="form-group row">
                      <div className="col-10">
                      <select className="form-control selectpicker">
                        <option>POS 58</option>
                      </select>
                      </div>
                      <div className="col-2">
                      <a href="#" className="btn btn-icon btn-light-primary mr-2">
																<i className="flaticon2-trash"></i>
															</a>                        </div>
                    </div>

                    <a href="#">+ ADICIONAR MAIS</a>
                        */}
                    <div className="form-group area-check-print">
                      {optImpressoras.map((imp) => {
                        if (imp.value != null) {
                          return (
                            <>

                              <label className="col-sm-6 checkbox checkbox-square checkbox-primary m-t-3">
                                <input type="checkbox" defaultChecked={impressorasCaixa.includes(imp.value)} onChange={e => { setMudou(true); adicionaRemoveImpressaoCaixa(e.target.checked, imp.value) }} />
                                {imp.label}
                                <span /></label>


                            </>
                          )
                        }
                        return null
                      })}
                    </div>
                  </div>
                  <hr />
                  <div className="mt-10">
                    <h4>Impressão e Aceite Automático</h4>
                    <div className="alert alert-secondary" role="alert">
                      Essa opção permite que assim que um pedido chegar, automaticamente ele seja aceito e impresso pelo sistema.
                      <div className="checkbox-inline mt-5">
                        <label className="checkbox checkbox-square checkbox-primary m-t-3">
                          <input type="checkbox" checked={autoAceiteImprimir} onChange={e => { setMudou(true); setAutoAceiteImprimir(e.target.checked) }} />
                          Desejo utilizar o auto imprimir e aceitar.
                          <span /></label>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="mt-10">
                    <h4>Impressão Produção</h4>
                    <div className="alert alert-secondary" role="alert">
                      A impressão produção permite a configuração para que cada categoria dos produtos da loja seja impressora em determinada impressora. O ticket da impressão de produção possui informações necessárias à produção.
                      <div className="checkbox-inline mt-5">
                        <label className="checkbox checkbox-square checkbox-primary m-t-4">
                          <input type="checkbox" checked={impressaoProducao} onChange={e => { setMudou(true); setImpressaoProducao(e.target.checked) }} />
                          Desejo utilizar a impressão produção
                          <span /></label>
                        <label className="checkbox checkbox-square checkbox-primary m-t-4">
                          <input type="checkbox" checked={impressaoEstabelecimento} onChange={e => { setMudou(true); setImpressaoEstabelecimento(e.target.checked) }} />
                          Desejo apresentar o nome do estabelecimento
                          <span /></label>
                        <label className="checkbox checkbox-square checkbox-primary m-t-4">
                          <input type="checkbox" checked={impressaoCliente} onChange={e => { setMudou(true); setImpressaoCliente(e.target.checked) }} />
                          Desejo apresentar o nome do cliente
                          <span /></label>
                        <label className="checkbox checkbox-square checkbox-primary m-t-4">
                          <input type="checkbox" checked={impressaoItemAgrupado} onChange={e => { setMudou(true); setImpressaoItemAgrupado(e.target.checked) }} />
                          Desejo imprimir os itens desagrupado
                          <span /></label>
                      </div>
                    </div>
                    {impressaoProducao === true &&
                      <div className="mt-10">
                        {categorias.map((c) => {
                          var imp = 0;
                          var agrup = '';
                          if (impressorasProducao[c.id_cardapio_categoria]) {
                            imp = impressorasProducao[c.id_cardapio_categoria].impressora;
                            agrup = impressorasProducao[c.id_cardapio_categoria].agrupamento;
                          }
                          return (
                            <div className="form-group row">
                              <label className="col-4 col-form-label font-weight-bolder">{c.nome_categoria}</label>
                              <div className="col-6">
                                <select className="form-control" value={imp} onChange={(e) => mudaImpressoraCategoria(c.id_cardapio_categoria, e.target.value, null)}>
                                  {optImpressoras.map((item) => {
                                    return (
                                      <option value={item.value}>{item.label}</option>
                                    )
                                  })}
                                </select>


                              </div>
                              <div className="col-2">
                                <input className="form-control" type="text" value={agrup} onChange={(e) => mudaImpressoraCategoria(c.id_cardapio_categoria, null, e.target.value)} />
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    }
                  </div>

                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />


                </div>
                {/*
                <div className="card-footer">
													<button type="submit" className="btn btn-success font-weight-bold mr-2">Salvar Alterações</button>
												</div>
                    */}
              </div>


              <div className="botao-salvar-alteracoes">
                <button disabled={!mudou} type="button" className="btn btn-success btn-lg btn-block" onClick={e => { salvarConfiguracoes() }}>Salvar Alterações</button>
              </div>

            </div>
            <div className="tab-pane fade" id="kt_tab_pane_2_4" role="tabpanel" aria-labelledby="kt_tab_pane_2_4">
              <div className="card card-custom">
                <div className="card-header flex-wrap">
                  {/*begin::Subheader*/}
                  <div className="subheader py-2 py-lg-4 subheader-transparent" id="kt_subheader">
                    <div className="d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
                      {/*begin::Details*/}
                      <div className="d-flex align-items-center flex-wrap mr-2">
                        {/*begin::Title*/}
                        <h5 className="text-dark font-weight-bold mt-2 mb-2 mr-5">Layout de Impressão</h5>
                        {/*end::Title*/}
                        {/*begin::Separator*/}
                        <div className="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200" />
                        {/*end::Separator*/}
                      </div>
                      {/*end::Details*/}
                    </div>
                  </div>
                  {/*end::Subheader*/}
                </div>
                s
              </div>
            </div>
          </div>
        </div>
        {/*end::Container*/}
      </div>
      {/*end::Entry*/}
    </>
  );

}

export default withRouter(ConfiguracoesImpressoras);