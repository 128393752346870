import styled from "styled-components";

export const Container = styled.div`
  border-radius: 16px;
  border: 1px solid #dcdcdc;
  margin: 30px 0;
  padding: 25px;

  ol {
    margin-bottom: 17px;
  }

  li {
    font-family: "Nunito";
    font-size: 14px;
    color: #333333;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  width: fit-content;
  align-items: center;
  gap: 5px;
  color: #333333;
  margin-bottom: 17px;
  i {
    font-size: 24px;
    color: #333333;
  }

  h2 {
    font-family: "Nunito";
    font-weight: 600;
    font-size: 16px;
    color: #333333;
    margin: 0;
  }
`;

export const ExpandInstructionsButton = styled.button`
  font-family: "Nunito";
  font-size: 14px;
  color: #ff3333;
  text-decoration: underline;
  border: none;
  background: none;
`;

export const InstructionsModalContent = styled.div`
  border-radius: 16px;
  padding: 35px;
  background: #ffffff;
  width: 100%;
`;

export const ModalTitleContainer = styled.div`
  display: flex;
  width: fit-content;
  align-items: center;
  gap: 19px;
  color: #333333;
  margin-bottom: 17px;
  i {
    font-size: 24px;
    color: #333333;
  }

  h2 {
    font-family: "Nunito";
    font-weight: 600;
    font-size: 24px;
    color: #333333;
    margin: 0;
  }
`;

export const Separator = styled.div`
  width: 100%;
  height: 1px;
  background: #e5eaee;
`;

export const FullInstructionsList = styled.div``;

export const InstructionsSubList = styled.div`
  margin: 35px 0;

  h3 {
    font-family: "Nunito";
    font-size: 16px;
    color: #333333;
    margin-bottom: 12px;
  }
  li {
    font-family: "Nunito";
    font-size: 12px;
    color: #333333;
  }
`;
