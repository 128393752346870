import React from 'react'
import Axios from 'axios';
import Swal from 'sweetalert2'
import qs from 'qs'; //TODO Verificar real necessidade. isso é QueryString
import withReactContent from 'sweetalert2-react-content'

const SwalRC = withReactContent(Swal)
const Apiv2 = Axios.create({
  baseURL: process.env.REACT_APP_BASEURL_V2, 
  headers: {
     "content-type": "application/x-www-form-urlencoded", // content-type accept for CodeIgniter
     'Authorization' : localStorage.getItem("hash") ? 'Basic '+localStorage.getItem("hash") : 'null'
   },
   transformRequest: [
     function(data, headers) {
       // transform to QueryString before request
       return qs.stringify(data);
     }
   ]
 });

 Apiv2.interceptors.response.use(
  response => {
    const statusCode = response.data.status
    // Verificando código se houver error
    if (!statusCode) {
      switch (response.data.code) {
        case 401:
          localStorage.clear();
          SwalRC.fire({
            type: 'error',
            title: response.data.erro,
            html: 'É necessário fazer o login novamente.',
            footer: (
              <a className="btn btn-primary btn-sm" href="index.html">
                Fazer Login
              </a>
            ),
            allowOutsideClick: false,
            showConfirmButton: false
          })

        break
        default:
          break
      }
    }
    return response
  },
  error => {
    if( error.config.hasOwnProperty('errorHandle') && error.config.errorHandle === false ) {
      return Promise.reject(error);
    }

    // if has response show the error
    //if (error.response) {
        //toast.error(error.response.data.message);
      //console.log()
      //if(error?.response?.status===500){
        SwalRC.fire({
          type: 'error',
          title: 'Ops! Ocorreu um erro inesperado ws2 '+error?.response?.status+'!',
          html: 'Uma equipe de fominhas altamente treinados já foi acionada para resolver o problema!',
          footer: (
            <>
            <button onClick={e=>{
              document.location.reload(true);
              }} className="btn btn-success">Tentar novamente</button>
            </>
            ),
          allowOutsideClick: false,
          showConfirmButton: false
        })
      //}
      return error;
    //}
          //Verificar erro 500
        /*console.log(response);
        if(response.status!=200){
          SwalRC.fire({
            type: 'error',
            title: 'Ops! Ocorreu um erro',
            html: 'Nossos macacos altamente treinados já estão trabalhando para resolver este problema!',
            footer: '',
            allowOutsideClick: true,
            showConfirmButton: false
          })
        }*/
  }
)

export default Apiv2;
