import {
  createContext,
  useContext,
  useState,
  useCallback,
  useEffect,
} from "react";
import { Chat } from "../../components/Chat";
import { ChatService } from "../../services/ChatService";

import chatSound from "../../audio/chat.mp3";
import io from "socket.io-client";

const ChatContext = createContext();

const ChatProvider = ({ children }) => {
  const [chats, setChats] = useState([]);
  const [audio] = useState(new Audio(chatSound));
  const [orderId, setOrderId] = useState();

  const openChat = useCallback((newChat) => {
    setChats((prevChats) => {
      const isChatAlreadyOpen = prevChats.some(
        (chat) => chat.orderInfo.id_pedido === newChat.orderInfo.id_pedido
      );

      if (isChatAlreadyOpen) {
        return prevChats;
      }

      return [...prevChats, newChat];
    });
  }, []);

  const getOrderInfo = useCallback(async () => {
    audio.play();

    const response = await ChatService.chatInfo(orderId);

    if (!response.ok) {
      return;
    }

    const { client_name, establishment_id, hash_pedido, id_usuario } =
      response.data;

    const chat = {
      clientName: client_name,
      token: hash_pedido,
      orderInfo: {
        id_usuario: id_usuario,
        id_estabelecimento: establishment_id,
        id_pedido: orderId,
      },
    };

    openChat(chat);
  }, [audio, openChat, orderId]);

  const isChatAlreadyOpen = useCallback(
    (currentOrderId) => {
      const chatIsOpen = chats.find(
        (chat) => chat.orderInfo.id_pedido === currentOrderId
      );

      if (chatIsOpen) {
        return true;
      }

      return false;
    },
    [chats]
  );

  const createSocket = useCallback(async () => {
    const socket = io(process.env.REACT_APP_SOCKETURL, {
      transports: ["websocket"],
      jsonp: false,
    });

    socket.on("connect", async () => {
      const formConnection = {
        merchant_socket_id: socket.id,
        id_estabelecimento: localStorage.getItem("id_estabelecimento"),
      };

      const response = await ChatService.createSocketConnection(formConnection);

      if (!response.ok) {
        return;
      }

      socket.on("socket-merchant", function ({ order_id }) {
        setOrderId(order_id);
      });
    });
  }, []);

  useEffect(() => {
    if (!isChatAlreadyOpen(orderId) && orderId) {
      getOrderInfo();
    }
  }, [getOrderInfo, isChatAlreadyOpen, orderId]);

  useEffect(() => {
    if (localStorage.getItem("hash")) {
      createSocket();
    }
  }, [createSocket]);

  const closeChat = useCallback((orderId) => {
    setChats((prevChats) => {
      const chatsWithoutChat = prevChats.filter(
        (chat) => chat.orderInfo.id_pedido !== orderId
      );

      return chatsWithoutChat;
    });

    setOrderId(0);
  }, []);

  const closeAllChat = useCallback(() => {
    setChats([]);
    setOrderId(0);
  }, []);

  return (
    <ChatContext.Provider
      value={{ chats, openChat, closeChat, audio, closeAllChat }}
    >
      {children}

      {chats.map((chat) => (
        <Chat
          key={chat.token}
          clientName={chat.clientName}
          token={chat.token}
          orderInfo={chat.orderInfo}
        />
      ))}
    </ChatContext.Provider>
  );
};

const useChat = () => {
  return useContext(ChatContext);
};

export { ChatProvider, useChat };
