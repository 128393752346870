import React, { useState, useEffect } from 'react';
import Api from '../../services/api';
import { useToasts } from 'react-toast-notifications';
import Swal from "sweetalert2";
import InputMask from "react-input-mask";

import { withRouter } from "react-router";

const Funcionamento = () => {

    useEffect(() =>{
        Api.get("software_app/listarHorarios").then((rps) => {

            setAutoAbrir({checked: rps.data.auto_abrir});
            setAutoFechar({checked: rps.data.auto_fechar});

            rps.data.obj.forEach((ob) => {
                if(ob.abreviacao==='dom'){
                    setDispDo({checked: ob.checked, initAt: ob.abertura, endAt: ob.fechamento, initAt2: ob.abertura2, endAt2: ob.fechamento2});
                }
                if(ob.abreviacao==='seg'){
                    setDispSg({checked: ob.checked, initAt: ob.abertura, endAt: ob.fechamento, initAt2: ob.abertura2, endAt2: ob.fechamento2});
                }
                if(ob.abreviacao==='ter'){
                    setDispTe({checked: ob.checked, initAt: ob.abertura, endAt: ob.fechamento, initAt2: ob.abertura2, endAt2: ob.fechamento2});
                }
                if(ob.abreviacao==='qua'){
                    setDispQa({checked: ob.checked, initAt: ob.abertura, endAt: ob.fechamento, initAt2: ob.abertura2, endAt2: ob.fechamento2});
                }
                if(ob.abreviacao==='qui'){
                    setDispQi({checked: ob.checked, initAt: ob.abertura, endAt: ob.fechamento, initAt2: ob.abertura2, endAt2: ob.fechamento2});
                }
                if(ob.abreviacao==='sex'){
                    setDispSx({checked: ob.checked, initAt: ob.abertura, endAt: ob.fechamento, initAt2: ob.abertura2, endAt2: ob.fechamento2});
                }
                if(ob.abreviacao==='sab'){
                    setDispSb({checked: ob.checked, initAt: ob.abertura, endAt: ob.fechamento, initAt2: ob.abertura2, endAt2: ob.fechamento2});
                }
            })
        })
    }, []);

    const { addToast } = useToasts();
    const [autoAbrir, setAutoAbrir] = useState({checked: false});
    const [autoFechar, setAutoFechar] = useState({checked: false});
    const [dispDo, setDispDo] = useState({checked: false, initAt: '', endAt: '', initAt2: '', endAt2: ''});
    const [dispSg, setDispSg] = useState({checked: false, initAt: '', endAt: '', initAt2: '', endAt2: ''});
    const [dispTe, setDispTe] = useState({checked: false, initAt: '', endAt: '', initAt2: '', endAt2: ''});
    const [dispQa, setDispQa] = useState({checked: false, initAt: '', endAt: '', initAt2: '', endAt2: ''});
    const [dispQi, setDispQi] = useState({checked: false, initAt: '', endAt: '', initAt2: '', endAt2: ''});
    const [dispSx, setDispSx] = useState({checked: false, initAt: '', endAt: '', initAt2: '', endAt2: ''});
    const [dispSb, setDispSb] = useState({checked: false, initAt: '', endAt: '', initAt2: '', endAt2: ''});

    function efetivar() {
        var objData = [];

        objData[objData.length] = {
            abreviacao: 'dom',
            checked: dispDo.checked,
            abertura: dispDo.initAt,
            fechamento: dispDo.endAt,
            abertura2: dispDo.initAt2,
            fechamento2: dispDo.endAt2
        };
        objData[objData.length] = {
            abreviacao: 'seg',
            checked: dispSg.checked,
            abertura: dispSg.initAt,
            fechamento: dispSg.endAt,
            abertura2: dispSg.initAt2,
            fechamento2: dispSg.endAt2
        };
        objData[objData.length] = {
            abreviacao: 'ter',
            checked: dispTe.checked,
            abertura: dispTe.initAt,
            fechamento: dispTe.endAt,
            abertura2: dispTe.initAt2,
            fechamento2: dispTe.endAt2
        };
        objData[objData.length] = {
            abreviacao: 'qua',
            checked: dispQa.checked,
            abertura: dispQa.initAt,
            fechamento: dispQa.endAt,
            abertura2: dispQa.initAt2,
            fechamento2: dispQa.endAt2
        };
        objData[objData.length] = {
            abreviacao: 'qui',
            checked: dispQi.checked,
            abertura: dispQi.initAt,
            fechamento: dispQi.endAt,
            abertura2: dispQi.initAt2,
            fechamento2: dispQi.endAt2
        };
        objData[objData.length] = {
            abreviacao: 'sex',
            checked: dispSx.checked,
            abertura: dispSx.initAt,
            fechamento: dispSx.endAt,
            abertura2: dispSx.initAt2,
            fechamento2: dispSx.endAt2
        };
        objData[objData.length] = {
            abreviacao: 'sab',
            checked: dispSb.checked,
            abertura: dispSb.initAt,
            fechamento: dispSb.endAt,
            abertura2: dispSb.initAt2,
            fechamento2: dispSb.endAt2
        };

        var dataPost = {
            horarios: JSON.stringify(objData),
            auto_abrir: autoAbrir.checked,
            auto_fechar: autoFechar.checked,
        };
        Api.post("software_app/alteraHorarios2", dataPost).then((rps) => {
            console.log(rps.data);

            if(rps.data.status){
                addToast(rps.data.mensagem, {
                        appearance: "success",
                        autoDismiss: true,
                        autoDismissTimeout: 2000
                  });
                } else {
                    Swal.fire({
                        title: "Atenção!",
                        icon: "error",
                        html: rps.data.mensagem,
                        showCloseButton: true,
                        showCancelButton: false,
                    });
                }
        })
    }

        return (
            <>
    {/*begin::Entry*/}
    <div className="d-flex flex-column-fluid">
        {/*begin::Container*/}
        <div className="container">


            {/*begin card de pesquisa de vendas */}
            <div className="card card-custom">
				<div className="card-header">
					<h3 className="card-title">Funcionamento</h3>
				</div>
                <div className="card-body">
                    <div className="row justify-content-md-center">
                        <label className="col-md-auto col-form-label text-right h5">Abrir Automaticamente?</label>
						<div className="col-md-auto text-center">
							<span className="switch switch-outline switch-icon switch-success">
					        <label>
								<input type="checkbox" checked={autoAbrir.checked} onChange={e=> {setAutoAbrir({...autoAbrir, checked: e.target.checked})}} />
								<span></span>
							</label>
							</span>
                        </div>
                    </div>
                    <div className="row justify-content-md-center">
                        <label className="col-md-auto col-form-label text-right h5">Fechar Automaticamente?</label>
						<div className="col-md-auto text-center">
							<span className="switch switch-outline switch-icon switch-success">
					        <label>
								<input type="checkbox" checked={autoFechar.checked} onChange={e=> {setAutoFechar({...autoFechar, checked: e.target.checked})}} />
								<span></span>
							</label>
							</span>
                        </div>
                    </div>
                </div>
            </div>
            <br />
            {/*begin relatorio de vendas */}
            <div className="card card-custom">
				<div className="card-header">
					<h3 className="card-title">Horário de Funcionamento</h3>
				</div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-12">

                            <table className="table">
                                <thead>
                                    <tr>
                                        <th className="text-center">Dias da Semana</th>
                                        <th className="text-center">Aberto</th>
                                        <th className="text-center">Abertura Primeiro Horário</th>
                                        <th className="text-center">Fechamento Primeiro Horário</th>
                                        <th className="text-center">Abertura Segundo Horário</th>
                                        <th className="text-center">Fechamento Segunddo Horário</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="text-center">Domingo</td>
                                        <td className="text-center">
                                            <label className="checkbox checkbox-square checkbox-primary">
                                            <input type="checkbox" checked={dispDo.checked} onChange={e=> {setDispDo({...dispDo, checked: e.target.checked})}} />
                                            <span /></label>
                                        </td>
                                        <td className="text-center">
                                            <InputMask mask={"99:99"} disabled={!dispDo.checked} value={dispDo.initAt} onChange={e=> {setDispDo({...dispDo, initAt: e.target.value})}}  className="form-control form-small form-disponibilidade" type="text" />
                                        </td>
                                        <td className="text-center">
                                            <InputMask mask={"99:99"} disabled={!dispDo.checked} value={dispDo.endAt} onChange={e=> {setDispDo({...dispDo, endAt: e.target.value})}}  className="form-control form-small form-disponibilidade" type="text" />

                                        </td>
                                        <td className="text-center">
                                            <InputMask mask={"99:99"} disabled={!dispDo.checked} value={dispDo.initAt2} onChange={e=> {setDispDo({...dispDo, initAt2: e.target.value})}}  className="form-control form-small form-disponibilidade" type="text" />
                                        </td>
                                        <td className="text-center">
                                            <InputMask mask={"99:99"} disabled={!dispDo.checked} value={dispDo.endAt2} onChange={e=> {setDispDo({...dispDo, endAt2: e.target.value})}}  className="form-control form-small form-disponibilidade" type="text" />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="text-center">Segunda-feira</td>
                                        <td className="text-center">
                                            <label className="checkbox checkbox-square checkbox-primary">
                                            <input type="checkbox" checked={dispSg.checked} onChange={e=> {setDispSg({...dispSg, checked: e.target.checked})}} />
                                            <span /></label>
                                        </td>
                                        <td className="text-center">
                                            <InputMask mask={"99:99"} disabled={!dispSg.checked} value={dispSg.initAt} onChange={e=> {setDispSg({...dispSg, initAt: e.target.value})}}  className="form-control form-small form-disponibilidade" type="text" />
                                        </td>
                                        <td className="text-center">
                                            <InputMask mask={"99:99"} disabled={!dispSg.checked} value={dispSg.endAt} onChange={e=> {setDispSg({...dispSg, endAt: e.target.value})}}  className="form-control form-small form-disponibilidade" type="text" />

                                        </td>
                                        <td className="text-center">
                                            <InputMask mask={"99:99"} disabled={!dispSg.checked} value={dispSg.initAt2} onChange={e=> {setDispSg({...dispSg, initAt2: e.target.value})}}  className="form-control form-small form-disponibilidade" type="text" />
                                        </td>
                                        <td className="text-center">
                                            <InputMask mask={"99:99"} disabled={!dispSg.checked} value={dispSg.endAt2} onChange={e=> {setDispSg({...dispSg, endAt2: e.target.value})}}  className="form-control form-small form-disponibilidade" type="text" />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="text-center">Terça-feira</td>
                                        <td className="text-center">
                                            <label className="checkbox checkbox-square checkbox-primary">
                                            <input type="checkbox" checked={dispTe.checked} onChange={e=> {setDispTe({...dispTe, checked: e.target.checked})}} />
                                            <span /></label>
                                        </td>
                                        <td className="text-center">
                                            <InputMask mask={"99:99"} disabled={!dispTe.checked} value={dispTe.initAt} onChange={e=> {setDispTe({...dispTe, initAt: e.target.value})}}  className="form-control form-small form-disponibilidade" type="text" />
                                        </td>
                                        <td className="text-center">
                                            <InputMask mask={"99:99"} disabled={!dispTe.checked} value={dispTe.endAt} onChange={e=> {setDispTe({...dispTe, endAt: e.target.value})}}  className="form-control form-small form-disponibilidade" type="text" />

                                        </td>
                                        <td className="text-center">
                                            <InputMask mask={"99:99"} disabled={!dispTe.checked} value={dispTe.initAt2} onChange={e=> {setDispTe({...dispTe, initAt2: e.target.value})}}  className="form-control form-small form-disponibilidade" type="text" />
                                        </td>
                                        <td className="text-center">
                                            <InputMask mask={"99:99"} disabled={!dispTe.checked} value={dispTe.endAt2} onChange={e=> {setDispTe({...dispTe, endAt2: e.target.value})}}  className="form-control form-small form-disponibilidade" type="text" />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="text-center">Quarta-feira</td>
                                        <td className="text-center">
                                            <label className="checkbox checkbox-square checkbox-primary">
                                            <input type="checkbox" checked={dispQa.checked} onChange={e=> {setDispQa({...dispQa, checked: e.target.checked})}} />
                                            <span /></label>
                                        </td>
                                        <td className="text-center">
                                            <InputMask mask={"99:99"} disabled={!dispQa.checked} value={dispQa.initAt} onChange={e=> {setDispQa({...dispQa, initAt: e.target.value})}}  className="form-control form-small form-disponibilidade" type="text" />
                                        </td>
                                        <td className="text-center">
                                            <InputMask mask={"99:99"} disabled={!dispQa.checked} value={dispQa.endAt} onChange={e=> {setDispQa({...dispQa, endAt: e.target.value})}}  className="form-control form-small form-disponibilidade" type="text" />

                                        </td>
                                        <td className="text-center">
                                            <InputMask mask={"99:99"} disabled={!dispQa.checked} value={dispQa.initAt2} onChange={e=> {setDispQa({...dispQa, initAt2: e.target.value})}}  className="form-control form-small form-disponibilidade" type="text" />
                                        </td>
                                        <td className="text-center">
                                            <InputMask mask={"99:99"} disabled={!dispQa.checked} value={dispQa.endAt2} onChange={e=> {setDispQa({...dispQa, endAt2: e.target.value})}}  className="form-control form-small form-disponibilidade" type="text" />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="text-center">Quinta-feira</td>
                                        <td className="text-center">
                                            <label className="checkbox checkbox-square checkbox-primary">
                                            <input type="checkbox" checked={dispQi.checked} onChange={e=> {setDispQi({...dispQi, checked: e.target.checked})}} />
                                            <span /></label>
                                        </td>
                                        <td className="text-center">
                                            <InputMask mask={"99:99"} disabled={!dispQi.checked} value={dispQi.initAt} onChange={e=> {setDispQi({...dispQi, initAt: e.target.value})}}  className="form-control form-small form-disponibilidade" type="text" />
                                        </td>
                                        <td className="text-center">
                                            <InputMask mask={"99:99"} disabled={!dispQi.checked} value={dispQi.endAt} onChange={e=> {setDispQi({...dispQi, endAt: e.target.value})}}  className="form-control form-small form-disponibilidade" type="text" />

                                        </td>
                                        <td className="text-center">
                                            <InputMask mask={"99:99"} disabled={!dispQi.checked} value={dispQi.initAt2} onChange={e=> {setDispQi({...dispQi, initAt2: e.target.value})}}  className="form-control form-small form-disponibilidade" type="text" />
                                        </td>
                                        <td className="text-center">
                                            <InputMask mask={"99:99"} disabled={!dispQi.checked} value={dispQi.endAt2} onChange={e=> {setDispQi({...dispQi, endAt2: e.target.value})}}  className="form-control form-small form-disponibilidade" type="text" />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="text-center">Sexta-feira</td>
                                        <td className="text-center">
                                            <label className="checkbox checkbox-square checkbox-primary">
                                            <input type="checkbox" checked={dispSx.checked} onChange={e=> {setDispSx({...dispSx, checked: e.target.checked})}} />
                                            <span /></label>
                                        </td>
                                        <td className="text-center">
                                            <InputMask mask={"99:99"} disabled={!dispSx.checked} value={dispSx.initAt} onChange={e=> {setDispSx({...dispSx, initAt: e.target.value})}}  className="form-control form-small form-disponibilidade" type="text" />
                                        </td>
                                        <td className="text-center">
                                            <InputMask mask={"99:99"} disabled={!dispSx.checked} value={dispSx.endAt} onChange={e=> {setDispSx({...dispSx, endAt: e.target.value})}}  className="form-control form-small form-disponibilidade" type="text" />

                                        </td>
                                        <td className="text-center">
                                            <InputMask mask={"99:99"} disabled={!dispSx.checked} value={dispSx.initAt2} onChange={e=> {setDispSx({...dispSx, initAt2: e.target.value})}}  className="form-control form-small form-disponibilidade" type="text" />
                                        </td>
                                        <td className="text-center">
                                            <InputMask mask={"99:99"} disabled={!dispSx.checked} value={dispSx.endAt2} onChange={e=> {setDispSx({...dispSx, endAt2: e.target.value})}}  className="form-control form-small form-disponibilidade" type="text" />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="text-center">Sábado</td>
                                        <td className="text-center">
                                            <label className="checkbox checkbox-square checkbox-primary">
                                            <input type="checkbox" checked={dispSb.checked} onChange={e=> {setDispSb({...dispSb, checked: e.target.checked})}} />
                                            <span /></label>
                                        </td>
                                        <td className="text-center">
                                            <InputMask mask={"99:99"} disabled={!dispSb.checked} value={dispSb.initAt} onChange={e=> {setDispSb({...dispSb, initAt: e.target.value})}}  className="form-control form-small form-disponibilidade" type="text" />
                                        </td>
                                        <td className="text-center">
                                            <InputMask mask={"99:99"} disabled={!dispSb.checked} value={dispSb.endAt} onChange={e=> {setDispSb({...dispSb, endAt: e.target.value})}}  className="form-control form-small form-disponibilidade" type="text" />

                                        </td>
                                        <td className="text-center">
                                            <InputMask mask={"99:99"} disabled={!dispSb.checked} value={dispSb.initAt2} onChange={e=> {setDispSb({...dispSb, initAt2: e.target.value})}}  className="form-control form-small form-disponibilidade" type="text" />
                                        </td>
                                        <td className="text-center">
                                            <InputMask mask={"99:99"} disabled={!dispSb.checked} value={dispSb.endAt2} onChange={e=> {setDispSb({...dispSb, endAt2: e.target.value})}}  className="form-control form-small form-disponibilidade" type="text" />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="6" className="text-right">
                                            <button className="btn btn-primary" onClick={e => {efetivar()}}>Salvar</button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
    </>

    );

}

export default withRouter(Funcionamento);
