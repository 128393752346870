import React, { useState, useEffect } from 'react';
import Api from '../../../../services/api';
import { withRouter } from "react-router";

import Swal from "sweetalert2";
import { useToasts } from 'react-toast-notifications';
import LoadingOverlay from 'react-loading-overlay';

import DataTable from 'react-data-table-component';
import { Modal } from "react-bootstrap";

import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';

import { parseISO, format } from 'date-fns';
import pt from "date-fns/locale/pt-BR"; 

const FCDados = () => {
  const { addToast } = useToasts();
  const [ caProcessa, setCaProcessa ] = useState(false);
  const [ modal, setModal ] = useState(false);
  const [ formDetalhes, setFormDetalhes ] = useState({});
  const [ eventos, setEventos ] = useState([]);
  const [ eventosDetalhes, setEventosDetalhes ] = useState([]);
  const [ dataSelecionada, setDataSelecionada ] = useState();
  
  function fecharModal(){
    setModal(false);
  }

   useEffect(() => {
    getListar();
  }, []);

  function getListar() { 
    setCaProcessa(true); 
    Api.get("pagamentoonlinecalendario/listar").then(rps => {
      if (rps.data.status === true) {
        if (rps.data.total !== 0)
          setEventos(rps.data.valores);
        else
          setEventos([]);
      } else {
        setEventos([]);
      }
      setCaProcessa(false);
    })
  }

  function detalhes(registro){
    console.log(registro);
    var ca = JSON.parse(JSON.stringify(registro));

    setFormDetalhes(ca);
    setModal(true);  
  }

  function handleDateClick(arg)  { // bind with an arrow function

    var dataClique = parseISO(arg.dateStr);
    var dataFormatada = format(dataClique, "dd/MM/yyyy");

    setDataSelecionada(dataFormatada);
    setEventosDetalhes();
  }

  function  handleEventClick(clickInfo) {
    console.log(clickInfo.event.startStr);
    setDataSelecionada(clickInfo.event.startStr);

    var dataClique = parseISO(clickInfo.event.startStr);
    var dataFormatada = format(dataClique, "dd/MM/yyyy");

    setDataSelecionada(dataFormatada);

    var data = {
      dataPag: clickInfo.event.startStr,
    };
    
    Api.post("pagamentoonlinecalendario/listarPag", data).then(rps => {
      
      setEventosDetalhes(rps.data.obj);
      console.log(rps.data.obj);
    })
  }
      
    return (
      
        <>
<div>

<div className="subheader espaco-header-pedidos subheader-transparent" id="kt_subheader">
          <div className="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
            {/*begin::Details*/}
            <div className="d-flex align-items-center flex-wrap mr-2">
              {/*begin::Title*/}
              <h5 className="text-dark font-weight-bold mt-5 mb-5 mr-5">Calendário</h5>
              {/*end::Title*/}
              {/*begin::Separator*/}
              <div className="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200" />
              {/*end::Separator*/}
            </div>
            {/*end::Details*/}
            {/*begin::Toolbar*/}
            <div className="d-flex align-items-center">
            </div>
            {/*end::Toolbar*/}
          </div>
        </div>
        {/*begin::Entry*/}
        <div className="d-flex flex-column-fluid">
          {/*begin::Container*/}
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h5 className="text-primary text-center font-weight-boldest">
                  As informações contidas no Calendário são meramente informativas e podem sofrer alterações a qualquer momento sem aviso prévio e ônus ao aiboo
                </h5>
              </div>
            </div> 

            <div className="row">
              <div className="col-md-8">
                <div className="card card-custom p-5">
                  <LoadingOverlay
                    active={caProcessa}
                    spinner
                    text='Carregando...'
                  >                 
                  
                  <FullCalendar
                    dateClick={handleDateClick}
                    plugins={[ dayGridPlugin, interactionPlugin ]}
                    initialView="dayGridMonth"
                    headerToolbar={{
                      left: 'prev,next today',
                      center: 'title',
                      right: 'dayGridMonth'
                    }}
                    locale='pt-br'
                    eventClick={handleEventClick}
                    events={
                      eventos.map((item) =>{ return(
                        { title: 'R$ ' + item.totalDia, date: item.dia }
                      )}) 
                    }
                    eventBackgroundColor = 'red'
                    eventTextColor = 'write'
                    eventBorderColor= 'red'  
                  />
                  </LoadingOverlay> 
                  
                  <div className="row">
                    <div className="col-md-12 mt-3">
                      <label className="mr-8"><i className="fas fa-check-circle text-success mr-1" />Pago</label>
                      <label className="mr-8"><i className="fas fa-comment-dollar text-success mr-1" />Confirmado</label>
                      <label className="mr-8"><i className="fas fa-calendar-alt text-warning mr-1" />Agendamento</label>
                      <label className="mr-8"><i className="fas fa-clock text-warning mr-1" />Pendente</label>
                      <label className="mr-8"><i className="fas fa-times-circle text-danger mr-1" />Falhado</label>
                      <label className="mr-8"><i className="fas fa-minus-circle text-danger mr-1" />Cancelado</label>
                    </div>
                  </div>
                  

                </div>
              </div>           

              <div className="col-md-4">
                <div className="card card-custom p-5">
                  <h4>Pagamentos em {dataSelecionada}</h4>
                </div>
                <div className="card card-custom p-5">
                  { eventosDetalhes != null ? 
                    eventosDetalhes.map((item) =>{ return(
                    <div className="row mt-3">
                      <div className="col-md-8">
                        
                        {item.is_paid === 1 && 
                          <a onClick={e => { detalhes(item) }} className="text-dark text-hover-info"><i className="fas fa-check-circle text-success mr-1" />Pago</a>
                        }
                        {item.status === 'CONFIRMED' && 
                          <a onClick={e => { detalhes(item) }} className="text-dark text-hover-info"><i className="fas fa-comment-dollar text-success mr-1" />Confirmado</a>
                        }
                        {item.status === 'WAITING' && 
                          <a onClick={e => { detalhes(item) }} className="text-dark text-hover-info"><i className="fas fa-calendar-alt text-warning mr-1" />Agendamento</a>
                        }
                        {item.status === 'FAILED' && 
                          <a onClick={e => { detalhes(item) }} className="text-dark text-hover-info"><i className="fas fa-clock text-warning mr-1" />Pendente</a>
                        }
                        {item.status === 'CANCELAD' && 
                          <a onClick={e => { detalhes(item) }} className="text-dark text-hover-info"><i className="fas fa-times-circle text-danger mr-1" />Falhado</a>
                        }
                        {item.status === 'DELAYED' && 
                          <a onClick={e => { detalhes(item) }} className="text-dark text-hover-info"><i className="fas fa-minus-circle text-danger mr-1" />Cancelado</a>
                        }
                      </div>
                      <div className="col-md-4 text-right">
                        {item.valorReceber}
                      </div>
                    </div>
                    )}) : 
                    <div className="row">
                      <div className="col-md-12 text-center">
                        <br /><br /><br /><br /><br />
                        <i className="fas fa-money-bill-wave icon-5x text-success mb-10" />
                        <h6><b>Nenhum pagamento previsto</b></h6>
                        <br /><br /><br /><br /><br />
                      </div>
                    </div>
                  } 
                </div>
              </div>     
            </div>    

      
                  
                  </div>
                  </div>
                  </div>
                  
      <Modal size={'xl'} show={modal} onHide={() => fecharModal()}>
      <Modal.Header>
      <Modal.Title>Detalhe do pagamento</Modal.Title>
          <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={e => {fecharModal()}}>
            <i aria-hidden="true" className="ki ki-close" />
          </button>
      </Modal.Header>
      <div className="row mt-5 ml-5 mr-5">
        <div className="form-group col-md-3">
          <label>Valor do pagamento</label>
          <h4><small>R$ </small>{formDetalhes.valorReceber}</h4>
        </div>

        <div className="form-group col-md-3">
          <label>Tipo do pagamento</label>
        </div>
        
        <div className="form-group col-md-3">
          <label>Data do pagamento</label>
          <h4>{dataSelecionada}</h4>
        </div>

        <div className="form-group col-md-3">
          <label>Status</label>
            {formDetalhes.status === 'SUCCEEDED' && 
              <h4><i className="fas fa-check-circle text-success mr-1" />Pago</h4>
            }
            {formDetalhes.status === 'CONFIRMED' && 
              <h4><i className="fas fa-comment-dollar text-success mr-1" />Confirmado</h4>
            }
            {formDetalhes.status === 'WAITING' && 
              <h4><i className="fas fa-calendar-alt text-warning mr-1" />Agendamento</h4>
            }
            {formDetalhes.status === 'FAILED' && 
              <h4><i className="fas fa-clock text-warning mr-1" />Pendente</h4>
            }
            {formDetalhes.status === 'CANCELAD' && 
              <h4><i className="fas fa-times-circle text-danger mr-1" />Falhado</h4>
            }
            {formDetalhes.status === 'DELAYED' && 
              <h4><i className="fas fa-minus-circle text-danger mr-1" />Cancelado</h4>
            }
        </div>
      </div>  
      
      <div className="row ml-5 mr-5">
        <div className="form-group col-md-3">
          <label>Data de criação</label><br />
          <label>{formDetalhes.created_at}</label>
        </div>

        <div className="form-group col-md-3">
          <label>Nº do comprovante</label><br />
          <label>{formDetalhes.transaction_id}</label>
        </div>

        <div className="form-group col-md-3">
          <label>ID do pagamento</label><br />
          <label>{formDetalhes.external_split_id}</label>
        </div>

        <div className="form-group col-md-3">
          <label>ID do favorecido</label><br />
          <label>{formDetalhes.establishment_id}</label>
        </div>
      </div>
      <Modal.Footer>
        <button type="button" onClick={e => { fecharModal(); }} className="btn btn-light-primary font-weight-bold" data-dismiss="modal">Fechar</button>
      </Modal.Footer>
    </Modal>        
                 
        </>
    );
}

export default withRouter(FCDados);                  