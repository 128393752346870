import React, { useState, useEffect } from 'react';
import Api from '../../services/api';
import { withRouter } from "react-router";

import { useToasts } from 'react-toast-notifications';
import LoadingOverlay from 'react-loading-overlay';
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";

const FormaPagamento = () => {
  const history = useHistory();

    const { addToast } = useToasts();
    const [ listaPagamento, setListaPagamento ] = useState([]);
    const [ listaPagamentoFiltrada, setListaPagamentoFiltrada ] = useState([]);
    const [ filtro, setFiltro ] = useState();
    const [ formasPagamento, setFormaPagamento ] = useState([]);
    const [ caFormas, setCaFormas ] = useState(true);
    const [ estabelecimento, setEstabelecimento ] = useState([]);

    const [ mudou , setMudou ] = useState(false);

    useEffect(() =>{
        getListar();
        getAcceptanceStatus();
      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[]);

    useEffect(() =>{
        var formasOrig = [];
        formasOrig = listaPagamento;

        if(filtro){
            let fForma = formasOrig.filter( (item) => {
              return ((JSON.stringify(item)).toLowerCase().normalize("NFD").replace(/[^a-zA-Zs]/g, "")).indexOf(filtro.toLowerCase().normalize("NFD").replace(/[^a-zA-Zs]/g, "")) !== -1
            })
            setListaPagamentoFiltrada(fForma)
          } else {
            setListaPagamentoFiltrada(formasOrig)
          }

    }, [listaPagamento, filtro]);

    function getEstabelecimento(){
      var data = {
        id_estabelecimento: localStorage.getItem('id_estabelecimento')
      };
  
      Api.post('estabelecimento/listarStatusPagOn', data).then(rps => {
        
        setEstabelecimento(rps.data.obj);
      });
    }

  function getListar(){
    setCaFormas(true);
    getEstabelecimento();
    Api.get("software_app/listarpagamentos/").then((rps) =>{

        console.log(rps.data.obj);

        rps.data.obj.forEach(sel => {
          if (sel.selected===true) {
            adicionaRemoveFormaPagamento(true, sel.chave);
          }
        })

        setListaPagamento(rps.data.obj);
        setCaFormas(false);
    });
    }

    function salvarFormaPagamento(){
      setCaFormas(true);
      var novasFormas = formasPagamento;

      var post = {
        formas: novasFormas
      };

      console.log(post.formas)

      Api.post("software_app/alteraPagamentos", post).then((rps) => {

        addToast('Formas de Pagamento salvas com sucesso', {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 5000
        });
        setCaFormas(false);

      });
      setMudou(false);
    }

    function adicionaRemoveFormaPagamento(adicionar, forma){
      var formas = formasPagamento;

      if(adicionar===true){
        if(!formas.includes(forma)){
          formas[formas.length] = forma;
        }
      } else {
        var nFormas = [];
        formas.forEach(f => {
          // eslint-disable-next-line eqeqeq
          if(f!=forma){
            nFormas[nFormas.length] = f;
          }
        });
        formas = nFormas;
      }
      console.log(formas);
      setFormaPagamento(formas);
    }

    async function updateStoreStatus(status) {
  
      var pgtoStatus;
  
      if (status === true) {
        pgtoStatus = 1;
      } else {
        pgtoStatus = 0;
      }
    
      const response = await Api.post("pagamentoonline/mudarStatus", {
        id_estabelecimento: localStorage.getItem('id_estabelecimento'),
        aceita_pgto_online: pgtoStatus,
      });
      if (response.data.status !== undefined && response.data.status === true) {
        addToast(response.data.mensagem, {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 2000,
        });
        getEstabelecimento();      
      } else {
        Swal.fire({
          title: "Atenção!",
          icon: "info",
          html: response.data.mensagem,
          showCloseButton: true,
          showCancelButton: false,
        });
      }
    }


    const [acceptsOnlinePayment, setAcceptsOnlinePayment] = useState(false);
    const [loadingAcceptsOnlinePayment, setLoadingAcceptsOnlinePayment] = useState(true);
    function getAcceptanceStatus() {
      Api.get("pagamentoonline/getAcceptanceStatus").then((rps) => {
        if (rps.data.status === true) {
          setLoadingAcceptsOnlinePayment(false);
          var data = rps.data.acceptsOnline;
          setAcceptsOnlinePayment(data);
        }
      });
    }
    function changeAcceptsOnlinePayment(newValue){
      setLoadingAcceptsOnlinePayment(true);
      Api.post("pagamentoonline/setAcceptanceStatus", {accepts: newValue}).then((rps) => {
        var data = rps.data;
        setLoadingAcceptsOnlinePayment(false);
        setAcceptsOnlinePayment(data.accepts);
  
        if(data.status===false){
          Swal.fire({
            title: "Erro!",
            icon: "error",
            html: data.erros,
            showCloseButton: true,
            showCancelButton: false
          });
          history.push("/financeiro/cadastro/recebimentos");
        } else {
          addToast(data.mensagem, {
            appearance: "success",
            autoDismiss: true,
            autoDismissTimeout: 2000
          });
        }
      });
    }


    return (
        <>
 {/*begin::Content*/}
<div className="content d-flex flex-column flex-column-fluid" id="kt_content">
  <div className="subheader espaco-header-pedidos subheader-transparent" id="kt_subheader">
    <div className="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
      {/*begin::Details*/}
      <div className="d-flex align-items-center flex-wrap mr-2">
        {/*begin::Title*/}
        <h5 className="text-dark font-weight-bold mt-2 mb-2 mr-5">Formas de Pagamento</h5>
        {/*end::Title*/}
        {/*begin::Separator*/}
        <div className="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200" />
        {/*end::Separator*/}
      </div>
      {/*end::Details*/}
    </div>
  </div>
  {/*begin::Entry*/}
  <div className="d-flex flex-column-fluid">
    {/*begin::Container*/}
    <div className="container">
      <div className="row text-center">
        <div className="col-xl-12">

          {/*begin::Stats Widget 16*/}
          <div className="card card-custom card-stretch gutter-b">
            {/*begin::Body*/}
            <div className="card-body">
              <div className="text-left">
                <h3 className="card-label">Selecione as formas de pagamento que deseja aceitar em sua loja</h3>
                <br />
                <div className="row">
                  <div className="col-md-2">
                    <h5>Pagamento Online </h5>
                  </div>
                  <div>
                  <LoadingOverlay active={loadingAcceptsOnlinePayment} spinner text='Carregando...' >
                    <span className="switch switch-lg switch-icon">
                      <label> 
                        <input
                          checked={acceptsOnlinePayment===true}
                          type="checkbox"
                          onClick={(e) => changeAcceptsOnlinePayment(e.target.checked)}
                          name="select"
                        />
                        <span></span>
                      </label>
                    </span>
                    </LoadingOverlay>
                  </div>
                </div>  

                <form className="mt-10">
                  <div className="input-group input-group-sm input-group-solid" style={{maxWidth: '408px'}}>
                    <input type="text" onChange={e => {setFiltro(e.target.value)}} className="form-control" id="kt_subheader_search_form" placeholder="Procurar..." />
                    <div className="input-group-append">
                      <span className="input-group-text">
                        <span className="svg-icon">
                          {/*begin::Svg Icon | path:assets/media/svg/icons/General/Search.svg*/}
                          <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                            <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                              <rect x={0} y={0} width={24} height={24} />
                              <path d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z" fill="#000000" fillRule="nonzero" opacity="0.3" />
                              <path d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z" fill="#000000" fillRule="nonzero" />
                            </g>
                          </svg>
                          {/*end::Svg Icon*/}
                        </span>
                        {/*<i className="flaticon2-search-1 icon-sm"></i>*/}
                      </span>
                    </div>
                  </div>
                </form>
                <LoadingOverlay
                    active={caFormas}
                    spinner
                    text='Carregando...'
                  >
                <div className="row mt-10 text-left">

                    {
                        listaPagamentoFiltrada.map( fpag => {

                            var nomeCartao = fpag.chave.toLowerCase();
                            nomeCartao = nomeCartao.split("|")[0];
                            nomeCartao = nomeCartao.replace(/\s/g, '');


                            return(
                                <>
                                  <div className="col-sm-6 col-md-4">
                                    <div className="checkbox-inline">
                                        <label className="checkbox checkbox-square checkbox-primary m-t-3">
                                        <input type="checkbox" defaultChecked={fpag.selected} onChange={(e) => { setMudou(true); adicionaRemoveFormaPagamento(e.target.checked, fpag.chave)}} />
                                        <img src={"assets/media/forma-pagamento/"+nomeCartao+".png"} alt="" className="ml-3 mr-2" style={{}} width="50px" /> {fpag.nome}
                                        <span /></label>
                                    </div>
                                  </div>
                                </>
                            )
                        })
                    }

                <div className="botao-salvar-alteracoes">
                  <button disabled={!mudou} type="button" className="btn btn-success btn-lg btn-block" onClick={e=>{salvarFormaPagamento()}}>Salvar Alterações</button>
                </div>

                </div>
                </LoadingOverlay>
              </div>
            </div>
          </div>
          {/*end::Body*/}
        </div>
      </div>
      {/*end::Container*/}
    </div>
    {/*end::Entry*/}
  </div>
  {/*end::Content*/}
</div>

        </>
    );

}

export default withRouter(FormaPagamento);
