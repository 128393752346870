import React, { useState, useEffect } from 'react';
import Api from '../../../../../services/api';
import { withRouter } from "react-router";
import { Wizard, Steps, Step } from 'react-albus';
import './index.css';

import Swal from "sweetalert2";
import { useToasts } from 'react-toast-notifications';
import LoadingOverlay from 'react-loading-overlay';

import DataTable from 'react-data-table-component';
import { Modal } from "react-bootstrap";

import DatePicker, { registerLocale } from "react-datepicker";
import InputMask from 'react-input-mask';
import pt from "date-fns/locale/pt-BR";
import Select from 'react-dropdown-select';
registerLocale("pt-BR", pt);

const FCDados = () => {
  const { addToast } = useToasts();
  const [ bancos, setBancos ] = useState([]);
  const [ caProcessa, setCaProcessa ] = useState(false);
  const [ buscaCodigo, setBuscaCodigo ] = useState();
  const [ buscaNome, setBuscaNome ] = useState();
  const [ buscaStatus, setBuscaStatus ] = useState();
  const [ modal, setModal ] = useState(false);
  const [ formBanco, setFormBanco ] = useState({});
  const [ periodo, setPeriodo ] = useState(0);
  const [ dataInicial, setDataInicial ] = useState();
  const [ dataFinal, setDataFinal ] = useState(new Date());
  const [ ficha, setFicha ] = useState(1);

  useEffect(() => {
    //getListar();
  }, []);

  function atualizaFicha(qual){
    if (qual == 'onde') 
      setFicha(1);
    else if (qual == 'valor')
      setFicha(2);
    else if (qual == 'confirma')
      setFicha(3);
  }

  function getListar() { 
    setCaProcessa(true); 
    Api.get("bancos/listar").then(rps => {
      setBancos(rps.data.grupos);
      setCaProcessa(false);
    })
  }
      
    return (
        <>
<div>

<div className="subheader espaco-header-pedidos subheader-transparent" id="kt_subheader">
          <div className="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
            {/*begin::Details*/}
            <div className="d-flex align-items-center flex-wrap mr-2">
              {/*begin::Title*/}
              <h5 className="text-dark font-weight-bold mt-2 mr-5">Financeiro</h5>
              {/*end::Title*/}
              {/*begin::Separator*/}
              <div className="subheader-separator subheader-separator-ver mt-2 mb-2 bg-gray-200" />
              {/*end::Separator*/}
              {/*begin::Title*/}
              <h6 className="text-dark mt-2 mr-5 ml-5">Minhas Vendas</h6>
              {/*end::Title*/}
              {/*begin::Separator*/}
              <div className="subheader-separator subheader-separator-ver mt-2 mb-2 bg-gray-200" />
              {/*end::Separator*/}
              {/*begin::Title*/}
              <span className="breadcrumb breadcrumb-transparent mt-5 mr-5">Transferir</span>
              {/*end::Title*/}
            </div>
            {/*end::Details*/}
            {/*begin::Toolbar*/}
            <div className="d-flex align-items-center">
              
            </div>
            {/*end::Toolbar*/}
          </div>
        </div>
        
        {/*begin::Entry*/}
<div className="d-flex flex-column-fluid">
  {/*begin::Container*/}
  <div className="container">
    {/*begin::Card*/}
    <div className="card card-custom">
      {/*begin::Card Body*/}
      <div className="card-body p-0">
        {/*begin::Wizard 5*/}
        <div className="wizard wizard-5 d-flex flex-column flex-lg-row flex-row-fluid" id="kt_wizard">
          {/*begin::Aside*/}
          <div className="wizard-aside bg-white d-flex flex-column flex-row-auto w-100 w-lg-300px w-xl-400px w-xxl-500px">
            {/*begin::Aside Top*/}
            <div className="d-flex flex-column-fluid flex-column px-xxl-30 px-10">
              {/*begin: Wizard Nav*/}
              <div className="wizard-nav d-flex d-flex justify-content-center pt-10 pt-lg-20 pb-5">
                {/*begin::Wizard Steps*/}
                <div className="wizard-steps">
                  {/*begin::Wizard Step 1 Nav*/}
                  <div className="wizard-step" data-wizard-type="current" data-wizard-state={ficha >= 1 ? "current" : ''}>
                    <div className="wizard-wrapper">
                      <div className="wizard-icon">
                        <i className="wizard-check ki ki-check" />
                        <span className="wizard-number">1</span>
                      </div>
                      <div className="wizard-label">
                        <h3 className="wizard-title">Para onde deseja transferir</h3>
                        <div className="wizard-desc">Confira os detalhes da sua conta</div>
                      </div>
                    </div>
                  </div>
                  {/*end::Wizard Step 1 Nav*/}
                  {/*begin::Wizard Step 2 Nav*/}
                  <div className="wizard-step" data-wizard-type="step" data-wizard-state={ficha >= 2 ? "current" : ''}>
                    <div className="wizard-wrapper">
                      <div className="wizard-icon">
                        <i className="wizard-check ki ki-check" />
                        <span className="wizard-number">2</span> 
                      </div>
                      <div className="wizard-label">
                        <h3 className="wizard-title">Inserir valor</h3>
                        <div className="wizard-desc">Digite o valor</div>
                      </div>
                    </div>
                  </div>
                  {/*end::Wizard Step 2 Nav*/}
                  {/*begin::Wizard Step 3 Nav*/}
                  <div className="wizard-step" data-wizard-type="step" data-wizard-state={ficha >= 3 ? "current" : ''}>
                    <div className="wizard-wrapper">
                      <div className="wizard-icon">
                        <i className="wizard-check ki ki-check" />
                        <span className="wizard-number">3</span>
                      </div>
                      <div className="wizard-label">
                        <h3 className="wizard-title">Confirmar transferência</h3>
                        <div className="wizard-desc">Confira e Confirme</div>
                      </div>
                    </div>
                  </div>
                  {/*end::Wizard Step 3 Nav*/}
                </div>
                {/*end::Wizard Steps*/}
              </div>
              {/*end: Wizard Nav*/}
            </div>
            {/*end::Aside Top*/}
          </div>
          {/*begin::Aside*/}
          {/*begin::Content*/}
          <div className="wizard-content bg-gray-100 d-flex flex-column flex-row-fluid py-15 px-5 px-lg-10">
           {/*begin::Form*/}
            <div className="d-flex justify-content-center flex-row-fluid">
              <div class="pb-5 w-100 w-md-450px w-lg-500px" id="kt_form">
    <Wizard>
    <Steps>
      <Step
        id="onde"
        render={({ next }) => (
          <div>
            {/*begin::Title*/}
            <div className="pb-10 pb-lg-15">
              <h3 className="font-weight-bolder text-dark font-size-h2">Para onde deseja transferir?</h3>
              <div className="text-muted font-weight-bold font-size-h5">Saldo disponível: R$ 17,61</div>
            </div>
            {/*begin::Title*/}

            <div className="row">
              <div className="col-md-12 text-center">
                <button className="btn btn-primary btn-lg"><i className="fas fa-landmark" />Conta Bancaria</button>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-md-12">
                <Select
                  //options={estabelecimentos}
                  placeholder="Selecione..." 
                  //defaultValue={estabelecimentos.find(
                  //  x => x.value === estabelecimento
                  //)}
                  //onChange={(e) => {setEstabelecimento(e) }}
                />
              </div>
            </div>

              {/*begin: Wizard Actions*/}
              <div className="d-flex justify-content-between pt-3">
                <div className="mr-2">
                  
                </div>
              <div>
                    
              <button type="button" onClick={(e) => { next(); atualizaFicha('valor'); }} className="btn btn-primary font-weight-bolder font-size-h6 pl-8 pr-4 py-4 my-3" data-wizard-type="action-next">Avançar
                <span className="svg-icon svg-icon-md ml-1">
                  {/*begin::Svg Icon | path:assets/media/svg/icons/Navigation/Right-2.svg*/}
                  <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                    <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                      <polygon points="0 0 24 0 24 24 0 24" />
                      <rect fill="#000000" opacity="0.3" transform="translate(8.500000, 12.000000) rotate(-90.000000) translate(-8.500000, -12.000000)" x="7.5" y="7.5" width={2} height={9} rx={1} />
                      <path d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z" fill="#000000" fillRule="nonzero" transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)" />
                    </g>
                  </svg>
                  {/*end::Svg Icon*/}
                </span></button>
              </div>
            </div>
            {/*end: Wizard Actions*/}
          </div>
        )}
      />
      <Step
        id="gandalf"
        render={({ next, previous }) => (
          <div>
            {/*begin::Title*/}
            <div className="pb-10 pb-lg-15">
              <h3 className="font-weight-bolder text-dark font-size-h2">Inserir valor</h3>
              <div className="text-muted font-weight-bold font-size-h5">Saldo disponível: R$ 17,61</div>
            </div>
            {/*begin::Title*/}

            <div className="row">
              <div className="form-group col-md-6">
                <label>Valor</label>
                <input className="form-control"
                  placeholder="R$ 0,00" 
                  //value={margemEsquerda} 
                  //onChange={(e) => { setMudou(true); setMargemEsquerda(e.target.value)}} 
                  />
                </div>
      
              <div className="form-group col-md-6">
                <label>Identificação</label>
                <input className="form-control"
                  placeholder="Opcional" 
                  //value={margemEsquerda} 
                  //onChange={(e) => { setMudou(true); setMargemEsquerda(e.target.value)}} 
                  />
              </div>
            </div>
         

            {/*begin: Wizard Actions*/}
            <div className="d-flex justify-content-between pt-3">
              <div className="mr-2">
                <button type="button" onClick={(e) => { previous(); atualizaFicha('onde'); }} className="btn btn-light-primary font-weight-bolder font-size-h6 pl-6 pr-8 py-4 my-3 mr-3" data-wizard-type="action-prev">
                  <span className="svg-icon svg-icon-md mr-1">
                    {/*begin::Svg Icon | path:assets/media/svg/icons/Navigation/Left-2.svg*/}
                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                      <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                        <polygon points="0 0 24 0 24 24 0 24" />
                        <rect fill="#000000" opacity="0.3" transform="translate(15.000000, 12.000000) scale(-1, 1) rotate(-90.000000) translate(-15.000000, -12.000000)" x={14} y={7} width={2} height={10} rx={1} />
                        <path d="M3.7071045,15.7071045 C3.3165802,16.0976288 2.68341522,16.0976288 2.29289093,15.7071045 C1.90236664,15.3165802 1.90236664,14.6834152 2.29289093,14.2928909 L8.29289093,8.29289093 C8.67146987,7.914312 9.28105631,7.90106637 9.67572234,8.26284357 L15.6757223,13.7628436 C16.0828413,14.136036 16.1103443,14.7686034 15.7371519,15.1757223 C15.3639594,15.5828413 14.7313921,15.6103443 14.3242731,15.2371519 L9.03007346,10.3841355 L3.7071045,15.7071045 Z" fill="#000000" fillRule="nonzero" transform="translate(9.000001, 11.999997) scale(-1, -1) rotate(90.000000) translate(-9.000001, -11.999997)" />
                      </g>
                    </svg>
                    {/*end::Svg Icon*/}
                  </span>Voltar</button>
              </div>
            <div>
                    
              <button type="button" onClick={(e) => { next(); atualizaFicha('confirma'); }} className="btn btn-primary font-weight-bolder font-size-h6 pl-8 pr-4 py-4 my-3" data-wizard-type="action-next">Avançar
                <span className="svg-icon svg-icon-md ml-1">
                  {/*begin::Svg Icon | path:assets/media/svg/icons/Navigation/Right-2.svg*/}
                  <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                    <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                      <polygon points="0 0 24 0 24 24 0 24" />
                      <rect fill="#000000" opacity="0.3" transform="translate(8.500000, 12.000000) rotate(-90.000000) translate(-8.500000, -12.000000)" x="7.5" y="7.5" width={2} height={9} rx={1} />
                      <path d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z" fill="#000000" fillRule="nonzero" transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)" />
                    </g>
                  </svg>
                  {/*end::Svg Icon*/}
                </span></button>
              </div>
            </div>
            {/*end: Wizard Actions*/}
          </div>
        )}
      />
      <Step
        id="dumbledore"
        render={({ previous }) => (
          <div>
            {/*begin::Title*/}
            <div className="pb-10 pb-lg-15">
              <h3 className="font-weight-bolder text-dark font-size-h2">Confirmar transferência</h3>
            </div>
            {/*begin::Title*/}

            <div className="row">
              <div className="form-group col-md-6">
                <label>Valor</label>
                <h6>R$ 0,12</h6>
              </div>
      
              <div className="form-group col-md-6">
                <label>Identificação</label>
                <h6>Dim dim para o papai</h6>
              </div>
            </div>

            <div className="row">
              <div className="form-group col-md-6">
                <label>Favorecido</label>
                <h6>
                  RENNO DE ANGELI MACHIOLLI<br />
                  CNPJ: 27.506.990/0001-90
                </h6>
              </div>
      
              <div className="form-group col-md-6">
                <label>Conta Bancária</label>
                <h6>
                  Nu Pagamentos<br />
                  Agência: 0001 - Conta: 89970453
                </h6>
              </div>
            </div>

            {/*begin: Wizard Actions*/}
            <div className="d-flex justify-content-between pt-3">
              <div className="mr-2">
                <button type="button" onClick={(e) => { previous(); atualizaFicha('valor'); }} className="btn btn-light-primary font-weight-bolder font-size-h6 pl-6 pr-8 py-4 my-3 mr-3" data-wizard-type="action-prev">
                  <span className="svg-icon svg-icon-md mr-1">
                    {/*begin::Svg Icon | path:assets/media/svg/icons/Navigation/Left-2.svg*/}
                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                      <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                        <polygon points="0 0 24 0 24 24 0 24" />
                        <rect fill="#000000" opacity="0.3" transform="translate(15.000000, 12.000000) scale(-1, 1) rotate(-90.000000) translate(-15.000000, -12.000000)" x={14} y={7} width={2} height={10} rx={1} />
                        <path d="M3.7071045,15.7071045 C3.3165802,16.0976288 2.68341522,16.0976288 2.29289093,15.7071045 C1.90236664,15.3165802 1.90236664,14.6834152 2.29289093,14.2928909 L8.29289093,8.29289093 C8.67146987,7.914312 9.28105631,7.90106637 9.67572234,8.26284357 L15.6757223,13.7628436 C16.0828413,14.136036 16.1103443,14.7686034 15.7371519,15.1757223 C15.3639594,15.5828413 14.7313921,15.6103443 14.3242731,15.2371519 L9.03007346,10.3841355 L3.7071045,15.7071045 Z" fill="#000000" fillRule="nonzero" transform="translate(9.000001, 11.999997) scale(-1, -1) rotate(90.000000) translate(-9.000001, -11.999997)" />
                      </g>
                    </svg>
                    {/*end::Svg Icon*/}
                  </span>Voltar</button>
              </div>
            <div>
                    
              <button type="button" className="btn btn-success font-weight-bolder font-size-h6 pl-8 pr-4 py-4 my-3" data-wizard-type="action-next">Confirmar
                <span className="svg-icon svg-icon-md ml-1">
                  {/*begin::Svg Icon | path:assets/media/svg/icons/Navigation/Right-2.svg*/}
                  <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                    <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                      <polygon points="0 0 24 0 24 24 0 24" />
                      <rect fill="#000000" opacity="0.3" transform="translate(8.500000, 12.000000) rotate(-90.000000) translate(-8.500000, -12.000000)" x="7.5" y="7.5" width={2} height={9} rx={1} />
                      <path d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z" fill="#000000" fillRule="nonzero" transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)" />
                    </g>
                  </svg>
                  {/*end::Svg Icon*/}
                </span></button>
              </div>
            </div>
            {/*end: Wizard Actions*/}
          </div>
        )}
      />
    </Steps>
  </Wizard>
</div>
              
            </div>
            {/*end::Form*/}
          </div>
          {/*end::Content*/}
        </div>
        {/*end::Wizard 5*/}
      </div>
      {/*end::Card Body*/}
    </div>
    {/*end::Card*/}
  </div>
  {/*end::Container*/}
</div>
{/*end::Entry*/}
{/*end::Content*/}
          
      </div>           
        </>
    );
}

export default withRouter(FCDados);                  