import Api from '../services/api';
import Swal from "sweetalert2";

async function MudaStatus(url, parametros, addT, callback) {
    Api.post(url, parametros).then((rps) => {
        if(rps.data.status===true){
            addT(rps.data.mensagem, {
                appearance: "success",
                autoDismiss: true,
                autoDismissTimeout: 2000
            });
            callback();
        } else {
            Swal.fire({
                title: "Atenção!",
                icon: "info",
                html: rps.data.erro,
                showCloseButton: true,
                showCancelButton: false
            });
        }
    });
}

export default MudaStatus;