import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  .cor-icone-red {
    color:#F64E60;
  }

  .mt--10 {
    margin-top: -10px;
  }

  .dropdown-nocaret::after {
    display: none !important;
  }
`

export default GlobalStyle
