import styled, { keyframes } from "styled-components";

export const Container = styled.div`
  h2 {
    font-family: "Nunito";
    font-weight: 600;
    font-size: 16px;
    color: #333333;
    margin-bottom: 17px;
  }

  small {
    font-family: "Nunito";
    font-size: 12px;
    color: #333333;
  }
`;

export const UploadContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 25px;
  margin-bottom: 5px;
`;

export const ProgressBarContainer = styled.div`
  width: 530px;
  height: 32px;
  background: #ecf0f3;
  border-radius: 8px;
`;
export const Gradient = keyframes`
    from {
      background-position: 0 0;
    }
    to {
      background-position: -200% 0;
    }
  `;

export const ProgressBar = styled.div`
  width: ${({ width, loading }) => (loading ? 100 : width)}%;
  transition: width 0.5s;
  height: 100%;
  background: ${({ loading }) =>
    loading
      ? "repeating-linear-gradient(to right, #dcdcdc 0%, #f1f2f3 50%, #dcdcdc 100%)"
      : "repeating-linear-gradient(to right, #00b333 0%, #08cf41 50%, #00b333 100%)"};

  background-size: 200% auto;
  background-position: 0 100%;
  animation: ${Gradient} 2s infinite;
  animation-fill-mode: forwards;
  animation-timing-function: linear;

  border-radius: 8px;
`;

export const CancelUploadButton = styled.button`
  text-align: center;
  height: 32px;
  background: #ffffff;
  border: 1px solid #ff3333;
  border-radius: 8px;
  color: #ff3333;
  padding: 0 25px;
`;
