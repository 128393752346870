import React, { useState, useEffect } from 'react';
import Api from '../../../../services/api';
import { withRouter } from "react-router";
import { cpf, cnpj } from 'cpf-cnpj-validator';

import Swal from "sweetalert2";
import { useToasts } from 'react-toast-notifications';
import LoadingOverlay from 'react-loading-overlay';
import Axios from 'axios';
import DataTable from 'react-data-table-component';
import { Modal } from "react-bootstrap";
import Select from 'react-select';
import CpfCnpjInput from 'react-cpf-cnpj-input';

import ReactTooltip from 'react-tooltip';
import Contratos from '../../../../components/contratos';
import Apiv2 from '../../../../services/apiv2';

const FCPlanos = () => {
  const { addToast } = useToasts();
  const [caProcessa, setCaProcessa] = useState(false);
  const [pagOnCadastro, setPagOnCadastro] = useState([]);
  const [listaBancoRecebimentos, setListaBancoRecebimentos] = useState([]);
  const [formCadBanco, setFormCadBanco] = useState({});
  const [modalBanco, setModalBanco] = useState(false);
  const [pagOnBancos, setPagOnBancos] = useState([]);

  const [planAndTax, setPlanAndTax] = useState({ html: '' });
  const [otherInfo, setOtherInfo] = useState({});
  const [loading, setLoading] = useState(true);


  const [acceptsOnlinePayment, setAcceptsOnlinePayment] = useState(false);
  const [loadingAcceptsOnlinePayment, setLoadingAcceptsOnlinePayment] = useState(true);

  useEffect(() => {
    getListarBancoRecebimentos();

    getOtherInfo();
    getPlanAndTax();
    getAcceptanceStatus();
  }, []);

  //Tabela Bancos
  const dataBancoRecebimento = listaBancoRecebimentos;
  const columnsBancoRecebimento = [
    {
      name: <th>Banco</th>,
      selector: 'banco',
      sortable: true,
      width: '20%',
      cell: row => <>{row.banco} - {row.nome}</>
    },
    {
      name: <th>Tipo de conta</th>,
      selector: 'nome_bairro',
      sortable: true,
      width: '15%',
      center: true,
      cell: row => {
        if (row.tipo_conta == 'CC') { return <div className="label label-primary label-inline"><b>Conta Corrente</b></div> }
        else if (row.tipo_conta == 'CP') { return <div className="label label-info label-inline"><b>Conta Poupança</b></div> }
      }
    },
    {
      name: <th>Nome do titular</th>,
      selector: 'nome_titular',
      sortable: true,
      width: '16%',
      cell: row => <>{row.nome_titular}</>
    },
    {
      name: <th>CNPJ/CPF</th>,
      selector: 'cnpj_cpf',
      sortable: true,
      width: '15%',
      center: true,
    },
    {
      name: <th>Agência</th>,
      selector: 'agencia',
      sortable: true,
      width: '10%',
      center: true,
      cell: row => <>{row.agencia} {row.agencia_dig}</>
    },
    {
      name: <th>Conta</th>,
      selector: 'conta',
      sortable: true,
      width: '15%',
      center: true,
    },
    {
      name: <th>Ações</th>,
      cell: (row) => {
        if (row.status == 'S') {
          { return <div className="label label-success label-inline"><b>Conta Ativa</b></div> }
        } else {
          return (<>
            <div class="dropdown dropdown-inline">
              <button type="button" class="btn btn-light-primary btn-icon btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i class="ki ki-bold-more-ver"></i>
              </button>
              <div class="dropdown-menu">
                <a class="dropdown-item" onClick={e => { ativarPagOnBanco(row) }}>Ativar</a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" onClick={e => { editarPagOnBanco(row) }}>Editar</a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" onClick={e => { excluirPagOnBanco(row) }}>Excluir</a>
              </div>
            </div>
          </>)
        }
      },
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      right: true,
    },
  ];

  function getOtherInfo() {
    Api.get("pagamentoonline/otherInfo").then((rps) => {
      console.log(rps.data);

      if (rps.data.status === true) {
        setLoading(false);
        var data = rps.data.data;

        setOtherInfo(data);


      }
    })
  }

  function getPlanAndTax() {
    Api.get("pagamentoonline/getTaxAndPlans").then((rps) => {
      if (rps.data.status === true) {
        setLoading(false);
        var data = rps.data.planAndTax;

        setPlanAndTax(data);
      }
    });
  }

  function getAcceptanceStatus() {
    Api.get("pagamentoonline/getAcceptanceStatus").then((rps) => {
      if (rps.data.status === true) {
        setLoadingAcceptsOnlinePayment(false);
        var data = rps.data.acceptsOnline;
        setAcceptsOnlinePayment(data);
      }
    });
  }

  function changeAcceptsOnlinePayment(newValue) {
    setLoadingAcceptsOnlinePayment(true);
    Api.post("pagamentoonline/setAcceptanceStatus", { accepts: newValue }).then((rps) => {
      var data = rps.data;
      setLoadingAcceptsOnlinePayment(false);
      setAcceptsOnlinePayment(data.accepts);

      if (data.status === false) {
        Swal.fire({
          title: "Erro!",
          icon: "error",
          html: data.erros,
          showCloseButton: true,
          showCancelButton: false
        });
      } else {
        addToast(data.mensagem, {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 2000
        });
      }
    });
  }


  function getListarBancoRecebimentos() {
    var data = {
      idPagOnCadastro: localStorage.getItem('id_estabelecimento')
    };

    Api.post("pagamentoonline/listarBancoRecebimentos", data).then(rps => {
      setListaBancoRecebimentos(rps.data.obj);
    })
  }

  function ativarPagOnBanco(idPagOnBanco) {
    setCaProcessa(true);
    var banco = JSON.parse(JSON.stringify(idPagOnBanco));

    Api.post('pagamentoonline/ativarPagOnBanco', banco).then(rps => {
      if (rps.data.status === true) {
        addToast(rps.data.mensagem, {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 2000
        });
        getListarBancoRecebimentos(pagOnCadastro?.id_pagon_cadastro);
        setCaProcessa(false);
      } else {
        Swal.fire({
          title: "Erro!",
          icon: "error",
          html: rps.data.erros,
          showCloseButton: true,
          showCancelButton: false,
        });
      }
    });
  }

  function fecharModalBanco() {
    setCaProcessa(false);
    setModalBanco(false);
  }

  function salvarBancoPagOn() {
    setCaProcessa(true);
    Api.post('pagamentoonline/adicionarPagOnBanco', formCadBanco).then(rps => {
      if (rps.data.status === true) {
        addToast(rps.data.mensagem, {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 2000
        });
        getListarBancoRecebimentos(pagOnCadastro?.id_pagon_cadastro);
        setModalBanco(false);
        setCaProcessa(false);
      } else {
        Swal.fire({
          title: "Erro!",
          icon: "error",
          html: rps.data.erros,
          showCloseButton: true,
          showCancelButton: false,
        });
      }
    });
  }


  function editarPagOnBanco(idPagOnBanco) {
    var banco = JSON.parse(JSON.stringify(idPagOnBanco));

    getListarBanco();
    setFormCadBanco(banco);
    setModalBanco(true);
  }

  function excluirPagOnBanco(idPagOnBanco) {
    var banco = JSON.parse(JSON.stringify(idPagOnBanco));

    setCaProcessa(true);

    Api.post('pagamentoonline/excluirPagOnBanco', banco).then(rps => {
      if (rps.data.status === true) {
        Swal.fire({
          title: "Confirmação!",
          icon: "info",
          html: rps.data.mensagem,
          showCloseButton: true,
          showCancelButton: false,
        });
        getListarBancoRecebimentos(pagOnCadastro?.id_pagon_cadastro);
        setCaProcessa(false);
      }
    });
  }

  function getListarBanco() {

    Api.get("pagamentoonline/listarBancos").then(rps => {
      var bancos = rps.data.obj;
      var nBancos = [];
      bancos.forEach(e => {
        nBancos[nBancos.length] = {
          value: e.cod,
          label: e.cod + ' - ' + e.banco,
          ispb: e.ispb
        };
      })
      setPagOnBancos(nBancos);
    })
  }

  function getPagOnCadastro() {
    var data = {
      id_estabelecimento: localStorage.getItem('id_estabelecimento')
    };

    Api.post('pagamentoonline/listarPagOnCadastro', data).then(rps => {

      setPagOnCadastro(rps.data.obj);
    });
  }

  function editarDados() {

  }

  function ativarPlano(idPlano) {
    var data = {
      id_pagon_cadastro: pagOnCadastro?.id_pagon_cadastro,
      id_pagon_planos_taxas: idPlano,
    };
    setCaProcessa(true);
    Api.post('pagamentoonline/ativarPagOnPlano', data).then(rps => {
      if (rps.data.status === true) {
        addToast(rps.data.mensagem, {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 2000
        });
        getPagOnCadastro(pagOnCadastro?.id_estabelecimento);
        setCaProcessa(false);
      } else {
        Swal.fire({
          title: "Erro!",
          icon: "error",
          html: rps.data.erros,
          showCloseButton: true,
          showCancelButton: false,
        });
      }
    });
  }

  function getPagOnCadastro(idEstabelecimento) {

    var data = {
      id_estabelecimento: idEstabelecimento
    };

    Api.post('pagamentoonline/listarPagOnCadastro', data).then(rps => {

      setPagOnCadastro(rps.data.obj);
    });
  }


  const [chavePix, setChavePix] = useState(null);
  const [loadingChave, setLoadingChave] = useState(false);
  function buscaChave() {
    setLoadingChave(true);
    Apiv2.post("/onlinepayment/bankaccount/getPix", { chave: chavePix }).then((rps) => {
      setLoadingChave(false);
      if (rps.data.status !== true) {
        Swal.fire({
          title: "Erro!",
          icon: "error",
          html: rps.data.message,
          showCloseButton: true,
          showCancelButton: false,
        });
        return;
      }

      var bancoS = '';
      pagOnBancos.forEach(banco => {
        if ((banco.ispb).indexOf(rps.data.ispb) !== -1) {
          bancoS = banco.value;
        }
      })

      if (bancoS === '') {
        Swal.fire("Não conseguimos selecionar o banco automaticamente. Selecione na lista ou entre em contato com o suporte.");
      }

      setFormCadBanco({
        ...formCadBanco,
        agencia: rps.data.branchCode,
        conta: rps.data.accountNumber,
        tipo_conta: rps.data.accountType,
        banco: bancoS
      })

    })
  }

  return (
    <>
      <ReactTooltip />
      <div className="subheader espaco-header-pedidos subheader-transparent" id="kt_subheader">
        <div className="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
          {/*begin::Details*/}
          <div className="d-flex align-items-center flex-wrap mr-2">
            {/*begin::Title*/}
            <h5 className="text-dark font-weight-bold mt-2 mr-5">Pagamento Online</h5>
            {/*end::Title*/}
            {/*begin::Separator*/}
            <div className="subheader-separator subheader-separator-ver mt-2 mb-2 bg-gray-200" />
            {/*end::Separator*/}
            {/*begin::Title*/}
            {/*<h6 className="text-dark mt-2 mr-5 ml-5">Contas e Taxas</h6>*/}
            {/*end::Title*/}
            {/*begin::Separator*/}
            {/*<div className="subheader-separator subheader-separator-ver mt-2 mb-2 bg-gray-200" />*/}
            {/*end::Separator*/}
            {/*begin::Title*/}
            <span className="breadcrumb breadcrumb-transparent mt-5 mr-5">Contas e Taxas</span>
            {/*end::Title*/}
          </div>
          {/*end::Details*/}
          {/*begin::Toolbar*/}
          <div className="d-flex align-items-center">
          </div>
          {/*end::Toolbar*/}
        </div>
      </div>

      {/*begin::Entry*/}
      <div className="d-flex flex-column-fluid mb-10">
        {/*begin::Container*/}
        <div className="container">
          <div className="card card-custom">
            <div className="ml-10 mr-10 mt-10 mb-10">

              {/*begin::Group - Pagamento Online - Recebimentos*/}
              <h6 className="font-weight-boldest">Como deseja receber seus pagamentos?</h6><br />
              <div className="row">
                <div className="col-md-6">
                  <div className="radio-list">
                    <label className="radio text-justify">
                      <input type="radio" name="radios1" checked="checked" />
                      <span></span><label className="font-weight-bolder">Pagamento Automático</label><br />
                      Receba o valor de suas vendas automaticamente na sua conta bancária levando em consideração seu plano e taxa ativo.
                      <br />

                    </label>
                    <label className="radio text-justify">
                      Pagamento Online
                      <LoadingOverlay active={loadingAcceptsOnlinePayment} spinner text='Carregando...' >
                        <span className="switch switch-lg switch-icon">
                          <label>
                            <input
                              checked={acceptsOnlinePayment === true}
                              type="checkbox"
                              onClick={(e) => changeAcceptsOnlinePayment(e.target.checked)}
                              name="select"
                            />
                            <span></span>
                          </label>
                        </span>
                      </LoadingOverlay>
                    </label>
                    {/*<label className="radio text-justify">
												<input type="radio" name="radios1" />
												<span></span><label className="font-weight-bolder">Pagamento em Conta Digital</label><br/>
                          Receba pelas suas vendas direto na Conta Digital, sem custo adicional. 
  Faça transferências sob demanda quando quiser.</label>*/}
                  </div>
                </div>

                <div className="col-md-6">

                  <div className="align-items-center mb-9 bg-light-success rounded p-5">
                    <label className="font-weight-bolder text-success font-size-lg">Taxas/plano</label>
                    <div dangerouslySetInnerHTML={{ __html: planAndTax.html }}></div>

                  </div>

                </div>


              </div>

              <div className="row">
                <div className="col-md-12 text-right">
                  <button className="btn btn-primary" onClick={e => {
                    setModalBanco(true);
                    setFormCadBanco();
                    getListarBanco();
                    setFormCadBanco({
                      id_estabelecimento: otherInfo.id_estabelecimento,
                      cnpj_cpf: otherInfo.cnpj_cpf,
                      nome_titular: otherInfo.razao_social
                    });
                  }}>Cadastrar conta</button>
                </div>
              </div>

              <LoadingOverlay
                active={caProcessa}
                spinner
                text='Carregando...'
              >
                <DataTable
                  title="Contas Cadastradas"
                  columns={columnsBancoRecebimento}
                  data={dataBancoRecebimento}
                  striped="true"
                  pagination="true"
                />
              </LoadingOverlay>

              {/*end::Group - Pagamento Online - Recebimentos*/}

            </div>
            <div class="card-footer text-right">

            </div>
          </div>
        </div>
      </div>

      <Modal size={'lg'} centered show={modalBanco} onHide={() => fecharModalBanco()}>
        <Modal.Header>
          <Modal.Title>Cadastrar Conta</Modal.Title>
          <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={e => { fecharModalBanco() }}>
            <i aria-hidden="true" className="ki ki-close" />
          </button>
        </Modal.Header>


        <div className="row ml-3 mr-3 mt-5">
          <div className="col-md-12">
            <div className="align-items-center mb-5 bg-light-danger rounded p-5">
              <label className="font-weight-bolder text-danger font-size-lg">Atenção</label>
              <label className='text-justify'>A conta bancária necessita ser da mesma titularidade (<b>{formCadBanco?.nome_titular}</b>) do CPF/CNPJ (<b>{formCadBanco?.cnpj_cpf}</b>) cadastrado no aplicativo.</label>
            </div>
          </div>
        </div>

        <div className="row ml-3 mr-3">
          <div className="form-group col-md-12">
            <label>Nome do Titular</label>
            <input className="form-control"
              value={formCadBanco?.nome_titular}
              onChange={(e) => { setFormCadBanco({ ...formCadBanco, nome_titular: e.target.value }) }}
              disabled={true}
            />
          </div>
        </div>

        <div className="row ml-3 mr-3">
          <div className="form-group col-md-12">
            <label>CNPJ/CPF (P/ alterar ligue (27) 4042-1580)</label>
            {/*<CpfCnpjInput className="form-control"
              maskField="_" 
              value={formCadBanco?.cnpj_cpf} 
              onChange={(e) => { setFormCadBanco({...formCadBanco, cnpj_cpf: e.target.value}) }}
              onBlur={(e) => {validaCpfCnpj(e.target.value) }} 
              disabled={true}
              />  */}
            <input className="form-control"
              value={formCadBanco?.cnpj_cpf}
              onChange={(e) => { setFormCadBanco({ ...formCadBanco, cnpj_cpf: e.target.value }) }}
              disabled={true}
            />
          </div>
        </div>

        <div className="row ml-3 mr-3">
          <div className="col-md-6">
            <label>Chave PIX</label>
            <input className="form-control"
              value={chavePix}
              onChange={(e) => { setChavePix(e.target.value) }}
            />
          </div>
          <div className="col-md-2"><br />
            <button disabled={loadingChave} className="btn btn-info" onClick={e => buscaChave()}>Carregar dados</button>
          </div>
          <div className="col-md-4">
          </div>
        </div>

        <LoadingOverlay active={loadingChave} spinner text='Carregando...' >

          <div className="row ml-3 mr-3">
            <div className="col-md-12">
              <label>Selecione o tipo da conta</label>
              <div className="radio-inline">
                <label className="radio text-justify">
                  <input type="radio" onChange={(e) => setFormCadBanco({ ...formCadBanco, tipo_conta: "CC" })} checked={formCadBanco?.tipo_conta === "CC"} />
                  <span></span><label className="font-weight-bolder">Conta Corrente</label>
                </label>
                <label className="radio text-justify">
                  <input type="radio" onChange={(e) => setFormCadBanco({ ...formCadBanco, tipo_conta: "CP" })} checked={formCadBanco?.tipo_conta === "CP"} />
                  <span></span><label className="font-weight-bolder">Conta Poupança</label>
                </label>
              </div>
            </div>
          </div>

          <div className="row ml-3 mr-3">
            <div className="form-group col-md-12">
              <label>Banco</label>
              <Select
                placeholder="Selecione o banco"
                options={pagOnBancos}
                value={pagOnBancos.find(x => x.value === formCadBanco?.banco)}
                onChange={(e) => { setFormCadBanco({ ...formCadBanco, banco: e.value }) }}
              />
            </div>
          </div>

          <div className="row ml-3 mr-3">
            <div className="form-group col-md-9">
              <label>Agência</label>
              <input className="form-control"
                value={formCadBanco?.agencia}
                maxlength="4"
                onChange={(e) => { setFormCadBanco({ ...formCadBanco, agencia: e.target.value }) }} />
            </div>

            <div className="form-group col-md-3">
              <label>Dígito (Opcional)</label>
              <input className="form-control"
                maxlength="1"
                value={formCadBanco?.agencia_dig}
                onChange={(e) => { setFormCadBanco({ ...formCadBanco, agencia_dig: e.target.value }) }} />
            </div>
          </div>

          <div className="row ml-3 mr-3">
            <div className="form-group col-md-12">
              <label>Conta (com dígito)</label>
              <input className="form-control"
                value={formCadBanco?.conta}
                onChange={(e) => { setFormCadBanco({ ...formCadBanco, conta: e.target.value }) }} />
            </div>
          </div>

        </LoadingOverlay>
        <Modal.Footer>
          <button type="button" onClick={e => { fecharModalBanco(); }} className="btn btn-light-primary font-weight-bold" data-dismiss="modal">Fechar</button>
          <button type="button" onClick={e => { salvarBancoPagOn(); }} className="btn btn-success font-weight-bold">Salvar</button>
        </Modal.Footer>
      </Modal>

    </>

  );
}

export default withRouter(FCPlanos);                  