import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Select from "react-select";

import apiRemaster from "../../../../services/apiRemaster";

export function CoverageAreasModal({
  orderId,
  show,
  handleClose,
  handleCallDeliveryMan,
}) {
  const [loading, setLoading] = useState(false);
  const [coverageAreas, setCoverageAreas] = useState([]);
  const [coverageAreaID, setCoverageAreaID] = useState();
  const [coverageAreaName, setCoverageAreaName] = useState(null);

  const formatter = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  });

  useEffect(() => {
    const load = async () => {
      setLoading(true);
      await apiRemaster.post("delivery/auth", {});
      await apiRemaster
        .get("v2/delivery/coverageAreas")
        .then((response) => {
          const data = response.data.coverageAreas;

          const arr = data
            .filter((d) => d.enabled)
            .filter((d) => !!d.coverageAreaID)
            .filter((d) => !!d.coverageArea?.name);

          const countMap = {};

          arr.forEach((item) => {
            const name = item.coverageArea?.name?.toLowerCase();
            countMap[name] = (countMap[name] || 0) + 1;
          });

          const duplicatedItems = Object.entries(countMap)
            .filter(([, value]) => Number(value) > 1)
            .map(([key]) => key);

          setCoverageAreas(
            arr.map((item) =>
              duplicatedItems.includes(item?.coverageArea?.name.toLowerCase())
                ? {
                    ...item,
                    coverageArea: {
                      ...item.coverageArea,
                      name: `${item?.coverageArea?.name} (${item?.coverageArea?.city?.name})`,
                    },
                  }
                : item
            )
          );
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    };

    load();
  }, []);

  return (
    <Modal size="lg" show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Selecione o Bairro de entrega do pedido:</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Select
          isMulti={false}
          isSearchable={true}
          placeholder="Selecione o bairro..."
          options={coverageAreas.map((x) => ({
            value: { id: x.coverageAreaID, name: x.coverageArea?.name },
            label: `${x.coverageArea?.name} - ${formatter.format(x.price)}`,
          }))}
          isLoading={loading}
          isDisabled={loading}
          onChange={({ value }) => {
            setCoverageAreaID(value.id);
            setCoverageAreaName(value.name);
          }}
        />
      </Modal.Body>

      <Modal.Footer>
        <Button variant="outline-primary" onClick={handleClose}>
          Fechar
        </Button>
        <Button
          variant="success"
          onClick={() =>
            handleCallDeliveryMan(orderId, coverageAreaID, coverageAreaName)
          }
        >
          Chamar entregador
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
