import React, { useState, useEffect, useMemo, useCallback } from "react";
import arrayMove from "array-move";
import { withRouter } from "react-router";
import LoadingOverlay from "react-loading-overlay";
import Axios from "axios";
import Swal from "sweetalert2";
import { useToasts } from "react-toast-notifications";
import "react-tabs/style/react-tabs.css";

import Api from "../../services/api";

import VariacoesModal from "../variacoes/modal/index";

import ExcluirGenerico from "../../helpers/excluirGenerico";
import MudaStatus from "../../helpers/mudaStatus";

import SortableCategory from "./components/SortableCategory";
import SortableVirtualizedProductList from "./components/SortableVirtualizedProduct";

import ModalCategory from "./components/ModalCategory";
import ModalProduct from "./components/ModalProduct";

import { Container } from "./styles";

const Products = () => {
  const statusVar = useMemo(
    () => [
      { value: "S", label: "Ativo" },
      { value: "F", label: "Em falta" },
      { value: "O", label: "Oculto" },
    ],
    []
  );

  const { addToast } = useToasts();
  const refImagem = React.useRef(null);

  const [modalProducts, setModalProducts] = useState(false);

  const [ingredientes, setIngredientes] = useState([]);
  const [variacoes, setVariacoes] = useState([]);
  const [startedInitialData, setStartedInitialData] = useState(false);

  const [caCategoria, setCaCategoria] = useState(true);
  const [caProdutos, setCaProdutos] = useState(false);
  const [caProdutoSelecionado, setCaProdutoSelecionado] = useState(false);

  const [products, setProducts] = useState([]);
  const [FilteredProducts, setFilteredProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [
    stringDisponibilidadeProduto,
    setStringDisponibilidadeProduto,
  ] = useState([]);

  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);

  const [modalCategorias, setModalCategorias] = useState(false);
  const [formCategorias, setFormCategorias] = useState({});
  const [
    stringDisponibilidadeCategoria,
    setStringDisponibilidadeCategoria,
  ] = useState({});

  const [formTamanho, setFormTamanho] = useState({});
  const [frmEdtTamanho, setFrmEdtTamanho] = useState({});

  const [modalVariacoesState, setModalVariacoesState] = useState(false);
  const [variacao, setVariacao] = useState(false);
  const [formVariacoes, setFormVariacoes] = useState();

  const [formIngredientes, setFormIngredientes] = useState();

  const [isSelectInitialCategory, setIsSelectInitialCategory] = useState(false);

  /*Inicio - Dados externos*/
  async function getIngredientes() {
    const response = await Api.get("estoque/listar");
    if (response.data.status !== undefined && response.data.status === true) {
      var est = response.data.estoque;
      var nEst = [];
      nEst[nEst.length] = { value: 0, label: "Selecione..." };
      est.forEach((e) => {
        nEst[nEst.length] = { value: e.id_estoque, label: e.nome_item };
      });
      setIngredientes(nEst);
    }
  }
  async function getVariacoes() {
    const response = await Api.get("variacoes/listar");
    if (response.data.status !== undefined && response.data.status === true) {
      var vari = response.data.variacoes;
      var nVari = [];
      nVari[nVari.length] = { value: 0, label: "Selecione..." };
      vari.forEach((v) => {
        nVari[nVari.length] = { value: v.id_variacao, label: v.nome_variacao };
      });
      setVariacoes(nVari);
    }
  }
  /*Final - Dados externos*/

  const resetFormVariacoes = useCallback(() => {
    setFormVariacoes({
      id_variacao: 0,
      nome_variacao_avulsa: "",
      id_cardapio_tamanho: "",
    });
  }, []);

  /* #region Funcoes Ingredients */
  const resetFormIngredientes = useCallback(() => {
    setFormIngredientes({
      quantidade: 1,
      id_estoque: 0,
      removivel: true,
    });
  }, []);
  /* #endregion */

  /* #region Funcoes Categoria */
  const getProductCategories = useCallback(async () => {
    setCaCategoria(true);
    const response = await Api.get("cardapiocategorias/listar");
    if (response.data.status !== undefined && response.data.status === true) {
      setCaCategoria(false);
      if (selectedCategory !== undefined) {
        var nCategories = [];
        response.data.categorias.forEach((c) => {
          if (
            selectedCategory.id_cardapio_categoria === c.id_cardapio_categoria
          ) {
            c.selectedClass = "categoria-selecionada";
          } else {
            c.selectedClass = "";
          }
          nCategories[nCategories.length] = c;
        });
        setCategories(nCategories);
      } else {
        setCategories(response.data.categorias);
      }
    }

    //Verificar se temos ID da categoria na URL
    //if(match.match.params.id!=undefined){
    //setCategoryLoading(false)
  }, [selectedCategory]);

  const handleCloseCategorias = useCallback(() => {
    getProductCategories();
    setModalCategorias(false);
  }, [getProductCategories]);

  const salvarCategoria = useCallback(() => {
    console.log(formCategorias);
    var nId = "";
    if (formCategorias.id_cardapio_categoria !== undefined)
      nId = formCategorias.id_cardapio_categoria;
    var nCard = {
      nome_categoria: formCategorias.nome_categoria,
      status: formCategorias.status,
      disponibilidade: stringDisponibilidadeCategoria,
    };
    Api.post("cardapiocategorias/adicionar/" + nId, nCard).then((rps) => {
      if (rps.data.status !== undefined && rps.data.status === true) {
        addToast(rps.data.mensagem, {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 2000,
        });
        setFormCategorias({
          ...formCategorias,
          id_cardapio_categoria: rps.data.id_cardapio_categoria,
        });
      } else {
        Swal.fire({
          title: "Atenção!",
          icon: "info",
          html: rps.data.erro,
          showCloseButton: true,
          showCancelButton: false,
        });
      }
    });
  }, [addToast, formCategorias, stringDisponibilidadeCategoria]);

  const editarCategoria = useCallback((obj) => {
    if (obj === null) {
      obj = {};
      obj.titulo = "Cadastrando nova categoria";
      obj.status = "S";
      obj.nome_categoria = "";
    } else {
      obj.titulo = "Editando categoria";
    }

    setFormCategorias(obj);
    setModalCategorias(true);
  }, []);

  const excluirCategoria = useCallback(
    (cat) => {
      ExcluirGenerico(
        "cardapiocategorias/excluir",
        { id_cardapio_categoria: cat.id_cardapio_categoria },
        addToast,
        () => {
          getProductCategories();
        }
      );
    },
    [addToast, getProductCategories]
  );

  const onSortEndCategory = useCallback(
    ({ oldIndex, newIndex }) => {
      let newOrder = arrayMove(categories, oldIndex, newIndex);
      setCategories(newOrder);
      Api.post("cardapiocategorias/ordem", { ordem: newOrder }).then((rps) => {
        if (rps.data.status === true) {
          addToast(rps.data.mensagem, {
            appearance: "success",
            autoDismiss: true,
            autoDismissTimeout: 2000,
          });
        }
      });
    },
    [addToast, categories]
  );
  /* #endregion */

  /* #region Funcoes Composicao */
  const onSortEndComposition = useCallback(
    ({ oldIndex, newIndex }) => {
      let newOrder = arrayMove(
        selectedProduct?.ingredientes,
        oldIndex,
        newIndex
      );
      setSelectedProduct({
        ...selectedProduct,
        ingredientes: newOrder,
      });
      Api.post("cardapioestoque/ordem", { ordem: newOrder }).then((rps) => {
        if (rps.data.status === true) {
          addToast(rps.data.mensagem, {
            appearance: "success",
            autoDismiss: true,
            autoDismissTimeout: 2000,
          });
        }
      });
    },
    [addToast, selectedProduct]
  );
  /* #endregion */

  const selecionaProduto = useCallback(
    (product) => {
      if (product === null) {
        product = [];
        product.titulo = "Adicionando produto";
        product.tabsAllowed = false;
        //*initDisponibilidade(null);
        resetFormIngredientes();
        resetFormVariacoes();
        product.multipleSize = false;

        //Inicializando arrays
        product.ingredientes = [];
        product.tamanhos = [];
        product.variacoes = [];
        product.id_cardapio_categoria = selectedCategory.id_cardapio_categoria;
        product.id_cardapio = null;
      } else {
        product.titulo = "Editando produto " + product.nome_prod;
        product.tabsAllowed = true;
        //*initDisponibilidade(product?.disponibilidade);
        resetFormIngredientes();
        resetFormVariacoes();

        if (product?.tamanhos.length > 0) {
          product.multipleSize = true;
          product.preco = 0;
          product.novo_preco = 0;
        } else {
          product.multipleSize = false;
        }
      }

      //CORRIGIR
      product.alwaysAvailable = false;

      setSelectedProduct(product);
      setModalProducts(true);
    },
    [
      resetFormIngredientes,
      resetFormVariacoes,
      selectedCategory.id_cardapio_categoria,
    ]
  );
  const atualizaProduto = useCallback(
    (ida) => {
      setCaProdutoSelecionado(true);
      if (ida === undefined) ida = selectedProduct.id_cardapio;
      Api.get(
        "cardapio/listar/" + selectedCategory.id_cardapio_categoria + "/" + ida
      ).then((rps) => {
        if (rps?.data?.status === true) {
          var nProd = [];
          var prodAtualizar = rps.data.cardapio[0];
          products.forEach((p) => {
            if (p.id_cardapio === prodAtualizar.id_cardapio) {
              nProd[nProd.length] = prodAtualizar;
            } else {
              nProd[nProd.length] = p;
            }
          });
          //setSelectedProduct(prodAtualizar);
          selecionaProduto(prodAtualizar);
          setProducts(nProd);
          setCaProdutoSelecionado(false);

          //setFilteredProducts(nProd);
        } else {
          alert("Erro ao obter produto");
        }
      });
    },
    [products, selecionaProduto, selectedCategory, selectedProduct]
  );

  /* #region Funcoes dependentes do produto */
  const sendFormIngredientes = useCallback(() => {
    var nIng = {
      id_estoque: formIngredientes.id_estoque,
      id_cardapio: selectedProduct.id_cardapio,
      quantidade: formIngredientes.quantidade,
      removivel: formIngredientes.removivel,
    };
    Api.post("cardapioestoque/inserir", nIng).then((rps) => {
      if (rps.data.status === true) {
        addToast(rps.data.mensagem, {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 2000,
        });
        atualizaProduto();
        resetFormIngredientes();
      } else {
        Swal.fire({
          title: "Atenção!",
          icon: "info",
          html: rps.data.erro,
          showCloseButton: true,
          showCancelButton: false,
        });
      }
    });
    resetFormIngredientes();
  }, [
    addToast,
    atualizaProduto,
    formIngredientes,
    resetFormIngredientes,
    selectedProduct,
  ]);

  const excluirComposicao = useCallback(
    (item) => {
      ExcluirGenerico(
        "cardapioestoque/excluir",
        { id_cardapio_estoque: item.id_cardapio_estoque },
        addToast,
        (e) => {
          atualizaProduto();
        }
      );
    },
    [addToast, atualizaProduto]
  );
  /* #endregion */

  /* #region Funcoes Produto */

  const salvarProduto = useCallback(() => {
    var idC = "";
    if (selectedProduct.id_cardapio != null) idC = selectedProduct.id_cardapio;

    var nCard = {
      id_cardapio_categoria: selectedProduct.id_cardapio_categoria,
      id_produto: selectedProduct.id_produto,
      nome_prod: selectedProduct.nome_prod,
      descricao: selectedProduct.descricao,
      preco: selectedProduct.preco,
      novo_preco: selectedProduct.novo_preco,
      disponibilidade: JSON.stringify(stringDisponibilidadeProduto),
      foto: selectedProduct.foto
    };

    Api.post("cardapio/adicionar/" + idC, nCard).then((rps) => {
      if (rps.data.status !== undefined && rps.data.status === true) {
        addToast(rps.data.mensagem, {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 2000,
        });
        setSelectedProduct({
          ...selectedProduct,
          id_cardapio: rps.data.id_cardapio,
        });
        atualizaProduto(rps.data.id_cardapio);
      } else {
        Swal.fire({
          title: "Atenção!",
          icon: "info",
          html: rps.data.erro,
          showCloseButton: true,
          showCancelButton: false,
        });
      }
    });
  }, [
    addToast,
    atualizaProduto,
    selectedProduct,
    stringDisponibilidadeProduto,
  ]);

  const onSortEndProduct = useCallback(
    ({ oldIndex, newIndex }) => {
      let newOrder = arrayMove(products, oldIndex, newIndex);
      setProducts(newOrder);
      setTimeout(() => {
        const dataToPost = newOrder.map((item) => ({
          id_cardapio: item.id_cardapio,
        }));
        Api.post("cardapio/ordem", { ordem: dataToPost }).then((rps) => {
          if (rps.data.status === true) {
            addToast(rps.data.mensagem, {
              appearance: "success",
              autoDismiss: true,
              autoDismissTimeout: 2000,
            });
          }
        });
      }, 100);
    },
    [addToast, products]
  );

  const getProductsByCategory = useCallback(async (idCategory) => {
    setCaProdutos(true);
    const response = await Api.get("cardapio/listar/" + idCategory);
    if (response.data.status !== undefined && response.data.status === true) {
      setCaProdutos(false);
      setProducts(
        response.data.cardapio.sort((a, b) => Number(a.ordem) - Number(b.ordem))
      );
      //setFilteredProducts(response.data.cardapio);
    }
  }, []);

  const handleImageChange = useCallback(
    async (e) => {
      const form_data = new FormData();
      form_data.append("arquivo", e.target.files[0]);
      form_data.append("id_cardapio", selectedProduct.id_cardapio);
      const cf = {
        baseURL: Api.defaults.baseURL,
        headers: {
          "content-type": "multipart/form-data",
          Authorization: Api.defaults.headers["Authorization"],
        },
      };
      const response = await Axios.post("cardapio/foto", form_data, cf);
      if (response.data.status !== undefined && response.data.status === true) {
        addToast(response.data.mensagem, {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 2000,
        });
        setSelectedProduct({
          ...selectedProduct,
          foto: response.data.url_imagem,
        });
      } else {
        Swal.fire({
          title: "Atenção!",
          icon: "info",
          html: response.data.erros,
          showCloseButton: true,
          showCancelButton: false,
        });
      }
      //setDefaultImage(response.data.url_imagem)
    },
    [addToast, selectedProduct]
  );

  const [produtoTamanhos, setProdutoTamanhos] = useState([]);

  const handleCloseModalProduto = useCallback(() => {
    getProductsByCategory(selectedCategory.id_cardapio_categoria);
    setModalProducts(false);
  }, [getProductsByCategory, selectedCategory]);

  const [filtro, setFiltro] = useState();

  const duplicaProduto = useCallback(
    (item) => {
      Api.post("cardapio/duplicar", { id_cardapio: item.id_cardapio }).then(
        (rps) => {
          if (rps.data.status !== undefined && rps.data.status === true) {
            addToast(rps.data.mensagem, {
              appearance: "success",
              autoDismiss: true,
              autoDismissTimeout: 2000,
            });
            getProductsByCategory(selectedCategory.id_cardapio_categoria);
          } else {
            Swal.fire({
              title: "Atenção!",
              icon: "info",
              html: rps.data.erro,
              showCloseButton: true,
              showCancelButton: false,
            });
          }
        }
      );
    },
    [addToast, getProductsByCategory, selectedCategory]
  );
  /* #endregion */

  /* #region Funcoes Tamanhos */
  function onSortEndItemSize({ oldIndex, newIndex }) {
    let newOrder = arrayMove(selectedProduct.tamanhos, oldIndex, newIndex);
    setSelectedProduct({
      ...selectedProduct,
      tamanhos: newOrder,
    });
    Api.post("cardapiotamanhos/ordem", { ordem: newOrder }).then((rps) => {
      if (rps.data.status === true) {
        addToast(rps.data.mensagem, {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 2000,
        });
      }
    });
  }

  const resetFormTamanho = useCallback(() => {
    setFormTamanho({ nome: "", preco: 0 });
  }, []);

  const sendFormTamanho = useCallback(
    (edicao) => {
      let nTam = null;
      let id = null;
      if (edicao === true) {
        nTam = frmEdtTamanho;
        id = frmEdtTamanho.id_cardapio_tamanho;
      } else {
        nTam = formTamanho;
        id = "";
        nTam.id_cardapio = selectedProduct.id_cardapio;
        nTam.status = "S";
      }

      Api.post("cardapiotamanhos/adicionar/" + id, nTam).then((rps) => {
        if (rps.data.status !== undefined && rps.data.status === true) {
          addToast(rps.data.mensagem, {
            appearance: "success",
            autoDismiss: true,
            autoDismissTimeout: 2000,
          });
          atualizaProduto();
          resetFormTamanho();
          setFrmEdtTamanho({});
        } else {
          Swal.fire({
            title: "Atenção!",
            icon: "info",
            html: rps.data.erro,
            showCloseButton: true,
            showCancelButton: false,
          });
        }
      });
    },
    [
      addToast,
      atualizaProduto,
      formTamanho,
      frmEdtTamanho,
      resetFormTamanho,
      selectedProduct,
    ]
  );

  const excluirTamanho = useCallback(
    (tam) => {
      ExcluirGenerico(
        "cardapiotamanhos/excluir",
        { id_cardapio_tamanho: tam.id_cardapio_tamanho },
        addToast,
        () => {
          atualizaProduto();
        }
      );
    },
    [addToast, atualizaProduto]
  );

  const editarTamanho = useCallback((tam) => {
    setFrmEdtTamanho(tam);
    console.log(tam);
  }, []);
  /* #endregion */

  /* #region Funcoes Variation */
  const sendFormVariacoes = useCallback(() => {
    let nVariacao = null;
    if (
      formVariacoes.id_variacao === 0 &&
      formVariacoes.nome_variacao_avulsa !== ""
    ) {
      nVariacao = {
        nome_variacao: formVariacoes.nome_variacao_avulsa,
        id_cardapio: selectedProduct?.id_cardapio,
        id_cardapio_tamanho: formVariacoes?.id_cardapio_tamanho,
      };
      setVariacao(nVariacao);
      setModalVariacoesState(true);
      resetFormVariacoes();
    } else {
      nVariacao = {
        id_cardapio: selectedProduct?.id_cardapio,
        id_variacao: formVariacoes?.id_variacao,
        id_cardapio_tamanho: formVariacoes?.id_cardapio_tamanho,
      };
      Api.post("cardapiovariacoes/adicionar", nVariacao).then((rps) => {
        if (rps.data.status === true) {
          addToast(rps.data.mensagem, {
            appearance: "success",
            autoDismiss: true,
            autoDismissTimeout: 2000,
          });
          atualizaProduto();
          resetFormVariacoes(null);
        } else {
          Swal.fire({
            title: "Atenção!",
            icon: "info",
            html: rps.data.erro,
            showCloseButton: true,
            showCancelButton: false,
          });
        }
      });
    }
  }, [
    addToast,
    atualizaProduto,
    formVariacoes,
    resetFormVariacoes,
    selectedProduct,
  ]);

  const editarVariacao = useCallback((v) => {
    setVariacao(v);
    setModalVariacoesState(true);
  }, []);

  const duplicarVariacao = useCallback(
    (item) => {
      Api.post("variacoes/duplicar_card", {
        variacao: item.id_variacao,
        categoria: item.id_cardapio,
      }).then((rps) => {
        if (rps.data.status !== undefined && rps.data.status === true) {
          addToast(rps.data.mensagem, {
            appearance: "success",
            autoDismiss: true,
            autoDismissTimeout: 2000,
          });
          atualizaProduto();
        }
      });
    },
    [addToast, atualizaProduto]
  );

  const excluirVariacao = useCallback(
    (item) => {
      ExcluirGenerico(
        "cardapiovariacoes/excluir",
        { id_cardapio_variacao: item.id_cardapio_variacao },
        addToast,
        () => {
          atualizaProduto();
        }
      );
    },
    [addToast, atualizaProduto]
  );

  const onSortEndVariation = useCallback(
    ({ oldIndex, newIndex }) => {
      let newOrder = arrayMove(selectedProduct?.variacoes, oldIndex, newIndex);
      setSelectedProduct({
        ...selectedProduct,
        variacoes: newOrder,
      });
      Api.post("cardapiovariacoes/ordem", { ordem: newOrder }).then((rps) => {
        if (rps.data.status === true) {
          addToast(rps.data.mensagem, {
            appearance: "success",
            autoDismiss: true,
            autoDismissTimeout: 2000,
          });
        }
      });
    },
    [addToast, selectedProduct]
  );
  /* #endregion */

  const selectCategory = useCallback(
    (category) => {
      setCaProdutos(true);
      setFiltro("");
      setSelectedCategory(category);
      var nCategories = [];
      categories.forEach((c) => {
        if (category.id_cardapio_categoria === c.id_cardapio_categoria) {
          c.selectedClass = "categoria-selecionada";
        } else {
          c.selectedClass = "";
        }
        nCategories[nCategories.length] = c;
      });
      setCategories(nCategories);
      getProductsByCategory(category.id_cardapio_categoria);
    },
    [categories, getProductsByCategory]
  );

  const getInitialData = useCallback(() => {
    getProductCategories();
    getIngredientes();
    getVariacoes();
    setStartedInitialData(true);
  }, [getProductCategories]);

  useEffect(() => {
    if (!isSelectInitialCategory && categories.length) {
      setIsSelectInitialCategory(true);
      selectCategory(categories[0]);
    }
  }, [categories, isSelectInitialCategory, selectCategory]);

  useEffect(() => {
    if (filtro !== undefined && filtro !== "") {
      let fProd = products.filter((item) => {
        return (
          JSON.stringify(item).toLowerCase().indexOf(filtro.toLowerCase()) !==
          -1
        );
      });
      setFilteredProducts(fProd);
    } else {
      setFilteredProducts(products);
    }
  }, [products, filtro]);

  useEffect(() => {
    var tamanhos = [];
    if (selectedProduct.tamanhos !== undefined) {
      tamanhos = selectedProduct.tamanhos;
    }
    var nTam = [];
    nTam[nTam.length] = { value: "", label: "Selecione..." };
    tamanhos.forEach((t) => {
      nTam[nTam.length] = { value: t.id_cardapio_tamanho, label: t.nome };
    });
    setProdutoTamanhos(nTam);
  }, [selectedProduct]);

  useEffect(() => {
    if (!startedInitialData) {
      getInitialData();
    }
  }, [getInitialData, startedInitialData]);

  return (
    <Container
      className="content d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      {/*begin::Entry*/}
      <div className="d-flex flex-column-fluid">
        {/*begin::Container*/}
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              {/*begin::Card*/}
              <div className="card card-custom gutter-b">
                <div className="card-header flex-wrap">
                  {/*begin::Subheader*/}
                  <div
                    className="subheader py-2 py-lg-4 subheader-transparent"
                    id="kt_subheader"
                  >
                    <div className="d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
                      {/*begin::Details*/}
                      <div className="d-flex align-items-center flex-wrap mr-2">
                        {/*begin::Title*/}
                        <h5 className="text-dark font-weight-bold mt-2 mb-2 mr-5">
                          Categorias{" "}
                        </h5>
                        {/*end::Title*/}
                        {/*begin::Separator*/}
                        <div className="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200" />
                        {/*end::Separator*/}
                      </div>
                      {/*end::Details*/}
                    </div>
                  </div>
                  {/*end::Subheader*/}
                  <div className="card-toolbar">
                    {/*begin::Button*/}
                    <button
                      onClick={(e) => {
                        editarCategoria(null);
                      }}
                      className="btn btn-primary font-weight-bolder"
                    >
                      Adicionar
                    </button>
                    {/*end::Button*/}
                  </div>
                </div>
                <div className="card-body no-padding">
                  <LoadingOverlay
                    active={caCategoria}
                    spinner
                    text="Carregando..."
                  >
                    {/* <SortableComponentCategory /> */}
                    <SortableCategory
                      categories={categories}
                      onSortEnd={onSortEndCategory}
                      selectCategory={selectCategory}
                      editarCategoria={editarCategoria}
                      excluirCategoria={excluirCategoria}
                    />
                  </LoadingOverlay>
                </div>
              </div>
              {/*end::Card*/}
            </div>
            <div className="col-md-8">
              {/*begin::Card*/}
              <div className="card card-custom gutter-b">
                <div className="card-header flex-wrap">
                  {/*begin::Subheader*/}
                  <div
                    className="subheader py-2 py-lg-4 subheader-transparent"
                    id="kt_subheader"
                  >
                    <div className="d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
                      {/*begin::Details*/}
                      <div className="d-flex align-items-center flex-wrap mr-2">
                        {/*begin::Title*/}
                        <h5 className="text-dark font-weight-bold mt-2 mb-2 mr-5">
                          Produtos
                          {selectedCategory.nome_categoria !== undefined && (
                            <> - {selectedCategory.nome_categoria}</>
                          )}
                        </h5>
                        {/*end::Title*/}
                        {/*begin::Separator*/}
                        <div className="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200" />
                        {/*end::Separator*/}
                        {/*begin::Search Form*/}
                        <div
                          className="d-flex align-items-center"
                          id="kt_subheader_search"
                        >
                          <span
                            className="text-dark-50 font-weight-bold"
                            id="kt_subheader_total"
                          >
                            {FilteredProducts.length !== products.length && (
                              <>{FilteredProducts.length} de </>
                            )}
                            {products?.length} produtos
                          </span>
                          <form className="ml-5">
                            <div
                              className="input-group input-group-sm input-group-solid"
                              style={{ maxWidth: "175px" }}
                            >
                              <input
                                disabled={
                                  selectedCategory?.id_cardapio_categoria ===
                                  undefined
                                }
                                value={filtro}
                                onChange={(e) => {
                                  setFiltro(e.target.value);
                                }}
                                type="text"
                                className="form-control"
                                id="kt_subheader_search_form"
                                placeholder="Procurar..."
                              />
                              <div className="input-group-append">
                                <span className="input-group-text">
                                  <span className="svg-icon">
                                    {/*begin::Svg Icon | path:assets/media/svg/icons/General/Search.svg*/}
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      xmlnsXlink="http://www.w3.org/1999/xlink"
                                      width="24px"
                                      height="24px"
                                      viewBox="0 0 24 24"
                                      version="1.1"
                                    >
                                      <g
                                        stroke="none"
                                        strokeWidth={1}
                                        fill="none"
                                        fillRule="evenodd"
                                      >
                                        <rect
                                          x={0}
                                          y={0}
                                          width={24}
                                          height={24}
                                        />
                                        <path
                                          d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z"
                                          fill="#000000"
                                          fillRule="nonzero"
                                          opacity="0.3"
                                        />
                                        <path
                                          d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z"
                                          fill="#000000"
                                          fillRule="nonzero"
                                        />
                                      </g>
                                    </svg>
                                    {/*end::Svg Icon*/}
                                  </span>
                                  {/*<i className="flaticon2-search-1 icon-sm"></i>*/}
                                </span>
                              </div>
                            </div>
                          </form>
                        </div>
                        {/*end::Search Form*/}
                      </div>
                      {/*end::Details*/}
                    </div>
                  </div>
                  {/*end::Subheader*/}
                  <div className="card-toolbar">
                    {/*begin::Button*/}
                    <button
                      disabled={
                        selectedCategory?.id_cardapio_categoria === undefined
                      }
                      className="btn btn-primary font-weight-bolder"
                      onClick={(e) => {
                        selecionaProduto(null);
                      }}
                    >
                      Adicionar Produto
                    </button>
                    {/*end::Button*/}
                  </div>
                </div>
                <div className="card-body no-padding">
                  <div className="table-div-header padding-horizontal">
                    <div className="table-div-header-item-produto" />
                    <div className="table-div-header-item-produto">Img</div>
                    <div className="table-div-header-item-produto">Produto</div>
                    <div className="table-div-header-item-produto">Preço</div>
                    <div className="table-div-header-item-produto">Status</div>
                    <div className="table-div-header-item-produto">Ações</div>
                  </div>

                  <LoadingOverlay
                    active={caProdutos}
                    spinner
                    text="Carregando..."
                  >
                    <SortableVirtualizedProductList
                      filteredProducts={FilteredProducts}
                      onSortEnd={onSortEndProduct}
                      selecionaProduto={selecionaProduto}
                      MudaStatus={MudaStatus}
                      ExcluirGenerico={ExcluirGenerico}
                      duplicaProduto={duplicaProduto}
                      getProductsByCategory={getProductsByCategory}
                      selectedCategory={selectedCategory}
                    />
                  </LoadingOverlay>
                </div>
              </div>
              {/*end::Card*/}
            </div>
          </div>
        </div>
        {/*end::Container*/}
      </div>
      {/*end::Entry*/}

      <ModalProduct
        modalProducts={modalProducts}
        refImagem={refImagem}

        // Produto
        handleCloseModalProduto={handleCloseModalProduto}
        selectedProduct={selectedProduct}
        setSelectedProduct={setSelectedProduct}
        caProdutoSelecionado={caProdutoSelecionado}
        salvarProduto={salvarProduto}
        setStringDisponibilidadeProduto={setStringDisponibilidadeProduto}

        handleImageChange={handleImageChange}
        selectedCategory={selectedCategory}

        // Tamanho
        frmEdtTamanho={frmEdtTamanho}
        formTamanho={formTamanho}
        setFormTamanho={setFormTamanho}
        sendFormTamanho={sendFormTamanho}
        setFrmEdtTamanho={setFrmEdtTamanho}
        editarTamanho={editarTamanho}
        excluirTamanho={excluirTamanho}
        produtoTamanhos={produtoTamanhos}

        // Ingredientes
        formIngredientes={formIngredientes}
        setFormIngredientes={setFormIngredientes}
        ingredientes={ingredientes}
        sendFormIngredientes={sendFormIngredientes}

        // Variacoes
        setFormVariacoes={setFormVariacoes}
        formVariacoes={formVariacoes}
        variacoes={variacoes}
        sendFormVariacoes={sendFormVariacoes}
        onSortEndVariation={onSortEndVariation}
        editarVariacao={editarVariacao}
        duplicarVariacao={duplicarVariacao}
        excluirVariacao={excluirVariacao}

        // Composição
        onSortEndComposition={onSortEndComposition}
        excluirComposicao={excluirComposicao}
        onSortEndItemSize={onSortEndItemSize}
      />

      <ModalCategory
        modalCategorias={modalCategorias}
        salvarCategoria={salvarCategoria}
        handleCloseCategorias={handleCloseCategorias}
        formCategorias={formCategorias}
        setFormCategorias={setFormCategorias}
        statusVar={statusVar}
        setStringDisponibilidadeCategoria={setStringDisponibilidadeCategoria}
      />

      <VariacoesModal
        modalVariacoesState={modalVariacoesState}
        setModalVariacoesState={setModalVariacoesState}
        variacao={variacao}
        atualizaPai={atualizaProduto}
      />
    </Container>
  );
};

export default withRouter(Products);
