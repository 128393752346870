import styled from "styled-components";

export const Container = styled.div`
  .classeInputImage {
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    opacity: 1e-5;
    pointer-events: none;
    background-color: red !important;
  }

  .infinite-list-product {
    &::-webkit-scrollbar {
      background-color: #fff;
      width: 16px;
    }

    /* background of the scrollbar except button or resizer */
    &::-webkit-scrollbar-track {
      background-color: #fff;
    }

    /* scrollbar itself */
    &::-webkit-scrollbar-thumb {
      background-color: #babac0;
      border-radius: 16px;
      border: 4px solid #fff;
    }

    /* set button(top and bottom of the scrollbar) */
    &::-webkit-scrollbar-button {
      display: none;
    }
  }
`;
