import React, { useState } from 'react';
import Api from '../../../services/api';
import { withRouter } from "react-router";
import LoadingOverlay from 'react-loading-overlay';
import Swal from "sweetalert2";

import { Bar } from 'react-chartjs-2';
import { Doughnut } from 'react-chartjs-2';
import { HorizontalBar } from 'react-chartjs-2';

import DatePicker, { registerLocale } from "react-datepicker";
import InputMask from 'react-input-mask';
import pt from "date-fns/locale/pt-BR";
registerLocale("pt-BR", pt);

const Graficos = () => {
    const [ periodo, setPeriodo ] = useState(0);
    const [ dataInicial, setDataInicial ] = useState();
    const [ dataFinal, setDataFinal ] = useState(new Date());
    const [ caProcessa, setCaProcessa ] = useState(false);

    //Dia da Semana
    const [ diaSemanaLabel , setDiaSemanaLabel ] = useState([]);
    const [ diaSemanaValor , setDiaSemanaValor ] = useState([]);
    //Mais Vendidos
    const [ maisVendidosLabel, setMaisVendidosLabel ] = useState([]);
    const [ maisVendidosQtde, setMaisVendidosQtde ] = useState([]);
    const [ maisVendidosCor, setMaisVendidosCor ] = useState([]);
    //Bairros
    const [ porBairroLabel, setPorBairroLabel ] = useState([]);
    const [ porBairroQtde, setPorBairroQtde ] = useState([]);
    const [ , setPorBairroCor ] = useState([]);
    //Forma de Pagamento
    const [ porFormaPagamentoLabel, setPorFormaPagamentoLabel ] = useState([]);
    const [ porFormaPagamentoQtde, setPorFormaPagamentoQtde ] = useState([]);
    const [ porFormaPagamentoCor, setPorFormaPagamentoCor ] = useState([]);

    const dataBar = {
        labels: diaSemanaLabel,
        datasets: [
            {
                label: 'Qtde. por dia da Semana',
                backgroundColor: 'rgba(255,99,132,0.2)',
                borderColor: 'rgba(255,99,132,1)',
                borderWidth: 1,
                hoverBackgroundColor: 'rgba(255,99,132,0.4)',
                hoverBorderColor: 'rgba(255,99,132,1)',
                data: diaSemanaValor
            }
        ]
    };

    //Gráfico de Rosca Mais Vendidos
    const data = {
        labels: maisVendidosLabel,
        datasets: [
            {
                data: maisVendidosQtde,
                backgroundColor: maisVendidosCor
            }
        ]
    };

    //Gráfico de por Bairro
    const dataBairro = {
        labels: porBairroLabel,
        datasets: [
            {
                label: 'Qtde. por Bairros',
                backgroundColor: 'rgba(255,99,132,0.2)',
                borderColor: 'rgba(255,99,132,1)',
                borderWidth: 1,
                hoverBackgroundColor: 'rgba(255,99,132,0.4)',
                hoverBorderColor: 'rgba(255,99,132,1)',
                data: porBairroQtde
            }
        ]
    };

    //Gráfico de por Forma de Pagamento
    const dataForma = {
        labels: porFormaPagamentoLabel,
        datasets: [
            {
                data: porFormaPagamentoQtde,
                backgroundColor: porFormaPagamentoCor
            }
        ],
    };

    function getRandomColor() {
        var letters = '0123456789ABCDEF';
        var color = '#';
        for (var i = 0; i < 6; i++ ) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }

    function getGerar() {
        setCaProcessa(true);

        var data = {
            data_inicio: dataInicial,
            data_final: dataFinal,
        };

        Api.post("relatorio/populapainel/",{periodo:periodo, data}).then((rps) =>{
            if(rps.data.status==false){
                Swal.fire({
                    title: "Atenção!",
                    icon: "info",
                    html: rps.data.mensagem,
                    showCloseButton: true,
                    showCancelButton: false,
                  });

                  //Dias da Semana
                  setDiaSemanaLabel([]);
                  setDiaSemanaValor([]);
                  //Mais Vendidos
                  setMaisVendidosLabel([]);
                  setMaisVendidosQtde([]);
                  setMaisVendidosCor([]);
                  //Bairros
                  setPorBairroLabel([]);
                  setPorBairroQtde([]);
                  setPorBairroCor([]);
                  //Forma de Pagamento
                  setPorFormaPagamentoLabel([]);
                  setPorFormaPagamentoQtde([]);
                  setPorFormaPagamentoCor([]);

                  setCaProcessa(false);
            }
            else {
                //***Inicio do Grafico Vendas por dia da Semana
                const formataSemanaTexto = rps.data.diaSemana.map( item => {
                    return item.dia
                })
                const formataSemanaValor = rps.data.diaSemana.map( item => {
                  return item.qtde
                })

                setDiaSemanaLabel(formataSemanaTexto);
                setDiaSemanaValor(formataSemanaValor);
                //Fim***

                //***Inicio do Gráfico mais Vendidos
                const formataMaisVendidosTexto = rps.data.obj.map( item => {
                  return item.nome
                })
                const formataMaisVendidosValor = rps.data.obj.map( item => {
                  return item.contagem_vezes
                })
                const formataMaisVendidosCor = rps.data.obj.map( item => {
                  return getRandomColor()
                })

                setMaisVendidosLabel(formataMaisVendidosTexto);
                setMaisVendidosQtde(formataMaisVendidosValor);
                setMaisVendidosCor(formataMaisVendidosCor);
                //Fim***

                //***Inicio do Gráfico por Bairro
                const formataBairroTexto = rps.data.pedidoBairro.map( item => {
                  return item.nome_bairro
                })
                const formataBairroValor = rps.data.pedidoBairro.map( item => {
                  return item.qtde
                })
                const formataBairroCor = rps.data.pedidoBairro.map( item => {
                  return getRandomColor()
                })

                setPorBairroLabel(formataBairroTexto);
                setPorBairroQtde(formataBairroValor);
                setPorBairroCor(formataBairroCor);
                //Fim***

                //***Inicio do Gráfico por Forma de pagamento
                const formataFormaPagamentoTexto = rps.data.formaPagamento.map( item => {
                  return item.pagamento
                })
                const formataFormaPagamentoValor = rps.data.formaPagamento.map( item => {
                  return item.qtde
                })
                const formataFormaPagamentoCor = rps.data.formaPagamento.map( item => {
                  return getRandomColor()
                })

                setPorFormaPagamentoLabel(formataFormaPagamentoTexto);
                setPorFormaPagamentoQtde(formataFormaPagamentoValor);
                setPorFormaPagamentoCor(formataFormaPagamentoCor);
                //Fim***

                setCaProcessa(false);
            }
        })

      }

    return (
            <>
            <div className="subheader espaco-header-pedidos subheader-transparent mt-3" id="kt_subheader">
          <div className="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
            {/*begin::Details*/}
            <div className="d-flex align-items-center flex-wrap mr-2">
              {/*begin::Title*/}
              <h5 className="text-dark font-weight-bold mt-2 mb-2 mr-5">Gráficos Operacionais</h5>
              {/*end::Title*/}
              {/*begin::Separator*/}
              <div className="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200" />
              {/*end::Separator*/}
              <div className="d-flex align-items-center mr-5">
                <select onChange={(e) => { setPeriodo(e.target.value)}} className="form-control">
                  <option value="0">Todo o período</option>
                  <option value="7">Últimos 7 dias</option>
                  <option value="30">Últimos 30 dias</option>
                  <option value="60">Últimos 60 dias</option>
                  <option value="90">Últimos 90 dias</option>
                  <option value="PP">Período Personalizado</option>
                </select>
              </div>
              {/*begin::Search Form*/}
              { periodo === "PP" &&
              <div className="d-flex align-items-center" id="kt_subheader_search">
                <div className="input-group" id="kt_daterangepicker_2">
                <DatePicker
                    name="dt_inicio"
                    onChange={setDataInicial}
                    isClearable
                    selectsStart
                    locale="pt-BR"
                    className="form-control"
                    placeholderText="dd/mm/aaaa"
                    selected ={dataInicial}
                    dateFormat="dd/MM/y"
                    startDate={dataInicial}
                    endDate={dataFinal}
                    customInput={
                        <InputMask mask="99/99/9999" />}/>
                &nbsp;&nbsp;
                <DatePicker
                    name="dt_final"
                    onChange={setDataFinal}
                    isClearable
                    locale="pt-BR"
                    className="form-control"
                    placeholderText="dd/mm/aaaa"
                    selected ={dataFinal}
                    dateFormat="dd/MM/y"
                    selectsEnd
                    startDate={dataInicial}
                    endDate={dataFinal}
                    minDate={dataInicial}
                    customInput={
                        <InputMask mask="99/99/9999" />}/>

                </div>
              </div>
              }
              {/*end::Search Form*/}
            </div>
            {/*end::Details*/}
            {/*begin::Toolbar*/}
            <div className="d-flex align-items-center">
              {/*begin::Button*/}
              <button onClick={e => { getGerar(); }} className="btn btn-light-primary pl-10 pr-10 btn-md btn-shadow-hover font-weight-bold">Filtrar</button>
              {/*end::Button*/}
              {/*begin::Button*/}
            </div>
            {/*end::Toolbar*/}
          </div>
        </div>

            <div className="d-flex flex-column-fluid">
                {/*begin::Container*/}
                <div className="container">

                    <div className="row">
                    <div className="col-lg-6">
                        {/*begin::Card*/}
                        <div className="card card-custom gutter-b">
                        {/*begin::Header*/}
                        <div className="card-header h-auto">
                            {/*begin::Title*/}
                            <div className="card-title py-5">
                            <h3 className="card-label">Resumo da operação</h3>
                            </div>
                            {/*end::Title*/}
                        </div>
                        {/*end::Header*/}
                        <div className="card-body">
                            {/*begin::Chart*/}
                            <LoadingOverlay
                            active={caProcessa}
                            spinner
                             text='Carregando...'
                            >
                            <Bar data={dataBar}/>
                            </LoadingOverlay>
                            {/*end::Chart*/}
                        </div>
                        </div>
                        {/*end::Card*/}
                    </div>
                    <div className="col-lg-6">
                        {/*begin::Card*/}
                        <div className="card card-custom gutter-b">
                        <div className="card-header">
                            <div className="card-title">
                            <h3 className="card-label">Os favoritos da galera</h3>
                            </div>
                        </div>
                        <div className="card-body">
                            {/*begin::Chart*/}
                            <LoadingOverlay
                            active={caProcessa}
                            spinner
                             text='Carregando...'
                            >
                                <Doughnut data={data}/>
                            </LoadingOverlay>
                            {/*end::Chart*/}
                        </div>
                        </div>
                        {/*end::Card*/}
                    </div>
                    </div>

                    <div className="row">
                    <div className="col-lg-6">
                        {/*begin::Card*/}
                        <div className="card card-custom gutter-b">
                        <div className="card-header">
                            <div className="card-title">
                            <h3 className="card-label">Bairros com mais pedidos</h3>
                            </div>
                        </div>
                        <div className="card-body">
                            {/*begin::Chart*/}
                            <LoadingOverlay
                            active={caProcessa}
                            spinner
                             text='Carregando...'
                            >
                                <HorizontalBar data={dataBairro}/>
                            </LoadingOverlay>
                            {/*end::Chart*/}
                        </div>
                        </div>
                        {/*end::Card*/}
                    </div>

                    <div className="col-lg-6">
                        {/*begin::Card*/}
                        <div className="card card-custom gutter-b">
                        <div className="card-header">
                            <div className="card-title">
                            <h3 className="card-label">Forma de pagamento</h3>
                            </div>
                        </div>
                        <div className="card-body">
                            {/*begin::Chart*/}
                            <LoadingOverlay
                            active={caProcessa}
                            spinner
                             text='Carregando...'
                            >
                                <Doughnut data={dataForma}/>
                            </LoadingOverlay>
                            {/*end::Chart*/}
                        </div>
                        </div>
                        {/*end::Card*/}
                    </div>
                    </div>


                </div>
            </div>
            </>

        );

    }

export default withRouter(Graficos);
