import { useContext } from "react"

import UpdateContext from "../contexts/update"

function useUpdateElectron() {
  const context = useContext(UpdateContext)
  if (!context) {
    throw new Error('useUpdateElectron must be used within an UpdateProvider')
  }
  return context
}

export default useUpdateElectron
