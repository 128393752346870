import React, { Component } from 'react';

import { withRouter } from "react-router";
import CpfCnpjInput from 'react-cpf-cnpj-input';
import { Wizard, Steps, Step } from 'react-albus';
import Timer from 'react-compound-timer'
import LoadingOverlay from 'react-loading-overlay';

import Swal from 'sweetalert2';

import { setLocalStorage } from './helpers';

import { setUserDataToSupportChat, showSupportChat } from '../../helpers/supportChat';

import Api from '../../services/api';
import Apiv2 from '../../services/apiv2';
import apiRemaster from '../../services/apiRemaster';

import './index.css';

class Login extends Component {
    constructor(props){
        super(props);
        this.state = {
            username: '',
            password: '',
            loading: false,
            usercnpj: '',
            ficha: 1,
            estabelecimentos: [],
            estabelecimentoContato: [],
            estabelecimentoFormaRecuperacao: [],
            estabelecimento_selecionado: 0,
            forma_recuperar: '',
            senha_alt: '',
            senha_alt_con: '',
            avanca: false,
            codigo: '',
            uuid: '',
            usuario: '',
        }
        this.authentication = this.authentication.bind(this);
        this._handleKeyDown = this._handleKeyDown.bind(this);
        this.redefinirPesqCnpj = this.redefinirPesqCnpj.bind(this);
        this.redefinirContato = this.redefinirContato.bind(this);
    }

    componentDidMount(){
      var hashLogin = this.props.match.params.hash;
      var hashUsr = this.props.match.params.usuario;

      if ("hash" in localStorage && (hashLogin===undefined || hashLogin==null)){
        this.props.history.push("/pedidos");
      }

      if(hashLogin!==undefined && hashUsr!==undefined){
        this.setState({username: atob(hashUsr), password: hashLogin})
        var root = this;
        setTimeout(function(){
          root.authentication();
        }, 500);

      }
    }

    //Criar funcao para login
    async authentication(){
        this.setState({loading: true});
        const { username, password } = this.state;
        await Api.post('login/index', {
            usuario: username,
            senha: password
        })
        .then(response => {
            if(response.data.status === true){
                const data = response.data;
                if (setLocalStorage(data) ){
                  this.setState({loading: false});
                  Api.defaults.headers['Authorization'] = 'Basic ' + data.usuario.sessao.hash
                  Apiv2.defaults.headers['Authorization'] = 'Basic ' + data.usuario.sessao.hash
                  apiRemaster.defaults.headers['Authorization'] = 'Basic ' + data.usuario.sessao.hash
                  this.props.history.push('/pedidos')
                  
                  setUserDataToSupportChat(data.usuario);
                  showSupportChat();

                }
            } else {
                this.setState({loading: false});
                Swal.fire(
                    'Erro!',
                    response.data.erro,
                    'error'
                )
            }
        })
        .catch(error => {
          console.log(error)
            this.setState({loading: false});

            Swal.fire(
                'Erro!',
                'Não foi possível fazer login, verifique seus dados e tente novamente!',
                'error'
            )
        })
    }

    //Passo 1 pesquisar Cnpj/Cpf para recuperar senha
    async redefinirPesqCnpj(next){
      this.setState({loading: true});
      const { usercnpj } = this.state;
      await Api.post('login/redefinir', {
          cnpj: usercnpj,
      })
      .then(response => {
          if(response.data.status === true){
                this.setState({estabelecimentos: response.data.data});
                this.setState({loading: false});
                this.setState({avanca: true});

                this.setState({ficha: 2});
                next();
          } else {
              this.setState({loading: false});
              this.setState({avanca: false});
              Swal.fire(
                  'Erro!',
                  response.data.erro,
                  'error'
              )
          }
      })
    }

    //Passo 2 pesquisar Cnpj/Cpf mais o estabelecimento selecionado
    async redefinirContato(previous, next){
      this.setState({loading: true});
      const { usercnpj } = this.state;
      const { estabelecimento_selecionado } = this.state;
      await Api.post('login/redefinirSenha', {
          cnpj: usercnpj,
          estabelecimento: estabelecimento_selecionado,
      })
      .then(response => {
          if(response.data.status === true){
              this.setState({estabelecimentoFormaRecuperacao: response.data.obj});
              console.log(response.data.obj)
              this.setState({loading: false});

              next();
              this.setState({ficha: 3});
          } else {
              this.setState({loading: false});
              Swal.fire(
                  'Erro!',
                  response.data.erro,
                  'error'
              )
              this.setState({ficha: 1});
              previous();
          }
      })
    }

    //Passo 3 confere se as senhas digitadas são iguais.
    async validaNovaSenha(next){
      const { senha_alt } = this.state;
      const { senha_alt_con } = this.state;

      if (senha_alt.length < 8){
        this.setState({loading: false});
              Swal.fire(
                  'Erro!',
                  'A Senha deve ter no mínimo 8 caracteres.',
                  'error'
              )
      } else if (senha_alt !== senha_alt_con){
        this.setState({loading: false});
              Swal.fire(
                  'Erro!',
                  'A senha e a confirmação de senha não são iguais.',
                  'error'
              )
      } else {

        next();
        this.setState({ficha: 4});
      }
    }

    //Passo 4 envio o Cnpj/Cpf, estabelecimento e forma selecionada
    async enviarCodigo(next){
      this.setState({loading: true});
      const { usercnpj } = this.state;
      const { estabelecimento_selecionado } = this.state;
      const { forma_recuperar } = this.state;
      await Api.post('login/enviarCodigo', {
          cnpj: usercnpj,
          estabelecimento: estabelecimento_selecionado,
          forma: forma_recuperar,
      })
      .then(response => {
          if(response.data.status === true){
              this.setState({loading: false});
              this.setState({uuid: response.data.uuid});
              this.setState({usuario: response.data.usuario});

              next();
              this.setState({ficha: 5});
          } else {
              this.setState({loading: false});
              Swal.fire(
                  'Erro!',
                  response.data.erro,
                  'error'
              )
          }
      })
  }

  //Passo 5 salvo a nova senha e finalizo o processo
  async salvarSenha(next){
    this.setState({loading: true});
    const { estabelecimento_selecionado } = this.state;
    const { codigo } = this.state;
    const { uuid } = this.state;
    const { senha_alt } = this.state;
    const { usuario } = this.state;

    await Api.post('login/salvarSenha', {
        estabelecimento: estabelecimento_selecionado,
        codigo: codigo,
        uuid: uuid,
        valor: senha_alt,
    })
    .then(response => {
        if(response.data.status === true){
            this.setState({loading: false});
            Swal.fire({
              title: 'Sucesso!',
              html: 'Senha alterada com sucesso. <br /><br /> * O nome do usuário é <h5>'+
                usuario + '</h5>.<br /<br /> *A senha é a que você definiu!',
              icon: 'success',
            }).then((result) => {
              if (result.isConfirmed) {
                console.log('oi');
                window.location.reload();
              }
            });
        } else {
            this.setState({loading: false});
            Swal.fire(
                'Erro!',
                response.data.erro,
                'error'
            )
        }
    })
  }

  atualizaFicha(qual){
    if (qual == 'identificacao')
      this.setState({ficha: 1});
    else if (qual == 'estabelecimento')
      this.setState({ficha: 2});
    else if (qual == 'senha')
      this.setState({ficha: 3});
    else if (qual == 'forma')
      this.setState({ficha: 4});
    else if (qual == 'validacao')
      this.setState({ficha: 5});
  }

    _handleKeyDown = (e) => {
      if (e.key === 'Enter') {
        console.log('do validate');
      }
    }

    //armazenar estado no redux
    render() {

        return (
          <>
              <div className="d-flex flex-column flex-root full-height">
                <div className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-row-fluid bg-white full-lg" id="kt_login">
                  <div className="login-aside d-flex flex-row-auto px-lg-0 px-5 pb-sm-40 flex-grow-1" style={{height: '100%', minHeight: '100%', backgroundSize: 'cover', maxWidth: '50%', backgroundRepeat: 'no-repeat', backgroundPosition: 'center',
                  backgroundImage: 'url(assets/bg.png)'}}>
                  </div>
                  <div className=" login-area-scroll">
                    <LoadingOverlay active={this.state.loading} spinner text='Carregando...' >
                    <div className="d-flex flex-column-fluid flex-center mt-6 mt-lg-0 login-area-center">
                      <form className="form login-area-form" noValidate="novalidate" id="kt_login_signin_form">
                        <div className="mt-lg-10 pb-15">
                          <a href="index.html">
                            <center><img alt="Logo" src="assets/media/logos/aiboo-by-pedeai.png" className="logo-sticky mb-15" style={{width: '200px', textAlign: 'center', margin: 'auto'}} /></center>
                          </a>
                          <h3 className="font-weight-bolder text-dark display5"><i className="icon-xl fas fa-lock" /> Painel do Parceiro</h3>
                          <span className="text-muted font-size-h6">Gerencie seu estabelecimento de forma fácil e rápida.</span>
                        </div>
                        <div className="form-group">
                          <label className="font-size-h6 font-weight-bolder text-dark">Usuário</label>
                          <input value={this.state.username} onChange={(e)=> this.setState({username: e.target.value })} className="form-control form-control-solid h-auto py-5 px-6 rounded-lg" type="text" name="username" autoComplete="off" />
                        </div>
                        <div className="form-group">
                          <div className="d-flex justify-content-between mt-n5">
                            <label className="font-size-h6 font-weight-bolder text-dark pt-5">Senha</label>
                          </div>
                          <input value={this.state.password} onKeyPress={(e) =>  {if(e.key==='Enter') {this.authentication()}}} onChange={(e)=> this.setState({password: e.target.value })} className="form-control form-control-solid h-auto py-5 px-6 rounded-lg" type="password" name="password" autoComplete="off" />
                          <div className="text-right mt-2">
                            Whatsapp suporte: (27) 4042-1580
                          </div>
                          <div className="text-right mt-2">
                            <a href="#" data-toggle="modal" data-target="#ModalEsqueci" className="text-primary font-size-6 font-weight-bolder text-hover-primary pt-6">Esqueci minha senha</a>
                          </div>
                        </div>
                        <div className="pb-lg-0 pb-10">
                          <button onClick={this.authentication} type="button" id="kt_login_signin_submit" className="btn btn-primary btn-block font-weight-bolder btn-lg font-size-h4 px-8 py-4 my-3 mr-3">ACESSAR</button>
                        </div>
                      </form>

                      {/* <div className="mt-10 mb-10 text-center">
                        <span className="opacity-70 mr-4 font-size-h6">Ainda não possui cadastro?</span>
                        <a href="#" data-toggle="modal" data-target="#ModalCadastro" className="text-primary font-size-h6 font-weight-bolder text-hover-primary text-bold">Cadastre seu estabelecimento!</a>
                      </div> */}
                    </div>
                    </LoadingOverlay>
                  </div>
                </div>
              </div>
            <div className="modal fade" id="ModalEsqueci" tabIndex={-1} role="dialog" aria-hidden="true">
              <div className="modal-dialog" role="document">
                <div className="modal-content">

                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Esqueci minha senha</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                      <i aria-hidden="true" className="ki ki-close" />
                    </button>
                  </div>
                  <div className="modal-body">

                  {/*begin::Wizard 5*/}
        <div className="wizard wizard-5 d-flex flex-column flex-lg-row flex-row-fluid" id="kt_wizard">
          {/*begin::Aside*/}
          <div className="wizard-aside bg-white d-flex flex-column flex-row-auto w-100 w-lg-300px w-xl-400px w-xxl-500px">
            {/*begin::Aside Top*/}
            <div className="d-flex flex-column-fluid flex-column px-xxl-30 px-10">
              {/*begin: Wizard Nav*/}
              <div className="wizard-nav d-flex d-flex justify-content-center pt-10 pt-lg-20 pb-5">
                {/*begin::Wizard Steps*/}
                <div className="wizard-steps">
                  {/*begin::Wizard Step 1 Nav*/}
                  <div className="wizard-step" data-wizard-type="current" data-wizard-state={this.state.ficha >= 1 ? "current" : ''}>
                    <div className="wizard-wrapper">
                      <div className="wizard-icon">
                        <i className="wizard-check ki ki-check" />
                        <span className="wizard-number">1</span>
                      </div>
                      <div className="wizard-label">
                        <h3 className="wizard-title">Identificação</h3>
                        <div className="wizard-desc">CNPJ ou CPF do estabelecimento</div>
                      </div>
                    </div>
                  </div>
                  {/*end::Wizard Step 1 Nav*/}
                  {/*begin::Wizard Step 2 Nav*/}
                  <div className="wizard-step" data-wizard-type="step" data-wizard-state={this.state.ficha >= 2 ? "current" : ''}>
                    <div className="wizard-wrapper">
                      <div className="wizard-icon">
                        <i className="wizard-check ki ki-check" />
                        <span className="wizard-number">2</span>
                      </div>
                      <div className="wizard-label">
                        <h3 className="wizard-title">Estabelecimento</h3>
                        <div className="wizard-desc">Selecione o estabelecimento</div>
                      </div>
                    </div>
                  </div>
                  {/*end::Wizard Step 2 Nav*/}
                  {/*begin::Wizard Step 3 Nav*/}
                  <div className="wizard-step" data-wizard-type="step" data-wizard-state={this.state.ficha >= 3 ? "current" : ''}>
                    <div className="wizard-wrapper">
                      <div className="wizard-icon">
                        <i className="wizard-check ki ki-check" />
                        <span className="wizard-number">3</span>
                      </div>
                      <div className="wizard-label">
                        <h3 className="wizard-title">Nova senha</h3>
                        <div className="wizard-desc">Digite a nova senha e valide</div>
                      </div>
                    </div>
                  </div>
                  {/*end::Wizard Step 3 Nav*/}
                  {/*begin::Wizard Step 4 Nav*/}
                  <div className="wizard-step" data-wizard-type="step" data-wizard-state={this.state.ficha >= 4 ? "current" : ''}>
                    <div className="wizard-wrapper">
                      <div className="wizard-icon">
                        <i className="wizard-check ki ki-check" />
                        <span className="wizard-number">4</span>
                      </div>
                      <div className="wizard-label">
                        <h3 className="wizard-title">Forma de recuperação</h3>
                        <div className="wizard-desc">Selecione e-mail ou o telefone</div>
                      </div>
                    </div>
                  </div>
                  {/*end::Wizard Step 4 Nav*/}
                  {/*begin::Wizard Step 5 Nav*/}
                  <div className="wizard-step" data-wizard-type="current" data-wizard-state={this.state.ficha >= 5 ? "current" : ''}>
                    <div className="wizard-wrapper">
                      <div className="wizard-icon">
                        <i className="wizard-check ki ki-check" />
                        <span className="wizard-number">5</span>
                      </div>
                      <div className="wizard-label">
                        <h3 className="wizard-title">Validação</h3>
                        <div className="wizard-desc">Confirme o código de validação</div>
                      </div>
                    </div>
                  </div>
                  {/*end::Wizard Step 5 Nav*/}
                </div>
                {/*end::Wizard Steps*/}
              </div>
              {/*end: Wizard Nav*/}
            </div>
            {/*end::Aside Top*/}
          </div>
          {/*begin::Aside*/}
          {/*begin::Content*/}
          <div className="wizard-content bg-gray-100 d-flex flex-column flex-row-fluid py-15 px-5 px-lg-10">
           {/*begin::Form*/}
            <div className="d-flex justify-content-center flex-row-fluid">
              <div class="pb-5 w-100 w-md-450px w-lg-500px" id="kt_form">
    <Wizard>
    <Steps>
      <Step
        id="identificacao"
        render={({ next }) => (
          <div>
            {/*begin::Title*/}
            <div className="pb-10 pb-lg-15">
              <h3 className="font-weight-bolder text-dark font-size-h2">Identificação do Estabelecimento</h3>
            </div>
            {/*begin::Title*/}

            <div className="row">
              <div className="col-md-12 text-center">
                <div className="form-group mt-10">
                  <label className="font-size-h6 font-weight-bolder text-dark">Digite o CNPJ ou CPF do seu estabelecimento</label>
                  <CpfCnpjInput
                    maskField="_"
                    className="form-control form-control h-auto py-5 px-6 rounded-lg"
                    value={this.state.usercnpj}
                    onChange={(e)=> this.setState({usercnpj: e.target.value })}
                    placeholder="CNPJ ou CPF (caso estabelecimento não tenha CNPJ)"
                  />
                </div>
              </div>
            </div>

              {/*begin: Wizard Actions*/}
              <div className="d-flex justify-content-between pt-3">
                <div className="mr-2">
                  {/*botão do lado esquerdo*/}
                </div>
              <div>

              <button type="button" onClick={(e) => { this.redefinirPesqCnpj(next); }} className="btn btn-primary font-weight-bolder font-size-h6 pl-8 pr-4 py-4 my-3" data-wizard-type="action-next">Avançar
                <span className="svg-icon svg-icon-md ml-1">
                  {/*begin::Svg Icon | path:assets/media/svg/icons/Navigation/Right-2.svg*/}
                  <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                    <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                      <polygon points="0 0 24 0 24 24 0 24" />
                      <rect fill="#000000" opacity="0.3" transform="translate(8.500000, 12.000000) rotate(-90.000000) translate(-8.500000, -12.000000)" x="7.5" y="7.5" width={2} height={9} rx={1} />
                      <path d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z" fill="#000000" fillRule="nonzero" transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)" />
                    </g>
                  </svg>
                  {/*end::Svg Icon*/}
                </span></button>
              </div>
            </div>
            {/*end: Wizard Actions*/}
          </div>
        )}
      />
      <Step
        id="estabelecimento"
        render={({ next, previous }) => (
          <div>
            {/*begin::Title*/}
            <div className="pb-10 pb-lg-15">
              <h3 className="font-weight-bolder text-dark font-size-h2">Selecione o seu estabelecimento</h3>
            </div>
            {/*begin::Title*/}
            <LoadingOverlay active={this.state.loading} spinner text='Carregando...' >
            <div className="row">
              <div className="form-group col-md-12">
                { this.state.estabelecimentos.map( estabelecimento => {
                  return(
                    <>
                      <label class="option option-plain">
                        <span class="option-control">
                          <span class="radio">
                              <input type="radio"
                                onChange={(e)=> this.setState({estabelecimento_selecionado: estabelecimento.id_estabelecimento})}
                                checked={this.state.estabelecimento_selecionado === estabelecimento.id_estabelecimento ? true : false}
                              />
                            <span></span>
                          </span>
                        </span>
                        <span class="option-label">
                          <span class="option-head">
                            <span class="option-title">{estabelecimento.nome}</span>
                          </span>
                          <span class="option-body">Razão Social: {estabelecimento.razao_social} - Bairro: {estabelecimento.endereco_bairro}</span>
                        </span>
                      </label>
                    </>
                  )}
                )}
              </div>
            </div>
            </LoadingOverlay>

            {/*begin: Wizard Actions*/}
            <div className="d-flex justify-content-between pt-3">
              <div className="mr-2">
                <button type="button" onClick={(e) => { previous(); this.atualizaFicha('identificacao'); }} className="btn btn-light-primary font-weight-bolder font-size-h6 pl-6 pr-8 py-4 my-3 mr-3" data-wizard-type="action-prev">
                  <span className="svg-icon svg-icon-md mr-1">
                    {/*begin::Svg Icon | path:assets/media/svg/icons/Navigation/Left-2.svg*/}
                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                      <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                        <polygon points="0 0 24 0 24 24 0 24" />
                        <rect fill="#000000" opacity="0.3" transform="translate(15.000000, 12.000000) scale(-1, 1) rotate(-90.000000) translate(-15.000000, -12.000000)" x={14} y={7} width={2} height={10} rx={1} />
                        <path d="M3.7071045,15.7071045 C3.3165802,16.0976288 2.68341522,16.0976288 2.29289093,15.7071045 C1.90236664,15.3165802 1.90236664,14.6834152 2.29289093,14.2928909 L8.29289093,8.29289093 C8.67146987,7.914312 9.28105631,7.90106637 9.67572234,8.26284357 L15.6757223,13.7628436 C16.0828413,14.136036 16.1103443,14.7686034 15.7371519,15.1757223 C15.3639594,15.5828413 14.7313921,15.6103443 14.3242731,15.2371519 L9.03007346,10.3841355 L3.7071045,15.7071045 Z" fill="#000000" fillRule="nonzero" transform="translate(9.000001, 11.999997) scale(-1, -1) rotate(90.000000) translate(-9.000001, -11.999997)" />
                      </g>
                    </svg>
                    {/*end::Svg Icon*/}
                  </span>Voltar</button>
              </div>
            <div>

              <button type="button" onClick={(e) => { this.redefinirContato(previous, next); }} className="btn btn-primary font-weight-bolder font-size-h6 pl-8 pr-4 py-4 my-3" data-wizard-type="action-next">Avançar
                <span className="svg-icon svg-icon-md ml-1">
                  {/*begin::Svg Icon | path:assets/media/svg/icons/Navigation/Right-2.svg*/}
                  <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                    <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                      <polygon points="0 0 24 0 24 24 0 24" />
                      <rect fill="#000000" opacity="0.3" transform="translate(8.500000, 12.000000) rotate(-90.000000) translate(-8.500000, -12.000000)" x="7.5" y="7.5" width={2} height={9} rx={1} />
                      <path d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z" fill="#000000" fillRule="nonzero" transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)" />
                    </g>
                  </svg>
                  {/*end::Svg Icon*/}
                </span></button>
              </div>
            </div>
            {/*end: Wizard Actions*/}
          </div>
        )}
      />
      <Step
        id="senha"
        render={({ next, previous }) => (
          <div>
            {/*begin::Title*/}
            <div className="pb-10 pb-lg-15">
              <h3 className="font-weight-bolder text-dark font-size-h2">Defina a nova senha</h3>
            </div>
            {/*begin::Title*/}
            <div className="row">
              <div className="form-group col-md-12">
                <div className="form-group">
                  <label className="font-size-h6 font-weight-bolder text-dark">Nova senha</label>
                  <input value={this.state.senha_alt}
                         onChange={(e)=> this.setState({senha_alt: e.target.value })}
                         className="form-control form-control h-auto py-5 px-6 rounded-lg"
                         type="text" name="senha_alt" autoComplete="off" />
                </div>
                <div className="form-group">
                  <label className="font-size-h6 font-weight-bolder text-dark">Confirme a senha</label>
                  <input value={this.state.senha_alt_con}
                         onChange={(e)=> this.setState({senha_alt_con: e.target.value })}
                         className="form-control form-control h-auto py-5 px-6 rounded-lg"
                         type="text" name="senha_alt_con" autoComplete="off" />
                </div>
              </div>
            </div>

            {/*begin: Wizard Actions*/}
            <div className="d-flex justify-content-between pt-3">
              <div className="mr-2">
                <button type="button" onClick={(e) => { previous(); this.atualizaFicha('estabelecimento'); }} className="btn btn-light-primary font-weight-bolder font-size-h6 pl-6 pr-8 py-4 my-3 mr-3" data-wizard-type="action-prev">
                  <span className="svg-icon svg-icon-md mr-1">
                    {/*begin::Svg Icon | path:assets/media/svg/icons/Navigation/Left-2.svg*/}
                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                      <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                        <polygon points="0 0 24 0 24 24 0 24" />
                        <rect fill="#000000" opacity="0.3" transform="translate(15.000000, 12.000000) scale(-1, 1) rotate(-90.000000) translate(-15.000000, -12.000000)" x={14} y={7} width={2} height={10} rx={1} />
                        <path d="M3.7071045,15.7071045 C3.3165802,16.0976288 2.68341522,16.0976288 2.29289093,15.7071045 C1.90236664,15.3165802 1.90236664,14.6834152 2.29289093,14.2928909 L8.29289093,8.29289093 C8.67146987,7.914312 9.28105631,7.90106637 9.67572234,8.26284357 L15.6757223,13.7628436 C16.0828413,14.136036 16.1103443,14.7686034 15.7371519,15.1757223 C15.3639594,15.5828413 14.7313921,15.6103443 14.3242731,15.2371519 L9.03007346,10.3841355 L3.7071045,15.7071045 Z" fill="#000000" fillRule="nonzero" transform="translate(9.000001, 11.999997) scale(-1, -1) rotate(90.000000) translate(-9.000001, -11.999997)" />
                      </g>
                    </svg>
                    {/*end::Svg Icon*/}
                  </span>Voltar</button>
              </div>
            <div>

              <button type="button" onClick={(e) => { this.validaNovaSenha(next); }}  className="btn btn-primary font-weight-bolder font-size-h6 pl-8 pr-4 py-4 my-3" data-wizard-type="action-next">Avançar
                <span className="svg-icon svg-icon-md ml-1">
                  {/*begin::Svg Icon | path:assets/media/svg/icons/Navigation/Right-2.svg*/}
                  <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                    <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                      <polygon points="0 0 24 0 24 24 0 24" />
                      <rect fill="#000000" opacity="0.3" transform="translate(8.500000, 12.000000) rotate(-90.000000) translate(-8.500000, -12.000000)" x="7.5" y="7.5" width={2} height={9} rx={1} />
                      <path d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z" fill="#000000" fillRule="nonzero" transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)" />
                    </g>
                  </svg>
                  {/*end::Svg Icon*/}
                </span></button>
              </div>
            </div>
            {/*end: Wizard Actions*/}
          </div>
        )}
      />
      <Step
        id="forma"
        render={({ next, previous }) => (
          <div>
            {/*begin::Title*/}
            <div className="pb-10 pb-lg-15">
              <h3 className="font-weight-bolder text-dark font-size-h2">Selecione a forma de recuperação</h3>
            </div>
            {/*begin::Title*/}
            <LoadingOverlay active={this.state.loading} spinner text='Carregando...' >
            <div className="row">
              <div className="form-group col-md-6">
                <label class="option">
                  <span class="option-control">
                    <span class="radio">
                      <input type="radio"
                          onChange={(e)=> this.setState({forma_recuperar: 'email'})}
                          checked={this.state?.forma_recuperar === 'email' ? true : false}
                        />
                      <span></span>
                    </span>
                  </span>
                  <span class="option-label">
                    <span class="option-head">
                      <span class="option-focus">E-mail</span>
                    </span>
                    <span class="option-title">
                      { this.state.estabelecimentoFormaRecuperacao.email }
                    </span>
                  </span>
                </label>
              </div>

              <div className="form-group col-md-6">
              <label class="option">
                  <span class="option-control">
                    <span class="radio">
                      <input type="radio"
                          onChange={(e)=> this.setState({forma_recuperar: 'SMS'})}
                          checked={this.state?.forma_recuperar === 'SMS' ? true : false}
                        />
                      <span></span>
                    </span>
                  </span>
                  <span class="option-label">
                    <span class="option-head">
                      <span class="option-focus">SMS</span>
                    </span>
                    <span class="option-title">
                      { this.state.estabelecimentoFormaRecuperacao.telefone }
                    </span>
                  </span>
                </label>
              </div>
            </div>

            </LoadingOverlay>

            {/*begin: Wizard Actions*/}
            <div className="d-flex justify-content-between pt-3">
              <div className="mr-2">
                <button type="button" onClick={(e) => { previous(); this.atualizaFicha('estabelecimento'); }} className="btn btn-light-primary font-weight-bolder font-size-h6 pl-6 pr-8 py-4 my-3 mr-3" data-wizard-type="action-prev">
                  <span className="svg-icon svg-icon-md mr-1">
                    {/*begin::Svg Icon | path:assets/media/svg/icons/Navigation/Left-2.svg*/}
                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                      <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                        <polygon points="0 0 24 0 24 24 0 24" />
                        <rect fill="#000000" opacity="0.3" transform="translate(15.000000, 12.000000) scale(-1, 1) rotate(-90.000000) translate(-15.000000, -12.000000)" x={14} y={7} width={2} height={10} rx={1} />
                        <path d="M3.7071045,15.7071045 C3.3165802,16.0976288 2.68341522,16.0976288 2.29289093,15.7071045 C1.90236664,15.3165802 1.90236664,14.6834152 2.29289093,14.2928909 L8.29289093,8.29289093 C8.67146987,7.914312 9.28105631,7.90106637 9.67572234,8.26284357 L15.6757223,13.7628436 C16.0828413,14.136036 16.1103443,14.7686034 15.7371519,15.1757223 C15.3639594,15.5828413 14.7313921,15.6103443 14.3242731,15.2371519 L9.03007346,10.3841355 L3.7071045,15.7071045 Z" fill="#000000" fillRule="nonzero" transform="translate(9.000001, 11.999997) scale(-1, -1) rotate(90.000000) translate(-9.000001, -11.999997)" />
                      </g>
                    </svg>
                    {/*end::Svg Icon*/}
                  </span>Voltar</button>
              </div>
            <div>

              <button type="button" onClick={(e) => { this.enviarCodigo(next); }}   className="btn btn-primary font-weight-bolder font-size-h6 pl-8 pr-4 py-4 my-3" data-wizard-type="action-next">Avançar
                <span className="svg-icon svg-icon-md ml-1">
                  {/*begin::Svg Icon | path:assets/media/svg/icons/Navigation/Right-2.svg*/}
                  <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                    <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                      <polygon points="0 0 24 0 24 24 0 24" />
                      <rect fill="#000000" opacity="0.3" transform="translate(8.500000, 12.000000) rotate(-90.000000) translate(-8.500000, -12.000000)" x="7.5" y="7.5" width={2} height={9} rx={1} />
                      <path d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z" fill="#000000" fillRule="nonzero" transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)" />
                    </g>
                  </svg>
                  {/*end::Svg Icon*/}
                </span></button>
              </div>
            </div>
            {/*end: Wizard Actions*/}
          </div>
        )}
      />
      <Step
        id="validacao"
        render={({ previous }) => (
          <div>
            {/*begin::Title*/}
            <div className="pb-10 pb-lg-15">
              <h3 className="font-weight-bolder text-dark font-size-h2">Validação</h3>
            </div>
            {/*begin::Title*/}

            <div className="row">
              <div className="form-group col-md-12">
                <div className="form-group">
                  <label className="font-size-h6 font-weight-bolder text-dark">Código</label>
                  <input value={this.state.codigo}
                         onChange={(e)=> this.setState({codigo: e.target.value })}
                         className="form-control form-control h-auto py-5 px-6 rounded-lg"
                         type="text" name="username" autoComplete="off" />
                </div>

              <Timer
                initialTime={300000}
                direction="backward"
              >
                {() => (
                    <React.Fragment>
                      Tempo restante para expiração do código<br />
                      <Timer.Minutes /> : <Timer.Seconds />
                    </React.Fragment>
                )}
              </Timer>
              </div>
            </div>

            {/*begin: Wizard Actions*/}
            <div className="d-flex justify-content-between pt-3">
              <div className="mr-2">
                <button type="button" onClick={(e) => { previous(); this.atualizaFicha('estabelecimento')}} className="btn btn-light-primary font-weight-bolder font-size-h6 pl-6 pr-8 py-4 my-3 mr-3" data-wizard-type="action-prev">
                  <span className="svg-icon svg-icon-md mr-1">
                    {/*begin::Svg Icon | path:assets/media/svg/icons/Navigation/Left-2.svg*/}
                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                      <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                        <polygon points="0 0 24 0 24 24 0 24" />
                        <rect fill="#000000" opacity="0.3" transform="translate(15.000000, 12.000000) scale(-1, 1) rotate(-90.000000) translate(-15.000000, -12.000000)" x={14} y={7} width={2} height={10} rx={1} />
                        <path d="M3.7071045,15.7071045 C3.3165802,16.0976288 2.68341522,16.0976288 2.29289093,15.7071045 C1.90236664,15.3165802 1.90236664,14.6834152 2.29289093,14.2928909 L8.29289093,8.29289093 C8.67146987,7.914312 9.28105631,7.90106637 9.67572234,8.26284357 L15.6757223,13.7628436 C16.0828413,14.136036 16.1103443,14.7686034 15.7371519,15.1757223 C15.3639594,15.5828413 14.7313921,15.6103443 14.3242731,15.2371519 L9.03007346,10.3841355 L3.7071045,15.7071045 Z" fill="#000000" fillRule="nonzero" transform="translate(9.000001, 11.999997) scale(-1, -1) rotate(90.000000) translate(-9.000001, -11.999997)" />
                      </g>
                    </svg>
                    {/*end::Svg Icon*/}
                  </span>Voltar</button>
              </div>
            <div>

              <button type="button" onClick={(e) => { this.salvarSenha()}} className="btn btn-success font-weight-bolder font-size-h6 pl-8 pr-4 py-4 my-3" data-wizard-type="action-next">Confirmar
                <span className="svg-icon svg-icon-md ml-1">
                  {/*begin::Svg Icon | path:assets/media/svg/icons/Navigation/Right-2.svg*/}
                  <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <polygon points="0 0 24 0 24 24 0 24"/>
                        <path d="M6.26193932,17.6476484 C5.90425297,18.0684559 5.27315905,18.1196257 4.85235158,17.7619393 C4.43154411,17.404253 4.38037434,16.773159 4.73806068,16.3523516 L13.2380607,6.35235158 C13.6013618,5.92493855 14.2451015,5.87991302 14.6643638,6.25259068 L19.1643638,10.2525907 C19.5771466,10.6195087 19.6143273,11.2515811 19.2474093,11.6643638 C18.8804913,12.0771466 18.2484189,12.1143273 17.8356362,11.7474093 L14.0997854,8.42665306 L6.26193932,17.6476484 Z" fill="#000000" fill-rule="nonzero" transform="translate(11.999995, 12.000002) rotate(-180.000000) translate(-11.999995, -12.000002) "/>
                    </g>
                  </svg>
                  {/*end::Svg Icon*/}
                </span></button>
              </div>
            </div>
            {/*end: Wizard Actions*/}
          </div>
        )}
      />
    </Steps>
  </Wizard>
</div>

            </div>
            {/*end::Form*/}
          </div>
          {/*end::Content*/}
        </div>
        {/*end::Wizard 5*/}
      </div>


                  <div className="modal-footer">
                    <button type="button" className="btn btn-light-primary font-weight-bold" data-dismiss="modal">Cancelar</button>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal fade" id="ModalCadastro" tabIndex={-1} role="dialog" aria-hidden="true">
              <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">Cadastrar Estabelecimento</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <i aria-hidden="true" className="ki ki-close" />
                </button>
                </div>
                <div className="modal-body">
                <p className="font-size-h6">Preencha os dados abaixo para que possamos entrar em contato e realizar o cadastro do seu estabelecimento no Aiboo.</p>
                <div className="form-group mt-10">
                  <label className="font-size-h6 font-weight-bolder text-dark">Seu Nome</label>
                  <input className="form-control form-control-solid h-auto py-5 px-6 rounded-lg" type="text" name="username" autoComplete="off" />
                </div>
                <div className="form-group mt-10">
                  <label className="font-size-h6 font-weight-bolder text-dark">Nome do Estabelecimento</label>
                  <input className="form-control form-control-solid h-auto py-5 px-6 rounded-lg" type="text" name="username" autoComplete="off" />
                </div>
                <div className="form-group mt-10">
                  <label className="font-size-h6 font-weight-bolder text-dark">Celular para Contato</label>
                  <input className="form-control form-control-solid h-auto py-5 px-6 rounded-lg" type="text" name="username" autoComplete="off" />
                </div>
                <div className="form-group mt-10">
                  <label className="font-size-h6 font-weight-bolder text-dark">Email</label>
                  <input className="form-control form-control-solid h-auto py-5 px-6 rounded-lg" type="text" name="username" autoComplete="off" />
                </div>
                </div>
                <div className="modal-footer">
                <button type="button" className="btn btn-light-primary font-weight-bold" data-dismiss="modal">Cancelar</button>
                <button type="button" className="btn btn-primary font-weight-bold">Solicitar cadastro</button>
                </div>
              </div>
              </div>
            </div>
          </>
        )
    }
}

export default withRouter(Login);
