import React, { Component } from 'react';
import { Button, Modal, Dropdown, DropdownButton, ButtonGroup } from "react-bootstrap";

import { withRouter } from 'react-router-dom'
import Api from '../../services/api';

import Iframe from 'react-iframe'
import LoadingOverlay from 'react-loading-overlay';
import Swal from "sweetalert2";

class Contratos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModalContratos: false,
      textoModal: '',
      email: '',
      link: '',
      contrato: '',
      obrigatorio: false,
      loading: false,
      disableButton: false
    }

    //this.openModalCategoria = this.openModalCategoria.bind(this);
    this.checkTermsVersion();
  }

  checkTermsVersion() {
    Api.get("terms/check").then((rps) => {
      if (rps.data.status === true) {
        if (rps.data.assinar === true) {
          this.setState({
            showModalContratos: true,
            textoModal: rps.data.texto,
            email: rps.data.email,
            link: rps.data.link,
            contrato: rps.data.contrato,
            obrigatorio: rps.data.obrigatorio,
          })
        }
      }

    });
  }

  gerarEmail() {
    this.setState({ loading: true });

    Api.post("terms/sendToSignature", { email: this.state.email, contrato: this.state.contrato }).then((rps) => {
      this.setState({ loading: false });
      console.log(rps.data);
      if (rps.data.status === true) {
        if (rps.data.close === true)
          this.setState({ showModalContratos: false });
        Swal.fire({
          title: "Sucesso!",
          icon: "success",
          html: rps.data.mensagem,
          showCloseButton: true,
          showCancelButton: false,
        });
      } else {
        if (rps.data.close === true)
          this.setState({ showModalContratos: false });
        Swal.fire({
          title: "Atenção!",
          icon: "info",
          html: rps.data.mensagem,
          showCloseButton: true,
          showCancelButton: false,
        });
      }

    });
  }

  close() {
    if (this.state.obrigatorio === false) {
      this.setState({ showModalContratos: false });
    } else {
      Swal.fire({
        title: "Atenção!",
        icon: "info",
        html: "A assinatura deste documento é obrigatória!",
        showCloseButton: true,
        showCancelButton: false,
      });
    }
  }

  render() {
    return (
      <>


        <Modal show={this.state.showModalContratos} shouldCloseOnOverlayClick={this.state.obrigatorio} onHide={this.state.showModalCategoria}>
          <LoadingOverlay active={this.state.loading} spinner text='Carregando...' >
            <Modal.Header closeButton>
              <Modal.Title>Contrato Aiboo</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {this.state.textoModal}

              <Iframe url={this.state.link}
                id="myId"
                className="myClassname"
                display="initial"
                position="relative"
                height="200px"
                width="100%"
              />

              <p>Confirme se este é o seu email para enviarmos o documento para assinatura:</p>
              <input className="form-control" value={this.state.email} onChange={(e) => { this.setState({ email: e.target.value }) }} />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={(e) => { this.close(); }}>Fechar</Button>
              <Button variant="success" onClick={(e) => { this.gerarEmail(); }}>Enviar para assinatura</Button>
            </Modal.Footer>
          </LoadingOverlay>
        </Modal>


      </>
    );
  }
}

export default Contratos;
