import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 35px;
  background: #ffffff;
  padding: 40px 25px 80px 40px;
`;

export const CardContent = styled.div`
  -webkit-box-shadow: 0px 0px 31px -8px rgba(0, 0, 0, 0.19);
  -moz-box-shadow: 0px 0px 31px -8px rgba(0, 0, 0, 0.19);
  box-shadow: 0px 0px 31px -8px rgba(0, 0, 0, 0.19);
  border-radius: 8px;
  padding: 30px;
`;
