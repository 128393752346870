import React, { useState, useEffect } from 'react';
import Api from '../../services/api';
import Swal from "sweetalert2";
import { useToasts } from 'react-toast-notifications';
import Axios from 'axios';
import DataTable from 'react-data-table-component';

import { withRouter } from "react-router";

const Estabelecimento = () => {
    const { addToast } = useToasts();
    const refLogo = React.useRef(null);
    const refBanner = React.useRef(null);

    const [ , setCaEstabelecimento ] = useState(true);
    const [ pendencias, setPendencias ] = useState([]);
    const [ mudou , setMudou ] = useState(false);
    const [ estabelecimento, setEstabelecimento ] = useState([]);
    const [ textoApp, setTextoApp ] = useState('');
    const [ frasePromocional, setFrasePromocional ] = useState('');
    const [ logotipo, setLogotipo ] = useState('');
    const [ banner, setBanner ] = useState('');
    const [ flagLogotipo, setFlagLogotipo ] = useState(false);
    const [ flagBanner, setFlagBanner ] = useState(false);
    const [ flagTextoApp, setFlagTextoApp ] = useState(false);
    const [ flagFrasePromocional, setFlagFrasePromocional ] = useState(false);

    useEffect(() =>{
      getListar();
      listaSolic();
    },[]);

  const data = pendencias;
  const columns = [
    {
      name: <th>Pendência</th>,
      selector: 'id_pendencias',
      sortable: true,
      width: '8%',
      right: true,
    },
    {
      name: <th>Procedência</th>,
      selector: 'tabela',
      width: '12%',
      sortable: true,
    },
    {
      name: <th>Informação</th>,
      selector: 'nome_campo',
      width: '15%',
      sortable: true,
      cell: row => {
        if(row.nome_campo==='frase_promocional'){
          return 'Frase Promocional';
        } else if (row.status === 'banner') {
          return 'Banner';
        } else {
          return 'Texto App';
        }
      }
    },
    {
      name: <th>Valor Anterior</th>,
      cell: row => row.nome_campo === 'logotipo' || row.nome_campo === 'banner' ? <img src={row.valor_ant} alt="" width="100px" /> : row.valor_ant,
      selector: 'valor_ant',
      sortable: false,
      width: '20%',
    },
    {
      name: <th>Valor Posterior</th>,
      cell: row => row.nome_campo === 'logotipo' || row.nome_campo === 'banner' ? <img src={row.valor_post} alt="" width="100px" /> : row.valor_post,
      selector: 'valor_post',
      sortable: false,
      width: '20%',
    },
    {
      name: <th>Data</th>,
      sortable: true,
      width: '13%',
      cell: row => row.status === 'Pendente' ?
       <div>{row.data_inclusao}</div> : <div>{row.data_status}</div>
      },
    {
      name: <th>Status</th>,
      sortable: true,
      width: '12%',
    cell: row => {
      if(row.status==='Pendente'){
        return <div className="alert alert-warning">Pendente</div>;
      } else if (row.status === 'Rejeitado') {
        return <div className="alert alert-danger">Rejeitado</div>;
      } else {
        return <div className="alert alert-success">Aprovado</div>;
      }
    }
    },
  ];

  function listaSolic() {
    Api.get("pendencias/listar").then(rps => {
      setPendencias(rps.data.obj);
    })
  }

    function getListar(){
        setCaEstabelecimento(true);
        Api.get("estabelecimento/getExibir").then((rps) =>{

          setEstabelecimento(rps.data.est);

          rps.data.pen.forEach(item => {

            if(item.nome_campo === "texto_app" && item.status === "Pendente") {
              setFlagTextoApp(true);
            }

            if(item.nome_campo === "frase_promocional" && item.status === "Pendente") {
              setFlagFrasePromocional(true);
            }

            if(item.nome_campo === "logotipo" && item.status === "Pendente") {
              setFlagLogotipo(true);
            }

            if(item.nome_campo === "banner" && item.status === "Pendente") {
              setFlagBanner(true);
            }
          });

          setTextoApp(rps.data.est.texto_app);
          setFrasePromocional(rps.data.est.frase_promocional);

          setCaEstabelecimento(false);
        });
    }

    async function handleLogoChange(e) {
      const form_data = new FormData();
      form_data.append('arquivo', e.target.files[0]);
      form_data.append('est_logo', estabelecimento.logotipo);

      const cf = {
          baseURL: Api.defaults.baseURL,
          headers: {
            'content-type': 'multipart/form-data',
            'Authorization' : Api.defaults.headers['Authorization']
        }
      }
      const response = await Axios.post('estabelecimento/logo', form_data, cf);
      if (response.data.status !== undefined && response.data.status === true) {
        addToast(response.data.mensagem, {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 5000
        });
        setLogotipo(response.data.url_imagem);
        setFlagLogotipo(true);
      } else {
        Swal.fire({
          title: "Atenção!",
          icon: "info",
          html: response.data.erros,
          showCloseButton: true,
          showCancelButton: false
        });
      }
    }

    async function handleBannerChange(e) {
      const form_data = new FormData();
      form_data.append('arquivo', e.target.files[0]);
      form_data.append('est_banner', estabelecimento.banner);

      const cf = {
          baseURL: Api.defaults.baseURL,
          headers: {
            'content-type': 'multipart/form-data',
            'Authorization' : Api.defaults.headers['Authorization']
        }
      }
      const response = await Axios.post('estabelecimento/banner', form_data, cf);
      if (response.data.status !== undefined && response.data.status === true) {
        addToast(response.data.mensagem, {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 5000
        });
        setBanner(response.data.url_imagem);
        setFlagBanner(true);
      } else {
        Swal.fire({
          title: "Atenção!",
          icon: "info",
          html: response.data.erros,
          showCloseButton: true,
          showCancelButton: false
        });
      }
    }

    function salvarEstabelecimento(){
        setCaEstabelecimento(true);

        var altEstabelecimento = estabelecimento;
        var altTextoEstabelecimento = textoApp;
        var altFrasePromocional = frasePromocional;

        var post = {
          estabelecimento: altEstabelecimento,
          texto_app: altTextoEstabelecimento,
          frase_promocional: altFrasePromocional
        };


        Api.post("estabelecimento/postEstabelecimento", post).then((rps) => {

          addToast('Estabelecimento salvo com sucesso', {
            appearance: "success",
            autoDismiss: true,
            autoDismissTimeout: 5000
          });
          setCaEstabelecimento(false);

        });
        setMudou(false);
      }

    return (
        <>
        {/*begin::Content*/}
        <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
        <div className="subheader espaco-header-pedidos subheader-transparent" id="kt_subheader">
            <div className="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
            {/*begin::Details*/}
            <div className="d-flex align-items-center flex-wrap mr-2">
                {/*begin::Title*/}
                <h5 className="text-dark font-weight-bold mt-2 mb-2 mr-5">Estabelecimento</h5>
                {/*end::Title*/}
                {/*begin::Separator*/}
                <div className="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200" />
                {/*end::Separator*/}
            </div>
            {/*end::Details*/}
            </div>
        </div>
        {/*begin::Entry*/}
  <div className="d-flex flex-column-fluid">
    {/*begin::Container*/}
    <div className="container">

    <ul className="nav nav-tabs nav-tabs-line nav-tabs-line-3x mb-5">
      <li className="nav-item">
        <a className="nav-link active" data-toggle="tab" href="#kt_tab_pane_1_4">CADASTRO</a>
      </li>
      <li className="nav-item">
        <a className="nav-link" data-toggle="tab" href="#kt_tab_pane_2_4">SOLICITAÇÕES</a>
      </li>
    </ul>

    <div className="tab-content">
      <div className="tab-pane fade active show" id="kt_tab_pane_1_4" role="tabpanel" aria-labelledby="kt_tab_pane_1_4">
        <div className="card card-custom">
          <div className="card-header flex-wrap">
            {/*begin::Subheader*/}
            <div className="subheader py-2 py-lg-4 subheader-transparent" id="kt_subheader">
              <div className="d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
                {/*begin::Details*/}
                <div className="d-flex align-items-center flex-wrap mr-2">
                  {/*begin::Title*/}
                  <h5 className="text-dark font-weight-bold mt-2 mb-2 mr-5">Cadastro da Loja</h5>
                  {/*end::Title*/}
                </div>
                {/*end::Details*/}
              </div>
            </div>
            {/*end::Subheader*/}
          </div>
          <div className="area-auto-open m-10">
          <form className="mt-10">
          <div className="row">
                  <div className="form-group col-md-6">
                    <label>CNPJ</label>
                    <input className="form-control" value={estabelecimento?.cnpj_cpf} disabled />
                  </div>

                  <div className="form-group col-md-6">
                    <label>Razão Social</label>
                    <input className="form-control" value={estabelecimento?.razao_social} disabled />
                  </div>
                </div>

                <div className="row">
                  <div className="form-group col-md-12">
                    <label>Nome</label>
                    <input className="form-control" value={estabelecimento?.nome} onChange={(e) => { setMudou(true); setEstabelecimento({...estabelecimento, nome: e.target.value}) }}/>
                  </div>
                </div>

                <div className="row">
                  <div className="form-group col-md-12">
                    <label>E-mail</label>
                    <input className="form-control" value={estabelecimento?.email} onChange={(e) => { setMudou(true); setEstabelecimento({...estabelecimento, email: e.target.value}) }}/>
                  </div>
                </div>

                <div className="row">
                  <div className="form-group col-md-10">
                    <label>Endereço</label>
                    <input className="form-control" value={estabelecimento?.endereco_rua} onChange={(e) => { setMudou(true); setEstabelecimento({...estabelecimento, endereco_rua: e.target.value}) }}/>
                  </div>

                  <div className="form-group col-md-2">
                    <label>Número</label>
                    <input className="form-control" value={estabelecimento?.endereco_numero} onChange={(e) => { setMudou(true); setEstabelecimento({...estabelecimento, endereco_numero: e.target.value}) }}/>
                  </div>
                </div>

                <div className="row">
                  <div className="form-group col-md-3">
                    <label>Bairro</label>
                    <input className="form-control" value={estabelecimento?.endereco_bairro} onChange={(e) => { setMudou(true); setEstabelecimento({...estabelecimento, endereco_bairro: e.target.value}) }}/>
                  </div>

                  <div className="form-group col-md-3">
                    <label>CEP</label>
                    <input className="form-control" value={estabelecimento?.endereco_cep} onChange={(e) => { setMudou(true); setEstabelecimento({...estabelecimento, endereco_cep: e.target.value}) }}/>
                  </div>

                  <div className="form-group col-md-6">
                    <label>Contato</label>
                    <input className="form-control" value={estabelecimento?.contato} onChange={(e) => { setMudou(true); setEstabelecimento({...estabelecimento, contato: e.target.value}) }}/>
                  </div>
                </div>

                <div className="separator separator-solid my-7"></div>
                <h4>As alterações abaixo, passam por aprovação da equipe do Aiboo.</h4>
                <br />

                <div className="row">
                  <div className="form-group col-md-6">
                    <label>Texto App</label>&nbsp;&nbsp;
                     {
                        flagTextoApp === true &&
                          <i className="icon-md fas fa-question-circle" data-toggle="tooltip" title="O campo está pendente para avaliação do Aiboo."></i>
                     }
                    <input disabled={flagTextoApp} className="form-control" value={textoApp} onChange={(e) => { setMudou(true); setTextoApp(e.target.value) }}/>
                  </div>

                  <div className="form-group col-md-6">
                    <label>Frase Promocional</label>&nbsp;&nbsp;
                     {
                        flagFrasePromocional === true &&
                          <i className="icon-md fas fa-question-circle" data-toggle="tooltip" title="O campo está pendente para avaliação do Aiboo."></i>
                     }
                    <input disabled={flagFrasePromocional} className="form-control" value={frasePromocional} onChange={(e) => { setMudou(true); setFrasePromocional(e.target.value) }}/>
                  </div>
                </div>

                <div className="row">
                  <div className="from-group col-md-6">
                  <label>Logotipo</label>&nbsp;&nbsp;
                  {
                        flagLogotipo === true &&
                          <i className="icon-md fas fa-question-circle" data-toggle="tooltip" title="O campo está pendente para avaliação do Aiboo."></i>
                     }
                  <div className="area-imagem dropzone--squared menu-category-drawer-item-details-tab__image">
                    <div className="dropzone__area" aria-disabled="false" style={{position: 'relative'}}
                      onClick={e => {refLogo.current.click()}} >
                      <div className="dropzone-preview dropzone__preview">
                        <img className="dropzone-preview__image" src={estabelecimento?.logotipo ? estabelecimento?.logotipo : "assets/media/no-img2.jpg"} alt="" />
                      </div>
                      <div disabled={flagLogotipo} className="dropzone-placeholder dropzone__placeholder">
                        <span>Trocar imagem</span>
                      </div>
                      <input disabled={flagLogotipo} type="file" ref={refLogo} name="imagemLogo" onChange={(e) => handleLogoChange(e)} accept=".png, .jpg, .jpeg" className="classeInputImage" />
                    </div>
                  </div>
                  </div>

                  <div className="from-group col-md-6">
                  <label>Banner</label>&nbsp;&nbsp;
                  {
                        flagBanner === true &&
                          <i className="icon-md fas fa-question-circle" data-toggle="tooltip" title="O campo está pendente para avaliação do Aiboo."></i>
                     }
                  <div className="area-imagem dropzone--squared menu-category-drawer-item-details-tab__image">
                    <div className="dropzone__area" aria-disabled="false" style={{position: 'relative'}}
                      onClick={e => {refBanner.current.click()}} >
                      <div className="dropzone-preview dropzone__preview">
                        <img className="dropzone-preview__image" src={estabelecimento?.banner ? estabelecimento?.banner : "assets/media/no-img2.jpg"} alt="" />
                      </div>
                      <div disabled={flagBanner} className="dropzone-placeholder dropzone__placeholder">
                        <span>Trocar imagem</span>
                      </div>
                      <input disabled={flagBanner} type="file" ref={refBanner} name="imagemBanner" onChange={(e) => handleBannerChange(e)} accept=".png, .jpg, .jpeg" className="classeInputImage" />
                    </div>
                  </div>
                  </div>
                </div>

                <br />

                <div className="row">
                  <div className="from-group col-md-6">
                  {
                    logotipo !== '' &&
                  <>
                  <label>Logotipo - Enviado para Aprovação</label>
                  <div className="area-imagem dropzone--squared menu-category-drawer-item-details-tab__image">
                    <div className="dropzone__area" aria-disabled="false" style={{position: 'relative'}} >
                      <div className="dropzone-preview dropzone__preview">
                        <img className="dropzone-preview__image" src={logotipo} alt="" />
                      </div>
                    </div>
                  </div>
                  </>
                  }
                  </div>
                  <div className="from-group col-md-6">
                  {
                    banner !== '' &&
                  <>
                  <label>Banner - Enviado para Aprovação</label>
                  <div className="area-imagem dropzone--squared menu-category-drawer-item-details-tab__image">
                    <div className="dropzone__area" aria-disabled="false" style={{position: 'relative'}} >
                      <div className="dropzone-preview dropzone__preview">
                        <img className="dropzone-preview__image" src={banner} alt="" />
                      </div>
                    </div>
                  </div>
                  </>
                  }
                  </div>
                </div>

                </form>

                <div className="row mt-10 text-left">


                <div className="botao-salvar-alteracoes">
                  <button disabled={!mudou} type="button" className="btn btn-success btn-lg btn-block" onClick={e=>{salvarEstabelecimento()}}>Salvar Alterações</button>
                </div>

                </div>
          </div>
        </div>
      </div>
      <div className="tab-pane fade" id="kt_tab_pane_2_4" role="tabpanel" aria-labelledby="kt_tab_pane_2_4">
  <div className="card card-custom">
    <div className="card-header flex-wrap">
      {/*begin::Subheader*/}
      <div className="subheader py-2 py-lg-4 subheader-transparent" id="kt_subheader">
        <div className="d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
          {/*begin::Details*/}
          <div className="d-flex align-items-center flex-wrap mr-2">
            {/*begin::Title*/}
            <h5 className="text-dark font-weight-bold mt-2 mb-2 mr-5">Alterações Solicitadas</h5>
            {/*end::Title*/}
            {/*begin::Separator*/}
            <div className="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200" />
            {/*end::Separator*/}
          </div>
          {/*end::Details*/}
        </div>

      </div>

    </div>
    <DataTable
          columns={columns}
          data={data}
          pagination="true"
        />
    </div></div>

    </div>


      <div className="row text-center">
        <div className="col-xl-12">


          {/*end::Body*/}
        </div>
      </div>
      {/*end::Container*/}
    </div>
    {/*end::Entry*/}
  </div>
  {/*end::Content*/}
</div>

        </>
    )
}

export default withRouter(Estabelecimento);
