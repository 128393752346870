import LoadingOverlay from "react-loading-overlay";
import styled, { keyframes } from "styled-components";

export const Container = styled.div`
  h2 {
    font-family: "Nunito";
    font-weight: 600;
    font-size: 20px;
    color: #333333;
    margin: 0;
  }
`;

export const Separator = styled.div`
  width: 100%;
  height: 1px;
  background: #e5eaee;
`;

export const UploadButtonContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 18px 0 40px 0;

  label {
    font-family: "Nunito";
    font-size: 12px;
    color: #333333;
    margin: 0 0 0 15px;
    max-width: 420px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const UploadButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 270px;
  padding: 15px;
  background: #f1f2f3;
  border: 1px solid #dcdcdc;
  border-radius: 8px;
  gap: 17px;

  label {
    font-family: "Nunito";
    font-size: 14px;
    color: #000000;
    margin: 0;
    cursor: pointer;
  }
`;

export const CheckboxInputContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  gap: 10px;

  label {
    cursor: pointer;
    font-family: "Nunito";
    font-size: 14px;
    color: #333333;
    margin: 0;
  }

  i {
    font-size: 13px;
    color: #989898;
  }
  span {
    color: #ff3333;
  }
`;

export const Checkbox = styled.input`
  cursor: pointer;
  width: 16px;
  height: 16px;
  &:checked {
    accent-color: #ff3333;
  }
`;

export const ToolTipTranslate = keyframes`
    from {
        transform: translateX(-10px);
    }
    to {
        transform: translate(0);
    }
  `;

export const Tooltip = styled.div`
  visibility: ${({ visibility }) => (visibility ? "visible" : "hidden")};
  animation-name: ${({ visibility }) => visibility && ToolTipTranslate};
  animation-duration: 0.5s;
  animation-iteration-count: 1;
  background: #e5eaee;
  border-radius: 2px;
  max-width: 300px;
  padding: 5px 12px;
  margin-left: 5px;
  z-index: 0;
  p {
    font-family: "Nunito";
    font-size: 12px;
    color: #333333;
    margin: 0;
  }
`;

export const FormItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 584px;
  height: 100px;
  background: #ffffff;
  border: 1px solid #dcdcdc;
  border-radius: 16px;
  padding: 0 25px;
  margin: 18px 0;
`;

export const CollumnInputContainer = styled.div`
  width: fit-content;

  p {
    font-family: "Nunito";
    font-size: 16px;
    color: #333333;
    margin: 0;
  }

  input {
    width: 184px;
    height: 44px;
    border-radius: 12px;
    border: 1px solid #e5eaee;
    background: #f1f5f9;
    padding: 15px;
    font-family: "Nunito";
    font-size: 14px;
    color: #333333;
  }
`;

export const FormContainer = styled.div`
  h3 {
    font-family: "Nunito";
    font-weight: 600;
    font-size: 16px;
    color: #333333;
    margin: 40px 0 0;
  }

  small {
    font-family: "Nunito";
    font-size: 12px;
    color: #333333;
    margin: 0;
  }
`;

export const SubmitButtonContainer = styled.div`
  display: flex;
  justify-content: end;
  button {
    width: 223px;
    height: 37px;
    background: #00b333;
    border-radius: 8px;
    color: #ffffff;
    font-family: "Nunito";
    font-size: 16px;
    border: none;
    transition: opacity 0.8s ease;
    align-self: flex-end;
    &:hover {
      opacity: 0.8;
    }
  }
`;

export const StyledLoader = styled(LoadingOverlay)`
  .MyLoader_overlay {
    background: rgba(0, 0, 0, 0);
  }

  padding-bottom: 10px;
`;
