/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useCallback } from "react";
import Api from "../../services/api";
import { withRouter } from "react-router";

import Swal from "sweetalert2";
import { useToasts } from "react-toast-notifications";
import CurrencyInput from "react-currency-input";
import { Checkbox } from "../../components/Checkbox";

const Bairros = () => {
  const { addToast } = useToasts();
  const [cidadesBairros, setCidadesBairros] = useState([]);
  const [estabelecimentoBairros, setEstabelecimentosBairros] = useState({
    bairros: [],
    cidade: { id_cidade: "" },
  });

  const [produtosSelecionados, setProdutosSelecionados] = useState({});
  const [formEdicaoBairro, setFormEdicaoBairro] = useState({});

  const [primeiroCarregamento, setPrimeiroCarregamento] = useState(true);

  const getBairros = useCallback(() => {
    Api.get("estabelecimentobairros/listar").then((rps) => {
      if (Boolean(rps.data.status)) {
        setCidadesBairros(rps.data.bairros);

        if (estabelecimentoBairros.cidade.id_cidade) {
          rps.data.bairros.forEach((e) => {
            if (
              e.cidade.id_cidade === estabelecimentoBairros.cidade.id_cidade
            ) {
              setEstabelecimentosBairros(e);
            }
          });
        }

        if (primeiroCarregamento === true) {
          setPrimeiroCarregamento(false);
          selecionaCidade(rps.data.bairros[0]);
        }
      }
    });
  }, [estabelecimentoBairros.cidade.id_cidade, primeiroCarregamento]);

  function selecionaCidade(cidade) {
    setEstabelecimentosBairros(cidade);
    setProdutosSelecionados({});
  }

  //Inicio - funções relativas ao bairro
  function habilitaBairro(bairro) {
    Api.post("estabelecimentobairros/habilitarBairro/" + bairro.id_bairro).then(
      (rps) => {
        if (rps.data.status !== undefined && rps.data.status === true) {
          addToast(rps.data.mensagem, {
            appearance: "success",
            autoDismiss: true,
            autoDismissTimeout: 2000,
          });
          getBairros();
        } else {
          Swal.fire({
            title: "Atenção!",
            icon: "info",
            html: rps.data.erro,
            showCloseButton: true,
            showCancelButton: false,
          });
        }
      }
    );
  }
  function sendFormBairro(nome, valor, bairro) {
    var aBairro = bairro.atendido;
    if (nome === "valor_taxa_old") {
      if (
        parseFloat(aBairro.valor_taxa) === 0 &&
        parseFloat(aBairro.valor_taxa_old) === 0
      ) {
        addToast("O frete deste bairro já é grátis!", {
          appearance: "warning",
          autoDismiss: true,
          autoDismissTimeout: 2000,
        });
        return;
      } else if (parseFloat(aBairro.valor_taxa) === 0) {
        aBairro["valor_taxa"] = aBairro.valor_taxa_old;
        aBairro["valor_taxa_old"] = 0;
      } else {
        aBairro["valor_taxa_old"] = aBairro.valor_taxa;
        aBairro["valor_taxa"] = 0;
      }
    } else {
      aBairro[nome] = valor;
    }
    /*var novoEstabelecimentoBairros = [];
      estabelecimentoBairros.bairros.forEach(eb => {
        if(eb.atendido.id_estabelecimento_bairro==bairro.atendido.id_estabelecimento_bairro) {
          eb.atendido = aBairro;
        }
        novoEstabelecimentoBairros[novoEstabelecimentoBairros.length] = eb;
      });
      setEstabelecimentosBairros({...estabelecimentoBairros, bairros: novoEstabelecimentoBairros});*/
    Api.post(
      "estabelecimentobairros/editar/" + aBairro.id_estabelecimento_bairro,
      aBairro
    ).then((rps) => {
      if (rps.data.status !== undefined && rps.data.status === true) {
        addToast(rps.data.mensagem, {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 2000,
        });
      } else {
        Swal.fire({
          title: "Atenção!",
          icon: "info",
          html: rps.data.erro,
          showCloseButton: true,
          showCancelButton: false,
        });
      }
    });
  }
  function mudaSts(s, frete) {
    Api.post("estabelecimentobairros/mudaStsLote/" + s + "/" + frete, {
      sel: produtosSelecionados,
    }).then((rps) => {
      if (rps.data.status !== undefined && rps.data.status === true) {
        addToast(rps.data.mensagem, {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 2000,
        });
        getBairros();
      } else {
        Swal.fire({
          title: "Atenção!",
          icon: "info",
          html: rps.data.erro,
          showCloseButton: true,
          showCancelButton: false,
        });
      }
    });
  }
  function alterarEmLote() {
    Api.post("estabelecimentobairros/mudaStsLote/S/E", {
      sel: produtosSelecionados,
      valor_taxa: formEdicaoBairro?.valor_taxa,
      valor_frete_gratis: formEdicaoBairro?.valor_frete_gratis,
      valor_minimo: formEdicaoBairro?.valor_minimo,
    }).then((rps) => {
      if (rps.data.status !== undefined && rps.data.status === true) {
        addToast(rps.data.mensagem, {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 2000,
        });
        getBairros();
      } else {
        Swal.fire({
          title: "Atenção!",
          icon: "info",
          html: rps.data.erro,
          showCloseButton: true,
          showCancelButton: false,
        });
      }
    });
  }
  function selecionaTudo(val) {
    var tes = {};
    estabelecimentoBairros.bairros.forEach((b) => {
      tes[b.id_bairro] = val;
    });
    setProdutosSelecionados(tes);
  }
  //Final - funções relativas ao bairro

  useEffect(() => {
    getBairros();
  }, [getBairros]);

  return (
    <>
      <div>
        <div
          className="subheader espaco-header-pedidos subheader-transparent"
          id="kt_subheader"
        >
          <div className="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
            {/*begin::Details*/}
            <div className="d-flex align-items-center flex-wrap mr-2">
              {/*begin::Title*/}
              <h5 className="text-dark font-weight-bold mt-2 mb-2 mr-5">
                Locais de Atendimento
              </h5>
              {/*end::Title*/}
              {/*begin::Separator*/}
              <div className="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200" />
              {/*end::Separator*/}
            </div>
            {/*end::Details*/}
            {/*begin::Toolbar*/}
            <div className="d-flex align-items-center">
              {/*begin::Button*/}
              {/*<a href="#" data-toggle="modal" data-target="#ModalAdicionarCidade" className="btn btn-light-primary pl-10 pr-10 btn-md btn-shadow-hover font-weight-bold">Adicionar Nova
                Cidade</a>*/}
              {/*end::Button*/}
              {/*begin::Button*/}
            </div>
            {/*end::Toolbar*/}
          </div>
        </div>
        {/*begin::Entry*/}
        <div className="d-flex flex-column-fluid">
          {/*begin::Container*/}
          <div className="container">
            <div className="card card-custom">
              <div className="card-header card-header-tabs-line">
                <div className="card-toolbar">
                  <ul className="nav nav-tabs nav-bold nav-tabs-line">
                    {cidadesBairros.map((cb) => {
                      let classe = "";
                      if (
                        cb.cidade.id_cidade ===
                        estabelecimentoBairros?.cidade.id_cidade
                      )
                        classe = "active";
                      return (
                        <li key={cb.cidade.id_cidade} className="nav-item">
                          <a
                            onClick={(e) => {
                              selecionaCidade(cb);
                            }}
                            className={"nav-link " + classe}
                          >
                            <span className="nav-text">
                              {cb.cidade.nome_cidade}
                            </span>
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </div>
                {/*<div className="card-toolbar">
                  <div className="dropdown dropdown-inline">
                    <button type="button" className="btn btn-hover-light-primary btn-icon btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <i className="ki ki-more-hor" />
                    </button>
                    <div className="dropdown-menu dropdown-menu-sm">
                      <a className="dropdown-item" href="#">Remover Cidade</a>
                    </div>
                    </div>
                    </div>*/}
              </div>
              <div className="tab-content">
                <div
                  className="tab-pane fade active show"
                  id="kt_tab_pane_1_4"
                  role="tabpanel"
                  aria-labelledby="kt_tab_pane_1_4"
                >
                  <div className="area-top-header-informacoes mt-10">
                    <div className="header-bairros-top">
                      <div className="form-group">
                        <label>Compra Mínima</label>
                        <CurrencyInput
                          value={formEdicaoBairro?.valor_minimo}
                          onChange={(e) => {
                            setFormEdicaoBairro({
                              ...formEdicaoBairro,
                              valor_minimo: e,
                            });
                          }}
                          maxLength={6}
                          precision={"2"}
                          decimalSeparator="."
                          className="form-control form-small"
                          type="text"
                        />
                      </div>
                    </div>
                    <div className="header-bairros-top">
                      <div className="form-group">
                        <label>Taxa de Entrega</label>
                        <div className="input-group input-group-solid">
                          <CurrencyInput
                            value={formEdicaoBairro?.valor_taxa}
                            onChange={(e) => {
                              setFormEdicaoBairro({
                                ...formEdicaoBairro,
                                valor_taxa: e,
                              });
                            }}
                            maxLength={6}
                            precision={"2"}
                            decimalSeparator="."
                            className="form-control form-small"
                            type="text"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="header-bairros-top">
                      <div className="form-group">
                        <label>Entrega Grátis em...</label>
                        <CurrencyInput
                          value={formEdicaoBairro?.valor_frete_gratis}
                          onChange={(e) => {
                            setFormEdicaoBairro({
                              ...formEdicaoBairro,
                              valor_frete_gratis: e,
                            });
                          }}
                          maxLength={6}
                          precision={"2"}
                          decimalSeparator="."
                          className="form-control form-small"
                          type="text"
                        />
                      </div>
                    </div>
                    <div className="header-bairros-top">
                      <a
                        onClick={(e) => {
                          alterarEmLote();
                        }}
                        className="btn btn-light-primary font-weight-bolder btn-md mr-3"
                      >
                        Alterar selecionados
                      </a>
                      <div className="btn-group">
                        <button
                          type="button"
                          className="btn btn-success font-weight-bolder"
                        >
                          Alterar Selecionados
                        </button>
                        <button
                          type="button"
                          className="btn btn-success dropdown-toggle dropdown-toggle-split"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <span className="sr-only">Toggle Dropdown</span>
                        </button>
                        <div className="dropdown-menu">
                          <a
                            onClick={(e) => {
                              mudaSts("N", "");
                            }}
                            className="dropdown-item"
                          >
                            <i className="fas fa-power-off text-success mr-3" />{" "}
                            Ativar Selecionados
                          </a>
                          <a
                            onClick={(e) => {
                              mudaSts("S", "");
                            }}
                            className="dropdown-item"
                          >
                            <i className="fas fa-power-off text-danger mr-3" />
                            Desativar Selecionados
                          </a>

                          <a
                            onClick={(e) => {
                              mudaSts("S", "F");
                            }}
                            className="dropdown-item"
                          >
                            <i className="fas fa-motorcycle text-success mr-3" />
                            Ativar frete grátis
                          </a>
                          <a
                            onClick={(e) => {
                              mudaSts("N", "F");
                            }}
                            className="dropdown-item"
                          >
                            <i className="fas fa-motorcycle text-danger mr-3" />
                            Desativar frete grátis
                          </a>
                        </div>
                      </div>
                      {/* <a href="#" className="btn btn-success font-weight-bolder btn-md m-t-19">
                              Alterar Selecionados
                            </a>

                            <div className="dropdown display-inline m-t-19">
                              <button className="btn btn-md btn-secondary font-weight-bolder dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i className="fas fa-power-off"></i> Selecionados
                              </button>
                              <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                  <a className="dropdown-item" href="#"><i className="fas fa-power-off text-success mr-3"></i> Ativar Selecionados</a>
                                  <a className="dropdown-item" href="#"><i className="fas fa-power-off text-danger mr-3"></i> Desativar Selecionados</a>
                              </div>
                          </div> */}
                    </div>
                  </div>
                  {/*
                  <form className="ml-9 mt-10">
                    <div className="input-group input-group-sm input-group-solid" style={{maxWidth: '408px'}}>
                      <input type="text" className="form-control" id="kt_subheader_search_form" placeholder="Procurar..." />
                      <div className="input-group-append">
                        <span className="input-group-text">
                          <span className="svg-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                              <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                <rect x={0} y={0} width={24} height={24} />
                                <path d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z" fill="#000000" fillRule="nonzero" opacity="0.3" />
                                <path d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z" fill="#000000" fillRule="nonzero" />
                              </g>
                            </svg>
                          </span>
                        </span>
                      </div>
                    </div>
                  </form>
                  */}
                  <div className="table-div-header padding-horizontal mt-5">
                    <div className="table-div-header-bairros-lista">
                      <Checkbox
                        onChange={(e) => {
                          selecionaTudo(e.target.checked);
                        }}
                      />
                    </div>
                    <div className="table-div-header-bairros-lista">Bairro</div>
                    <div className="table-div-header-bairros-lista">
                      Compra Mínima
                    </div>
                    <div className="table-div-header-bairros-lista">
                      Taxa de Entrega
                    </div>
                    <div className="table-div-header-bairros-lista">
                      Entrega Grátis Em...
                    </div>
                  </div>

                  {estabelecimentoBairros.bairros.map((eb) => {
                    var desabilitado = true;
                    if (eb.atendido != null && eb.atendido?.inativo !== "S") {
                      desabilitado = false;
                    }

                    var checkedIn = false;
                    if (
                      produtosSelecionados[eb.id_bairro] === undefined ||
                      produtosSelecionados[eb.id_bairro] === false
                    ) {
                      checkedIn = false;
                    } else {
                      checkedIn = true;
                    }
                    if (produtosSelecionados["t"]) {
                      checkedIn = true;
                    }

                    return (
                      <div
                        key={eb.id_bairro}
                        className="table-item padding-horizontal"
                      >
                        <div className="table-bairros-lista">
                          <Checkbox
                            onChange={(e) => {
                              setProdutosSelecionados({
                                ...produtosSelecionados,
                                [eb.id_bairro]: e.target.checked,
                              });
                            }}
                            checked={checkedIn}
                          />
                        </div>
                        <div className="table-bairros-lista">
                          {(eb.atendido === null ||
                            eb.atendido?.inativo === "S") && (
                            <button
                              onClick={(e) => {
                                habilitaBairro(eb);
                              }}
                              className="btn btn-icon btn-danger btn-sm mr-2"
                            >
                              <i className="fas fa-power-off" />
                            </button>
                          )}
                          {eb.atendido != null &&
                            eb.atendido?.inativo !== "S" && (
                              <button
                                onClick={(e) => {
                                  habilitaBairro(eb);
                                }}
                                className="btn btn-icon btn-success btn-sm mr-2"
                              >
                                <i className="fas fa-power-off" />
                              </button>
                            )}
                          {eb.nome_bairro}
                        </div>
                        <div className="table-bairros-lista">
                          <CurrencyInput
                            onBlur={(e) => {
                              sendFormBairro(
                                "valor_minimo",
                                e.target.value,
                                eb
                              );
                            }}
                            disabled={desabilitado}
                            maxLength={6}
                            precision={"2"}
                            value={eb.atendido?.valor_minimo}
                            decimalSeparator="."
                            className="form-control form-small"
                            type="text"
                          />
                        </div>
                        <div className="table-bairros-lista">
                          {parseFloat(eb.atendido?.valor_taxa_old) > 0 && (
                            <div className="input-group">
                              <CurrencyInput
                                onBlur={(e) => {
                                  sendFormBairro(
                                    "valor_taxa",
                                    e.target.value,
                                    eb
                                  );
                                }}
                                disabled={true}
                                maxLength={6}
                                precision={"2"}
                                value={eb.atendido?.valor_taxa_old}
                                decimalSeparator="."
                                className="form-control form-small line-through "
                                type="text"
                              />
                              <div className="input-group-append">
                                <button
                                  disabled={desabilitado}
                                  onClick={(e) => {
                                    sendFormBairro("valor_taxa_old", 0, eb);
                                  }}
                                  className="btn btn-success btn-sm btn-sm-2"
                                  type="button"
                                >
                                  <i className="fas fa-motorcycle" />
                                </button>
                              </div>
                            </div>
                          )}
                          {(parseFloat(eb.atendido?.valor_taxa_old) === 0 ||
                            eb.atendido === undefined ||
                            eb.atendido === null) && (
                            <div className="input-group">
                              <CurrencyInput
                                onBlur={(e) => {
                                  sendFormBairro(
                                    "valor_taxa",
                                    e.target.value,
                                    eb
                                  );
                                }}
                                disabled={desabilitado}
                                maxLength={6}
                                precision={"2"}
                                value={eb.atendido?.valor_taxa}
                                decimalSeparator="."
                                className="form-control form-small"
                                type="text"
                              />
                              <div className="input-group-append">
                                <button
                                  disabled={desabilitado}
                                  onClick={(e) => {
                                    sendFormBairro("valor_taxa_old", 0, eb);
                                  }}
                                  className="btn btn-secondary btn-sm btn-sm-2"
                                  type="button"
                                >
                                  <i className="fas fa-motorcycle" />
                                </button>
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="table-bairros-lista">
                          <CurrencyInput
                            onBlur={(e) => {
                              sendFormBairro(
                                "valor_frete_gratis",
                                e.target.value,
                                eb
                              );
                            }}
                            disabled={desabilitado}
                            maxLength={6}
                            precision={"2"}
                            value={eb.atendido?.valor_frete_gratis}
                            decimalSeparator="."
                            className="form-control form-small"
                            type="text"
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div
                  className="tab-pane fade"
                  id="kt_tab_pane_2_4"
                  role="tabpanel"
                  aria-labelledby="kt_tab_pane_2_4"
                ></div>
              </div>
            </div>
          </div>
          {/*end::Container*/}
        </div>
        {/*end::Entry*/}
      </div>
    </>
  );
};

export default withRouter(Bairros);
