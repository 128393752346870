import styled, { css } from "styled-components";

export const Container = styled.div``;

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  width: 382px;
  background: #ffffff;
  border-radius: 8px 8px 0px 0px;
  padding: 17px 21px;

  border: 1px solid #dcdcdc;

  ${({ notification }) =>
    notification &&
    css`
      border: 1px solid #ff3333;
    `}

  span {
    font-family: "Nunito";
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #333333;
  }

  aside {
    display: flex;
    gap: 25px;

    button {
      background-color: transparent;
      border: none;
      cursor: pointer;
    }
  }
`;

export const Main = styled.main`
  position: relative;
  height: 382px;
  width: 382px;
  overflow-x: hidden;
  overflow-y: scroll;
  padding-top: 15px;

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f2f4;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #999999;
    border: 3px solid #f1f2f4;
  }

  background-color: #fff;
  border-left: 1px solid #dcdcdc;
  border-right: 1px solid #dcdcdc;

  padding: 0 16px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 5px;
`;

export const Content = styled.div`
  ${({ visible }) =>
    !visible &&
    css`
      display: none;
    `}
`;

export const ContentCounter = styled.div`
  margin: 5px 15px;
  p {
    padding: 0;
    margin: 0;
    color: #999999;
    font-size: 12px;
    opacity: 0.5;
  }
`;

export const MessageContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  align-items: ${(props) =>
    props.type === "client" ? "flex-start" : "flex-end"};
`;

export const Footer = styled.footer`
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border: 1px solid #dcdcdc;

  button {
    background-color: transparent;
    border: none;
    cursor: pointer;

    font-family: "Nunito";
    font-size: 14px;
    line-height: 19px;
    color: #ff3333;

    &:focus-visible {
      outline: 2px solid #bbb !important;
      border-radius: 3px;
    }
  }
`;

export const InputWrapper = styled.div`
  display: flex;
  position: relative;
  width: 100%;

  input {
    padding: 14px;
    font-size: 14px;
    background: #ffffff;
    width: 100%;
    border: none;

    &::placeholder {
      font-size: 14px;
      color: #999999;
      opacity: 0.5;
      font-family: "Nunito";
    }

    &:focus-visible {
      outline: 2px solid #bbb !important;
      border-radius: 2px;
    }
  }
`;

export const MessageShimmer = styled.div`
  display: flex;

  border-radius: 16px 0px 16px 16px;
  padding: 5px 10px 15px 5px;

  width: 70%;
  height: 50px;

  background-color: #f6f7f8;
`;

export const Shimmer = styled.div`
  background: #f6f7f8;
  background-image: linear-gradient(
    to right,
    #f6f7f8 0%,
    #edeef1 20%,
    #f6f7f8 40%,
    #f6f7f8 100%
  );
  background-repeat: no-repeat;
  background-size: 800px 104px;
  display: inline-block;
  position: relative;

  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-name: placeholderShimmer;
  -webkit-animation-timing-function: linear;
`;

export const GhostScroll = styled.div`
  width: 100%;
  min-height: 50px;
`;

export const CurrentMessageButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: #f2f2f2;
  border: none;
  border-radius: 50%;
  height: 40px;
  width: 40px;

  position: fixed;
  bottom: 90px;
  right: 40px;
  box-shadow: 8px 5px 15px -2px rgba(70, 70, 70, 0.4);
`;
