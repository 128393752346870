import React, { useState, useRef  } from 'react';
import { Doughnut  } from 'react-chartjs-2';
import Api from '../../services/api';
import Swal from "sweetalert2";
import LoadingOverlay from 'react-loading-overlay';
import InputMask from 'react-input-mask';
import "react-datepicker/dist/react-datepicker.css";

import ReactToPrint from "react-to-print";
import "./index.scss";

import ReactTooltip from 'react-tooltip';

import { withRouter } from "react-router";

import DatePicker, { registerLocale } from "react-datepicker";
import pt from "date-fns/locale/pt-BR";
registerLocale("pt-BR", pt);

const Relatorio = () => {
    const [ dataInicio, setDataInicio ] = useState('');
    const [ dataFinal, setDataFinal ] = useState('');
    const [ dataInicio_Formatada, setDataInicioFormatada ] = useState('');
    const [ dataFinal_Formatada, setDataFinalFormatada ] = useState('');

    const [ pedidos, setPedidos ] = useState([]);

    const [ pedidosCountCancelados, setPedidosCountCancelados ] = useState(0);
    const [ pedidosSomaCancelados, setPedidosSomaCancelados ] = useState(0);
    const [ pedidosCountEfetivados, setPedidosCountEfetivados ] = useState(0);
    const [ pedidosSomaEfetivados, setPedidosSomaEfetivados ] = useState(0);
    const [ pedidosSomaEntregues, setPedidosSomaEntregues ] = useState(0);
    const [ pedidosSomaDesconto, setPedidosSomaDesconto ] = useState(0);
    const [ pedidosCountOnlinePC, setPedidosCountOnlinePC ] = useState(0);
    const [ pedidosSomaOnlinePC, setPedidosSomaOnlinePC ] = useState(0);
    const [ pedidosCountPresencialPC, setPedidosCountPresencialPC ] = useState(0);
    const [ pedidosSomaPresencialPC, setPedidosSomaPresencialPC ] = useState(0);
    const [ pedidosCountOnlinePD, setPedidosCountOnlinePD ] = useState(0);
    const [ pedidosSomaOnlinePD, setPedidosSomaOnlinePD ] = useState(0);
    const [ pedidosCountPresencialPD, setPedidosCountPresencialPD ] = useState(0);
    const [ pedidosSomaPresencialPD, setPedidosSomaPresencialPD ] = useState(0);
    const [ pedidosSomaGeral, setPedidosSomaGeral ] = useState(0);
    const printRef = useRef();

    const [ caProcessa, setCaProcessa ] = useState(false);

    const data = {
        labels: ['Cancelados', 'Efetivados'],
        datasets: [
            {
                data: [pedidosCountCancelados, pedidosCountEfetivados],
                backgroundColor: ['#ff0000', '#008000'],
                hoverBackgroundColor: ['#ff0000', '#008000']
            }
        ]
    };

        function efetuarPesquisa(){
            setCaProcessa(true);
            var data = {
                data_inicio: dataInicio,
                data_final: dataFinal,
            };

            Api.post('relatorio/geraRelatorio', data).then((rps) => {
                console.log(rps);

                if(rps.data.status==false){
                    Swal.fire({
                        title: "Atenção!",
                        icon: "info",
                        html: rps.data.erro,
                        showCloseButton: true,
                        showCancelButton: false,
                      });
                    setDataInicioFormatada(rps.data.data_inicio);
                    setDataFinalFormatada(rps.data.data_final);
                } else {
                    setPedidos(rps.data.retorno);
                    processaDados(rps.data.retorno);
                    setDataInicioFormatada(rps.data.data_inicio);
                    setDataFinalFormatada(rps.data.data_final);
                }
                setCaProcessa(false);
            });
        }

        function processaDados(dados){

            var countPedidosCancelados = 0;
            var sumPedidosCancelados = 0;
            var countPedidosEfetivados = 0;
            var sumPedidosEfetivados = 0;
            var sumTaxaEntregue = 0;
            var sumDesconto = 0;
            var countPC_Online = 0;
            var sumPC_Online = 0;
            var countPC_Presencial = 0;
            var sumPC_Presencial = 0;
            var countPD_Online = 0;
            var sumPD_Online = 0;
            var countPD_Presencial = 0;
            var sumPD_Presencial = 0;
            var sumGeral = 0;

            dados.map( pedido => {
                if(pedido?.status==='PC'){
                    countPedidosCancelados++;
                    sumPedidosCancelados+= parseFloat(pedido?.valor_pedido); 

                    if (pedido?.pagamento == -2 || pedido?.pagamento == -10 || pedido?.pagamento == -11|| pedido?.pagamento == -12) {
                        countPC_Online++;
                        sumPC_Online+= parseFloat(pedido?.valor_pedido);
                    } else {
                        countPC_Presencial++;
                        sumPC_Presencial+= parseFloat(pedido?.valor_pedido);
                    }
                }
                if(pedido?.status==='PD'){
                    countPedidosEfetivados++;
                    sumPedidosEfetivados+= parseFloat(pedido?.valor_pedido);
                    sumTaxaEntregue+= parseFloat(pedido?.valor_taxa);
                    sumDesconto+= parseFloat(pedido?.valor_desconto);
                    sumGeral+= (parseFloat(pedido?.valor_pedido) + parseFloat(pedido?.valor_taxa) - parseFloat(pedido?.valor_desconto));

                    if (pedido?.pagamento == -2 || pedido?.pagamento == -10 || pedido?.pagamento == -11|| pedido?.pagamento == -12) {
                        countPD_Online++;
                        sumPD_Online+= parseFloat(pedido?.valor_pedido);
                    } else {
                        countPD_Presencial++;
                        sumPD_Presencial+= parseFloat(pedido?.valor_pedido);
                    }
                }
              return null
            });
            setPedidosCountCancelados(countPedidosCancelados);
            setPedidosSomaCancelados(sumPedidosCancelados);
            setPedidosCountEfetivados(countPedidosEfetivados);
            setPedidosSomaEfetivados(sumPedidosEfetivados);
            setPedidosSomaEntregues(sumTaxaEntregue);
            setPedidosSomaDesconto(sumDesconto);
            setPedidosCountOnlinePC(countPC_Online);
            setPedidosSomaOnlinePC(sumPC_Online);
            setPedidosCountPresencialPC(countPC_Presencial);
            setPedidosSomaPresencialPC(sumPC_Presencial);
            setPedidosCountOnlinePD(countPD_Online);
            setPedidosSomaOnlinePD(sumPD_Online);
            setPedidosCountPresencialPD(countPD_Presencial);
            setPedidosSomaPresencialPD(sumPD_Presencial);
            setPedidosSomaGeral(sumGeral);
        }


        return (
            <>
    {/*begin::Entry*/}
    <div className="d-flex flex-column-fluid">
        {/*begin::Container*/}
        <div className="container">


            {/*begin card de pesquisa de vendas */}
            <div className="card card-custom">
				<div className="card-header">
					<h3 className="card-title">Relatório Geral</h3>
				</div>
                <div className="card-body">
                    <div className="row justify-content-md-center">
                        <div className="col-md-2 text-center">
                            <h5>Data Inicial</h5>
                            <div>
                            <DatePicker
                                name="dt_inicio"
                                onChange={setDataInicio}
                                isClearable
                                locale="pt-BR"
                                className="form-control"
                                placeholderText="dd/mm/aaaa"
                                selected ={dataInicio}
                                dateFormat="dd/MM/y"
                                customInput={
                                    <InputMask mask="99/99/9999" />}/>
                            </div>
                        </div>
                        <div className="col-md-2 text-center">
                            <h5>Data Final</h5>
                            <div>
                            <DatePicker
                                name="dt_final"
                                onChange={setDataFinal}
                                isClearable
                                locale="pt-BR"
                                className="form-control"
                                placeholderText="dd/mm/aaaa"
                                selected ={dataFinal}
                                dateFormat="dd/MM/y"
                                customInput={
                                    <InputMask mask="99/99/9999" />}/>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <br />
                            <button className="btn btn-primary" onClick={e=>{efetuarPesquisa()}}>Ver Relatório</button>
                        </div>
                    </div>
                </div>
            </div>
            <br />
            {/*begin relatorio de vendas */}
            <div className="card card-custom">
				<div className="card-header">
        <h3 className="card-title">Relatório de vendas do dia {dataInicio_Formatada} ao dia {dataFinal_Formatada}</h3>
				</div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-6">
                        <LoadingOverlay
                            active={caProcessa}
                            spinner
                             text='Carregando...'
                        >
                            <Doughnut data={data}/>
                        </LoadingOverlay>
                        </div>
                        <div className="col-md-6">
                            <LoadingOverlay
                                active={caProcessa}
                                spinner
                                text='Carregando...'
                            >
                            <table className="table">
                                <tr>
                                    <td bgcolor="#F2F2F2"><b>{pedidosCountCancelados} Pedidos Cancelados:</b></td>
                                    <td bgcolor="#F2F2F2" className="text-right"><b>{pedidosSomaCancelados.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</b></td>
                                </tr>
                                <tr>
                                    <td><label className='ml-10'>{pedidosCountOnlinePC} Pedidos <font color="blue">Online:</font></label></td>
                                    <td className="text-right">{pedidosSomaOnlinePC.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</td>
                                </tr>
                                <tr>
                                    <td><label className='ml-10'>{pedidosCountPresencialPC} Pedidos <font color="red">Presencial:</font></label></td>
                                    <td className="text-right">{pedidosSomaPresencialPC.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</td>
                                </tr>
                                <tr>
                                    <td bgcolor="#F2F2F2"><b>{pedidosCountEfetivados} Pedidos Efetivados:</b></td>
                                    <td bgcolor="#F2F2F2" className="text-right"><b>{pedidosSomaEfetivados.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</b></td>
                                </tr>
                                <tr>
                                    <td><label className='ml-10'>{pedidosCountOnlinePD} Pedidos <font color="blue">Online:</font></label></td>
                                    <td className="text-right">{pedidosSomaOnlinePD.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</td>
                                </tr>
                                <tr>
                                    <td><label className='ml-10'>{pedidosCountPresencialPD} Pedidos <font color="red">Presencial:</font></label></td>
                                    <td className="text-right">{pedidosSomaPresencialPD.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</td>
                                </tr>
                                <tr>
                                    <td bgcolor="#F2F2F2">Total em Taxas de Entrega:</td>
                                    <td bgcolor="#F2F2F2" className="text-right">{pedidosSomaEntregues.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</td>
                                </tr>
                                <tr>
                                    <td bgcolor="#F2F2F2">Total de Descontos:</td>
                                    <td bgcolor="#F2F2F2" className="text-right">{pedidosSomaDesconto.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</td>
                                </tr>
                                <tr>
                                    <td bgcolor="#F2F2F2"><b>Total Geral: </b> <i className="fas fa-question-circle" data-tip={"Total Geral = Pedidos Efetivados + Taxa Entrega - Descontos"} /></td>
                                    <td bgcolor="#F2F2F2" className="text-right"><b>{pedidosSomaGeral.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</b></td>
                                </tr>
                            </table>
                            </LoadingOverlay>
                        </div>
                    </div>
                </div>
            </div>

            {/*begin pedidos */}
            <div className="card card-custom">
				<div className="card-header">
					<h3 className="card-title">Pedidos </h3>
                    
                    <div className="text-right">
                    <ReactToPrint
                        trigger={() => <button className="btn btn-lg btn-secondary mt-3 mb-3"><i className="fas fa-print" />Imprimir</button>}
                        content={() => printRef.current}
                    />
                    </div>
				</div>
                <div className="card-body">
                <LoadingOverlay
                            active={caProcessa}
                            spinner
                             text='Carregando...'
                        >
                    <div ref={printRef}>
                      <div className="cabecalho">
                      <table>
                        <tr>
                            <td width="40%">
                            <img alt="Logo" src="assets/media/logos/aiboo.png" className="logo-sticky max-h-45px" />
                            </td>
                            <td width="60%">
                            <h5 className="text-left"><b>Aiboo Delivery Serviços de Venda LTDA</b><br />
                                    Rua Hans L. Kraus, 31 - Cohab - João Neiva/ES<br />
                                    atendimento@aiboo.com.br
                                </h5>
                            </td>
                        </tr>
                        </table>
                        <br />
                        <h3 className="text-center">Relatório de vendas do dia {dataInicio_Formatada} ao dia {dataFinal_Formatada}</h3>
                        <br />
                      </div>
                     
                    <table className="table table-striped">
                        <thead>                       
                            <tr>
                                <th className="text-center">Hash</th>
                                <th>Cliente</th>
                                <th className="text-center">Data</th>
                                <th className="text-center">Valor em Produtos</th>
                                <th className="text-center">Desconto</th>
                                <th className="text-center">Taxa Entrega</th>
                                <th className="text-center">Pagamento</th>
                                <th className="text-center">Status</th>
                            </tr>
                        </thead>

                        <tbody>

                        {
                        pedidos.map( pedido => {
                            var pagamento = '';
                            var status = '';
                            var statusColor = '';
                            var imagemPagamento = '';

                            if(pedido?.pagamento == -2 || pedido?.pagamento == -10 || pedido?.pagamento == -11|| pedido?.pagamento == -12){
                                pagamento = 'Online'
                                imagemPagamento = 'fas fa-link text-danger';
                            } else if(pedido?.pagamento == -1){
                                pagamento = 'Dinheiro';
                                imagemPagamento = 'far fa-money-bill-alt text-success';
                            } else {
                                pagamento = 'Cartão';
                                imagemPagamento = 'far fa-credit-card text-dark';
                            }

                            switch(pedido?.status) {
                                case 'PP':
                                case 'PENDING_ORDER':

                                    status = 'Pendente';
                                    break;

                                case 'PI':
                                case 'PRINTED_ORDER':

                                    status = 'Impresso';
                                    break;

                                case 'PA':
                                case 'ACCEPTED_ORDER':

                                    status = 'Aceito';
                                    break;

                                case 'PICKUP_READY':

                                    status = 'Pronto para retirada';
                                    break;

                                case 'PE':
                                case 'SHIPPED_ORDER':

                                    status = 'Enviado';
                                    break;

                                case 'PD':
                                case 'DELIVERED_ORDER':

                                    status = 'Entregue';
                                    statusColor = 'success';
                                    break;

                                case 'PC':
                                case 'CANCELED_ORDER':

                                    status = 'Cancelado';
                                    statusColor = 'danger';
                                    break;
                                    
                                default:
                                    status = 'Status desconhecido';
                                    statusColor = 'danger';

                            }
                            

                            return(
                            <><ReactTooltip />
                            <tr>
                                
                                <td>{pedido.hash_pedido}</td>
                                <td>{pedido.usuario_nome}</td>
                                <td className="text-center">{pedido.data_criacao}</td>
                                <td className="text-right">{pedido.valor_pedido}</td>
                                <td className="text-right">{pedido.valor_desconto}</td>
                                <td className="text-right">{pedido.valor_taxa}</td>
                                <td className="text-center">
                                    <i className={""+imagemPagamento+""} data-tip={""+pagamento+""} />
                                </td>
                                <td className="text-center">
                                    <span className={"label label-inline label-light-"+statusColor+" font-weight-bold"}>{status}</span>
                                </td>
                            </tr>
                            </>)
                        })}

                        </tbody>
                    </table>
                    </div>

                    </LoadingOverlay>
                </div>
            </div>

        </div>
    </div>
    </>

    );

}

export default withRouter(Relatorio);
