import React from "react";
import * as S from "./styles";

export const Checkbox = ({ className, checked, ...props }) => {
  const [internalChecked, setInternalChecked] = React.useState(!!checked);
  const ref = React.useRef();
  const finalChecked = checked !== undefined ? checked : internalChecked;

  return (
    <S.CheckboxContainer className={className}>
      <S.HiddenCheckbox checked={finalChecked} {...props} ref={ref} />
      <S.StyledCheckbox
        checked={finalChecked}
        onClick={() => {
          setInternalChecked(!internalChecked);
          ref?.current?.click();
        }}
      >
        <S.Icon viewBox="0 0 24 24">
          <polyline points="20 6 9 17 4 12" />
        </S.Icon>
      </S.StyledCheckbox>
    </S.CheckboxContainer>
  );
};
