import React, { useEffect, useMemo } from "react";

import Routes from "./routes";

import useUpdateElectron from "./hooks/useUpdateElectron";
import {
  showSupportChat,
  setupSupportChat,
  setUserDataToSupportChat,
} from "./helpers/supportChat";

import Update from "./components/Update";

//const ipcRenderer = window.require("electron").ipcRenderer;  //contextIsolation: false

setupSupportChat();

if (localStorage.getItem("hash")) {
  setUserDataToSupportChat();
  showSupportChat();
}

if (process.env.REACT_APP_USE_ELECTRON) {
  console.log("Using Electron");
  console.log(process.env.REACT_APP_USE_ELECTRON);
}

function App({ updateAvailable }) {
  useEffect(() => {
    const observer = new MutationObserver(() => {
      const persistentIframe = document.querySelector(
        'iframe[style*="z-index: 2147483647"]'
      );

      if (persistentIframe) {
        persistentIframe.remove();
      }
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });

    return () => observer.disconnect();
  }, []);

  return updateAvailable ? <Update /> : <Routes />;
}

const AppWrapper = () => {
  const { updateAvailable } = useUpdateElectron();

  const updateAvailableState = useMemo(
    () => updateAvailable,
    [updateAvailable]
  );

  return <App updateAvailable={updateAvailableState} />;
};

export default AppWrapper;
